import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { IconButtonAnimate } from '../../../components/animate';
import './news.styles.css';
import { styled } from '@mui/material/styles';

export default function News() {
  const { t } = useTranslation();

  const onClick = () => {
    window.$sleek.toggle('tiny.changelog');
  };

  return (
    <Stack>
      <IconButtonAnimateCustom
        onClick={onClick}
        sx={{
          width: 40,
          height: 40,
        }}
        data-badge-changelog
        data-sleek-changelog
      >
        <Box>🥳</Box>
      </IconButtonAnimateCustom>
    </Stack>
  );
}

const IconButtonAnimateCustom = styled(IconButtonAnimate)`
  color: #fff !important;
  ::after {
    right: 2px !important;
    top: 8px !important;
    background-color: #f45757 !important;
  }
`;
