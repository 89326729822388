import { Stack } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';

export default function Loader() {
  return (
    <Stack>
      <Skeleton
        variant="text"
        sx={{
          height: 80,
          width: '70%',
          borderRadius: 1,
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          height: 80,
          width: '70%',
          borderRadius: 1,
        }}
      />
      <Skeleton
        variant="text"
        height={80}
        sx={{
          width: '60%',
          borderRadius: 1,
          alignSelf: 'flex-end',
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          height: 80,
          width: '70%',
          borderRadius: 1,
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          height: 80,
          width: '80%',
          borderRadius: 1,
          alignSelf: 'flex-end',
        }}
      />
    </Stack>
  );
}
