import React, { useState } from 'react';
import HoverCard from '../../../../components/simply/Cards/HoverCard';
import { Stack, Box, useTheme, Typography, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SeverErrorIllustration } from '../../../../assets';
import Iconify from '../../../../components/Iconify';
import { Transition } from '../../../../sections/@dashboard/user/initial/InitialSettings';
import SendToAFriendForm from '../SendToAFriendForm';

interface Props {
  widgetId: string;
}

export default function SendInstructionCard({ widgetId }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <HoverCard sx={{ p: 2, pr: 4, cursor: 'pointer' }} onClick={() => setIsModalOpen(true)}>
        <Stack direction="row" spacing={2}>
          <Stack>
            <Box
              sx={{
                width: 120,
                height: 72,
                backgroundColor: theme.palette.primary.lighter,
                borderRadius: 2,
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <SeverErrorIllustration />
            </Box>
          </Stack>
          <Stack justifyContent="center">
            <Typography variant="h6">{t('widgets.setup.sendToFriend.headline')}</Typography>
            <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
              {t('widgets.setup.sendToFriend.description')}
            </Typography>
          </Stack>
          <Stack flexGrow={1} justifyContent="center" alignItems="flex-end">
            <Iconify
              sx={{ width: 32, height: 32 }}
              icon="material-symbols:arrow-outward-rounded"
              color={theme.palette.primary.main}
            />
          </Stack>
        </Stack>
      </HoverCard>
      <Dialog
        open={isModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="email-a-friend-widget"
      >
        {isModalOpen && <SendToAFriendForm widgetId={widgetId} handleClose={handleCloseModal} />}
      </Dialog>
    </>
  );
}
