import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userSlice from './slices/userSlice';
import usersSlice from './slices/usersSlice';
import snackbarSlice from './slices/snackbarSlice';
import reviewSlice from './slices/reviewSlice';
import integrationsSlice from './slices/integrationSlice';
import integrationsFlowSlice from './slices/integrationFlowSlice';
import notificationSlice from './slices/notificationSlice';
import drawerSlice from './slices/drawerSlice';
import onboardingSlice from './slices/onboarding';
import publicConfigSlice from './slices/publicConfig';
import inviteSlice from './slices/invites';
import inviteRemindersSlice from './slices/inviteReminders';
import platformSlice from './slices/platform';
import modal from './slices/modal';
import workspaceReviewsSlice from './slices/workspaceReviewsSlice';
import workspaceFlowSlice from './slices/workspaceFlowSlice';
import workspaceLeadsSlice from './slices/workspaceLeadsSlice';
import workspaceLinksSlice from './slices/workspaceLinksSlice';
import workspaceBookingsSlice from './slices/workspaceBookingsSlice';
import teamInvitesSlice from './slices/teamInviteSlice';
import workspaceTeamInvitesSlice from './slices/workspaceTeamInvitesSlice';
import apiIntegrationsSlice from './slices/apiIntegrationsSlice';
import leadFormsSlice from './slices/leadFormsSlice';
import leadFormsSubmissionsSlice from './slices/leadFormsSubmissionSlice';
import automationSlice from './slices/automationsSlice';
import contentSlice from './slices/contentSlice';
import bookingSchedulesSlice from './slices/bookingSchedules';
import conversationsSlice from './slices/chat';
import workspaceChatsSlice from './slices/workspaceChatsSlice';
import stripeSlice from './slices/stripeSlice';
import bookingsSlice from './slices/bookingsSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  // chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,

  user: userSlice,
  drawer: drawerSlice,
  snackbar: snackbarSlice,
  notifications: notificationSlice,
  reviews: reviewSlice,
  invites: inviteSlice,
  inviteReminders: inviteRemindersSlice,
  integrations: integrationsSlice,
  integrationsFlow: integrationsFlowSlice,
  onboarding: onboardingSlice,
  publicConfig: publicConfigSlice,
  modal,
  platform: platformSlice,
  users: usersSlice,
  workspaceReview: workspaceReviewsSlice,
  workspaceFlow: workspaceFlowSlice,
  workspaceLeads: workspaceLeadsSlice,
  workspaceLinks: workspaceLinksSlice,
  workspaceBookings: workspaceBookingsSlice,
  workspaceChats: workspaceChatsSlice,
  teamInvites: teamInvitesSlice,
  workspaceTeamInvites: workspaceTeamInvitesSlice,
  apiIntegrations: apiIntegrationsSlice,
  leadForms: leadFormsSlice,
  leadFormsSubmissions: leadFormsSubmissionsSlice,
  automations: automationSlice,
  product: persistReducer(productPersistConfig, productReducer),
  content: contentSlice,
  bookingSchedules: bookingSchedulesSlice,
  bookings: bookingsSlice,
  conversations: conversationsSlice,
  stripe: stripeSlice,
});

export { rootPersistConfig, rootReducer };
