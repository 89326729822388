import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { goToBillingPortal } from '..';
import { useTranslation } from 'react-i18next';

export default function ManageSubscription() {
  const { t } = useTranslation();
  const [billingLoading, setBillingLoading] = useState(false);
  return (
    <LoadingButton
      size="large"
      fullWidth
      variant="contained"
      loading={billingLoading}
      onClick={() => {
        setBillingLoading(true);
        goToBillingPortal();
      }}
    >
      {t('billing.managePlan')}
    </LoadingButton>
  );
}
