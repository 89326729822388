import { useEffect } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { Invite, setInviteLoadingState, setInvites } from '../redux/slices/invites';
import { convertFStoDate } from '../utils/formatTime';

// if passed here it will load X users reviews
export default function useInvites() {
  const dispatch = useDispatch();
  const workspaceId = useSelector((state) => state.workspaceReview.currentWorkspace?.id);

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId) {
      dispatch(setInviteLoadingState());
      const q = query(
        collection(DB, getCollection[Collection.USERS_INVITES](workspaceId)),
        orderBy('createTime', 'desc'),
        limit(100)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const invites = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            createTime: data.updateTime
              ? convertFStoDate(data.updateTime)
              : convertFStoDate(data.createTime),
          } as Invite;
        });

        dispatch(setInvites(invites));
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
}
