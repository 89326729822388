import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, DialogActions, Stack } from '@mui/material';
import { useSelector } from '../redux/store';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Functions } from '../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { selectCurrentPlatformInvites } from '../redux/selectors/invites';

export default function AcceptInvite({ handleClose }: { handleClose: () => void }) {
  const { t } = useTranslation();
  const [declineId, setDeclineId] = useState<string | null>(null);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ids = useSelector(selectCurrentPlatformInvites);

  useEffect(() => {
    if (ids.length === 0) {
      handleClose();
    }
  }, [ids.length]);

  const handleAccept = async (inviteId: string) => {
    try {
      setIsAccepting(true);
      const functionRef = httpsCallable(Functions, 'acceptTeamInvite');
      const response: any = await functionRef({
        inviteId,
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        enqueueSnackbar(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
          { variant: 'error' }
        );
        return;
      }
      enqueueSnackbar(t('team.accept.member.accept.success'), { variant: 'success' });
    } catch (error) {
      console.error(error);
    } finally {
      setIsAccepting(false);
    }
  };
  const handleDecline = async () => {
    try {
      setIsDeclining(true);
      const functionRef = httpsCallable(Functions, 'voidTeamInvite');
      const response: any = await functionRef({
        inviteId: declineId,
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        enqueueSnackbar(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
          { variant: 'error' }
        );
        return;
      }
      enqueueSnackbar(t('team.accept.member.decline.success'), { variant: 'success' });
      setDeclineId(null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeclining(false);
    }
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">{t('team.accept.member.title')}</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Box sx={{ mt: 2 }} />
        <Stack sx={{ width: '100%' }}>
          {ids.map((invite) => (
            <>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack>{t('team.accept.member.body', { companyName: invite.companyName })}</Stack>
                <Stack direction="row" spacing={2}>
                  <LoadingButton
                    variant="contained"
                    loading={isAccepting}
                    onClick={() => handleAccept(invite.id)}
                  >
                    {t('general.accept')}
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    disabled={isAccepting}
                    color="error"
                    onClick={() => setDeclineId(invite.id)}
                  >
                    {t('general.decline')}
                  </LoadingButton>
                </Stack>
              </Stack>
              <Box sx={{ mt: 2 }} />
            </>
          ))}
        </Stack>
      </DialogContent>
      <Dialog open={Boolean(declineId)} onClose={() => setDeclineId(null)}>
        <DialogTitle id="alert-dialog-title">
          🚨{t('team.accept.member.confirm.title')}🚨
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('team.accept.member.confirm.body')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeclineId(null)}>{t('general.no')}</Button>
          <LoadingButton
            onClick={handleDecline}
            loading={isDeclining}
            variant="contained"
            color="error"
          >
            {t('general.yes')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
