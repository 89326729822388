import { useState } from 'react';
import lenus from '../../../../../assets/integrations/lenus.png';
import {
  Card,
  Stack,
  DialogContent,
  TextField,
  Box,
  InputAdornment,
  Dialog,
  Link,
} from '@mui/material';
import Image from '../../../../../components/Image';
import { LoadingButton } from '@mui/lab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { setDoc, doc } from 'firebase/firestore';
import { DB } from '../../../../../contexts/FirebaseContext';
import { getCollection } from '../../../../../api';
import { Collection } from '../../../../../enum';
import { selectCurrentPlatformWorkspaceId } from '../../../../../redux/selectors/workspaces';
import { useSelector } from '../../../../../redux/store';
import { ApiIntegrationStatus } from '../../../../../redux/slices/apiIntegrationsSlice';
import * as Sentry from '@sentry/react';
import { selectUser } from '../../../../../redux/slices/userSlice';

export default function Connect() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState('');
  const handleOpen = () => {
    Sentry.captureMessage(
      `User wants to be notified when Lenus integration is ready, workspaceId=${workspaceId} platform=${user.platform} userId=${user.id}`
    );
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const onConnect = async () => {
    // try {
    //   setError('');
    //   if (!apiKey) {
    //     return setError('apiKey.required');
    //   }
    //   if (!workspaceId) {
    //     throw new Error('Workspace ID is missing');
    //   }
    //   setLoading(true);
    //   // TODO: test if the API key is valid
    //   await new Promise((resolve) => setTimeout(resolve, 2000));
    //   // TODO: save the API key or throw error (replace with API call)
    //   await setDoc(
    //     doc(DB, getCollection[Collection.API_INTEGRATIONS](workspaceId, 'lenus', 'readable')),
    //     {
    //       apiKey,
    //       status: ApiIntegrationStatus.STABLE,
    //       createTime: new Date(),
    //       userId: workspaceId,
    //       readable: true,
    //     }
    //   );
    //   enqueueSnackbar(t('apiKey.connect.success'), { variant: 'success' });
    //   setLoading(false);
    //   setOpen(false);
    // } catch (error) {
    //   setLoading(false);
    //   setError(error.message);
    //   enqueueSnackbar(t('general.error'));
    //   console.error(error);
    // }
  };
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Stack alignItems="center" py={3}>
            <Image src={lenus} alt="lenus" sx={{ height: 43 }} />
          </Stack>
          <LoadingButton type="submit" variant="contained" onClick={handleOpen}>
            Connect Lenus
          </LoadingButton>
        </Stack>
      </Card>
      <Dialog keepMounted open={open} onClose={handleClose}>
        <DialogTitle mb={2}>Connect Lenus</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Lenus integration is not yet ready, let us notify you when it is 🤩
          </DialogContentText>
        </DialogContent>
        {/* <DialogContent>
          <DialogContentText>{t('integrations.lenus.description')}</DialogContentText>
          <TextField
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            autoFocus
            InputProps={{
              startAdornment: loading ? (
                <InputAdornment position="start">
                  <CircularProgress size={16} />
                </InputAdornment>
              ) : null,
            }}
            disabled={loading}
            margin="dense"
            id="name"
            label={t('integrations.lenus.apiKey.label')}
            type="text"
            fullWidth
            error={Boolean(error)}
            helperText={t(error)}
          />
          <Box mt={2} />
          <DialogContentText>
            {t('integrations.lenus.helper.title')}
          </DialogContentText>

          <Typography variant="body2" color="">
            <ol style={{ marginLeft: 32, color: theme.palette.grey[500] }}>
              <li>
                {t('integrations.lenus.helper.step1.part1')}{' '}
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      'https://app.lenusapp.com/dashboard/settings/integrations',
                      '_blank'
                    )
                  }
                >
                  {t('integrations.lenus.helper.step1.part2')}
                </Link>
              </li>
              <li>{t('integrations.lenus.helper.step2')}</li>
              <li>{t('integrations.lenus.helper.step3')}</li>

              <li>{t('integrations.lenus.helper.step4')}</li>

              <li>{t('integrations.lenus.helper.step5')}</li>

              <li>{t('integrations.lenus.helper.step6')}</li>

              <li>{t('integrations.lenus.helper.step7')}</li>
            </ol>
          </Typography>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>{t('general.cancel')}</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => {
              enqueueSnackbar("We'll notify you when the integration is ready");
              setOpen(false);
            }}
          >
            Notify me
          </LoadingButton>
          {/* <LoadingButton loading={loading} variant="contained" onClick={onConnect}>
            Connect
          </LoadingButton> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
