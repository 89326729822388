import { useEffect, useState } from 'react';
import { query, collection, where, limit, getDocs } from 'firebase/firestore';
import { Collection, Intl } from '../enum';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { PublicConfig, setPublicConfig, selectPublicConfig } from '../redux/slices/publicConfig';
import { useDispatch, useSelector } from 'react-redux';

export default function usePublicSlug(slug?: string) {
  const dispatch = useDispatch();
  const [isLoadingPublicConfig, setIsLoadingPublicConfig] = useState(true);
  // const [config, setConfig] = useState<PublicConfig | null>(null);
  const config = useSelector(selectPublicConfig);

  useEffect(() => {
    let unsubscribe: () => void;
    const getPublicSlug = async () => {
      try {
        if (!slug) throw new Error('Invalig slug');
        const q = query(
          collection(DB, getCollection[Collection.PUBLIC]()),
          where('slug', '==', slug),
          limit(1)
        );
        const querySnapshot = await getDocs(q);
        const [publicConfig] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as PublicConfig),
        }));

        dispatch(setPublicConfig(publicConfig));
        // setConfig(publicConfig || null);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingPublicConfig(false);
      }
    };
    getPublicSlug();

    return () => {
      unsubscribe?.();
    };
  }, [slug, dispatch]);
  return {
    isLoadingPublicConfig,
    userId: config?.userId,
    title: config?.title,
    description: config?.description,
    questions: config?.questions,
    logo: config?.logo?.original || config?.companyLogo,
    companyPageLogo: config?.companyPageLogo?.original || config?.companyPageLogoTmp,
    color: config?.color,
    onlyVideo: config?.onlyVideo || false,
    videoIntro: config?.videoIntro,
    videoProcessed: config?.videoProcessed,
    companyName: config?.companyName || '',
    reviewerPageLanguage: config?.reviewerPageLanguage || Intl.EN,
  };
}
