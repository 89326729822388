import React, { useState } from 'react';
import reviewData from './dummyData.json';
import { Card } from '@mui/material';
import Word from './Word';
import { Stack } from '@mui/material';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import { Ratings, Verified } from '@simplylabs_/shared';

export default function DummyReview() {
  const [index, setIndex] = useState(0);

  const highlightColor = '#FFA0A0';

  const handleNext = () => {
    if (index === reviewData.highlights.length - 1) {
      setIndex(0);
      return;
    }
    setIndex(index + 1);
  };

  const handlePrevious = () => {
    if (index === 0) {
      setIndex(reviewData.highlights.length - 1);
      return;
    }
    setIndex(index - 1);
  };

  const wordList = reviewData.highlights[index].full.split(' ');
  const firstPart = reviewData.highlights[index].part[0];
  const lastPart = reviewData.highlights[index].part[1];
  const words = wordList.map((word, index) => {
    const isHighlighted = index >= firstPart && index <= lastPart;
    const isFirst = index === firstPart;
    const isLast = index === lastPart;
    return (
      <Word
        key={index}
        word={word}
        isHighlighted={isHighlighted}
        isFirst={isFirst}
        isLast={isLast}
        highlightColor={highlightColor}
      />
    );
  });

  return (
    <Stack flexDirection="row">
      <Button onClick={handlePrevious}>H</Button>
      <Card sx={{ aspectRatio: '1/1', p: 4, backgroundColor: '#FFC8C8' }}>
        <h4 style={{ textAlign: 'center', fontWeight: 300, fontSize: 16 }}>{reviewData.title}</h4>
        <div
          style={{
            display: 'flex',
            height: '90%',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: -40,
          }}
        >
          <div style={{ fontSize: 42, lineHeight: 1 }}>“</div>
          <p style={{ margin: 0, fontSize: 22, fontWeight: 700 }}>...{words}...</p>
        </div>
        <div style={{ paddingLeft: 4, paddingRight: 4 }}>
          <Stack direction="row" alignItems="center">
            <div>{reviewData.name}</div>
            <Box sx={{ ml: 0.5 }} />
            <Verified />
          </Stack>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: -2,
              marginTop: 4,
            }}
          >
            <Ratings size="small" rating={reviewData.rating} />
          </div>
        </div>
      </Card>
      <Button onClick={handleNext}>H</Button>
    </Stack>
  );
}
