import { useRef, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Input, Divider, IconButton, InputAdornment, CircularProgress } from '@mui/material';
// @types
import { SendMessage } from '../../../@types/chat';
// utils
import uuidv4 from '../../../utils/uuidv4';
// components
import Iconify from '../../../components/Iconify';
import EmojiPicker from '../../../components/EmojiPicker';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { upload } from 'src/hooks/useUpload';
import { IMAGE_METADATA_TYPE } from 'src/enum';
import { useSelector } from 'src/redux/store';
import { VisuallyHiddenInput } from 'src/components/support/SubmitMessage';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}));

// ----------------------------------------------------------------------

type Props = {
  disabled: boolean;
  workspaceId: string;
  conversationId: string | null;
  onSend: (data: SendMessage) => void;
};

export default function ChatMessageInput({ disabled, workspaceId, conversationId, onSend }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');
  const user = useSelector((state) => ({
    uid: state.user.id as string,
    displayName: state.user.displayName ?? null,
    photoURL: state.user.photoURL ?? null,
  }));
  const handleAttach = () => {
    fileInputRef.current?.click();
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey
    ) {
      handleSend();
    }
  };

  const handleSend = () => {
    if (!message) {
      return '';
    }
    if (onSend && conversationId) {
      onSend({
        conversationId,
        messageId: uuidv4(),
        message,
        contentType: 'text',
        attachments: [],
        createdAt: new Date(),
        senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
      });
    }
    return setMessage('');
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.[0];
    if (image) {
      try {
        if (!workspaceId || !conversationId) return;
        setUploading(true);
        // Handle file processing here
        const messageRef = doc(
          collection(DB, 'workspaceChats', workspaceId, 'conversations', conversationId, 'messages')
        );

        const response = await upload(
          image,
          `workspaceChats/${workspaceId}/conversations/${conversationId}/`,
          {
            userId: workspaceId,
            conversationId,
            messageId: messageRef.id,
            type: IMAGE_METADATA_TYPE.CHAT_IMAGE,
          }
        );
        const imageUrl = response?.url;

        if (!imageUrl) {
          throw new Error(`Error uploading image ${JSON.stringify(response)}`);
        }
        setDoc(messageRef, {
          createTime: new Date(),
          updateTime: new Date(),
          message: '',
          image: {
            original: imageUrl ?? null,
          },
          sender: {
            displayName: user.displayName,
            photoURL: user.photoURL,
            uid: user.uid,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <RootStyle>
      <Input
        disabled={disabled}
        fullWidth
        multiline
        maxRows={4}
        value={message}
        disableUnderline
        onKeyUp={handleKeyUp}
        onChange={(event) => setMessage(event.target.value)}
        placeholder="Type a message (shift+enter for new line)"
        startAdornment={
          <InputAdornment position="start">
            <EmojiPicker disabled={disabled} value={message} setValue={setMessage} />
          </InputAdornment>
        }
        endAdornment={
          <Stack direction="row" spacing={1} sx={{ flexShrink: 0, mr: 1.5 }}>
            <IconButton component="label" disabled={uploading || disabled} size="small">
              {uploading ? (
                <CircularProgress size={16} sx={{ color: '#001D06' }} />
              ) : (
                <>
                  <Iconify icon="ph:image" />
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleFileChange} />
                </>
              )}
            </IconButton>
            {/* <IconButton disabled={disabled} size="small" onClick={handleAttach}>
              <Iconify icon="eva:attach-2-fill" width={22} height={22} />
            </IconButton>
            <IconButton disabled={disabled} size="small">
              <Iconify icon="eva:mic-fill" width={22} height={22} />
            </IconButton> */}
          </Stack>
        }
      />

      <Divider orientation="vertical" flexItem />

      <IconButton color="primary" disabled={!message} onClick={handleSend} sx={{ mx: 1 }}>
        <Iconify icon="ic:round-send" width={22} height={22} />
      </IconButton>

      {/* <input type="file" ref={fileInputRef} style={{ display: 'none' }} /> */}
    </RootStyle>
  );
}
