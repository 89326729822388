import React from 'react';
import Image from './Image';
import { Box, Stack } from '@mui/material';
import { range } from 'lodash';
import Iconify from '@simplytech/simply.general.iconify';

export default function VideothumbnailPicker({
  thumbFrame = 2,
  videoPlaybackId,
  onSelectFrame,
}: {
  thumbFrame?: number;
  videoPlaybackId: string;
  onSelectFrame: (frame: number) => void;
}) {
  return (
    <Stack direction="row" columnGap={0} sx={{ overflowX: 'scroll' }}>
      {range(0, 10).map((frame) => (
        <Box
          sx={{
            position: 'relative',
            width: 100,
            transition: 'all 0.2s ease-in-out',
            cursor: 'pointer',
            '&:hover': {
              filter: 'brightness(1.1)',
            },
          }}
          onClick={() => onSelectFrame(frame)}
        >
          <Image
            sx={{ display: 'flex', width: 100, aspectRatio: '9/16' }}
            src={`https://image.mux.com/${videoPlaybackId}/thumbnail.png?time=${frame}`}
          />
          {thumbFrame === frame && (
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                zIndex: 12,
                bottom: 4,
                right: 4,
              }}
            >
              <Iconify icon="carbon:checkmark-filled" fontSize={32} color="primary.light" />
            </Box>
          )}
        </Box>
      ))}
    </Stack>
  );
}
