import { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import Iconify from '../../components/Iconify';
import uuidv4 from '../../utils/uuidv4';
import Button from '@mui/material/Button';
import DraggableOptions from './components/DraggableOptions';
import { useSelector } from 'src/redux/store';
import { selectLeadFormSuggestions } from 'src/redux/slices/leadFormsSlice';
import { useTranslation } from 'react-i18next';

export default function Options({
  id,
  options,
  setOptions,
}: {
  id: string;
  options: any[];
  setOptions: (items: any[]) => void;
}) {
  const { t } = useTranslation();
  const suggestionsMap = useSelector(selectLeadFormSuggestions);

  const texts = options.map((o) => o.content);
  // make sure it is an array and the AI didn't fuck up
  const suggestions = Array.isArray(suggestionsMap[id]) ? suggestionsMap[id] : [];
  const nextSuggestion = suggestions?.filter((s) => !texts.includes(s))?.[0] || '';
  return (
    <>
      <DraggableOptions initialItems={options} onChange={setOptions} />
      <Button
        startIcon={<Iconify icon="material-symbols:add-circle-outline" />}
        onClick={() => setOptions([...options, { id: uuidv4(), content: nextSuggestion }])}
      >
        {t('leads.form.answeradd.button')}
      </Button>
    </>
  );
}
