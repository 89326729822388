import { useLottie } from 'lottie-react';
import successAnimation from './success.json';
import planeAnimation from './plane.json';
import videoOptimizingAnimation from './videoOptimizing.json';
import aiLoader from './ai.json';
import zenWait from './zen-wait.json';

const Lottie = ({ animation }: any) => {
  const options = {
    animationData: animation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return View;
};

export default Lottie;

export { successAnimation, planeAnimation, videoOptimizingAnimation, aiLoader, zenWait };
