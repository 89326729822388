import React from 'react';
import phone from './iphone_blank.png';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from '../../redux/store';
import { LanguageCode } from '../../enum';
import { useTranslation } from 'react-i18next';
import { getLocalFromLngCode } from '../../locales/helper';

export default function PhonePlaceholder({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const slug = useSelector((state) => state.publicConfig.slug);
  const link = `https://app.simplyreview.com/r/${slug}`;
  //   const locale = getLocalFromLngCode(language);

  return (
    <Stack direction="row" justifyContent="center" sx={{ position: 'relative' }}>
      {children}
      <img src={phone} style={{ width: '43%' }} alt="sms-template-preview" />
    </Stack>
  );
}
