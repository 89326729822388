import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'src/redux/store';
import { Box, Stack, Typography } from '@mui/material';
import { Message } from 'src/hooks/useMessages';
import { grey } from '@mui/material/colors';
import { getFirstName } from 'src/utils/names';
import Avatar from '../Avatar';
import { formatMessageDate } from './formatChatTime';
import { RequestState } from 'src/enum';
import Loader from './Loader';
import { ErrorIcon } from 'src/theme/overrides/CustomIcons';

export default function MessageScrollView({
  messages,
  isLoadingMessages,
  hasErrorMsg,
  conversationId,
}: {
  messages: Message[];
  isLoadingMessages: boolean;
  hasErrorMsg: boolean;
  conversationId: string | null;
}) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const userId = useSelector((state) => state.user.id);
  const isLoadingConversations = useSelector(
    (state) =>
      state.conversations.state === RequestState.IDLE ||
      state.conversations.state === RequestState.PENDING
  );
  const hasErrorConvo = useSelector((state) => state.conversations.state === RequestState.REJECTED);

  const isLoading = isLoadingConversations || conversationId ? isLoadingMessages : false;
  const hasError = hasErrorConvo || hasErrorMsg;
  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [messages]);
  // const grouped = groupMessages(messages);

  return (
    <div className="chat-container" ref={scrollRef}>
      <div className="message-list">
        {isLoading && <Loader />}
        {hasError && (
          <Stack height={200} alignItems="center" justifyContent="center">
            <ErrorIcon color="error" style={{ fontSize: 48 }} />
            <Typography variant="body1" color="error">
              Something went wrong
            </Typography>
          </Stack>
        )}
        {(!conversationId || messages?.length < 1) && (
          <Stack sx={{ height: '50vh' }} alignItems="center" justifyContent="center">
            <Box sx={{ fontSize: 48 }}>👋</Box>
            <Typography align="center" variant="body1">
              Ask any question regarding
              <br /> features or support
            </Typography>
          </Stack>
        )}
        {!isLoading &&
          !hasError &&
          messages
            .sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime())
            .map((m, i) => {
              const isMe = m.sender.uid === userId;
              // check if previous message is same sender
              // Same sender as next message
              const isSameSender =
                i < messages.length - 1 && m.sender.uid === messages[i + 1].sender.uid;

              // check if next message is within 5 minutes
              const isWithin5Minutes =
                i > 0 &&
                new Date(m.createTime).getTime() - new Date(messages[i - 1].createTime).getTime() <
                  300000;
              return (
                <>
                  {!isWithin5Minutes && (
                    <Typography
                      sx={{
                        color: grey[500],
                        alignSelf: isMe ? 'flex-end' : 'flex-start',
                        fontSize: 10,
                      }}
                    >
                      {formatMessageDate(m.createTime)}
                    </Typography>
                  )}

                  <div key={m.id} className={isMe ? 'message me' : 'message'}>
                    {m.message}
                    {m?.image && (
                      <img
                        src={m.image?.thumbnail || m.image?.original}
                        style={{ maxWidth: 200, maxHeight: 200, borderRadius: 8 }}
                      />
                    )}
                  </div>

                  {!isSameSender && !isMe && (
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Avatar sx={{ width: 20, height: 20 }} src={m.sender.photoURL || ''} />{' '}
                      <Typography fontSize={12}>
                        {getFirstName(m.sender.displayName || '')}
                      </Typography>
                    </Stack>
                  )}
                </>
              );
            })}
      </div>
    </div>
  );
}
