import {
  AppBar,
  Dialog,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Toolbar,
} from '@mui/material';
import { Stack } from '@mui/material';
import { Button, Grid } from '@mui/material';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppMessage } from 'src/components/InAppMessages';
import useAdminAppMessages from 'src/components/InAppMessages/useAdminAppMessages';
import Label from 'src/components/Label';
import { DB } from 'src/contexts/FirebaseContext';
import { Transition } from 'src/sections/@dashboard/user/initial/InitialSettings';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';

export default function AppMessages() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { appMessages } = useAdminAppMessages();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const toggleActive = async (messageId: string, active: boolean) => {
    try {
      if (!messageId) {
        throw new Error('Title and body are required');
      }
      await updateDoc(doc(DB, 'appMessages', messageId), {
        active,
      });
      enqueueSnackbar(t('general.success.default'));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };
  return (
    <>
      <Stack sx={{ p: 2 }}>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Add App Message
        </Button>
      </Stack>
      <Grid container spacing={1}>
        {appMessages.map((m, index) => (
          <Grid item xs={12} md={4} lg={4}>
            <AppMessage index={index} key={m.id} m={m} onDismiss={() => {}} preview />
            <FormControlLabel
              control={<Switch checked={m.active} onChange={() => toggleActive(m.id, !m.active)} />}
              label={`${m.active ? 'Active' : 'Inactive'}`}
            />
          </Grid>
        ))}
      </Grid>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <AppMessagePreview onClose={handleClose} />
      </Dialog>
    </>
  );
}

function AppMessagePreview({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [active, setActive] = useState(false);
  const [title, setTitle] = useState('##### Tip: Reviews now on mobile! ⭐️📱');
  const [body, setMessage] = useState(
    'You can now manage your reviews from your phone 🥳 and get push notifications when a new review is received<br><br> Download/update the app here: (or search for SimplyLabs) <br> - [iOS app](https://apps.apple.com/dk/app/simplylabs/id6479582831) (version 1.0.4)<br> - [Android app](https://play.google.com/store/apps/details?id=com.simplylabs.simply) (version 1.0.4)<br><br>  *Should you find any bugs please let us know so we can fix it ASAP* 🙏'
  );

  const submitMessage = async () => {
    try {
      if (!title || !body) {
        throw new Error('Title and body are required');
      }
      await addDoc(collection(DB, 'appMessages'), {
        createTime: new Date(),
        title,
        body,
        active,
      });
      enqueueSnackbar(t('general.success.default'));
      onClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };
  return (
    <Stack sx={{ p: 6 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6} sx={{ borderRight: '1px solid #f0f0f0' }}>
          <Stack alignItems="center" justifyContent="center" p={4}>
            <Stack alignSelf="flex-end">
              <FormControlLabel
                control={<Switch checked={active} onChange={() => setActive(!active)} />}
                label={`${active ? 'Active' : 'Inactive'}`}
              />
            </Stack>
            <TextField
              placeholder="Title"
              value={title}
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
            />
            <Stack mt={2} />
            <TextField
              placeholder="Message"
              fullWidth
              multiline
              value={body}
              rows={4}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button fullWidth variant="contained" sx={{ mt: 2 }} onClick={submitMessage}>
              Add message
            </Button>
          </Stack>
          <Stack p={4}>
            You can use markdown to format the message like:
            <pre style={{ backgroundColor: '#f0f0f0', padding: 16, borderRadius: 8 }}>
              {`
- # (Title)
- ## (Subtitle)
- ### (Sub-subtitle)
- #### (Sub-sub-subtitle)
- ##### (Sub-sub-sub-subtitle)
- <br/> (New line)
- **Bold** (Bold text)
- *Italic* (Italic text)
- [Link](https://link.com) (Link)
- \`code\` (Code)
- \`\`\` (Code block)
- > (Quote)
- - (List)

`}
            </pre>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Stack alignSelf="flex-end">
            <Label variant={'ghost'} color={'success'} sx={{}}>
              Preview
            </Label>
          </Stack>
          <Stack alignItems="center" justifyContent="center" p={4}>
            <AppMessage index={0} m={{ id: '1', title, body }} onDismiss={() => {}} preview />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
