import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';

export enum LeadActivityType {
  NOTE = 1,
  CALL = 2,
  EMAIL = 3,
  MEETING = 4,
}

export type LeadActivity = {
  id: string;
  content: string;
  type: LeadActivityType;
  createTime: Date;
  updateTime?: Date;
};

export default function useLeadActivity(leadId: string) {
  const [leadActivity, setLeadActivity] = useState<any[]>([]);
  const [state, setState] = useState(RequestState.IDLE);
  const workspaceId = useSelector((state) => state.workspaceLeads.currentWorkspace?.id) as string;

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId && leadId) {
      setState(RequestState.PENDING);
      const q = query(
        collection(
          DB,
          getCollection[Collection.LEADS_FORMS_SUBMISSIONS_ACTIVITY](workspaceId, leadId)
        ),
        orderBy('createTime', 'desc'),
        limit(100)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const forms = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              createTime: data.createTime.toDate(),
              updateTime: data.updateTime?.toDate(),
            } as LeadActivity;
          });

          setLeadActivity(forms);
          setState(RequestState.RESOLVED);
        },
        (error) => {
          console.error('Error getting lead activity:', error);
          setState(RequestState.REJECTED);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId, leadId]);

  return { leadActivity, isLoading: state === RequestState.PENDING || state === RequestState.IDLE };
}
