import { Box, Stack, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import Iconify from '../Iconify';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Markdown from '../Markdown';
import useAppMessages from './useAppMessages';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { Collection } from 'src/enum';
import { useSelector } from 'src/redux/store';

export default function InAppMessages() {
  const l = useLocation();
  const userId = useSelector((state) => state.user.id);
  const messages = useAppMessages();

  console.log({ messages });

  const markLatestMessageAsRead = (id: string, createTime: Date) => {
    try {
      if (!userId) return;
      const appMessageDocRef = doc(DB, getCollection[Collection.ONBOARDING](userId), 'details');
      setDoc(appMessageDocRef, { latestAppMessage: createTime }, { merge: true });
    } catch (error) {
      console.error('Error marking latest message as read', error);
    }
  };

  // if (!l.pathname.includes('leads')) return null;
  const render = messages
    .filter((m) => (m.displayPath ? l.pathname.includes(m.displayPath) : true))
    .map((m, index) => (
      <AppMessage
        index={index}
        key={m.id}
        m={m}
        onDismiss={() => markLatestMessageAsRead(m.id, m.createTime)}
      />
    ));

  return <>{render}</>;
}

export function AppMessage({
  index,
  m,
  onDismiss,
  preview = false,
}: {
  index: number;
  m: any;
  onDismiss: () => void;
  preview?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <AppMessageContainer
      key={m.id}
      // @ts-ignore
      preview={preview}
    >
      <div
        className={`chat-widget ${isOpen ? 'open' : ''}`}
        style={{
          zIndex: 10000 - index,
        }}
      >
        <Stack
          //   onClick={toggleChat}
          sx={{
            padding: 2,
            cursor: 'pointer',
            borderBottom: `1px solid ${grey[200]}`,
          }}
          spacing={2}
          // alignItems="center"
        >
          {/* Close icon button */}
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 8,
              right: 8,
              borderRadius: '50%',
              backgroundColor: grey[100],
              cursor: 'pointer',
              padding: 1,
            }}
            role="button"
            onClick={() => {
              setIsOpen(false);
              setTimeout(() => {
                onDismiss();
              }, 400);
            }}
          >
            <Iconify fontSize={24} icon="carbon:close" />
          </Box>
          <Stack>
            <Markdown>{m.title}</Markdown>
            <Markdown>{m.body}</Markdown>
          </Stack>
        </Stack>
      </div>
    </AppMessageContainer>
  );
}

const AppMessageContainer = styled('div')`
  .chat-widget {
    padding: 0px;
    width: 400px;
    position: ${(props) =>
      // @ts-ignore
      props.preview ? 'block' : 'fixed'};
    bottom: 8px;
    right: 8px;
    background-color: #fff;
    border: 1px solid ${grey[200]};
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    transform: translateY(100%); /* Update this for initial state */
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    .chat-container {
    }
  }

  .chat-widget.open {
    width: 400px;
    transform: translateY(0);
    .chat-container {
      height: 50vh; /* Adjust based on input height */
    }
    .message-input {
      height: calc(100% - 1px);
      border-top: 1px solid ${grey[200]};
      textarea {
        font-size: 16px;
      }
    }
  }

  .chat-toggle {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .chat-container {
    position: relative;
    height: 0vh; /* Adjust based on input height */
    transition: height 0.3s ease-in-out;
    overflow-y: auto;
  }

  .message-list {
    padding: 16px;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    overflow-y: auto;
    padding-bottom: 16px;
  }

  .message {
    background-color: ${grey[100]};
    padding: 16px;
    font-size: 14px;
    border-radius: 8px;
    align-self: flex-start;
  }
  .message.me {
    align-self: flex-end;
    background-color: #001d06;
    color: #fff;
  }

  .message-input {
    transition: width 0.2s ease-in-out;
    background-color: #fff;
    // position: absolute;
    height: 0vh;
    bottom: 0;
    left: 0;
    width: 100%;
    textarea {
      transition: font-size 0.2s ease-in-out;
      font-size: 2px;
    }
  }
`;
