import { Video } from 'remotion';
import { AbsoluteFill, Sequence, useVideoConfig } from 'remotion';
import { loadFont } from '@remotion/google-fonts/Montserrat';
import { Title } from './Text';

const { fontFamily } = loadFont();

interface ISubtitle {
  startTime: string;
  endTime: string;
  captions: string;
}
interface FomattedSubtitle {
  from: number;
  durationInFrames: number;
  text: string;
}
export const SimpleSubtitles: React.FC<{
  videoUrl: string;
  fontColor: string;
  fontSize: number;
  backgroundColor: string;
  subtitles: ISubtitle[];
}> = ({ videoUrl, fontColor, fontSize, backgroundColor, subtitles }) => {
  const { fps } = useVideoConfig();
  const formattedSubtitles: FomattedSubtitle[] = subtitles.map((subtitle) => {
    const [hour, min, sec] = subtitle.startTime.split(':');
    const [endhour, endmin, endsec] = subtitle.endTime.split(':');
    const startFrame = Number(hour) * 60 * 60 * fps + Number(min) * 60 * fps + Number(sec) * fps;
    const endFrame =
      Number(endhour) * 60 * 60 * fps + Number(endmin) * 60 * fps + Number(endsec) * fps;
    return {
      from: startFrame,
      durationInFrames: endFrame - startFrame,
      text: subtitle.captions,
    };
  });

  // A <AbsoluteFill> is just a absolutely positioned <div>!
  return (
    <AbsoluteFill style={{ backgroundColor: 'white' }}>
      <AbsoluteFill>
        <Video src={videoUrl} />
      </AbsoluteFill>
      <AbsoluteFill>
        {formattedSubtitles.map((fs) => (
          <Sequence key={fs.from} from={fs.from} durationInFrames={fs.durationInFrames}>
            <Title
              titleText={fs.text}
              fontColor={fontColor}
              fontSize={fontSize}
              backgroundColor={backgroundColor}
            />
          </Sequence>
        ))}
      </AbsoluteFill>
    </AbsoluteFill>
  );
};
