import { Stack, Box, Card, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import Wave from 'src/components/animate/backgrounds/Wave';
import Blobs from 'src/components/animate/backgrounds/blobs';
import FloatBalls from 'src/components/animate/backgrounds/floatBalls';
import Snakes from 'src/components/animate/backgrounds/snakes';
import Sundown from 'src/components/animate/backgrounds/sundown';
import { customShadows } from 'src/theme/shadows';

export default function PatternSelect({
  color,
  pattern,
  setPattern,
}: {
  color: string;
  pattern: string | null;
  setPattern: Dispatch<SetStateAction<string | null>>;
}) {
  return (
    <Stack direction="row" spacing={2}>
      <Card
        // elevation={pattern === null ? 12 : 0}
        sx={{
          width: 200,
          height: 200,
          zIndex: 0,
          cursor: 'pointer',
          boxShadow: pattern === null ? customShadows.dark.z16 : customShadows.dark.z1,
        }}
        onClick={() => setPattern(null)}
      >
        <Typography
          sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          None
        </Typography>
        <Box
          sx={{
            width: 200,
            height: 200,
          }}
        />
      </Card>
      <Card
        elevation={pattern === 'sundown' ? 4 : 0}
        sx={{
          width: 200,
          height: 200,
          zIndex: 0,
          cursor: 'pointer',
          boxShadow: pattern === 'sundown' ? customShadows.dark.z16 : customShadows.dark.z1,
        }}
        onClick={() => setPattern('sundown')}
      >
        <Typography
          sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          Sundown
        </Typography>
        <Box
          sx={{
            transform: 'scaleY(0.5)',
            position: 'absolute',
            top: -220,
            width: '100%',
          }}
        >
          <Sundown color={color} />
        </Box>
      </Card>
      <Card
        sx={{
          width: 200,
          height: 200,
          zIndex: 0,
          cursor: 'pointer',
          boxShadow: pattern === 'blobs' ? customShadows.dark.z16 : customShadows.dark.z1,
        }}
        onClick={() => setPattern('blobs')}
      >
        <Typography
          sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          Blobs
        </Typography>
        <Box
          sx={{
            transform: 'scaleY(1)',
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <Blobs color={color} />
        </Box>
      </Card>

      {/* <Box>
        <Blobs color={color} />
      </Box> */}
    </Stack>
  );
}
