import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Tooltip, Chip } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAVBAR } from '../../../config';
// components
// import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { useDispatch, useSelector } from '../../../redux/store';
import { Platforms } from '../../../redux/slices/platform';
import SimplyReviewIcon from '../../../assets/Logo/SimplyReviewIcon';
import { BOOKINGS, CHAT, FLOW, LEADS, LINKS, REVIEW } from '../../../theme/palette';
import SimplyFlowIcon from '../../../assets/Logo/SimplyFlowIcon';
import ProductButton from '../../../components/buttons/ProductButton';
import { useTranslation } from 'react-i18next';
import { PATH_DASHBOARD } from '../../../routes/paths';
import NProgress from 'nprogress';
import { selectPlatform, setUserPlaform } from '../../../redux/slices/userSlice';
import { useSnackbar } from 'notistack';
import SimplyLeadsIcon from '../../../assets/Logo/SimplyLeadsIcon';
import EarlyUser from '../../../config/featureFlags';
import SimplyLinksIcon from '../../../assets/Logo/SimplyLinksIcon';
import AdminUser, { BetaUser, SuperAdminUser } from 'src/guards/UserRoles';
import SimplyBookingIcon from 'src/assets/Logo/SimplyBookingIcon';
import SimplyChatIcon from 'src/assets/Logo/SimplyChatIcon';
import { PremiumFeatureLabel } from 'src/components/PremiumFeature';
import BetaLabel from 'src/components/BetaLabel';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVerticalPlatform({ isOpenSidebar, onCloseSidebar }: Props) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  // const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
  //   useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const isCollapse: any = true;

  return (
    <RootStyle
      sx={{
        width: {
          lg: NAVBAR.PRODUCT_NAVBAR_WIDTH,
        },
        // ...(collapseClick && {
        //   position: 'absolute',
        // }),
      }}
    >
      {/* {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          <VerticalProducts />
        </Drawer>
      )} */}

      <Drawer
        open
        variant="persistent"
        // onMouseEnter={onHoverEnter}
        // onMouseLeave={onHoverLeave}
        PaperProps={{
          sx: {
            zIndex: 1301,
            width: NAVBAR.PRODUCT_NAVBAR_WIDTH,
            borderRightStyle: 'dashed',
            bgcolor: 'background.default',
            left: 0,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
            ...(isCollapse && {
              width: NAVBAR.PRODUCT_NAVBAR_WIDTH,
            }),
            // ...(collapseHover && {
            //   ...cssStyles(theme).bgBlur(),
            //   boxShadow: (theme) => theme.customShadows.z24,
            // }),
          },
        }}
      >
        <VerticalProducts />
      </Drawer>
    </RootStyle>
  );
}

export function VerticalProducts() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector((state) => state.user.id);
  const platform = useSelector(selectPlatform);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const switchPlatform = async (platform: Platforms, link: string) => {
    if (!userId) return alert(t('general.error.default'));
    NProgress.start();
    try {
      // const userRef = doc(collection(DB, 'users'), userId);
      // await setDoc(userRef, { platform }, { merge: true });
      dispatch(setUserPlaform(platform));
      navigate(link);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }

    NProgress.done();
  };
  return (
    <Scrollbar
      sx={{
        backgroundColor: theme.palette.background.neutral,
        height: 1,
        zIndex: 1301,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack sx={{ p: 1, pt: 2 }} justifyContent="center" alignItems="center">
        <Tooltip placement="right" title="SimplyReview" arrow>
          <ProductButton
            variant="contained"
            size="small"
            backgroundColor={REVIEW.darker}
            onClick={() => switchPlatform(Platforms.SIMPLY_REVIEW, PATH_DASHBOARD.general.app)}
            disableElevation
            sx={{
              minWidth: 40,
              height: 40,
              backgroundColor: 'common.black',
              boxShadow:
                platform === Platforms.SIMPLY_REVIEW ? `0px 0px 0px 3px ${REVIEW.lighter}` : 'none',
            }}
          >
            <SimplyReviewIcon color={REVIEW.lighter} />
          </ProductButton>
        </Tooltip>
        <Box sx={{ mt: 2 }} />
        <Tooltip placement="right" title="SimplyFlow" arrow>
          <ProductButton
            backgroundColor={FLOW.darker}
            disableElevation
            variant="contained"
            size="small"
            onClick={() => switchPlatform(Platforms.SIMPLY_FLOW, PATH_DASHBOARD.general.appFlow)}
            sx={{
              minWidth: 40,
              height: 40,
              backgroundColor: 'common.black',
              boxShadow:
                platform === Platforms.SIMPLY_FLOW ? `0px 0px 0px 3px ${FLOW.lighter}` : 'none',
            }}
          >
            <SimplyFlowIcon width={18} color={FLOW.light} />
          </ProductButton>
        </Tooltip>
        <Box sx={{ mt: 2 }} />

        <Tooltip placement="right" title="SimplyLeads" arrow>
          <ProductButton
            backgroundColor={LEADS.darker}
            disableElevation
            variant="contained"
            size="small"
            onClick={() => switchPlatform(Platforms.SIMPLY_LEADS, PATH_DASHBOARD.general.appLeads)}
            sx={{
              minWidth: 40,
              height: 40,
              backgroundColor: 'common.black',
              boxShadow:
                platform === Platforms.SIMPLY_LEADS ? `0px 0px 0px 3px ${LEADS.light}` : 'none',
            }}
          >
            <SimplyLeadsIcon width={18} color={LEADS.light} />
          </ProductButton>
        </Tooltip>
        <Box sx={{ mt: 2 }} />

        <Tooltip placement="right" title="SimplyLinks" arrow>
          <ProductButton
            backgroundColor={LINKS.darker}
            disableElevation
            variant="contained"
            size="small"
            onClick={() =>
              switchPlatform(Platforms.SIMPLY_LINKS, PATH_DASHBOARD.general.linksCustomisePage)
            }
            sx={{
              minWidth: 40,
              height: 40,
              backgroundColor: 'common.black',
              boxShadow:
                platform === Platforms.SIMPLY_LINKS ? `0px 0px 0px 3px ${LINKS.light}` : 'none',
            }}
          >
            <SimplyLinksIcon width={18} color={LINKS.light} />
          </ProductButton>
        </Tooltip>
        <Box sx={{ mt: 2 }} />
        <SuperAdminUser>
          <>
            <Tooltip placement="right" title="SimplyContent" arrow>
              <ProductButton
                backgroundColor={LINKS.darker}
                disableElevation
                variant="contained"
                size="small"
                onClick={() =>
                  switchPlatform(Platforms.SIMPLY_CONTENT, PATH_DASHBOARD.general.appContent)
                }
                sx={{
                  minWidth: 40,
                  height: 40,
                  backgroundColor: 'common.black',
                  boxShadow:
                    platform === Platforms.SIMPLY_CONTENT
                      ? `0px 0px 0px 3px ${LINKS.light}`
                      : 'none',
                }}
              >
                <SimplyLinksIcon width={18} color={LINKS.light} />
              </ProductButton>
            </Tooltip>
            <Box sx={{ mt: 2 }} />
          </>
        </SuperAdminUser>

        <BetaUser>
          <>
            <Tooltip placement="right" title="SimplyBooking" arrow>
              <ProductButton
                backgroundColor={BOOKINGS.darker}
                disableElevation
                variant="contained"
                size="small"
                onClick={() =>
                  switchPlatform(Platforms.SIMPLY_BOOKING, PATH_DASHBOARD.general.appBooking)
                }
                sx={{
                  minWidth: 40,
                  height: 40,
                  backgroundColor: 'common.black',
                  boxShadow:
                    platform === Platforms.SIMPLY_BOOKING
                      ? `0px 0px 0px 3px ${BOOKINGS.light}`
                      : 'none',
                }}
              >
                <Box sx={{ position: 'absolute', top: -8, right: -6 }}>
                  <BetaLabel platform={Platforms.SIMPLY_BOOKING} />
                </Box>
                <SimplyBookingIcon width={18} color={BOOKINGS.light} />
              </ProductButton>
            </Tooltip>
            <Box sx={{ mt: 2 }} />
          </>
        </BetaUser>
        <AdminUser>
          <>
            <Tooltip placement="right" title="SimplyChat" arrow>
              <ProductButton
                backgroundColor={CHAT.darker}
                disableElevation
                variant="contained"
                size="small"
                onClick={() =>
                  switchPlatform(Platforms.SIMPLY_CHAT, PATH_DASHBOARD.general.chatConversations)
                }
                sx={{
                  minWidth: 40,
                  height: 40,
                  backgroundColor: 'common.black',
                  boxShadow:
                    platform === Platforms.SIMPLY_CHAT ? `0px 0px 0px 3px ${CHAT.light}` : 'none',
                }}
              >
                <SimplyChatIcon width={18} color={CHAT.light} />
              </ProductButton>
            </Tooltip>
            <Box sx={{ mt: 2 }} />
          </>
        </AdminUser>
      </Stack>

      {/* <Box sx={{ flexGrow: 1 }} /> */}

      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  );
}
