import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import PulseDot from '../../../../components/PulseDot';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface Props {
  url: string;
  isActive: boolean;
}

export default function WidgetActivation({ url, isActive }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const pulseColor = isActive ? theme.palette.success.main : theme.palette.error.main;
  return (
    <>
      <Box>
        <Typography variant="h6">{t('widgets.setup.activation.headline')}</Typography>
        <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
          {t('widgets.setup.activation.description')}
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }} />
      <Box sx={{ py: 4, display: 'flex' }}>
        <PulseDot color={pulseColor} />
        <Typography sx={{ ml: 3 }} variant="body1">
          {isActive
            ? t('widgets.setup.activation.completeText', {
                url,
              })
            : t('widgets.setup.activation.awaitingText')}
        </Typography>
      </Box>
    </>
  );
}
