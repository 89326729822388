import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intl, RequestState } from '../../enum';
import type { RootState } from '../store';
import uuidv4 from 'src/utils/uuidv4';

export enum LinkType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
  SNAPCHAT = 'SNAPCHAT',
  PINTEREST = 'PINTEREST',
  SPOTIFY = 'SPOTIFY',
  TUMBLR = 'TUMBLR',
  REDDIT = 'REDDIT',
  CUSTOM = 'CUSTOM',
  REVIEW_PAGE = 'REVIEW_PAGE',
  REVIEW_COMPANY_PAGE = 'REVIEW_COMPANY_PAGE',
  LEADS_PAGE = 'LEADS_PAGE',
}

export interface LinkPageType {
  id: string;
  type: LinkType;
  thumbnail?: string | File;
  title: string;
  link?: string;
  visible: boolean;
}

export interface PublicConfig {
  id?: string;
  status: RequestState;
  slug?: string;
  userId?: string;
  logo?: { original: string; thumbnail: string };
  companyPageLogo?: { original: string; thumbnail: string };
  questions?: any[];
  title?: string;
  description?: string;
  color?: string;
  onlyVideo?: boolean;
  videoIntro?: string;
  companyName?: string;
  companyLogo?: string;
  companyPageLogoTmp?: string;
  videoProcessed?: boolean;
  reviewerPageLanguage?: Intl;
  leadPageLogo?: { original: string; thumbnail: string };
  leadPageName?: string;
  leadPageColor?: string;
  leadPageSuccessText?: string;
  leadPagePattern?: string | null;
  linkPageLogo?: { original: string; thumbnail: string };
  linkPageName?: string;
  linkPageColor?: string;
  linkPagePattern?: string | null;
  linkPageLinks?: LinkPageType[];
}

// Define the initial state using that type
const initialState: PublicConfig = {
  status: RequestState.IDLE,
};

export const publicConfigSlice = createSlice({
  name: 'publicConfig',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPublicConfigLoading: (state, action: PayloadAction<undefined>) => ({
      ...state,
      status: RequestState.PENDING,
    }),
    // Use the PayloadAction type to declare the contents of `action.payload`
    setPublicConfig: (state, action: PayloadAction<PublicConfig | undefined>) => ({
      // ...state,
      ...action.payload,
      status: RequestState.RESOLVED,
      linkPageLinks: action.payload?.linkPageLinks?.map((link) => {
        if (link.type === LinkType.REVIEW_COMPANY_PAGE) {
          return {
            ...link,
            thumbnail: action.payload?.companyPageLogo?.thumbnail,
            link: `https://app.simplyreview.com/c/${action.payload?.slug}`,
          };
        }
        if (link.type === LinkType.REVIEW_PAGE) {
          return {
            ...link,
            link: `https://app.simplyreview.com/r/${action.payload?.slug}`,
          };
        }
        if (link.type === LinkType.LEADS_PAGE) {
          return {
            ...link,
            link: `https://app.simplyleads.io/l/${action.payload?.slug}`,
          };
        }
        return link;
      }),
    }),
    setPublicConfigError: (state, action: PayloadAction<undefined>) => ({
      ...state,
      status: RequestState.REJECTED,
    }),
    updatePublicConfigInfo: (state, action: PayloadAction<Partial<PublicConfig>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  setPublicConfig,
  setPublicConfigLoading,
  setPublicConfigError,
  updatePublicConfigInfo,
} = publicConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const getSlugSelector = (state: RootState) => state.publicConfig?.slug;
export const hasSlugSelector = (state: RootState) => Boolean(state.publicConfig?.slug);
export const hasFinishedReviewPageSelector = (state: RootState) =>
  Boolean(state.publicConfig?.title);
export const selectPublicConfig = (state: RootState) => state.publicConfig;

export const selectPublicConfigHasLoaded = (state: RootState) =>
  state.publicConfig.status === RequestState.RESOLVED;
export const selectISPublicConfigLoading = (state: RootState) =>
  state.publicConfig.status === RequestState.IDLE ||
  state.publicConfig.status === RequestState.PENDING;

export default publicConfigSlice.reducer;
