import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export enum NotificationType {
  NEW_REVIEW = 'NEW_REVIEW',
  ACCEPTED_TEAM_INVITE = 'ACCEPTED_TEAM_INVITE',
  VOIDED_TEAM_INVITE = 'VOIDED_TEAM_INVITE',
  REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER',
  REVIEW_REMINDER_INVITE = 'REVIEW_REMINDER_INVITE',
  NEW_LEAD_SUBMISSION = 'NEW_LEAD_SUBMISSION',
  NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE',
}

export interface Notification {
  id: string;
  type?: NotificationType;
  title: string;
  metadata?: any;
  seen: boolean;
  createTime: Date;
}

// Define a type for the slice state
interface NotificationState {
  list: Notification[];
}

// Define the initial state using that type
const initialState: NotificationState = {
  list: [],
};

export const notificationSlice = createSlice({
  name: 'notifications',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setNotifications: (state, action: PayloadAction<Notification[]>) => ({
      ...state,
      list: action.payload,
    }),
  },
});

export const { setNotifications } = notificationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectNotifications = (state: RootState) => state.notifications.list;

export default notificationSlice.reducer;
