import React from 'react';
import Page from '../../../components/Page';
import { Box, Card, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import zenfit from '../../../assets/integrations/zenfit.png';
import Image from '../../../components/Image';
import { useTranslation } from 'react-i18next';
import { Collection } from '../../../enum';
import { getCollection } from '../../../api';
import { addDoc, collection, doc } from 'firebase/firestore';
import { DB } from '../../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { AutomationPlatform, AutomationType } from '../../../redux/slices/automationsSlice';
import { selectCurrentPlatformWorkspaceId } from '../../../redux/selectors/workspaces';
import { useSelector } from '../../../redux/store';
import { selectZenfitApi } from '../../../redux/slices/apiIntegrationsSlice';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../routes/paths';
import Iconify from '../../../components/Iconify';
import { useTheme } from '@mui/material';

export default function ZenfitAutomation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const connectedZenfitAPIKey = useSelector(selectZenfitApi);

  const onCreateZenfitReviewAutomation = async () => {
    try {
      setIsSubmitting(true);
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }
      await addDoc(collection(DB, getCollection[Collection.AUTOMATIONS]()), {
        createTime: new Date(),
        config: {
          reminderDays: 7,
        },
        type: AutomationType.REVIEW_REMINDER,
        workspaceId,
        platform: AutomationPlatform.ZENFIT,
        active: true,
      });
      enqueueSnackbar(t('general.success.default'));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };
  if (!connectedZenfitAPIKey) {
    return (
      <Card
        sx={{
          maxWidth: 400,
          position: 'relative',
          p: 3,
          py: 6,
          '&:hover': {
            boxShadow: 3,
            cursor: 'pointer',
          },
        }}
        onClick={() => navigate(`${PATH_DASHBOARD.user.account}?tab=integrations`)}
      >
        <Stack direction="row" justifyContent="center">
          <Image sx={{ width: 180 }} src={zenfit} alt="zenfit" />
        </Stack>
        <Box mt={2} />
        <Iconify icon="ic:round-warning-amber" fontSize={24} color={theme.palette.warning.main} />
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{t('reviewAutomation.zenfit.requiresSetup')}</Typography>
        </Stack>
      </Card>
    );
  }
  return (
    <Card
      sx={{
        maxWidth: 400,
        position: 'relative',
        p: 3,
        py: 6,
        '&:hover': {
          boxShadow: 3,
          cursor: 'pointer',
        },
      }}
      onClick={() => !isSubmitting && onCreateZenfitReviewAutomation()}
    >
      {isSubmitting && (
        <Stack
          position="absolute"
          sx={{ backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 100, left: 0, top: 0 }}
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      )}
      <Stack direction="row" justifyContent="center">
        <Image sx={{ width: 180 }} src={zenfit} alt="zenfit" />
      </Stack>
      <Box mt={2} />
      <Typography>{t('reviewAutomation.zenfit.descriptionInitial')}</Typography>
    </Card>
  );
}
