import { QuestionType } from './QuestionsSection';

export function getTypeConfig(type: QuestionType) {
  switch (type) {
    case QuestionType.RADIO:
      return {
        icon: 'material-symbols:data-check',
        label: 'Checklist answer',
      };
    case QuestionType.NUMBER:
      return {
        icon: 'fa-solid:hashtag',
        label: 'Number answer',
      };
    case QuestionType.LONG_TEXT:
      return {
        icon: 'material-symbols:featured-play-list-outline',
        label: 'Description answer',
      };
    case QuestionType.TEXT:
      return {
        icon: 'material-symbols:text-format',
        label: 'Text answer',
      };
    default:
      console.error(`Unknown type: ${type}`);
      return {
        icon: '',
        label: '',
      };
  }
}
