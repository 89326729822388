import { useEffect } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where, doc } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { Review, setReviewLoadingState, setReview } from '../redux/slices/reviewSlice';
import { selectCurrentPlatformWorkspaceId } from '../redux/selectors/workspaces';

// if passed here it will load X users reviews
export default function useReview(reviewId: string | null) {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId && reviewId) {
      dispatch(setReviewLoadingState(RequestState.PENDING));
      const q = doc(DB, getCollection[Collection.USERS_REVIEWS](workspaceId), reviewId);
      unsubscribe = onSnapshot(q, (doc) => {
        const review = {
          id: doc.id,
          ...doc.data(),
        } as Review;

        dispatch(setReview(review));
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [reviewId, workspaceId]);
}
