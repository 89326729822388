import { useEffect, useState } from 'react';
import { onSnapshot, doc, collection, query, where } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
// import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { setWorkspaceLeads, WorkspaceLeads } from '../redux/slices/workspaceLeadsSlice';

export default function useWorkspaceLeads() {
  const dispatch = useDispatch();
  const [isLoadingWorkspaceLeads, setisLoadingWorkspaceLeads] = useState(true);
  const workspaceLeadsCurrent = useSelector((state) => state.user.workspaceLeadsCurrent);
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_LEADS]()),
        where(`members.${userId}.uid`, '==', userId)
      );

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.group('useWorkspaceLeads');
          const workspaceLeads = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as WorkspaceLeads[];

          async function setWorkspaceLeadsData() {
            dispatch(
              setWorkspaceLeads({
                workspaces: workspaceLeads,
                current: workspaceLeadsCurrent,
              })
            );
            setisLoadingWorkspaceLeads(false);
            console.groupEnd();
          }

          setWorkspaceLeadsData();
        },
        (error) => {
          console.error('useWorkspaceLeads', error);
          dispatch(
            setWorkspaceLeads({
              workspaces: [],
              current: null,
            })
          );
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingWorkspaceLeads };
}
