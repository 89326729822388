import React from 'react';
import { Alert } from '@mui/material';
import { useSelector } from '../../redux/store';
import { addDays, formatDistanceToNow } from 'date-fns';
import {
  selectCurrentPlatformIsTrailing,
  selectHasCurrentPlatformPremium,
} from '../../redux/selectors/workspaces';
import { Platforms } from '../../redux/slices/platform';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LEADS } from '../../theme/palette';

const reviewGoal = 10;

export default function Promotion() {
  const { t } = useTranslation();
  const hasPremium = useSelector(selectHasCurrentPlatformPremium);
  const platform = useSelector((state) => state.user.platform);
  const reviewCount = useSelector((state) => state.reviews.list?.length);
  const trial = useSelector(selectCurrentPlatformIsTrailing);
  const reviewRemaining = reviewGoal - reviewCount;
  const navigate = useNavigate();

  const goToBilling = () => {
    navigate('/dashboard/user/account?tab=billing');
  };

  if (hasPremium) {
    return null;
  }
  /**
   * Hide until loaded
   */
  if (!trial) {
    return null;
  }
  const monthFromCreation = addDays(trial.trialStart, 30);

  console.log({ monthFromCreation, trialStartTime: trial.trialStart });
  /**
   * if it is one month after sign up, hide
   */
  if (!trial.isTrialing) {
    return null;
  }

  // if (hasPremium && reviewRemaining < 0) {
  //   return <Alert severity="info">You did it! 🎉 Get the month for free!</Alert>;
  // }

  if (platform === Platforms.SIMPLY_REVIEW) {
    // if (!hasPremium && reviewRemaining <= 0) {
    //   return <Alert severity="info">You did it! 🎉 Get the month for free when upgrading!</Alert>;
    // }
    // if (hasPremium) {
    //   return (
    //     <Alert severity="info">
    //       Get <b>{reviewRemaining} more reviews</b> and get the month for free!
    //     </Alert>
    //   );
    // }
    return (
      <Alert sx={{ cursor: 'pointer' }} severity="info" onClick={goToBilling}>
        {t('trial.promotion.general', { trialEnd: monthFromCreation })}
      </Alert>
    );
  }

  if (platform === Platforms.SIMPLY_FLOW) {
    return (
      <Alert sx={{ cursor: 'pointer' }} severity="info" onClick={goToBilling}>
        {t('trial.promotion.general', { trialEnd: monthFromCreation })}
      </Alert>
    );
  }
  if (platform === Platforms.SIMPLY_LEADS) {
    return (
      <Alert sx={{ cursor: 'pointer' }} severity="info" onClick={goToBilling}>
        {t('trial.promotion.general', { trialEnd: monthFromCreation })}
      </Alert>
    );
  }
  return null;
}
