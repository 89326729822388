import React from 'react';
import { loadFont } from '@remotion/google-fonts/Montserrat';

const { fontFamily } = loadFont();

const title: React.CSSProperties = {
  fontFamily,
  fontWeight: 'bold',
  fontSize: 160,
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  textShadow: '0px 0px 8px rgba(0,0,0,0.1)',
};

const word: React.CSSProperties = {
  marginLeft: 40,
  marginRight: 40,
  padding: 10,
  display: 'inline-block',
};

export const Title: React.FC<{
  titleText: string;
  fontColor: string;
  fontSize: number;
  backgroundColor: string;
}> = ({ titleText, fontColor, fontSize, backgroundColor }) => (
  <h1 style={title}>
    <span
      key={titleText}
      style={{
        ...word,
        color: fontColor,
        fontSize,
        backgroundColor,
        borderRadius: 20,
      }}
    >
      {titleText}
    </span>
  </h1>
);
