import { useState } from 'react';
import ReviewComments from './ReviewComments';
import { Box, Button, useTheme } from '@mui/material';

export default function ReviewCommentsContainer({
  userId,
  reviewId,
  commentCount,
}: {
  userId?: string;
  reviewId: string;
  commentCount?: number;
}) {
  const theme = useTheme();
  const [displayComments, setdisplayComments] = useState(false);
  if (displayComments && userId) {
    return <ReviewComments userId={userId} reviewId={reviewId} />;
  }
  if (commentCount) {
    return (
      <Box sx={{ textAlign: 'right' }}>
        <Button
          size="small"
          sx={{ color: theme.palette.grey[500] }}
          onClick={() => setdisplayComments(true)}
        >
          ({commentCount}) kommentarer
        </Button>
      </Box>
    );
  }
  return null;
}
