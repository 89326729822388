import React, { useEffect, useRef, useState } from 'react';
import Iconify from '../Iconify';
import { useSelector } from 'src/redux/store';
import { CircularProgress, IconButton, TextField } from '@mui/material';
import { createConversation, sendMessage } from 'src/sections/@dashboard/chat/ChatWindow';

import { darken } from '@mui/material/styles';
import { IMAGE_METADATA_TYPE, RequestState } from 'src/enum';
import { collection, setDoc, doc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { upload } from 'src/hooks/useUpload';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';

export default function SubmitMessage({
  isOpen,
  workspaceId,
  conversationId,
}: {
  isOpen: boolean;
  workspaceId: string;
  conversationId: string | null;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const user = useSelector((state) => ({
    uid: state.user.id as string,
    displayName: state.user.displayName ?? null,
    photoURL: state.user.photoURL ?? null,
  }));
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    try {
      e.preventDefault();
      if (!message?.trim() || !workspaceId) return;
      if (!conversationId) {
        // if no conversation is selected, create a new conversation
        await createConversation({
          workspaceId,
          user,
          message: {
            message,
            sender: {
              displayName: user.displayName,
              photoURL: user.photoURL,
              uid: user.uid,
            },
          },
          type: 'EXTERNAL',
        });
        setMessage('');
        return;
      }
      await sendMessage({
        workspaceId,
        conversationId,
        user,
        message: {
          message,
          sender: {
            displayName: user.displayName,
            photoURL: user.photoURL,
            uid: user.uid,
          },
        },
        type: 'EXTERNAL',
      });

      setMessage('');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.[0];
    if (image) {
      try {
        if (!workspaceId || !conversationId) return;
        setUploading(true);
        // Handle file processing here

        const messageRef = doc(
          collection(DB, 'workspaceChats', workspaceId, 'conversations', conversationId, 'messages')
        );

        const response = await upload(
          image,
          `workspaceChats/${workspaceId}/conversations/${conversationId}/`,
          {
            userId: workspaceId,
            conversationId,
            messageId: messageRef.id,
            type: IMAGE_METADATA_TYPE.CHAT_IMAGE,
          }
        );
        // @ts-ignore
        const imageUrl = response?.url;

        if (!imageUrl) {
          throw new Error(`Error uploading image ${JSON.stringify(response)}`);
        }
        setDoc(messageRef, {
          createTime: new Date(),
          updateTime: new Date(),
          message: '',
          image: {
            original: imageUrl ?? null,
          },
          sender: {
            displayName: user.displayName,
            photoURL: user.photoURL,
            uid: user.uid,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div className="message-input">
      <form onSubmit={handleSubmit} style={{ padding: 8 }}>
        <CssTextField
          inputRef={inputRef}
          placeholder="Write a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          type="submit"
          multiline
          autoFocus={isOpen}
          variant="outlined"
          maxRows={4}
          minRows={1}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              handleSubmit(e);
            }
          }}
          sx={{
            borderRadius: 64,
          }}
          // variant="outlined"
          InputProps={{
            endAdornment: (
              <>
                {conversationId && (
                  <IconButton
                    disabled={uploading}
                    component="label"
                    role={undefined}
                    sx={{
                      // backgroundColor: '#FFBF42',
                      color: '#001D06',
                      zIndex: 1002,
                      // '&:hover': {
                      //   backgroundColor: darken('#FFBF42', 0.05),
                      // },
                    }}
                  >
                    {uploading ? (
                      <CircularProgress size={16} sx={{ color: '#001D06' }} />
                    ) : (
                      <>
                        <Iconify icon="ph:image" />
                        <VisuallyHiddenInput
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </>
                    )}
                  </IconButton>
                )}
                <IconButton
                  type="submit"
                  sx={{
                    backgroundColor: '#FFBF42',
                    color: '#001D06',
                    zIndex: 1002,
                    '&:hover': {
                      backgroundColor: darken('#FFBF42', 0.05),
                    },
                  }}
                >
                  <Iconify icon="fluent:send-48-filled" style={{}} />
                </IconButton>
              </>
            ),
          }}
        />
      </form>
    </div>
  );
}
const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    padding: '8px 8px 8px 16px',
    '& .MuiOutlinedInput-input': {
      color: '#001D06',
      zIndex: 1002,
    },
    '& fieldset': {
      borderColor: '#E0E3E7',
      backgroundColor: grey[100],
      border: 'none',
      borderRadius: 24,
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
    '&.MuiButtonBase-root': {
      alignSelf: 'flex-end',
    },
  },
});

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
