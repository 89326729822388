import { useEffect, useState } from 'react';
import { Functions } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';

import { httpsCallable } from 'firebase/functions';
import { useSelector } from 'src/redux/store';
import {
  selectHasGoogleCalendarApi,
  selectHasStripeApi,
} from 'src/redux/slices/apiIntegrationsSlice';
import { CircularProgress, FormControlLabel, Radio, RadioGroup, Skeleton } from '@mui/material';
import { setError } from 'src/redux/slices/usersSlice';

export default function Stripe() {
  const calendar = useSelector(selectHasStripeApi);
  const [calendars, setCalendars] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <div>All Gucci</div>
      )}
    </div>
  );
}
