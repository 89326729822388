import { useEffect } from 'react';
import { query, collection, where, limit, onSnapshot } from 'firebase/firestore';
import { Collection } from '../enum';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentPlatformWorkspaceId } from '../redux/selectors/workspaces';
import {
  GoogleCalendarApiIntegration,
  setGoogleCalendarApiIntegrations,
} from '../redux/slices/apiIntegrationsSlice';

export default function useGoogleCalendarIntegrations() {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  // const [isLoadingApis, setIsLoadingApis] = useState(true);

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId) {
      const q = query(
        collection(
          DB,
          getCollection[Collection.API_INTEGRATIONS_READ](workspaceId, 'googleCalendar')
        ),
        where('readable', '==', true),
        where('userId', '==', workspaceId),
        limit(1)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          // console.log('querySnapshot', querySnapshot);

          const [googleCalendar] = querySnapshot.docs.map(
            (doc) =>
              ({
                id: 'googleCalendar',
                ...doc.data(),
              } as GoogleCalendarApiIntegration)
          );
          // console.log('googleCalendar', googleCalendar);
          dispatch(setGoogleCalendarApiIntegrations([googleCalendar]));
        },
        (error) => {
          console.error(error);
          //   dispatch(setRemoveZenfitApiIntegration());
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
  console.groupEnd();
  return {};
}
