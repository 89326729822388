import React from 'react';

export default function SimplyFlowIcon({ width = 12, color = '#FF6534' }) {
  return (
    <svg
      style={{ width }}
      width="14"
      height="23"
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.92303 5.68965L6.60512 5.00756C7.47668 4.136 7.47668 2.73392 6.60512 1.88131L5.4683 0.744491C4.59674 -0.12707 3.19467 -0.12707 2.34205 0.744491L1.20524 1.88131C0.333676 2.73392 0.333676 4.136 1.18629 5.00756L1.86838 5.68965L2.01996 5.84122C3.68729 7.60329 2.22837 9.32747 1.90627 9.66851L1.16734 10.4074C0.295782 11.279 0.295782 12.6811 1.16734 13.5337L2.30416 14.6705C3.17572 15.5421 4.5778 15.5421 5.43041 14.6705L6.56723 13.5337C7.43879 12.6621 7.43879 11.2601 6.56723 10.4074L5.88514 9.72535C5.65777 9.49799 4.00939 7.69803 5.77146 5.84122L5.92303 5.68965Z"
        fill={color}
      />
      <path
        d="M11.7023 16.7734H10.0918C8.86024 16.7734 7.875 17.7587 7.875 18.9902V20.6007C7.875 21.8323 8.86024 22.8175 10.0918 22.8175H11.7023C12.9338 22.8175 13.9191 21.8323 13.9191 20.6007V18.9902C13.9191 17.7587 12.9338 16.7734 11.7023 16.7734Z"
        fill={color}
      />
    </svg>
  );
}
