import ReactPlayer from 'react-player/lazy';

export default function VideoPlayer({ hasControls = true, videoPlaybackId }: any) {
  return (
    <div style={{ position: 'relative' }}>
      <ReactPlayer
        className="react-player"
        height="100%"
        width="100%"
        // playing
        style={{ minHeight: 207 }}
        url={`https://stream.mux.com/${videoPlaybackId}.m3u8`} // ?default_subtitles_lang=en
        controls={hasControls}
        onError={(e) => console.log('onError', e)}
      />
    </div>
  );
}
export function VideoPlayerPreview({ hasControls = true, video }: any) {
  return (
    <div style={{ position: 'relative' }}>
      <ReactPlayer
        className="react-player"
        height="100%"
        width="100%"
        style={{ minHeight: 207 }}
        url={video}
        controls={hasControls}
        onError={(e) => console.log('onError', e)}
      />
    </div>
  );
}
