import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { Collection } from '../enum';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';

interface Comment {
  id: string;
  createTime: any;
  message: string;
  author: string;
}

// if passed here it will load X users reviews
export default function useReviewComments({
  userId,
  reviewId,
}: {
  userId: string | undefined;
  reviewId: string;
}) {
  const [comments, setComments] = useState<Comment[]>([]);
  useEffect(() => {
    let unsubscribe: () => void;

    if (userId && reviewId) {
      const q = query(
        collection(DB, getCollection[Collection.USERS_REVIEW_COMMENTS](userId, reviewId)),
        orderBy('createTime', 'desc'),
        limit(3)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const commentDocs = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Comment)
        );
        setComments(commentDocs);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [reviewId, userId]);

  return { comments };
}
