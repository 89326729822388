// @mui
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Stack,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import { IconButtonAnimate } from 'src/components/animate';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
} as const;

type Props = {
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headLabel: {
    id: string;
    label: string;
    alignRight?: boolean;
    filterOptions?: { label: string; value: string | number }[];
  }[];
  numSelected: number;
  onRequestSort: (id: string) => void;
  onSelectAllClick: (checked: boolean) => void;
  selectedFilters?: (string | number)[];
  onSelectFilters?: (id: string | number | null) => void;
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  selectedFilters,
  onSelectFilters,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onSelectAllClick(event.target.checked)
            }
          />
        </TableCell>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              headCell.filterOptions
                ? { display: 'flex', alignItems: 'center', justifyContent: 'space-around' }
                : {}
            }
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => onRequestSort(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            {headCell.filterOptions && (
              <>
                <IconButtonAnimate
                  size="small"
                  onClick={handleOpen}
                  sx={{
                    width: 40,
                    height: 40,
                    // ...(open && { bgcolor: 'action.selected' }),
                  }}
                >
                  <Iconify icon="material-symbols:more-vert" />
                </IconButtonAnimate>
                <MenuPopover
                  id={headCell.id}
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleClose}
                  sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                  }}
                >
                  <Stack spacing={0.75}>
                    {headCell?.filterOptions?.map((option) => (
                      <MenuItem
                        key={option.value}
                        // @ts-ignore
                        selected={selectedFilters?.includes(option.value)}
                        onClick={() => {
                          onSelectFilters?.(option.value ?? null);
                          handleClose();
                        }}
                      >
                        {t(option.label as string)}
                      </MenuItem>
                    ))}
                  </Stack>
                </MenuPopover>
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
