import React from 'react';
import { useSelector } from 'src/redux/store';
import ExternalLinkButton from './OpenLink';

export default function CompanyReviewPageLink() {
  const slug = useSelector((state) => state.publicConfig.slug);
  const companyName = useSelector((state) => state.publicConfig.companyName || 'Your');
  const url = `https://app.simplyreview.com/c/${slug}`;
  return <ExternalLinkButton href={url}>{companyName} public review page</ExternalLinkButton>;
}
