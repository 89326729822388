import React from 'react';

export default function Play() {
  return (
    <div
      style={{
        display: 'flex',
        // backgroundColor: "red",
        borderRadius: '50%',
        // width: 80,
        // height: 80,
        // padding: 20,

        marginLeft: -4,
      }}
    >
      <svg
        style={{
          transform: 'translateX(3px)',
          // fill: "rgba(255, 255, 255, 0.8)",
          // fillOpacity: 0.4,
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="50px"
        height="50px"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
        // style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
      >
        <path
          fill="rgba(255, 255, 255, 1)"
          d="m11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
        />
      </svg>
    </div>
  );
}
