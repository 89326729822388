import React from 'react';
import { Stack, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { HEADER } from '../../config';

export default function SubtitlePageLoader() {
  return (
    <Stack direction="row">
      <Box width={'50%'}>
        <Skeleton variant="rectangular" sx={{ paddingTop: '12%', borderRadius: 1.5, mb: 2 }} />
        <Skeleton variant="rectangular" sx={{ paddingTop: '12%', borderRadius: 1.5, mb: 2 }} />
        <Skeleton variant="rectangular" sx={{ paddingTop: '12%', borderRadius: 1.5, mb: 2 }} />
        <Skeleton variant="rectangular" sx={{ paddingTop: '12%', borderRadius: 1.5, mb: 2 }} />
        <Skeleton variant="rectangular" sx={{ paddingTop: '12%', borderRadius: 1.5, mb: 2 }} />
        <Skeleton variant="rectangular" sx={{ paddingTop: '12%', borderRadius: 1.5, mb: 2 }} />
        <Skeleton variant="rectangular" sx={{ paddingTop: '12%', borderRadius: 1.5, mb: 2 }} />
      </Box>
      <Box width={'10%'} />
      <Box width={'30%'} sx={{ position: 'relative' }}>
        <Box style={{ position: 'sticky', top: HEADER.DASHBOARD_DESKTOP_HEIGHT }}>
          <Box sx={{ position: 'relative' }}>
            <Skeleton variant="rectangular" sx={{ paddingTop: '150%', borderRadius: 1.5, mb: 2 }} />
          </Box>
          <Skeleton variant="rectangular" sx={{ paddingTop: '20%', borderRadius: 1.5 }} />
        </Box>
      </Box>
      <Box width={'10%'} />
    </Stack>
  );
}
