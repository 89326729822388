import { useMediaQuery } from 'react-responsive';

export default function useResponsiveQuery() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // Detects if the device is in landscape mode
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

  return { isDesktop, isMobile, isTablet, isNotMobile, isLandscape, isPortrait };
}
