import React from 'react';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Lottie, { zenWait } from '../../components/Lottie';
import { PATH_DASHBOARD } from '../../routes/paths';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function EmptyState() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  return (
    <Page title="Leads" height="100%">
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%', width: '100%' }}>
        <Grid container spacing={3} height={'100%'}>
          <Grid item md={12} position="relative">
            <Stack height="100%" alignItems="center" justifyContent="center" spacing={2}>
              <Box sx={{ width: 250 }}>
                <Lottie animation={zenWait} />
              </Box>
              <Typography variant="subtitle1">{t('leads.custom.empty.headline')}</Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate(PATH_DASHBOARD.general.leadsForm)}
              >
                {t('leads.custom.empty.button')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
