import React from 'react';

export default function SimplyBookingIcon({ width = 12, color = '#598FF8' }) {
  return (
    <svg
      style={{ width, padding: 2 }}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 74.67 114.67"
      // style="enable-background:new 0 0 74.67 114.67;"
    >
      <g>
        <path
          style={{ fill: color }}
          d="M74.67,66.85v8.53c0,6.47-5.25,11.72-11.72,11.72h-8.53c-6.47,0-11.72-5.25-11.72-11.72v-8.53
		c0-6.47,5.25-11.72,11.72-11.72h8.53C69.42,55.13,74.67,60.38,74.67,66.85z"
        />
      </g>
      <g>
        <path
          style={{ fill: color }}
          d="M31.98,39.29v8.53c0,6.47-5.25,11.72-11.72,11.72h-8.53C5.25,59.54,0,54.29,0,47.82l0-8.53
		c0-6.47,5.25-11.72,11.72-11.72h8.53C26.73,27.57,31.98,32.81,31.98,39.29z"
        />
      </g>
      <g>
        <path
          style={{ fill: color }}
          d="M54.42,0l8.53,0c6.47,0,11.72,5.25,11.72,11.72v8.53c0,6.47-5.25,11.72-11.72,11.72h-8.53
		c-6.47,0-11.72-5.25-11.72-11.72v-8.53C42.7,5.25,47.94,0,54.42,0z"
        />
      </g>
      <g>
        <path
          style={{ fill: color }}
          d="M11.72,82.7h8.53c6.47,0,11.72,5.25,11.72,11.72v8.53c0,6.47-5.25,11.72-11.72,11.72h-8.53
		C5.25,114.67,0,109.42,0,102.95l0-8.53C0,87.94,5.25,82.7,11.72,82.7z"
        />
      </g>
    </svg>
  );
}
