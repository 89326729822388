import { httpsCallable } from 'firebase/functions';
import { DB, Functions } from '../../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';

import {
  IconButton,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import Iconify from '../../../components/Iconify';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { collection, doc, setDoc } from 'firebase/firestore';
import { resolvePlatformToProductCollection } from 'src/api';
import { Platforms } from 'src/redux/slices/platform';

export default function DowngradeUser({
  product,
  userId,
  displayName,
}: {
  product: Platforms;
  userId: string;
  displayName: string;
}) {
  const [loading, setloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectUserId, setSelectUserId] = useState<string | null>(null);

  const handleSubmit = async (userId: string, product: Platforms) => {
    try {
      const collectionPath = resolvePlatformToProductCollection(product);
      if (!userId || !product || !collectionPath) return alert('Something is not working!');
      setloading(true);

      const productRef = doc(collection(DB, collectionPath), userId);
      await setDoc(productRef, { subscription: null }, { merge: true });

      enqueueSnackbar('User downgraded', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });

      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const handleClose = () => {
    setSelectUserId(null);
  };

  const handleOk = () => {
    handleSubmit(userId, product);
    handleClose();
  };

  return (
    <>
      <Tooltip title={`Downgrade ${displayName} to free plan`}>
        <LoadingButton
          variant="outlined"
          loading={loading}
          startIcon={<Iconify icon="material-symbols:trending-down-rounded" />}
          onClick={() => setSelectUserId(userId)}
        >
          Downgrade
        </LoadingButton>
      </Tooltip>
      <Dialog
        open={Boolean(selectUserId)}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">🚨You are about to Downgrade🚨</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Downgrade {displayName} to free plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleOk} variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
