import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Stack, TextareaAutosize, Tooltip, Typography, styled } from '@mui/material';
import { LabelStyle } from 'src/pages/auth/Drawer/Content';
import { blue, grey } from '@mui/material/colors';
import { useSelector } from 'src/redux/store';
import Image from 'src/components/Image';
import useChat from './Chat';
import { LoadingButton } from '@mui/lab';
import Markdown, { CustomMarkdown } from 'src/components/Markdown';
import { Chip } from '@mui/material';
import { backgroundContext } from './context';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function SwipeableTemporaryDrawer({
  drawer,
  setDrawer,
}: {
  drawer: string | null;
  setDrawer: React.Dispatch<string | null>;
}) {
  const { loading, message, handleSubmit } = useChat();
  const content = useSelector((state) => state.content.map[drawer as string]);

  const images = Array.isArray(content?.image)
    ? content?.image
    : [content?.image, content?.unsplashUrl].filter(Boolean);
  return (
    <Drawer
      PaperProps={{ sx: { width: { xs: 1, sm: 600 } } }}
      anchor="right"
      open={Boolean(drawer)}
      onClose={() => setDrawer(null)}
    >
      {content && (
        <Stack sx={{ p: 4 }}>
          <Stack>
            <Typography variant="body1">{content.title}</Typography>
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Stack>
            <LabelStyle>Caption</LabelStyle>
            <Box mt={1} />
            <StyledTextareaElement value={content.title} />
            {/* <LoadingButton
              loading={loading}
              onClick={() =>
                handleSubmit(
                  `Give me a new caption for Instagram, this was the previous one: ${content.description}`
                )
              }
            >
              Rewrite caption
            </LoadingButton> */}
          </Stack>
          <Box sx={{ mb: 3 }} />
          <Stack>
            <LabelStyle>Description</LabelStyle>
            <Box mt={1} />
            <Box sx={{ fontSize: '14px !important', li: { fontSize: '12px !important' } }}>
              <Markdown>{content.description}</Markdown>
              {/* <StyledTextareaElement disabled={loading} value={content.description} /> */}
            </Box>
          </Stack>

          <Box sx={{ mb: 3 }} />
          <Stack>
            <LoadingButton
              variant="outlined"
              fullWidth
              loading={loading}
              onClick={() =>
                handleSubmit(
                  `${
                    content.contentType === 'VIDEO'
                      ? `Write how to record a video with the following context:`
                      : 'Write the post from the following context:'
                  }
[CLIENT_CONTEXT]: ${backgroundContext}                
[CONTENT_TYPE]: ${content.contentType}
[OUTLINE]:${content.description}
[TIP]: ${content.tip}`
                )
              }
            >
              How would you create this post
            </LoadingButton>
            <Box sx={{ fontSize: '12px !important', li: { fontSize: '12px !important' } }}>
              <Markdown children={message} />
            </Box>
          </Stack>
          <Box sx={{ mb: 3 }} />
          <Stack>
            <LabelStyle>Creation & posting</LabelStyle>
            <Box mt={1} />
            <Typography variant="body2">
              It should take approx. {content.estimatedTime} min to create
            </Typography>
            <Typography variant="body2">
              Post it around {content.whenToPublish} on the day
            </Typography>
          </Stack>
          <Box sx={{ mb: 3 }} />
          <Stack>
            <LabelStyle>Suggested images</LabelStyle>
            <Box mt={1} />
            <Stack direction="row" spacing={1}>
              {images?.map((image) => (
                <Box sx={{ position: 'relative', width: 140 }}>
                  <Image src={image} ratio="16/9" sx={{ borderRadius: 1 }} />
                </Box>
              ))}
            </Stack>
          </Stack>
          <Box sx={{ mb: 3 }} />
          <Stack>
            <LabelStyle>Tags:</LabelStyle>
            <Box mt={1} />
            <Stack direction="row">
              {/* <Divider
                orientation="vertical"
                // sx={{ borderRightWidth: 'thick', borderRadius: 1, mr: 2 }}
              /> */}
              <Stack direction="row" spacing={1}>
                {content?.tags.map((tag) => (
                  <Chip label={tag} />
                ))}
              </Stack>
            </Stack>
          </Stack>
          <Box sx={{ mb: 3 }} />
          <Stack>
            <LabelStyle>Tip:</LabelStyle>
            <Box mt={1} />
            <Stack direction="row">
              <Divider
                orientation="vertical"
                sx={{ borderRightWidth: 'thick', borderRadius: 1, mr: 2 }}
              />
              <Typography variant="body2">{content.tip}</Typography>
            </Stack>
          </Stack>
          <Box sx={{ mb: 3 }} />
        </Stack>
      )}
    </Drawer>
  );
}

const StyledTextareaElement = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  font-family: "Public Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
