import { useEffect, useState } from 'react';
import { onSnapshot, doc, collection, query, where } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
// import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { WorkspaceBookings, setWorkspaceBookings } from '../redux/slices/workspaceBookingsSlice';

export default function useWorkspaceBooking() {
  const dispatch = useDispatch();
  const [isLoadingWorkspaceBookings, setisLoadingWorkspaceBookings] = useState(true);
  const workspaceBookingsCurrent = useSelector((state) => state.user.workspaceBookingsCurrent);
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_BOOKINGS]()),
        where(`members.${userId}.uid`, '==', userId)
      );

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.group('useWorkspaceBookings');
          const workspaceBookings = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as WorkspaceBookings[];
          async function setWorkspaceBookingData() {
            dispatch(
              setWorkspaceBookings({
                workspaces: workspaceBookings,
                current: workspaceBookingsCurrent,
              })
            );
            setisLoadingWorkspaceBookings(false);
            console.groupEnd();
          }

          setWorkspaceBookingData();
        },
        (error) => {
          console.error('useWorkspaceBookings', error);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingWorkspaceBookings };
}
