import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/Iconify';
import { CircularProgress, OutlinedInput } from '@mui/material';
import useZenfitLeadApiIntegrations from 'src/hooks/useZenfitLeadApiIntegrations';
import { useSelector } from 'src/redux/store';
import { selectLoadingState } from 'src/redux/slices/apiIntegrationsSlice';
import { useSnackbar } from 'notistack';
import { httpsCallable } from 'firebase/functions';
import { Functions } from 'src/contexts/FirebaseContext';
import { useTranslation } from 'react-i18next';

export default function ZenfitLeadKey({ userId }: { userId: string }) {
  useZenfitLeadApiIntegrations(userId);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const hasZenfitLeadAPI = useSelector((state) => state.apiIntegrations.zenfitLeadIds.length > 0);
  const loading = useSelector(selectLoadingState);
  const [apiKey, setApiKey] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = async () => {
    try {
      if (!apiKey || !userId) return alert('Something is not working!');
      setSubmitting(true);

      const functionRef = httpsCallable(Functions, 'setupZenfitLead');
      const response: any = await functionRef({
        userId,
        apiKey,
      });

      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }

      enqueueSnackbar(t('flow.setup.workspace.success'));
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });

      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Paper component="form" sx={{ display: 'flex', alignItems: 'center' }}>
      <OutlinedInput
        size="small"
        value={hasZenfitLeadAPI ? '*************' : apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        placeholder="Zenfit lead api key"
        disabled={submitting || loading || hasZenfitLeadAPI}
      />
      <IconButton
        type="button"
        disabled={submitting || loading || hasZenfitLeadAPI}
        onClick={handleSubmit}
      >
        {submitting ? <CircularProgress size={16} /> : <Iconify icon="material-symbols:save" />}
      </IconButton>
      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
      {/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
    </Paper>
  );
}
