import { useEffect, useState } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { Invite, setInviteLoadingState } from '../redux/slices/invites';
import { convertFStoDate } from '../utils/formatTime';

// if passed here it will load X users reviews
export default function useInvite(inviteId: string | null) {
  // const dispatch = useDispatch();
  const userId = useSelector((state) => state.workspaceReview.currentWorkspace?.id);
  const [invite, setInvite] = useState<Invite | null>(null);

  useEffect(() => {
    let unsubscribe: () => void;

    if (userId && inviteId) {
      // dispatch(setInviteLoadingState(RequestState.PENDING));
      const q = doc(DB, getCollection[Collection.USERS_INVITES](userId), inviteId);
      unsubscribe = onSnapshot(q, (doc) => {
        if (!doc.exists()) return null;
        const data = doc.data();
        const invite = {
          ...data,
          id: doc.id,
          createTime: convertFStoDate(data.createTime),
        } as Invite;

        setInvite(invite);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId, inviteId]);

  return invite;
}
