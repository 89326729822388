import React from 'react';
import { Alert, useTheme, AlertTitle, Typography, Stack } from '@mui/material';
import { InfoIcon } from '../../../theme/overrides/CustomIcons';
import { useSelector } from 'src/redux/store';
import { selectLeadFormSuggestions } from 'src/redux/slices/leadFormsSlice';
import { Question } from '../../../redux/slices/leadFormsSlice';
import { QuestionType } from '../QuestionsSection';
import Lottie, { aiLoader } from 'src/components/Lottie';
import { useTranslation } from 'react-i18next';

export default function Suggestions({ question }: { question: Question }) {
  const suggestions = useSelector(selectLeadFormSuggestions);
  const theme = useTheme();
  const { t } = useTranslation();
  const currentSuggestions = suggestions?.[question?.id];

  if (!currentSuggestions && question?.type !== QuestionType.RADIO) {
    return (
      <Alert
        severity="info"
        icon={false}
        sx={{
          p: 2,
          pt: 1.5,
          width: '100%',
          justifyContent: 'center',
          backgroundColor: theme.palette.primary.lighter,
        }}
      >
        <Stack
          alignSelf="center"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          width="100%"
        >
          <Typography variant="body1" color="primary.main">
            {t('leads.form.ai.suggestions')}
            {/* AI Getting suggestions */}
          </Typography>
          <Stack sx={{ width: 100 }}>
            <Lottie animation={aiLoader} />
          </Stack>
        </Stack>
      </Alert>
    );
  }
  if (!currentSuggestions && question?.type === QuestionType.RADIO) return null;
  if (currentSuggestions?.length < 1) return null;
  if (question?.type !== QuestionType.RADIO) {
    return (
      <Alert
        severity="info"
        icon={<InfoIcon sx={{ color: theme.palette.primary.light }} />}
        sx={{
          p: 2,
          pt: 1.5,
          justifySelf: 'center',
          backgroundColor: theme.palette.primary.lighter,
        }}
      >
        <Typography fontSize={14} color={theme.palette.primary.light}>
          {currentSuggestions?.join(', ')}
        </Typography>
      </Alert>
    );
  }
  return (
    <Alert
      severity="info"
      icon={<InfoIcon sx={{ color: theme.palette.primary.light }} />}
      sx={{
        p: 2,
        pt: 1.5,
        justifySelf: 'center',
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      <AlertTitle sx={{ fontSize: 14, mb: 0 }} color={theme.palette.primary.light}>
        {t('leads.form.options.maxSuggestions')}
      </AlertTitle>
      <Typography fontSize={14} color={theme.palette.primary.light}>
        {t('leads.form.others.asked')} <b>{currentSuggestions?.join(', ')}</b>
      </Typography>
    </Alert>
  );
}
