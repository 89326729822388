import { useEffect, useState } from 'react';
import { onSnapshot, doc, collection, query, where } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { WorkspaceReview } from '../redux/slices/workspaceReviewsSlice';
import { setWorkspaceFlow } from '../redux/slices/workspaceFlowSlice';

export default function useWorkspaceFlow() {
  const dispatch = useDispatch();
  const [isLoadingWorkspaceFlow, setisLoadingWorkspaceFlow] = useState(true);
  const workspaceFlowCurrent = useSelector((state) => state.user.workspaceFlowCurrent);
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_FLOW]()),
        where(`members.${userId}.uid`, '==', userId)
      );

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.group('useWorkspaceFlow');
          const workspaceFlow = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as WorkspaceReview[];

          async function setWorkspaceReviewData() {
            dispatch(
              setWorkspaceFlow({
                workspaces: workspaceFlow,
                current: workspaceFlowCurrent,
              })
            );
            setisLoadingWorkspaceFlow(false);
            console.groupEnd();
          }

          setWorkspaceReviewData();
        },
        (error) => {
          setisLoadingWorkspaceFlow(false);
          console.error('useWorkspaceFlow', error);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingWorkspaceFlow };
}
