import headerBarStarWidget from '../../assets/widget/TopLineReviewBar.png'; // '../../assets/widget/header_top_bar.png';
import microReviewCount from '../../assets/widget/micro_review_count.png';
import cardsWidget from '../../assets/widget/text_review.png'; // ' ../../assets/widget/gallery_view.png';
import videoWidget from '../../assets/widget/video_review.png'; // '../../assets/widget/gallery_view.png';
import textVideoWidget from '../../assets/widget/text_video_review.png'; // '../../assets/widget/gallery_view.png';
import floatingVideoWidget from '../../assets/widget/floating_video.png'; // '../../assets/widget/gallery_view.png';
// import beforeAfterWidget from '../../assets/widget/before_after_review.png';

export enum WidgetState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const widgetsConfig = {
  minireviewcount: {
    title: 'widgets.minireviewcount.title',
    cover: microReviewCount,
    isPremium: false,
  },
  headerbar: {
    title: 'widgets.headerbar.title',
    cover: headerBarStarWidget,
    isPremium: false,
  },
  videocarousel: {
    title: 'widgets.videocarousel.title',
    cover: videoWidget,
    isPremium: true,
  },
  textvideocarousel: {
    title: 'widgets.textvideocarousel.title',
    cover: textVideoWidget,
    isPremium: true,
  },
  textcarousel: {
    title: 'widgets.textcarousel.title',
    cover: cardsWidget,
    isPremium: true,
  },
  floatingvideo: {
    title: 'widgets.floatingvideo.title',
    cover: floatingVideoWidget,
    isPremium: true,
  },
  // beforeAfter: {
  //   title: 'widgets.beforeafter.title',
  //   cover: beforeAfterWidget,
  //   isPremium: true,
  // },
};
