import React from 'react';
import Page from '../../../components/Page';
import { Container, Grid, Skeleton, Stack } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export default function LoadingScreen() {
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  return (
    <Page title="Automations" sx={{ height: '100%' }}>
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Stack height="100%" width="100%">
              <Skeleton variant="rounded" height={50} width="100%" />
              <Box mt={8} />
              <Skeleton variant="rounded" height={150} width="100%" />
              <Box mt={6} />
              <Skeleton variant="rounded" height={150} width="100%" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Stack
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
              sx={{ height: 'calc(100vh - 100px)' }}
            >
              <Skeleton variant="rounded" height="100%" width="100%" />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
