import React from 'react';
import { useSelector } from '../redux/store';

const earlyUsers = [
  'evfYvSq29JNecLz8LNYEdZCJWeA2',
  'UlLkuQrkdidFr6q1Gq9nVkZECNJ3',
  'QQtIb7kGyTUWZODYXG2QdybbTA13',
  'LALh6MoO66cIdVsfA19yr7uCAvm2',
  'VcdLWKOm3BQCc651nfM48LkmToH2',
  'tTkTAf2gb4eTTGXUXIossIKnkxW2',
];

export const isEarlyUser = (uid?: string) => earlyUsers.includes(uid || '');

export default function EarlyUser({ children }: { children: React.ReactElement }) {
  const userId = useSelector((state) => state.user.id || '');

  if (isEarlyUser(userId)) {
    return children;
  }
  return null;
}
