import React, { useState } from 'react';
import { ChromePicker, Color } from 'react-color';
import { Popover, useTheme } from '@mui/material';
import Iconify from '../Iconify';
import { blue, green, lightBlue, orange, purple, red, grey, brown } from '@mui/material/colors';

function ColorPicker({
  color = undefined,
  disabled,
  onChange,
}: {
  color: Color | undefined;
  disabled?: boolean;
  onChange: (color: Color) => void;
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [tmpColor, setTmpColor] = useState(color);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const presetColors = [
    purple.A700,
    lightBlue.A700,
    green.A700,
    brown.A700,
    red.A700,
    orange.A700,
    blue.A700,
  ];
  return (
    <div>
      <div
        style={{
          display: 'flex',
          border: `1px solid ${grey[300]}`,
          borderRadius: 5,
          padding: 4,
        }}
      >
        {presetColors.map((color) => (
          <div
            key={color}
            role="button"
            style={{
              height: 24,
              width: '100%',
              backgroundColor: color,
              borderRadius: 4,
              cursor: disabled ? 'not-allowed' : 'pointer',
              marginRight: 3,
            }}
            onClick={() => {
              if (!disabled) {
                setTmpColor(color);
                onChange(color);
              }
            }}
          />
        ))}
        <div
          role="button"
          style={{
            height: 24,
            width: '100%',
            borderRadius: 4,
            cursor: disabled ? 'not-allowed' : 'pointer',
            border: `1px solid ${grey[300]}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          // @ts-ignore
          onClick={handleClick}
        >
          <Iconify
            style={{
              color: theme.palette.primary.main,
              // backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
            }}
            icon={'akar-icons:hashtag'}
            width={18}
            height={18}
          />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div>
          <ChromePicker
            color={tmpColor}
            onChange={(c: any) => setTmpColor(c.hex)}
            onChangeComplete={(c: any) => onChange(c.hex)}
          />
        </div>
      </Popover>
    </div>
  );
}

export default ColorPicker;
