// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  profile: '/company/:id',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    imageEditor: path(ROOTS_DASHBOARD, '/image-editor'),
    // review
    app: path(ROOTS_DASHBOARD, '/app'),
    invites: path(ROOTS_DASHBOARD, '/invites'),
    reviews: path(ROOTS_DASHBOARD, '/reviews'),
    integrations: path(ROOTS_DASHBOARD, '/integrations'),
    integrationsNew: path(ROOTS_DASHBOARD, '/integrations/new'),
    social: path(ROOTS_DASHBOARD, '/social'),
    videoCustomisation: path(ROOTS_DASHBOARD, '/video-customisation'),
    reviewAutomations: path(ROOTS_DASHBOARD, '/automations'),
    // SIMPLY FLOW
    appFlow: path(ROOTS_DASHBOARD, '/flow/app'),
    videoFlow: path(ROOTS_DASHBOARD, '/flow/integrations-flow'),
    videoFlowWizard: path(ROOTS_DASHBOARD, '/flow/integrations-flow'),
    integrationsFlowNew: path(ROOTS_DASHBOARD, '/flow/integrations-flow/new'),
    // LEADS
    appLeads: path(ROOTS_DASHBOARD, '/leads/app'),
    leadsForm: path(ROOTS_DASHBOARD, '/leads/form'),
    leadSubmissions: path(ROOTS_DASHBOARD, '/leads/list'),
    leadCustomisePage: path(ROOTS_DASHBOARD, '/leads/customise-page'),
    leadEmbedOnWebsite: path(ROOTS_DASHBOARD, '/leads/embed-on-website'),
    // LINKS:
    appLinks: path(ROOTS_DASHBOARD, '/links/app'),
    linksCustomisePage: path(ROOTS_DASHBOARD, '/links/customise-page'),
    // BOOKING
    appBooking: path(ROOTS_DASHBOARD, '/booking/app'),
    bookingSchedules: path(ROOTS_DASHBOARD, '/booking/schedules'),
    bookingList: path(ROOTS_DASHBOARD, '/booking/list'),
    // CHAT
    chatConversations: path(ROOTS_DASHBOARD, '/chat/conversations'),
    // CONTENT
    appContent: path(ROOTS_DASHBOARD, '/content/app'),
    // OTHER
    imageModule: path(ROOTS_DASHBOARD, '/image-module'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
