import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import axios from 'axios';
import { collection, addDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { getCollection } from 'src/api';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection } from 'src/enum';
import { backgroundContext, targetAudience } from './context';

// const REACT_APP_API_URL = 'http://localhost:9000';

// const socket = io(REACT_APP_API_URL);

export default function useChat() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  // useEffect(() => {
  //   socket.on('chatgptResChunk', (data: { chatID: string; content: string }) => {
  //     setMessage(() => data.content);
  //   });

  //   socket.on('resError', (data: { chatID: string; error: unknown }) => {
  //     console.error(data);
  //   });
  // }, [socket]);
  const handleSubmit = async (userPrompt: string, systemInstructions?: string) => {
    if (!userPrompt) return;
    try {
      setLoading(true);
      setMessage('');
      await axios.post('http://localhost:9000/api/chat/createMessage', {
        system:
          systemInstructions ||
          'Write as short as possible. You ALWAYS reply in markdown, You can also use bullets, bold, italic. No other tags/formats allowed!',
        message: {
          role: 'user',
          content: userPrompt,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return { loading, message, handleSubmit };
}
export function useGeneratePost() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (userPrompts: string[], systemInstructions?: string) => {
    if (!userPrompts) return;
    try {
      setLoading(true);
      setMessage('');
      await Promise.allSettled(
        userPrompts.map(async (userPrompt) => {
          const res = await axios.post('http://localhost:9000/api/chat/createMessagePost', {
            system:
              systemInstructions ||
              `You are coaching social media. You decide if it should be a post, story, reel, tiktok, etc. 
[CONTEXT_ABOUT_YOUR_CLIENT]: ${backgroundContext}.
[TARGET_AUDIENCE]: ${targetAudience}.
ALWAYS output in the following format of a SINGLE JSON object:
{
  title: // example caption for the content
  description: // write an outline for content in bullets, thought not too many, then explain how to create it in actionable steps, expand on your instructions. Don't include too much info (write this is markdown language)
  tip: // tip for your client to make the content great
  tags: // tags of what the content is about format: string array, example: ["clothes", "fashion"]
  imagePrompt: // image prompt to generate an image visualizing the content, avoid humans and text
  videoPrompt: // video prompt to generate a video visualizing the content, avoid humans and text
  estimatedTime: // time to create content in minutes
  whenToPublish: // time of day when it is suggested to publish content piece format: time 24h.
  unsplashKeyword: // keyword to search for an image on unsplash that matches the content
  contentType: // type of content, choose from: [IMAGE, VIDEO, CAROUSEL]
}`,
            message: {
              role: 'user',
              content: userPrompt,
            },
          });
          addDoc(collection(DB, getCollection[Collection.CONTENT]()), {
            ...res.data,
            createTime: new Date(),
          });
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return { loading, message, handleSubmit };
}
export function useGenerateTopics(callback: (topics: string[]) => void) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (userPrompt: string, systemInstructions?: string) => {
    if (!userPrompt) return;
    try {
      setLoading(true);

      const res = await axios.post('http://localhost:9000/api/chat/createMessageTopics', {
        system:
          systemInstructions ||
          `Write some different topics. Be concise, avoid being generic and repetitive. You Always reply with a JSON string array, example format: ["1","2","3"]`,
        message: {
          role: 'user',
          content: userPrompt,
        },
      });
      callback(res.data.topics);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return { loading, handleSubmit };
}
