import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// @mui
import { Tooltip, TextField, IconButton, InputAdornment } from '@mui/material';
//
import Iconify from './Iconify';
import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { fontSize } from '@mui/system';

// ----------------------------------------------------------------------

type Props = {
  value: string;
  startAdornment?: React.ReactNode;
  dataId?: string;
  multiline?: boolean;
};

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    fontSize: 14,
  },
});

export default function CopyInviteLinkSmall({ value, startAdornment, ...other }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onCopy = () => {
    if (value) {
      enqueueSnackbar(t('general.copied'));
    }
  };

  return (
    <CssTextField
      data-joyride-id={other.dataId}
      fullWidth
      value={value}
      color="primary"
      size="small"
      multiline={other.multiline}
      disabled
      InputProps={{
        startAdornment: startAdornment || null,
        endAdornment: (
          <InputAdornment position="end" sx={{ width: 24 }}>
            <CopyToClipboard text={value} onCopy={onCopy}>
              <Tooltip title={t('general.copy') as string}>
                <IconButton>
                  <Iconify
                    style={{
                      color: theme.palette.primary.main,
                      // backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
                    }}
                    icon={'eva:copy-fill'}
                    width={24}
                    height={24}
                  />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}
