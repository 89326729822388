import { useEffect, useState, useRef } from 'react';
// @types
//
import Scrollbar from '../../../components/Scrollbar';
import LightboxModal from '../../../components/LightboxModal';
import ChatMessageItem from './ChatMessageItem';
import { Conversation } from 'src/redux/slices/chat';
import { useSelector } from 'src/redux/store';
import useMessages from 'src/hooks/useMessages';

// ----------------------------------------------------------------------

type Props = {
  conversation: Conversation;
};

export default function ChatMessageList({ conversation }: Props) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { messages } = useMessages(conversation?.id);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [messages]);

  const imagesLightbox = messages
    // .filter((messages) => messages.contentType === 'image')
    .map((messages) => messages.message);

  const handleOpenLightbox = (url: string) => {
    setSelectedImage(url);
  };

  return (
    <>
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
        {messages.map((message) => (
          <ChatMessageItem
            key={message.id}
            message={message}
            conversation={conversation}
            onOpenLightbox={handleOpenLightbox}
          />
        ))}
      </Scrollbar>

      <LightboxModal
        mainSrc={selectedImage || ''}
        isOpen={Boolean(selectedImage)}
        onCloseRequest={() => setSelectedImage(null)}
      />
    </>
  );
}
