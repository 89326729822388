import { Button } from '@mui/material';
import Iconify from '../Iconify';

interface ExternalLinkButtonProps {
  href: string;
  children: React.ReactNode;
}

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({ href, children }) => (
  <Button
    component="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    endIcon={<Iconify icon="tabler:external-link" />}
    variant="text" // or "text" for a link style
  >
    {children}
  </Button>
);

export default ExternalLinkButton;
