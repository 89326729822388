import React, { useEffect } from 'react';
import { AUTH, realtimeDB } from '../contexts/FirebaseContext';
import { onDisconnect, onValue, push, ref, serverTimestamp, set } from 'firebase/database';
import { useSelector } from '../redux/store';

export default function usePresence() {
  const userId = useSelector((state) => state.user.id);
  useEffect(() => {
    // Fetch the current user's ID from Firebase Authentication.
    if (!userId) return;
    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    const isOfflineForDatabase = {
      state: 'offline',
      offline_at: serverTimestamp(),
    };

    const isOnlineForDatabase = {
      state: 'online',
      online_at: serverTimestamp(),
    };
    // Since I can connect from multiple devices or browser tabs, we store each connection instance separately
    // any time that connectionsRef's value is null (i.e. has no children) I am offline
    const db = realtimeDB;
    const myConnectionsRef = ref(db, `users/${userId}`);

    // stores the timestamp of my last disconnect (the last time I was seen online)
    const lastOnlineRef = ref(db, `users/${userId}`);

    const connectedRef = ref(db, '.info/connected');
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
        const con = myConnectionsRef;

        // When I disconnect, remove this device
        onDisconnect(con).remove();

        // Add this device to my connections list
        // this value could contain info about the device or a timestamp too
        set(con, isOnlineForDatabase);

        // When I disconnect, update the last time I was seen online
        onDisconnect(lastOnlineRef).set(isOfflineForDatabase);
      }
    });
    return () => {};
  }, [userId]);
}
