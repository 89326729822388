import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useSelector } from '../redux/store';
import { Platforms } from '../redux/slices/platform';
import { styled } from '@mui/material/styles';
import Logo from '../components/SRLogo';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  selectCurrentPlatformIsTrailing,
  selectHasCurrentPlatformWorkspaces,
  selectHasCurrentPlatformPremium,
} from '../redux/selectors/workspaces';
import { selectPlatform, selectUser } from '../redux/slices/userSlice';
import PricingPlanCardReview from '../sections/pricing/PricingPlanCardReview';
import PricingPlanCardReviewTeam from '../sections/pricing/PricingPlanCardReviewTeam';
import PricingPlanCardFlow from '../sections/pricing/PricingPlanCardFlow';
import PricingPlanCardPremiumLeads from '../sections/pricing/PricingPlanCardLeads';
import SubscriptionModalTitle from './SubscriptionModalTitle';
import { selectHasStripeLoaded } from 'src/redux/slices/stripeSlice';
import { Loader } from 'src/components/LoadingScreen';

export default function SubscriptionModal() {
  const { t } = useTranslation();
  // const [loading, setLoading] = React.useState(false);
  const trial = useSelector(selectCurrentPlatformIsTrailing);
  const premium = useSelector(selectHasCurrentPlatformPremium);
  const platform = useSelector(selectPlatform);
  const hasStripeLoaded = useSelector(selectHasStripeLoaded);
  const hasPlatformWorkspaces = useSelector(selectHasCurrentPlatformWorkspaces);
  const user = useSelector(selectUser);

  /**
   * Block if user:
   * - ended trial
   * - is not premium
   * - is logged in
   * - platform has loaded
   * - has workspaces
   */
  const shouldBlock =
    trial?.isTrialing === false && !premium && Boolean(user?.id) && Boolean(hasPlatformWorkspaces);

  return (
    <StyledDialog fullWidth maxWidth={'lg'} open={shouldBlock} sx={{ marginLeft: 8 }}>
      {/* <DialogTitle>Optional sizes</DialogTitle> */}
      <DialogContent>
        {hasStripeLoaded ? (
          <>
            <Stack justifyContent="center" alignItems="center">
              <Logo />
            </Stack>
            <Box mt={4} />
            <SubscriptionModalTitle />
            <Box mt={4} />
            <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
              {platform === Platforms.SIMPLY_REVIEW && (
                <>
                  <PricingPlanCardReview index={1} />
                  <PricingPlanCardReviewTeam index={1} />
                </>
              )}
              {platform === Platforms.SIMPLY_FLOW && <PricingPlanCardFlow index={1} />}
              {platform === Platforms.SIMPLY_LEADS && <PricingPlanCardPremiumLeads index={1} />}
            </Stack>
            <Box mt={4} />
          </>
        ) : (
          <Stack justifyContent="center" height={400} alignItems="center">
            <Loader />
          </Stack>
        )}
      </DialogContent>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(({ theme }) => ({}));
