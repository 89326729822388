import { ReactNode, useMemo } from 'react';
// @mui
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import componentsOverride from '../theme/overrides';
import { useSelector } from '../redux/store';
import { getPalette } from '../theme';
import { selectPlatform } from '../redux/slices/userSlice';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeColorPresets({ children }: Props) {
  const defaultTheme = useTheme();
  const platform = useSelector(selectPlatform);
  const { setColor } = useSettings();
  const paletteOverride = getPalette(platform);
  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      // palette: {
      ...paletteOverride,
      // },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(paletteOverride.light.primary.main, 0.24)}`,
      },
    }),
    [setColor, defaultTheme]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
