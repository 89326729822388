import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

export default function SkeletonTableList({ sx }: any) {
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Skeleton animation="wave" height={60} width="100%" />
      <Skeleton animation="wave" height={60} />
      <Skeleton animation="wave" height={60} />
    </Box>
  );
}
