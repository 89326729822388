import React, { useEffect } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { setNotifications, Notification } from '../redux/slices/notificationSlice';
import { Collection } from '../enum';
import { useDispatch } from '../redux/store';
import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';

export default function useNotifications() {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const userId = user?.id;
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.USERS_NOTIFICATIONS](userId)),
        // where('seen', '==', false),
        orderBy('createTime', 'desc'),
        limit(100)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const notifications = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Notification)
        );
        dispatch(setNotifications(notifications));
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);

  return <div />;
}
