import { sentenceCase } from 'change-case';

export function getInitials(name: string) {
  return sentenceCase(
    name
      .split(' ')
      .map((word, i) => (i < 2 ? word[0] : null))
      .filter(Boolean)
      .join('')
  );
}

export function getFirstAndLastName(name: string) {
  const [firstName, lastName] = name.split(' ');
  return { firstName, lastName };
}

export function getFirstName(name: string) {
  const firstName = name?.split(' ')[0];
  return firstName;
}
