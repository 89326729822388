import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intl, RequestState } from '../../enum';
import type { RootState } from '../store';
import { Timestamp } from 'firebase/firestore';
import { Question } from './leadFormsSlice';
import { QuestionType } from 'src/pages/leads/QuestionsSection';
import { keyBy } from 'lodash';
import { LeadActivityType } from 'src/hooks/useLeadActivity';

export type Answers = {
  [key: string]:
    | string
    | {
        'fitness-5-option-1': string;
        'fitness-5-option-2': string;
        'fitness-5-option-3': { code: string; phone: string };
        'fitness-5-option-4'?: string;
      };
};

export enum LeadType {
  MANUAL = 'MANUAL',
}

interface LeadFormSubmissionBase {
  id: string;
  // type?: LeadType;
  createTime: Date;
  status?: 1 | 2 | 3 | 4 | 5 | 6 | null;
  note?: string;
  forwardedToZenfit?: boolean;
  lastestActivity?: {
    content: string;
    createTime: Date;
    updateTime: Date;
    type: LeadActivityType;
    author: {
      id: string;
      email: string;
      name?: string;
    };
  };
}

export interface LeadFormSubmissionManual extends LeadFormSubmissionBase {
  type: LeadType.MANUAL;
  name?: string;
  email?: string;
  phone?: string;
  instagram?: string;
}

export interface LeadFormSubmission extends LeadFormSubmissionBase {
  answers: Answers;
  type: undefined;
  formId: Intl;
  questions: Question[];
  scriptRecommendation: string;
  origin: {
    hostname: string;
    protocol: string;
    url: string;
  };
  emailValidation: {
    reason: string;
    valid: boolean;
    validators: {
      disposable: { valid: boolean };
      regex: { valid: boolean };
      mx: { valid: boolean };
      typo: { valid: boolean };
    };
  };
  phoneValidation: {
    isValidPhoneNumber: boolean;
  };
}

// Define a type for the slice state
interface LeadFormSubmissionsState {
  list: (LeadFormSubmission | LeadFormSubmissionManual)[];
  map: { [key: string]: LeadFormSubmission | LeadFormSubmissionManual };
  loading: RequestState;
}

// Define the initial state using that type
const initialState: LeadFormSubmissionsState = {
  list: [],
  map: {},
  loading: RequestState.IDLE,
};

export const leadFormsSubmissionsSlice = createSlice({
  name: 'leadFormsSubmissions',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLeadFormsSubmissionsLoadingState: (state) => ({
      ...state,
      loading: RequestState.PENDING,
    }),
    setLeadFormsSubmissions: (state, action: PayloadAction<LeadFormSubmission[]>) => ({
      ...state,
      list: action.payload,
      map: keyBy(action.payload, 'id'),
      loading: RequestState.RESOLVED,
    }),
  },
});

export const { setLeadFormsSubmissionsLoadingState, setLeadFormsSubmissions } =
  leadFormsSubmissionsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const loadingLeadFormSubmissionsState = (state: RootState) =>
  state.leadFormsSubmissions.loading === RequestState.IDLE ||
  state.leadFormsSubmissions.loading === RequestState.PENDING;

export const selectHasLeads = (state: RootState) => state.leadFormsSubmissions.list.length > 0;

export const selectLeadSubmissions = (state: RootState) =>
  state.leadFormsSubmissions.list.map((item) => {
    if (item?.type === LeadType.MANUAL) {
      return {
        id: item.id,
        createTime: item.createTime,
        name: item.name,
        email: item.email,
        phone: item.phone,
        instagram: item.instagram,
        status: item.status,
        type: item.type,
        isPhoneValid: true,
        isEmailValid: true,
      };
    }
    const form = Object.entries(item.answers).reduce((acc, [key, value]) => {
      const question = item.questions.find((question) => question.id === key);
      if (question) {
        if (question.type === QuestionType.CONTACT && typeof value === 'object') {
          return {
            ...acc,
            name: value['fitness-5-option-1'],
            email: value['fitness-5-option-2'],
            phone: value['fitness-5-option-3'].code + value['fitness-5-option-3'].phone,
            instagram: value['fitness-5-option-4'],
          };
        }
        return {
          ...acc,
          [question.content]: value,
        };
      }
      return acc;
    }, {} as { [key: string]: any });
    return {
      ...form,
      id: item.id,
      createTime: item.createTime,
      name: form.name,
      email: form.email,
      phone: form.phone,
      instagram: form.instagram,
      status: item.status,
      scriptRecommendation: item.scriptRecommendation,
      utms: extractUTMParams(item.origin.url),
      isEmailValid: item.emailValidation
        ? item.emailValidation?.validators?.disposable?.valid &&
          item.emailValidation?.validators?.regex?.valid &&
          item.emailValidation?.validators?.mx?.valid &&
          item.emailValidation?.validators?.typo?.valid
        : true,
      isPhoneValid: item.phoneValidation ? item.phoneValidation?.isValidPhoneNumber : true,
    };
  });

export const selectLeadSubmission = (state: RootState, id: string | null) => {
  if (!id) return null;
  const item = state.leadFormsSubmissions.map[id];
  if (!item) {
    return null;
  }
  if (item.type === LeadType.MANUAL) {
    return {
      id: item.id,
      createTime: item.createTime,
      name: item.name,
      email: item.email,
      phone: item.phone,
      instagram: item.instagram,
      status: item.status,
      type: item.type,
      isPhoneValid: true,
      isEmailValid: true,
      utms: undefined,
      scriptRecommendation: null,
      note: item.note,
    };
  }

  const form = Object.entries(item.answers).reduce((acc, [key, value]) => {
    const question = item.questions.find((question) => question.id === key);
    if (question) {
      if (question.type === QuestionType.CONTACT && typeof value === 'object') {
        return {
          ...acc,
          name: value['fitness-5-option-1'],
          email: value['fitness-5-option-2'],
          phone: value['fitness-5-option-3'].code + value['fitness-5-option-3'].phone,
          instagram: value['fitness-5-option-4'],
        };
      }
      return {
        ...acc,
        [question.id]: value,
      };
    }
    return acc;
  }, {} as { [key: string]: any });
  return {
    ...form,
    id: item.id,
    createTime: item.createTime,
    name: form.name,
    email: form.email,
    phone: form.phone,
    instagram: form.instagram,
    note: item.note,
    utms: extractUTMParams(item.origin.url),
    scriptRecommendation: item.scriptRecommendation,
    status: item.status,
    questions: item.questions.filter((question) => question.type !== QuestionType.CONTACT),
    isEmailValid: item.emailValidation
      ? item.emailValidation?.validators?.disposable?.valid &&
        item.emailValidation?.validators?.regex?.valid &&
        item.emailValidation?.validators?.mx?.valid &&
        item.emailValidation?.validators?.typo?.valid
      : true,
    isPhoneValid: item.phoneValidation ? item.phoneValidation?.isValidPhoneNumber : true,
  };
};

export default leadFormsSubmissionsSlice.reducer;

function extractUTMParams(url: string) {
  const regex = /[?&](utm_[^=]+)=([^&]+)/g;
  let match;
  let utmParams: { [key: string]: string } = {};

  while (true) {
    match = regex.exec(url);
    if (!match) break;
    let key = decodeURIComponent(match[1]);
    let value = decodeURIComponent(match[2]);
    utmParams[key] = value;
  }

  return utmParams;
}
