import { capitalCase } from 'change-case';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Avatar, Typography, AvatarGroup, IconButton, Stack } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// @types
// import { Participant } from '../../../@types/chat';
// components
import Iconify from '../../../components/Iconify';
import BadgeStatus from '../../../components/BadgeStatus';
import { Participant } from 'src/redux/slices/chat';
import { getInitials } from 'src/utils/names';
import { useUserLocally } from 'src/hooks/useUser';
import { UserDoc } from 'src/redux/slices/userSlice';
import { PublicConfig } from 'src/redux/slices/publicConfig';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';
import SimplyLinksIcon from 'src/assets/Logo/SimplyLinksIcon';
import { LEADS, LINKS, REVIEW } from 'src/theme/palette';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  minHeight: 92,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  participants: Participant[];
};

export default function ChatHeaderDetail({ participants }: Props) {
  const isGroup = participants.length > 1;
  const externalUser = participants.find((participant) => participant.type === 'EXTERNAL');
  const { configLocally, userLocally, isLoadingUser } = useUserLocally(externalUser?.uid);

  return (
    <RootStyle>
      {/* {isGroup ? (
        <GroupAvatar participants={participants} />
      ) : ( */}
      <OneAvatar configLocally={configLocally} chatUser={{ ...externalUser, ...userLocally }} />
      {/* )} */}

      <Box sx={{ flexGrow: 1 }} />
      <Stack direction="row" spacing={1}>
        {configLocally?.title && (
          <IconButton sx={{ height: 40, width: 40, backgroundColor: REVIEW.darker }}>
            <SimplyReviewIcon width={10} color={REVIEW.light} />
          </IconButton>
        )}
        {configLocally?.leadPageName && (
          <IconButton sx={{ height: 40, width: 40, backgroundColor: LEADS.dark }}>
            <Stack>
              <SimplyLeadsIcon width={18} color={LEADS.light} />
            </Stack>
          </IconButton>
        )}

        {configLocally?.linkPageName && (
          <IconButton sx={{ height: 40, width: 40, backgroundColor: LINKS.darker }}>
            <SimplyLinksIcon width={18} color={LINKS.light} />
          </IconButton>
        )}
      </Stack>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

function OneAvatar({
  configLocally,
  chatUser,
}: {
  configLocally: PublicConfig;
  chatUser: UserDoc & Participant;
}) {
  if (!chatUser) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <Avatar src={chatUser.photoURL} alt={chatUser.displayName}>
          {chatUser.displayName && getInitials(chatUser.displayName)}
        </Avatar>
        {/* <BadgeStatus
          status={participant.status}
          sx={{ position: 'absolute', right: 2, bottom: 2 }}
        /> */}
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">
          {chatUser.displayName}
          {configLocally?.companyName ? ` - (${configLocally.companyName})` : ''}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {chatUser?.email}
        </Typography>
        <Typography variant="body2" fontSize={12} color="text.secondary">
          {configLocally?.userId}
        </Typography>

        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {participant.status !== 'offline'
            ? capitalCase(participant.status)
            : fToNow(participant.lastActivity || '')}
        </Typography> */}
      </Box>
    </Box>
  );
}

// ----------------------------------------------------------------------

function GroupAvatar({ participants }: Props) {
  return (
    <div>
      <AvatarGroup
        max={3}
        sx={{
          mb: 0.5,
          '& .MuiAvatar-root': { width: 32, height: 32 },
        }}
      >
        {participants.map((participant) => (
          <Avatar key={participant.uid} alt={participant.displayName} src={participant.photoURL}>
            {getInitials(participant.displayName)}
          </Avatar>
        ))}
      </AvatarGroup>
      <Link
        variant="body2"
        underline="none"
        component="button"
        color="text.secondary"
        onClick={() => {}}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {participants.length} persons
          <Iconify icon="eva:arrow-ios-forward-fill" />
        </Box>
      </Link>
    </div>
  );
}
