import { Card, Skeleton, Stack } from '@mui/material';
import Image from '../../../../../components/Image';
import zenfit from '../../../../../assets/integrations/zenfit.png';

export default function LoadingZenfit() {
  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Stack alignItems="center" py={3}>
          <Image src={zenfit} alt="zenfit" />
        </Stack>
        <Skeleton variant="rounded" height={36} />
      </Stack>
    </Card>
  );
}
