import React, { useEffect, useState } from 'react';
import ReactJoyride from 'react-joyride';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

const tour = {
  dashboard: [
    {
      target: '[data-joyride-id=dashboard-invitelink]',
      title: 'You share this link to get reviews!',
      content: 'You share this link to get reviews!',
      placement: 'bottom',
      scrollOffset: 120,
      disableBeacon: true,
      // disableScrollParentFix: true,
    },
    {
      target: '[data-joyride-id=dashboard-rating]',
      title: 'This is where your overall review rating will be shown!',
      content: 'This is where your overall review rating will be shown!',
      disableBeacon: true,
    },
    {
      target: '[data-joyride-id=dashboard-recent-reviews]',
      title: 'This is where your overall review rating will be shown!',
      content: 'This is where your overall review rating will be shown!',
      disableBeacon: true,
    },
    {
      target: '[data-joyride-id=dashboard-nav-reviews]',
      title: 'This is where your overall review rating will be shown!',
      content: 'This is where your overall review rating will be shown!',
      disableBeacon: true,
    },
    {
      target: '[data-joyride-id=dashboard-nav-integrations]',
      title: 'This is where your overall review rating will be shown!',
      content: 'This is where your overall review rating will be shown!',
      disableBeacon: true,
    },
    {
      target: '[data-joyride-id=dashboard-header-notification]',
      title: 'This is where your overall review rating will be shown!',
      content: 'This is where your overall review rating will be shown!',
      disableBeacon: true,
    },
  ],
};

export default function Joyrider() {
  const [steps, setsteps] = useState<{ target: string; content: string }[]>([]);

  const theme = useTheme();
  const l = useLocation();
  console.log({ l });

  useEffect(() => {
    setTimeout(() => {
      switch (l.pathname) {
        case '/dashboard/app':
          // setsteps(tour.dashboard);
          // TODO: setOnboarding to true
          break;

        default:
          break;
      }
    }, 2000);

    return () => {};
  }, [l.pathname]);
  const defaultOptions = {
    arrowColor: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: theme.palette.primary.main,
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: theme.palette.common.black,
    width: undefined,
    zIndex: 10000,
  };
  return (
    <ReactJoyride
      steps={steps}
      continuous
      disableScrolling
      locale={{
        back: 'Back',
        close: 'Close',
        last: 'End tour',
        next: 'Next',
        open: 'Open the dialog',
        skip: 'Skip',
      }}
      styles={{ options: defaultOptions }}
    />
  );
}
