import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Typography } from '@mui/material';
// @types
// components
import Image from '../../../components/Image';
import { Conversation } from 'src/redux/slices/chat';
import { Message } from 'src/hooks/useMessages';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type Props = {
  message: Message;
  conversation: Conversation;
  onOpenLightbox: (value: string) => void;
};

export default function ChatMessageItem({ message, conversation, onOpenLightbox }: Props) {
  const userId = useSelector((state) => state.user.id);
  const sender = {
    avatar: message.sender.photoURL,
    name: 'Me',
  };
  const senderDetails =
    message.sender.uid === userId ? { type: 'me' } : { avatar: sender?.avatar, name: sender?.name };

  const isInternal = conversation?.participants?.[message.sender.uid]?.type !== 'EXTERNAL';
  const isMe = message.sender.uid === userId;
  const isImage = message?.image?.original; // message.contentType === 'image';
  const firstName = message.sender.displayName && message.sender.displayName.split(' ')[0];

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          ...(isMe && {
            ml: 'auto',
          }),
        }}
      >
        {!isMe && (
          <Avatar
            alt={senderDetails.name}
            src={senderDetails.avatar || ''}
            sx={{ width: 24, height: 24, mr: 2 }}
          />
        )}

        <div>
          <InfoStyle
            variant="caption"
            sx={{
              ...(isInternal && { justifyContent: 'flex-end' }),
            }}
          >
            {!isMe && `${firstName},`}&nbsp;
            {formatDistanceToNowStrict(new Date(message.updateTime), {
              addSuffix: true,
            })}
          </InfoStyle>

          <ContentStyle
            sx={{
              ...(isInternal && { color: 'grey.800', bgcolor: 'primary.lighter' }),
              // ...(isImage && { p: 0 }),
            }}
          >
            {isImage ? (
              <Image
                alt="attachment"
                src={message?.image?.thumbnail || message?.image?.original}
                onClick={() => onOpenLightbox(message.image?.original as string)}
                sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
              />
            ) : (
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {message.message}
              </Typography>
            )}
          </ContentStyle>
        </div>
      </Box>
    </RootStyle>
  );
}
