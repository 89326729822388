import { AvatarGroup, Avatar, IconButton, Tooltip } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { ModalType, setModal } from '../../../redux/slices/modal';
import { useSelector } from '../../../redux/store';
import { Member } from '../../../redux/slices/workspaceReviewsSlice';
import { getInitials } from '../../../utils/names';
import { selectIsSuperOwner } from '../../../redux/slices/userSlice';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { TeamInvite } from '../../../redux/slices/teamInviteSlice';
import { selectHasCurrentPlatformPremium } from '../../../redux/selectors/workspaces';

export default function Team({
  pendingInvites,
  members,
}: {
  pendingInvites: TeamInvite[];
  members: Member[];
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const hasPremium = useSelector(selectHasCurrentPlatformPremium);
  const isSuperOwner = useSelector(selectIsSuperOwner);
  const userId = useSelector((state) => state.user.id);
  const theme = useTheme();
  const bgColors = [
    theme.palette.primary.lighter,
    theme.palette.primary.light,
    theme.palette.primary.dark,
    theme.palette.primary.darker,
  ];

  const handleInviteVoid = (inviteId: string) => {
    if (isSuperOwner) {
      return dispatch(setModal({ type: ModalType.REMOVE_MEMBER, context: { inviteId } }));
    }
    return enqueueSnackbar(t('team.invites.guard.owner'), { variant: 'warning' });
  };

  const handleRemoveMember = (memberId: string) => {
    if (isSuperOwner && userId !== memberId) {
      return dispatch(setModal({ type: ModalType.REMOVE_MEMBER, context: { userId: memberId } }));
    }
    if (isSuperOwner && userId === memberId) {
      return enqueueSnackbar(t('team.members.guard.removeYou'), {
        variant: 'warning',
      });
    }
    return enqueueSnackbar(t('team.members.guard.owner'), { variant: 'warning' });
  };

  const handleInviteTeam = () => {
    if (hasPremium) {
      dispatch(setModal({ type: ModalType.INVITE_MEMBER, context: null }));
    } else {
      return enqueueSnackbar(t('general.premiumFeature'), { variant: 'error' });
    }
  };
  return (
    <AvatarGroup
      sx={{
        '& .MuiAvatar-root': { width: 32, height: 32 },
      }}
      max={99}
    >
      {pendingInvites.map((invite, i) => (
        <Tooltip title={t('team.invitePending.tooltip', { displayName: invite.displayName })}>
          <Avatar
            key={i}
            sx={{ cursor: 'pointer', backgroundColor: 'grey.300' }}
            alt={invite.displayName}
            onClick={() => handleInviteVoid(invite.id)}
          >
            <Iconify icon="mdi:account-pending" />
          </Avatar>
        </Tooltip>
      ))}
      {members.map((member, i) => {
        const memberName = member?.displayName || member.email;
        return (
          <Tooltip title={memberName}>
            <Avatar
              key={i}
              sx={{ cursor: 'pointer', backgroundColor: bgColors[i] }}
              alt={memberName}
              onClick={() => {
                handleRemoveMember(member.uid);
              }}
            >
              {getInitials(memberName)}
            </Avatar>
          </Tooltip>
        );
      })}
      <Tooltip title={t('team.invite.add.tooltip')}>
        <AddIconButton
          aria-label="invite-team"
          sx={{ ml: -0.5, mt: 0.25 }}
          onClick={handleInviteTeam}
        >
          <Iconify icon="mdi:user-add" color="primary.main" />
        </AddIconButton>
      </Tooltip>
    </AvatarGroup>
  );
}

const AddIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 32,
  border: `1px solid transparent`,
  backgroundColor: theme.palette.primary.lighter,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));
