import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { getCollection } from 'src/api';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection } from 'src/enum';
import { WorkspaceLeads } from 'src/redux/slices/workspaceLeadsSlice';
import UpgradeUser from '../UserAdminActions/UpgradeUser';
import { Platforms } from 'src/redux/slices/platform';
import { LabelStyle } from './Content';
import DowngradeUser from '../UserAdminActions/DowngradeUser';
import { Integration, IntegrationState } from 'src/redux/slices/integrationSlice';
import { Stats } from 'src/redux/slices/reviewSlice';
import { PublicConfig } from 'src/redux/slices/publicConfig';
import { Invite } from 'src/redux/slices/invites';
import Iconify from 'src/components/Iconify';
import ChangeTrialStart from '../UserAdminActions/ChangeTrialStart';
import ZenfitLeadKey from './ZenfitLead';

export default function Leads({
  userId,
  displayName,
  publicConfig,
  reviewStats,
  widgets,
  invite,
  workspaceLeads,
}: {
  publicConfig: PublicConfig | null;
  reviewStats: Stats | null;
  widgets: Integration[];
  invite: Invite | null;
  userId: string;
  displayName: string;
  workspaceLeads: WorkspaceLeads | null;
}) {
  if (!workspaceLeads) {
    return (
      <>
        <Stack direction="row">
          <Stack sx={{ width: '100%' }}>No Leads workspace found</Stack>
        </Stack>
      </>
    );
  }
  // const completedReviewPageSetup = Boolean(publicConfig?.title && publicConfig?.description);
  // const firstReview = Boolean((reviewStats?.reviewCount as number) > 0);
  // const hasWidget = Boolean(widgets.length > 0);
  // const completedSetupCompanyPage = Boolean(
  //   publicConfig?.companyPageLogo && publicConfig?.companyName
  // );
  // const completedWidgetSetup = Boolean(
  //   widgets.find((widget) => widget.state === IntegrationState.ACTIVE)
  // );
  // const hasInvite = Boolean(invite);
  // const tasks = [
  //   { title: 'Setup review page', completed: completedReviewPageSetup },
  //   { title: 'Setup company page', completed: completedSetupCompanyPage },
  //   { title: 'First invite', completed: hasInvite },
  //   { title: 'First review', completed: firstReview },
  //   { title: 'Widget created', completed: hasWidget },
  //   { title: 'Widget installed', completed: completedWidgetSetup },
  // ];

  return (
    <>
      <Stack spacing={2} sx={{ px: 0.5, py: 1 }}>
        <Stack direction="row">
          <LabelStyle sx={{ mt: 1.5 }}>Trial start date</LabelStyle>
          <Stack sx={{ width: '100%' }}>
            {workspaceLeads?.trialStart ? (
              <ChangeTrialStart
                userId={userId}
                product={Platforms.SIMPLY_LEADS}
                trialStart={workspaceLeads?.trialStart?.toDate()}
              />
            ) : (
              'N/A'
            )}
          </Stack>
        </Stack>
        <Stack direction="row">
          <LabelStyle sx={{ mt: 1.5 }}>Leads page</LabelStyle>
          {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
          <Stack sx={{ width: '100%' }}>
            <Button
              variant="outlined"
              onClick={() => {
                window.open(`https://app.simplyleads.io/l/${publicConfig?.slug}`, '_blank');
              }}
              endIcon={<Iconify icon="tabler:external-link" />}
            >
              /l/{publicConfig?.slug}
            </Button>
          </Stack>

          {/* </Stack> */}
        </Stack>
        {!workspaceLeads.subscription ? (
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Upgrade</LabelStyle>
            <Stack sx={{ width: '100%' }}>
              <UpgradeUser
                userId={userId}
                displayName={displayName}
                product={Platforms.SIMPLY_LEADS}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Downgrade</LabelStyle>
            <Stack sx={{ width: '100%' }}>
              <DowngradeUser
                userId={userId}
                displayName={displayName}
                product={Platforms.SIMPLY_LEADS}
              />
            </Stack>
          </Stack>
        )}
        <Stack direction="row">
          <LabelStyle sx={{ mt: 1.5 }}>Zenfit lead API key</LabelStyle>
          <Stack sx={{ width: '100%' }}>
            <ZenfitLeadKey userId={userId} />
          </Stack>
        </Stack>
        {/* <Stack direction="row">
          <LabelStyle sx={{ mt: 2 }}>Onboarding</LabelStyle>
          <Stack sx={{ width: '100%' }}>
            {tasks.map(({ title, completed }) => (
              <MenuItem
                key={title}
                value={title}
                sx={{
                  mx: 0,
                  my: 0.5,
                  borderRadius: 1,
                  borderWidth: 0.5,
                  borderStyle: 'solid',
                  borderColor: completed ? 'primary.main' : 'grey.500',
                }}
              >
                <Box
                  sx={{
                    mr: 1,
                    width: 14,
                    height: 14,
                    borderRadius: 0.5,
                    bgcolor: completed ? 'primary.main' : 'grey.500',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Iconify
                    color="white"
                    icon={completed ? 'quill:checkmark' : 'radix-icons:cross-2'}
                  />
                </Box>
                <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                  {title}
                </Typography>
              </MenuItem>
            ))}
          </Stack>
        </Stack> */}
      </Stack>
    </>
  );
}

export function useWorkspaceLeads(userId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [workspaceLeads, setWorkspaceLeads] = useState<WorkspaceLeads | null>(null);
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_LEADS]()),
        where(`members.${userId}.uid`, '==', userId)
      );
      unsubscribe = onSnapshot(q, (snapshot) => {
        const workspaceLeadss = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as WorkspaceLeads[];

        async function setUserData() {
          setWorkspaceLeads(workspaceLeadss[0]);
          setIsLoading(false);
        }
        setUserData();
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { loadingWsLeads: isLoading, workspaceLeads };
}
