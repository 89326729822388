import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import keyBy from 'lodash.keyby';
import { RequestState } from '../../enum';

export interface Participant {
  uid: string;
  displayName: string;
  photoURL: string;
  email: string;
  type: 'TEAM' | 'EXTERNAL';
}

export interface Conversation {
  id: string;
  createTime: Date;
  updateTime: Date;
  latestMessage: {
    uid: string;
    content: string;
  };
  participants: { [uid: string]: Participant };
}

// Define a type for the slice state
interface ConversationState {
  ids: string[];
  map: Record<string, Conversation>;
  state: RequestState;
  currentId: string | null;
}

// Define the initial state using that type
const initialState: ConversationState = {
  ids: [],
  map: {},
  state: RequestState.IDLE,
  currentId: null,
};

export const conversationsSlice = createSlice({
  name: 'conversations',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setConversations: (
      state,
      action: PayloadAction<{ currentId: string; conversations: Conversation[] }>
    ) => ({
      ...state,
      ids: action.payload.conversations.map((c) => c.id),
      map: keyBy(action.payload.conversations, 'id'),
      state: RequestState.RESOLVED,
      currentId: action.payload.currentId,
    }),
    setConversationsFailed: (state) => ({
      ...state,
      state: RequestState.REJECTED,
    }),
    setCurrentConversation: (state, action: PayloadAction<string | null>) => ({
      ...state,
      currentId: action.payload,
    }),
  },
});

export const { setConversations, setConversationsFailed, setCurrentConversation } =
  conversationsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectConversation = (state: RootState) =>
  state.conversations.currentId && state.conversations.map[state.conversations.currentId];

export const selectConversationParticipants = (state: RootState) =>
  state.conversations.currentId
    ? Object.entries(state.conversations.map[state.conversations.currentId]?.participants).map(
        ([_, value]) => value
      )
    : [];

export const selectConversationsLoading = (state: RootState) =>
  state.conversations.state === RequestState.PENDING ||
  state.conversations.state === RequestState.IDLE;

export default conversationsSlice.reducer;
