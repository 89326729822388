import React from 'react';
import { Alert, Box, Container, Grid, Stack, Typography, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Page from 'src/components/Page';
// import { Form } from '@simplytech/simply.widgets.leads.form';
import usePublicSlug from 'src/hooks/usePublicSlug';
import useSettings from 'src/hooks/useSettings';
import { useSelector } from 'src/redux/store';
import { loadingLeadFormState, selectLeadForm } from 'src/redux/slices/leadFormsSlice';
import useLeadForms from 'src/hooks/useLeadForm';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSnackbar } from 'notistack';
import { validateEmail } from 'src/utils/validation';
import { countries } from 'src/components/PhoneField';
import Lottie, { successAnimation } from 'src/components/Lottie';
import { submitLeadForm } from 'src/api';
import { Avatar } from '@mui/material';
import { getIntl } from '../../locales/helper';
import Sundown from '../../components/animate/backgrounds/sundown';
import Blobs from 'src/components/animate/backgrounds/blobs';
import Page404 from '../Page404';
// import { Helmet } from 'react-helmet';
import DanielPixelScript from './DanielPixelScript';

import { Form } from '@simplylabs_/shared';

export default function LeadPageContainer() {
  const { slug } = useParams<{ slug: string }>();
  const isLoading = useSelector(loadingLeadFormState);
  const leadForm = useSelector(selectLeadForm);
  const { isLoadingPublicConfig, userId } = usePublicSlug(slug);
  useLeadForms(userId);
  if (isLoading || isLoadingPublicConfig) {
    return <LoadingScreen />;
  }
  if (!leadForm) return <Page404 />;
  return <LeadPage />;
}

export function LeadPage({
  leadPageColor,
  leadPageLogo,
  leadPageName,
  leadPagePattern,
}: {
  leadPageColor?: string;
  leadPageLogo?: string;
  leadPageName?: string;
  leadPagePattern?: string | null;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const {
    color,
    companyName,
    companyPageLogo,
    leadPageName: configLeadPageName,
    leadPageLogo: configLeadPageLogo,
    leadPageColor: configLeadPageColor,
    leadPageSuccessText: configLeadPageSuccessText,
    leadPagePattern: configLeadPagePattern,
    userId,
  } = useSelector((state) => state.publicConfig);
  const leadForm = useSelector(selectLeadForm);
  console.log({ leadForm, countries });
  const language = leadForm?.language === 'no' ? 'nn' : leadForm?.language;
  const country = countries.find((c) => c.languageCode === language);
  console.log({ country });

  const defaultCountryCode = country?.phone || '+45';

  const [answers, setAnswers] = React.useState({
    'fitness-5': {
      'fitness-5-option-3': { code: defaultCountryCode },
    },
  });
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const handleAnswer = (id: string, value: string | number | object) => {
    setAnswers((prev) => ({ ...prev, [id]: value }));
  };
  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      let errors: string[] = [];
      if (!userId || !leadForm) {
        throw new Error('User or lead form is not defined');
      }
      Object.entries(answers).forEach(([id, value]) => {
        if (value && typeof value === 'object') {
          Object.entries(value).forEach(([id, v]) => {
            // @ts-ignore
            if (id === 'fitness-5-option-2' && !validateEmail(v)) {
              errors.push('Email is not valid');
            }
          });
        }
      });

      if (errors.length > 0) {
        return enqueueSnackbar(errors.join(', '), { variant: 'error' });
      }

      const response = await submitLeadForm({
        workspaceId: userId,
        formId: leadForm.id,
        answers,
        origin: {
          url: window.location.href,
          hostname: window.location.hostname,
          protocol: window.location.protocol,
        },
      });
      if (response.status !== 200) {
        throw new Error(`Error submitting lead form: status code ${response.status}`);
      }
      setSubmitted(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const customColor = leadPageColor || configLeadPageColor || leadForm?.color || color || '#161615';

  const backgroundColor = alpha(customColor, 0.1);
  // const patternColor = alpha(customColor, 0.25);

  if (submitted) {
    // @ts-ignore
    // const [firstName] = (answers?.['fitness-5']?.['fitness-5-option-1'] || '')?.split(' ');
    return <SuccessLeadForm successText={configLeadPageSuccessText} /* firstName={firstName} */ />;
  }

  const pattern = leadPagePattern !== undefined ? leadPagePattern : configLeadPagePattern;

  return (
    <Page title="Leads" height="100%" sx={{ backgroundColor }}>
      {<DanielPixelScript />}
      {/* <Box
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,

          width: '100%',
          height: '100vh',
          zIndex: -1,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            // width: 500,
            // height: 500,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <FloatBalls color={customColor} />
        </Box>
      </Box> */}
      {pattern === 'blobs' && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,

            width: '100%',
            height: '100vh',
            zIndex: -1,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              // width: 500,
              // height: 500,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Blobs color={customColor} />
          </Box>
        </Box>
      )}
      {pattern === 'sundown' && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              // width: 500,
              // height: 500,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Sundown color={customColor} />
          </Box>
        </Box>
      )}
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
        <Grid container spacing={3} sx={{ zIndex: 1, height: '100%', marginTop: 0 }}>
          {/* <Grid item md={12} xs={12}></Grid> */}
          <Grid item md={12} xs={12} alignSelf="center">
            <Stack>
              <Avatar
                src={leadPageLogo || configLeadPageLogo?.thumbnail || companyPageLogo?.thumbnail}
                sx={{
                  mx: 'auto',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: 'common.white',
                  width: { xs: 80, md: 128 },
                  height: { xs: 80, md: 128 },
                }}
              />
            </Stack>
            <Stack alignItems="center" mb={2}>
              <Typography variant="subtitle1" fontSize={44} color="textPrimary">
                {leadPageName || configLeadPageName || companyName}
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <Form
                questions={leadForm.form}
                answers={answers}
                initialActive={leadForm.form[0].id}
                customColor={leadForm.color || color || '#000000'}
                updateAnswer={handleAnswer}
                handleSubmit={handleSubmit}
                defaultCountryCode={defaultCountryCode}
                previousText={leadForm.previousText}
                submitText={leadForm.submitText}
                nextText={leadForm.nextText}
                submitting={submitting}
              />
            </Stack>
            {/* <Stack>
              <Alert severity="info">
                When you submit the form, we process the personal data you provide. You can read
                more about Daniel Orlando Aarsheim's processing of your personal data and your
                rights in our data policy.
              </Alert>
            </Stack> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export function SuccessLeadForm({
  leadPageColor,
  successText,
}: {
  leadPageColor?: string;
  successText?: string;
}) {
  // TODO: default success text = use lead form language
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const { color } = useSelector((state) => state.publicConfig);
  const leadForm = useSelector(selectLeadForm);
  const backgroundColor = alpha(leadPageColor || leadForm?.color || color || '#161615', 0.05);
  return (
    <Page title="Leads" height="100%" sx={{ backgroundColor }}>
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
        <Grid container spacing={3} sx={{ height: '100%', marginTop: 0 }}>
          <Grid item md={12} xs={12} alignSelf="center">
            <Stack alignItems="center" mb={2}>
              <Lottie animation={successAnimation} />
            </Stack>
            <Stack alignItems="center">
              <Typography
                variant="body1"
                color="textPrimary"
                sx={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
              >
                {successText ||
                  t('leads.success.default.text', { lng: getIntl(leadForm.language) })}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
