import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import Iconify from './Iconify';

export default function InfoTooltip({ title, size }: { title: string; size?: 'small' }) {
  return (
    <Tooltip sx={{ zIndex: 1000 }} title={title}>
      <IconButton size={size}>
        <Iconify icon="bx:info-circle" />
      </IconButton>
    </Tooltip>
  );
}
