import { useEffect, useState } from 'react';
import { onSnapshot, orderBy, collection, query, limit } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';

export default function useInvoice() {
  const [isloading, setisloading] = useState(true);
  const [invoice, setInvoice] = useState<any>(null);

  useEffect(() => {
    let unsubscribe: () => void;

    const q = query(collection(DB, 'invoicesTesting'), orderBy('createTime', 'desc'), limit(1));
    unsubscribe = onSnapshot(q, (snap) => {
      const doc = snap.docs[0].data();

      setInvoice(doc);
      setisloading(false);
    });

    return () => {
      unsubscribe?.();
    };
  }, []);
  return { isloading, invoice };
}
