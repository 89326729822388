import {
  Stack,
  Divider,
  OutlinedInput,
  Typography,
  CircularProgress,
  Rating,
  Box,
  MenuItem,
  Button,
  Tabs,
  Tab,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Scrollbar from '../../../components/Scrollbar';
import UpgradeUser from '../UserAdminActions/UpgradeUser';
import DowngradeUser from '../UserAdminActions/DowngradeUser';
import LoginAsUser from '../UserAdminActions/LoginInAsUser';
import useUserActivity from '../../../hooks/useUserActivity';
import Iconify from '../../../components/Iconify';
import { IntegrationState } from '../../../redux/slices/integrationSlice';
import { getRatingDecimalScore } from '../../../utils/getRating';
import { format } from 'date-fns';
import { sentenceCase } from 'change-case';
import { Platforms } from 'src/redux/slices/platform';
import { useState } from 'react';
import Review, { useWorkspaceReview } from './Review';
import { Subscription } from 'src/enum';
import { Badge } from '@mui/material';
import Flow, { useWorkspaceFlow } from './Flow';
import Leads, { useWorkspaceLeads } from './Leads';
import Links, { useWorkspaceLinks } from './Links';
import { WorkspaceReview } from 'src/redux/slices/workspaceReviewsSlice';
import { WorkspaceFlow } from 'src/redux/slices/workspaceFlowSlice';
import { WorkspaceLeads } from 'src/redux/slices/workspaceLeadsSlice';
import { WorkspaceLinks } from 'src/redux/slices/workspaceLinksSlice';

const PRIORITIZES = ['low', 'medium', 'hight'];

export const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

export default function Content({ userId }: { userId: string }) {
  const { loading, publicConfig, reviewStats, widgets, user, invite, customer, subscriptions } =
    useUserActivity(userId);
  const { loadingWsReview, workspaceReview } = useWorkspaceReview(userId);
  const { loadingWsFlow, workspaceFlow } = useWorkspaceFlow(userId);
  const { loadingWsLeads, workspaceLeads } = useWorkspaceLeads(userId);
  const { loadingWsLinks, workspaceLinks } = useWorkspaceLinks(userId);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { t } = useTranslation();

  if (loading || loadingWsReview || loadingWsFlow || loadingWsLeads || loadingWsLinks)
    return <CircularProgress />;

  // .sort((a, b) => (a.completed === b.completed ? 0 : a.completed ? 1 : -1));
  const displayName = user?.displayName as string;

  return (
    <>
      <Divider />

      <Scrollbar>
        <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
          <Stack spacing={3} direction="row" alignItems="center">
            <OutlinedInput
              fullWidth
              multiline
              size="small"
              placeholder="Task name"
              value={displayName}
              sx={{
                typography: 'h6',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
              }}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{getRatingDecimalScore(reviewStats?.reviewAverage as number)}</Typography>
              <Rating value={reviewStats?.reviewAverage} readOnly precision={0.5} />
            </Stack>
          </Stack>
          {customer && (
            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Stripe Customer</LabelStyle>
              {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
              <Stack sx={{ width: '100%' }}>
                <Button
                  variant="text"
                  onClick={() => {
                    window.open(`${customer?.stripeLink}`, '_blank');
                  }}
                  endIcon={<Iconify icon="tabler:external-link" />}
                >
                  Stripe Link
                </Button>
              </Stack>

              {/* </Stack> */}
            </Stack>
          )}
          {subscriptions.length > 0 && (
            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Stripe Subscription</LabelStyle>
              {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
              <Stack sx={{ width: '100%' }}>
                {subscriptions.map((subscription) =>
                  subscription.items.map((item) => (
                    <>
                      <Typography variant="body2" key={item.id}>
                        Created: {format(item.created * 1000, 'dd-MM-yyyy')}
                      </Typography>
                      <Typography variant="body2" key={item.id}>
                        {sentenceCase(item.price.product.name)}
                      </Typography>
                      <Typography variant="body2" key={item.id}>
                        {sentenceCase(item.plan.currency)}: {item.plan.amount / 100}{' '}
                        {item.plan.interval} - {item.price.tax_behavior} tax
                      </Typography>
                      <Typography variant="body2" key={item.id}>
                        Status: {sentenceCase(subscription.status)}
                      </Typography>
                    </>
                  ))
                )}
              </Stack>

              {/* </Stack> */}
            </Stack>
          )}

          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Impersonate</LabelStyle>
            {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
            <Stack sx={{ width: '100%' }}>
              <LoginAsUser userId={userId} displayName={displayName} />
            </Stack>
            {/* </Stack> */}
          </Stack>
          {/* <Stack direction="row"> */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                label={<TabLabel label="Review" workspace={workspaceReview} />}
                {...a11yProps(0)}
              />
              <Tab label={<TabLabel label="Flow" workspace={workspaceFlow} />} {...a11yProps(1)} />
              <Tab
                label={<TabLabel label="Leads" workspace={workspaceLeads} />}
                {...a11yProps(2)}
              />
              <Tab
                label={<TabLabel label="Links" workspace={workspaceLinks} />}
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Review
              workspaceReview={workspaceReview}
              userId={userId}
              displayName={displayName}
              publicConfig={publicConfig}
              reviewStats={reviewStats}
              widgets={widgets}
              invite={invite}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Flow
              workspaceFlow={workspaceFlow}
              userId={userId}
              displayName={displayName}
              publicConfig={publicConfig}
              reviewStats={reviewStats}
              widgets={widgets}
              invite={invite}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Leads
              workspaceLeads={workspaceLeads}
              userId={userId}
              displayName={displayName}
              publicConfig={publicConfig}
              reviewStats={reviewStats}
              widgets={widgets}
              invite={invite}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Links
              workspaceLinks={workspaceLinks}
              userId={userId}
              displayName={displayName}
              publicConfig={publicConfig}
            />
          </CustomTabPanel>

          {/* </Stack> */}
          {/* </Stack> */}

          {/* <Timeline>
            <TimeItem title="Review page" completed={completedReviewPageSetup} />
          </Timeline> */}
        </Stack>
      </Scrollbar>

      <Divider />
    </>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index && ( */}
      <Box sx={{ p: 0 }}>
        <Typography>{children}</Typography>
      </Box>
      {/* )} */}
    </div>
  );
}

function TabLabel({
  label,
  workspace,
}: {
  label: string;
  workspace?: WorkspaceReview | WorkspaceFlow | WorkspaceLeads | WorkspaceLinks | null;
}) {
  const isWorkspaceSetup = Boolean(workspace);
  const isPremium = workspace?.subscription;
  return (
    <Tooltip title={isWorkspaceSetup ? (isPremium ? 'Premium' : 'Free') : 'Not setup'}>
      <Stack direction="row" alignItems="center">
        <Typography>
          {label} {isWorkspaceSetup ? (isPremium ? '🏅' : null) : '❌'}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
