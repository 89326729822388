import Disconnect from './Disconnect';
import Connect from './Connect';
import { useSelector } from 'react-redux';
import {
  selectLoadingState,
  selectZenfitApi,
} from '../../../../../redux/slices/apiIntegrationsSlice';
import LoadingZenfit from './Loading';

export default function Zenfit() {
  const isLoading = useSelector(selectLoadingState);
  const connectedAPIKey = useSelector(selectZenfitApi);
  if (isLoading) return <LoadingZenfit />;
  if (connectedAPIKey) return <Disconnect />;

  return <Connect />;
}
