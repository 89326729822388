import { useSelector } from '../../../redux/store';
import { selectCurrentWorkspaceMembers } from '../../../redux/slices/workspaceReviewsSlice';
import usePendingTeamInvites from '../../../hooks/usePendingTeamInvites';
import {
  selectWorkspaceFlowTeamInvites,
  selectWorkspaceReviewTeamInvites,
} from '../../../redux/slices/workspaceTeamInvitesSlice';
import { Platforms } from '../../../redux/slices/platform';
import Team from './Team';
import { selectCurrentWorkspaceFlowMembers } from '../../../redux/slices/workspaceFlowSlice';
import { selectPlatform } from '../../../redux/slices/userSlice';

export default function TeamContainer() {
  usePendingTeamInvites();

  const pendingFlowInvites = useSelector(selectWorkspaceFlowTeamInvites);
  const membersFlow = useSelector(selectCurrentWorkspaceFlowMembers);
  const pendingReviewInvites = useSelector(selectWorkspaceReviewTeamInvites);
  const membersReview = useSelector(selectCurrentWorkspaceMembers);
  const platform = useSelector(selectPlatform);

  if (platform === Platforms.SIMPLY_REVIEW) {
    return <Team pendingInvites={pendingReviewInvites} members={membersReview} />;
  }
  if (platform === Platforms.SIMPLY_FLOW) {
    // TODO: enable flow team invites
    // 1. make sure invite handles Platform type
    // 2. make sure different places using workspaceId using the correct value across products
    return <Team pendingInvites={pendingFlowInvites} members={membersFlow} />;
  }

  return null; // <Team pendingInvites={pendingReviewInvites} members={membersReview} />;
}
