// import React from 'react';
// import './index.css';

// export default function Sundown() {
//   return (
//     <>
//       <div className="row-1 row" />
//       <div className="row-2 row" />
//       <div className="row-3 row" />
//       <div className="row-4 row" />
//       <div className="row-5 row" />
//       <div className="row-6 row" />
//       <div className="row-7 row" />
//       <div className="row-8 row" />
//       <div className="row-9 row" />
//       <div className="row-10 row" />
//     </>
//   );
// }

import React from 'react';

import { makeStyles } from '@mui/material';
import { Box } from '@mui/material';
import { BoxProps, alpha, styled } from '@mui/system';

const Row = styled(Box)<BoxProps & { color: string }>(({ theme, color }) => ({
  background: color,
  height: '10vh',
  width: '100%',
  animation: 'fade 5s infinite',
  animationDirection: 'alternate',
  '&.row-1': { animationDelay: '0s' },
  '&.row-2': { animationDelay: '0.5s' },
  '&.row-3': { animationDelay: '1s' },
  '&.row-4': { animationDelay: '1.5s' },
  '&.row-5': { animationDelay: '2s' },
  '&.row-6': { animationDelay: '2.5s' },
  '&.row-7': { animationDelay: '3s' },
  '&.row-8': { animationDelay: '3.5s' },
  '&.row-9': { animationDelay: '4s' },
  '&.row-10': { animationDelay: '4.5s' },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
  '@keyframes fade': {
    from: { background: color },
    to: { background: 'transparent' },
  },
}));

export default function Sundown({ color }: { color: string }) {
  const colorO = alpha(color, 0.5);
  return (
    <>
      {Array.from({ length: 10 }, (_, i) => (
        <Row key={`${colorO}-${i}`} className={`row-${i + 1}`} color={colorO} />
      ))}
    </>
  );
}
