import React from 'react';

export default function SimplyLeadsLogo({ width = 84, color = '#333' }) {
  return (
    <svg viewBox="0 0 70 29" fill="none" style={{ width }}>
      <path
        d="M29.5302 12.2067C28.9754 11.9098 28.5348 11.4975 28.2084 10.9532C27.8821 10.4089 27.7026 9.79863 27.6699 9.08941H29.5465C29.5629 9.48525 29.6608 9.83161 29.824 10.1285C29.9871 10.4254 30.2156 10.6398 30.493 10.8047C30.7704 10.9697 31.0968 11.0521 31.4558 11.0521H32.2554C32.5328 11.0521 32.7939 10.9862 33.0713 10.8542C33.3324 10.7223 33.5609 10.5408 33.7404 10.2769C33.9199 10.0295 34.0015 9.71616 34.0015 9.3533C34.0015 8.97395 33.8872 8.67707 33.6588 8.42967C33.4303 8.19876 33.1692 8.00084 32.8429 7.86889C32.5165 7.73694 32.0759 7.5885 31.4884 7.40707C30.7215 7.19266 30.1014 6.97824 29.6118 6.76383C29.1223 6.54941 28.7143 6.21954 28.3716 5.77422C28.0289 5.32889 27.8494 4.73513 27.8494 3.99292C27.8494 3.36617 28.0126 2.80539 28.3227 2.32708C28.6327 1.84877 29.0407 1.46942 29.5629 1.20552C30.0687 0.95812 30.6399 0.826172 31.2437 0.826172H32.0759C32.7123 0.826172 33.2671 0.95812 33.773 1.20552C34.2789 1.45292 34.6868 1.81578 35.0132 2.31059C35.3396 2.80539 35.5354 3.39915 35.6007 4.10837H33.7404C33.6751 3.56409 33.4956 3.15175 33.2019 2.87136C32.9081 2.59097 32.5328 2.45903 32.0433 2.45903H31.26C30.9989 2.45903 30.7541 2.525 30.5093 2.64045C30.2809 2.75591 30.085 2.93734 29.9382 3.15175C29.7913 3.36617 29.726 3.63006 29.726 3.92695C29.726 4.2898 29.8403 4.58669 30.0524 4.81759C30.2646 5.0485 30.5256 5.21344 30.8357 5.34539C31.1457 5.47733 31.5863 5.60928 32.1738 5.77422C32.9408 5.98863 33.5772 6.20305 34.0667 6.41746C34.5563 6.63188 34.9806 6.97824 35.3233 7.44006C35.666 7.90188 35.8455 8.52863 35.8455 9.32031C35.8455 9.99655 35.6823 10.6068 35.3559 11.1181C35.0295 11.6294 34.5889 12.0252 34.0341 12.3056C33.4793 12.586 32.8592 12.718 32.1901 12.718H31.3579C30.7051 12.652 30.085 12.5036 29.5302 12.2067Z"
        fill={color}
      />
      <path d="M36.4492 4.19141H38.179V12.5206H36.4492V4.19141Z" fill={color} />
      <path
        d="M39.2402 4.1913H40.9537V5.44481C41.1332 5.06546 41.4269 4.73559 41.8022 4.4552C42.1776 4.1913 42.5692 4.04286 42.9608 4.04286H43.5809C44.1194 4.04286 44.56 4.15832 44.9027 4.40572C45.2291 4.65312 45.5228 4.99948 45.7676 5.4613C45.9797 5.04896 46.3061 4.71909 46.7141 4.4387C47.122 4.17481 47.5463 4.02637 47.9706 4.02637H48.5907C49.0802 4.02637 49.5208 4.15832 49.9125 4.4387C50.3041 4.7026 50.5979 5.08195 50.81 5.54377C51.0221 6.00558 51.1364 6.53338 51.1364 7.12714V12.4875H49.4229V7.4735C49.4229 7.12714 49.374 6.79727 49.2598 6.51688C49.1455 6.23649 48.9987 6.02208 48.8192 5.85714C48.6397 5.69221 48.4112 5.60974 48.1664 5.60974H47.6442C47.3505 5.60974 47.0894 5.7252 46.8446 5.93961C46.5998 6.15403 46.4203 6.4674 46.2735 6.84675C46.1429 7.2261 46.0613 7.63844 46.0613 8.06727V12.5205H44.2989V7.4735C44.2989 7.12714 44.25 6.79727 44.1358 6.51688C44.0379 6.23649 43.891 6.00558 43.6952 5.85714C43.4993 5.7087 43.2872 5.62623 43.0424 5.62623H42.5039C42.2102 5.62623 41.9491 5.74169 41.7043 5.9726C41.4595 6.20351 41.28 6.50039 41.1332 6.87974C41.0026 7.25909 40.921 7.65493 40.921 8.08376V12.537H39.2402V4.1913Z"
        fill={color}
      />
      <path
        d="M58.1039 4.65274C58.6261 5.04858 59.0504 5.55988 59.3441 6.21962C59.6379 6.87936 59.8011 7.58858 59.8011 8.36377C59.8011 9.15546 59.6542 9.88117 59.3605 10.5409C59.0667 11.2007 58.6588 11.7119 58.1203 12.0913C57.5981 12.4706 56.978 12.6686 56.2926 12.6686H55.7867C55.4277 12.6686 55.0524 12.5531 54.6771 12.3222C54.3017 12.0913 53.9917 11.8274 53.7796 11.4975V14.4004H52.0498V4.19092H53.7796V5.23001C53.9917 4.90014 54.3018 4.61975 54.6934 4.38884C55.085 4.15793 55.4604 4.04248 55.852 4.04248H56.3579C56.9943 4.05897 57.5654 4.2569 58.1039 4.65274ZM54.5955 5.92274C54.3017 6.15365 54.0733 6.48352 53.9101 6.91235C53.7469 7.34118 53.649 7.81949 53.649 8.36377C53.649 8.90806 53.7306 9.38637 53.9101 9.8152C54.0733 10.244 54.3017 10.5739 54.5955 10.8048C54.8892 11.0357 55.2156 11.1677 55.5746 11.1677H56.1457C56.4721 11.1677 56.7821 11.0522 57.0759 10.8048C57.3696 10.5739 57.6144 10.2275 57.7939 9.79871C57.9734 9.36987 58.0713 8.89156 58.0713 8.36377C58.0713 7.83598 57.9734 7.35767 57.7939 6.92884C57.6144 6.50001 57.3696 6.17014 57.0759 5.92274C56.7821 5.69183 56.4721 5.55988 56.1457 5.55988H55.5583C55.1993 5.55988 54.8729 5.69183 54.5955 5.92274Z"
        fill={color}
      />
      <path d="M60.3721 2.44238H62.0855V12.5199H60.3721V2.44238Z" fill={color} />
      <path
        d="M65.1212 12.0098C65.1375 12.1088 65.1702 12.2077 65.1865 12.3232C65.2028 12.4386 65.2028 12.5376 65.2028 12.653C65.2028 12.884 65.1539 13.1479 65.0723 13.4282L64.648 14.4179H66.3125C66.3941 14.2529 66.4593 14.0715 66.5409 13.8736L70.0004 4.19189H68.238L66.5736 9.07396C66.4593 9.45331 66.3288 9.88215 66.1982 10.3605H66.1493C66.0351 9.88215 65.9045 9.45331 65.774 9.05747L64.0768 4.19189H62.3145L64.9254 11.449C65.007 11.6634 65.0723 11.8449 65.1212 12.0098Z"
        fill={color}
      />
      <path
        d="M28.0778 16.3638H29.4812V26.6887H34.85V27.9257H28.0615V16.3638H28.0778Z"
        fill={color}
      />
      <path
        d="M34.9805 28.0413H27.9473V16.2319H29.6117V26.5569H34.9805V28.0413ZM28.2084 27.7939H34.7521V26.8043H29.3833V16.4793H28.2247V27.7939H28.2084Z"
        fill={color}
      />
      <path
        d="M38.2449 19.4644H38.9303C40.7906 19.4644 42.1614 21.2786 42.1614 23.3403C42.1614 23.6537 42.1451 23.8186 42.1287 23.9836H36.1399C36.2378 25.7484 37.1679 26.8699 38.2613 26.8699H38.8977C39.9257 26.8699 40.6274 26.1112 40.7906 25.2371H42.0471C41.884 26.7545 40.6111 28.041 38.914 28.041H38.2613C36.3357 28.041 34.8018 26.2102 34.8018 23.7032C34.8018 21.1467 36.5641 19.4644 38.2449 19.4644ZM40.8233 22.8455C40.7906 21.6745 39.9747 20.6354 38.9303 20.6354H38.2939C37.3638 20.6354 36.4989 21.4601 36.2378 22.8455H40.8233Z"
        fill={color}
      />
      <path
        d="M38.93 28.173H38.261C36.2211 28.173 34.6709 26.2598 34.6709 23.7033C34.6709 20.9984 36.5312 19.3325 38.2446 19.3325H38.93C40.774 19.3325 42.2916 21.1303 42.2916 23.3404C42.2916 23.6538 42.2753 23.8352 42.259 24.0002L42.2426 24.1156H42.1284H36.2864C36.417 25.6825 37.2166 26.7546 38.2773 26.7546H38.9137C39.9418 26.7546 40.5455 25.9629 40.6924 25.2207L40.7087 25.1217H42.21L42.1937 25.2537C42.0142 26.9195 40.6108 28.173 38.93 28.173ZM38.2446 19.5799C36.2538 19.5799 34.9157 21.7076 34.9157 23.7033C34.9157 26.1113 36.3517 27.9256 38.261 27.9256H38.93C40.4313 27.9256 41.7041 26.8371 41.9326 25.3691H40.9209C40.6924 26.3423 39.8928 27.002 38.93 27.002H38.2936C37.0697 27.002 36.1396 25.765 36.0416 24.0002V23.8682H42.0468C42.0631 23.7363 42.0631 23.5878 42.0631 23.3404C42.0631 21.2623 40.6598 19.5799 38.9463 19.5799H38.2446ZM40.9535 22.9776H36.0906L36.1232 22.8291C36.3843 21.4437 37.2655 20.5201 38.3099 20.5201H38.9463C40.0233 20.5201 40.9209 21.5591 40.9698 22.8456L40.9535 22.9776ZM36.3843 22.7302H40.6924C40.6108 21.6416 39.8438 20.7675 38.93 20.7675H38.2936C37.4124 20.7675 36.6617 21.5426 36.3843 22.7302Z"
        fill={color}
      />
      <path
        d="M42.748 25.7319C42.748 24.099 43.9882 23.3733 45.718 23.0764L48.0679 22.6806V22.5816C48.0679 21.4271 47.4314 20.6354 46.5666 20.6354H45.8812C44.9674 20.6354 44.2657 21.2127 44.1677 22.2682H42.846C42.9765 20.5364 44.3309 19.4644 45.8812 19.4644H46.5666C48.28 19.4644 49.3733 20.7508 49.3733 22.6146V26.0782C49.3733 26.7215 49.6018 26.771 50.3361 26.771V27.909H49.4223C48.6716 27.909 48.1005 27.5791 48.1331 26.5236C47.6925 27.4307 46.6155 28.041 45.3916 28.041C43.7924 28.0575 42.748 27.1668 42.748 25.7319ZM45.5222 26.8864C46.9745 26.8864 48.0842 25.9133 48.0842 24.3134V23.7856L46.0117 24.165C44.8531 24.3794 44.1188 24.6598 44.1188 25.6824C44.1188 26.4081 44.7063 26.8864 45.5222 26.8864Z"
        fill={color}
      />
      <path
        d="M45.4079 28.173C43.7271 28.173 42.6338 27.2164 42.6338 25.732C42.6338 24.2146 43.6455 23.3075 45.7017 22.9446L47.9536 22.5652C47.9536 21.4932 47.3825 20.751 46.5665 20.751H45.8812C44.9836 20.751 44.3799 21.3282 44.2819 22.2684L44.2656 22.3838H42.6991L42.7154 22.2519C42.8459 20.553 44.1677 19.3325 45.8812 19.3325H46.5665C48.3289 19.3325 49.5038 20.652 49.5038 22.6147V26.0784C49.5038 26.6061 49.6181 26.6556 50.3524 26.6556H50.483V28.0411H49.4386C48.9653 28.0411 48.6063 27.9091 48.3779 27.6617C48.1984 27.4803 48.1005 27.2329 48.0515 26.936C47.4967 27.6947 46.5013 28.173 45.4079 28.173ZM45.8975 20.5201H46.5828C47.5293 20.5201 48.2147 21.3942 48.2147 22.5982V22.7962L45.7669 23.2085C43.8087 23.5384 42.8949 24.3465 42.8949 25.7485C42.8949 27.101 43.8577 27.9421 45.4242 27.9421C46.5992 27.9421 47.6272 27.3648 48.0678 26.4907L48.3126 25.9794L48.2963 26.5567C48.28 26.9855 48.3779 27.3154 48.5737 27.5133C48.7695 27.7112 49.0633 27.8102 49.4712 27.8102H50.2708V26.9195C49.6344 26.9195 49.308 26.8371 49.308 26.0948V22.6312C49.308 20.7839 48.2473 19.5964 46.6155 19.5964H45.9301C44.3799 19.5964 43.2049 20.6355 43.0254 22.1529H44.1024C44.2004 21.1303 44.902 20.5201 45.8975 20.5201ZM45.5222 27.002C44.6083 27.002 43.9882 26.4742 43.9882 25.666C43.9882 24.495 44.9347 24.2146 45.9791 24.0167L48.1984 23.6043V24.2806C48.1984 25.8969 47.105 27.002 45.5222 27.002ZM47.9536 23.9177L46.028 24.2641C44.8368 24.4785 44.233 24.7589 44.233 25.666C44.233 26.3258 44.7389 26.7546 45.5222 26.7546C46.9908 26.7546 47.9699 25.765 47.9699 24.2971V23.9177H47.9536Z"
        fill={color}
      />
      <path
        d="M50.5977 23.7531C50.5977 21.3121 52.0337 19.4648 54.0735 19.4648H54.5957C55.4442 19.4648 56.3581 20.0586 56.815 20.7843V16.1826H58.1041V27.926H56.815V26.7549C56.3417 27.4642 55.379 28.0579 54.5794 28.0579H54.0898C52.05 28.0579 50.5977 26.1447 50.5977 23.7531ZM54.1224 26.8869H54.7425C55.9501 26.8869 56.9292 25.6169 56.9292 23.7696C56.9292 21.9224 55.9501 20.6524 54.7425 20.6524H54.1224C53.0291 20.6524 51.9521 21.8894 51.9521 23.7696C51.9521 25.6334 53.0128 26.8869 54.1224 26.8869Z"
        fill={color}
      />
      <path
        d="M54.579 28.1735H54.0895C52.0334 28.1735 50.4668 26.2767 50.4668 23.7532C50.4668 21.1967 51.9844 19.333 54.0732 19.333H54.5954C55.3297 19.333 56.1619 19.7618 56.7004 20.4051V16.0508H58.2344V28.0415H56.7004V27.1014C56.1456 27.7282 55.2971 28.1735 54.579 28.1735ZM54.0732 19.5804C52.1313 19.5804 50.7116 21.3287 50.7116 23.7532C50.7116 26.1283 52.1639 27.9261 54.0895 27.9261H54.579C55.3134 27.9261 56.2435 27.3818 56.7168 26.6726L56.9452 26.3427V27.7941H57.9896V16.2982H56.9452V21.2132L56.7168 20.8504C56.2598 20.1247 55.3623 19.5804 54.5954 19.5804H54.0732ZM54.7422 27.0025H54.1221C52.9962 27.0025 51.8212 25.7819 51.8212 23.7532C51.8212 22.1864 52.7024 20.504 54.1221 20.504H54.7422C56.064 20.504 57.0594 21.906 57.0594 23.7532C57.0594 25.6005 56.064 27.0025 54.7422 27.0025ZM54.1221 20.7679C53.143 20.7679 52.066 21.906 52.066 23.7697C52.066 25.65 53.1104 26.7715 54.1221 26.7715H54.7422C55.9172 26.7715 56.8147 25.4851 56.8147 23.7697C56.8147 22.0544 55.9335 20.7679 54.7422 20.7679H54.1221Z"
        fill={color}
      />
      <path
        d="M59.1484 25.5176H60.4539C60.5518 26.3587 61.2535 26.87 62.0205 26.87H62.6732C63.4075 26.87 64.1256 26.5237 64.1256 25.6C64.1256 24.8908 63.7339 24.5115 62.9017 24.363L61.4004 24.0826C60.0296 23.8187 59.3443 23.0435 59.3443 21.856C59.3443 20.4211 60.6008 19.481 61.9226 19.481H62.6243C63.9461 19.481 65.2189 20.4376 65.2515 21.856H63.9624C63.9134 21.1798 63.3096 20.652 62.6079 20.652H61.9389C61.2535 20.652 60.6824 21.0313 60.6824 21.7736C60.6824 22.3343 61.0087 22.7632 61.7594 22.9116L63.326 23.225C64.5825 23.4724 65.4637 23.9507 65.4637 25.5176C65.4637 27.068 64.1256 28.0411 62.7222 28.0411H62.0205C60.5355 28.0576 59.2137 27.068 59.1484 25.5176Z"
        fill={color}
      />
      <path
        d="M62.7219 28.173H62.0202C60.372 28.173 59.0829 27.035 59.0176 25.5341V25.4021H60.5678L60.5841 25.5176C60.6657 26.2433 61.2695 26.7546 62.0365 26.7546H62.6892C62.9993 26.7546 64.0273 26.6721 64.0273 25.6C64.0273 24.9568 63.6846 24.6269 62.9014 24.4785L61.4001 24.1981C59.9804 23.9342 59.246 23.1095 59.246 21.8395C59.246 20.2726 60.6168 19.3325 61.9549 19.3325H62.6566C64.3374 19.3325 65.3818 20.6025 65.4144 21.8395V21.9715H63.8805L63.8641 21.856C63.8152 21.2458 63.2767 20.7675 62.6239 20.7675H61.9549C61.4327 20.7675 60.8126 21.0313 60.8126 21.7736C60.8126 22.3178 61.139 22.6642 61.7917 22.7962L63.3583 23.1095C64.5169 23.3404 65.5939 23.7858 65.5939 25.5341C65.5939 27.1834 64.1252 28.173 62.7219 28.173ZM59.2787 25.6495C59.4092 26.9525 60.5678 27.9256 62.0202 27.9256H62.7219C64.011 27.9256 65.3491 27.0185 65.3491 25.5176C65.3491 24.0497 64.5658 23.5878 63.3093 23.3404L61.7428 23.0271C60.9758 22.8786 60.5678 22.4333 60.5678 21.7736C60.5678 21.0313 61.1227 20.5201 61.9549 20.5201H62.6239C63.3583 20.5201 63.9784 21.0478 64.0926 21.7241H65.1533C65.0554 20.652 64.1252 19.5964 62.6566 19.5964H61.9223C60.7147 19.5964 59.4582 20.4376 59.4582 21.856C59.4582 23.0106 60.1109 23.7198 61.4164 23.9672L62.9177 24.2476C63.8152 24.4125 64.2395 24.8413 64.2395 25.6C64.2395 26.6226 63.4236 27.002 62.6566 27.002H62.0038C61.1553 27.002 60.4699 26.4412 60.3231 25.6495H59.2787Z"
        fill={color}
      />
      <path
        d="M15.2847 22.0055L14.3056 22.9951C13.555 23.7538 12.3474 23.7538 11.5968 22.9951L10.6177 22.0055C9.86703 21.2468 9.86703 20.0263 10.6177 19.2676L11.5968 18.278C12.3474 17.5193 13.555 17.5193 14.3056 18.278L15.2847 19.2676C16.0354 20.0263 16.0354 21.2468 15.2847 22.0055Z"
        fill={color}
      />
      <path
        d="M21.5181 15.7052L20.539 16.6949C19.7884 17.4536 18.5808 17.4536 17.8302 16.6949L16.8511 15.7052C16.1004 14.9465 16.1004 13.726 16.8511 12.9673L17.8302 11.9777C18.5808 11.219 19.7884 11.219 20.539 11.9777L21.5181 12.9673C22.2688 13.726 22.2688 14.9465 21.5181 15.7052Z"
        fill={color}
      />
      <path
        d="M9.23006 15.7697L8.25095 16.7593C7.5003 17.518 6.29274 17.518 5.54209 16.7593L4.56299 15.7697C3.81234 15.011 3.81234 13.7905 4.56299 13.0318L5.54209 12.0422C6.29274 11.2835 7.5003 11.2835 8.25095 12.0422L9.23006 13.0318C9.9807 13.7905 9.9807 15.011 9.23006 15.7697Z"
        fill={color}
      />
      <path
        d="M15.4635 9.46843L14.4843 10.458C13.7337 11.2167 12.5261 11.2167 11.7755 10.458L10.7964 9.46843C10.0457 8.70973 10.0457 7.48921 10.7964 6.73051L11.7755 5.7409C12.5261 4.9822 13.7337 4.9822 14.4843 5.7409L15.4635 6.73051C16.1978 7.48921 16.1978 8.72622 15.4635 9.46843Z"
        fill={color}
      />
    </svg>
  );
}
