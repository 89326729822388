import React, { useEffect, useRef, useState } from 'react';
import SubtitleEditor, { SubTitle } from './index';
import { MenuItem, Stack, Tab, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { HEADER } from '../../config';
import { usePrompt } from '../../hooks/usePrompt';
import { useTranslation } from 'react-i18next';
import { convertTimeStingToNumber } from '../../utils/time';
import { LoadingButton } from '@mui/lab';
import { CallbackListener, Player, PlayerRef } from '@remotion/player';
import { SimpleSubtitles } from '../remotion/Subtitles/SimpleSubtitles';
import { Tabs } from '@mui/material';
import Iconify from '@simplytech/simply.general.iconify';
import ColorPicker from '../colorPicker';
import { Select } from '@mui/material';
import VideothumbnailPicker from '../VideothumbnailPicker';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { httpsCallable } from 'firebase/functions';
import { Functions } from '../../contexts/FirebaseContext';
import { selectCurrentPlatformWorkspaceId } from '../../redux/selectors/workspaces';
import { useSelector } from '../../redux/store';
import { useSearchParams } from 'react-router-dom';
import EarlyUser from '../../config/featureFlags';

export default function Container({
  playbackId,
  subtitles,
  handleSaveSubtitles,
  renderNoSubtitles,
}: {
  playbackId: string;
  subtitles: SubTitle[];
  handleSaveSubtitles: (subtitles: SubTitle[]) => Promise<void>;
  renderNoSubtitles: () => React.ReactElement;
}) {
  const playerRef = useRef<PlayerRef>(null);
  const { t } = useTranslation();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const [searchParams] = useSearchParams();
  const reviewId = searchParams.get('reviewId');
  const [saving, setSaving] = useState(false);
  const [isRendering, setisRendering] = useState(false);
  const [fontColor, setFontColor] = useState('#fff');
  const [fontSize, setFontSize] = useState(60);
  const [backgroundColor, setBackgroundColor] = useState('#161615');
  const [thumbFrame, setThumbFrame] = useState(2);
  const [tab, setTab] = useState(0);
  const [subs, setSubs] = useState(subtitles);
  const [displayIndex, setDisplayIndex] = useState<number>(-1);

  const isDirty = JSON.stringify(subs) !== JSON.stringify(subtitles);
  usePrompt(t('captions.unsaved'), isDirty);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (subtitles.length) {
      setSubs(subtitles);
    }
  }, [subtitles?.length]);

  const handleChange = (value: string, index: number) => {
    const updatedSubs = subs.map((sub, i) => {
      if (i === index) {
        return {
          ...sub,
          captions: value,
        };
      }
      return sub;
    });
    setSubs(updatedSubs);
  };
  const onTimeupdate: CallbackListener<'timeupdate'> = (e) => {
    const cTime = e.detail.frame / 30;
    if (cTime) {
      const currentSubIndex = subs.findIndex(
        (sub) =>
          convertTimeStingToNumber(sub.startTime) <= cTime &&
          convertTimeStingToNumber(sub.endTime) >= cTime
      );
      if (currentSubIndex !== displayIndex) {
        setDisplayIndex(currentSubIndex);
      }
    }
  };
  useEffect(() => {
    playerRef.current?.addEventListener('timeupdate', onTimeupdate);
    return () => {
      playerRef.current?.removeEventListener('timeupdate', onTimeupdate);
    };
  }, [playerRef.current]);

  const saveSubtitles = async () => {
    try {
      setSaving(true);
      await handleSaveSubtitles(subs);
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const handleChangeTab = (_: any, newValue: number) => {
    setTab(newValue);
  };

  const renderVideo = async () => {
    try {
      if (!workspaceId || !reviewId) {
        throw new Error('WorkspaceId or reviewId is missing');
      }
      if (!subtitles || subtitles?.length < 1) {
        enqueueSnackbar(t('videoCustomisation.export.noSubtitles'), { variant: 'error' });
        return;
      }
      setisRendering(true);
      enqueueSnackbar(t('videoCustomisation.rendering.start'), { variant: 'info' });
      const functionRef = httpsCallable(Functions, 'onRenderRemotionVideo');
      const response: any = await functionRef({
        reviewId,
        workspaceId,
        videoUrl: `https://stream.mux.com/${playbackId}/low.mp4`,
        fontColor,
        fontSize,
        backgroundColor,
        subtitles: subs,
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        enqueueSnackbar(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
          { variant: 'error' }
        );
        return;
      }
      enqueueSnackbar(t('videoCustomisation.rendering.success'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setisRendering(false);
    }
  };

  return (
    <Stack direction="row">
      <Box width={'50%'}>
        {subs && subs?.length > 0 ? (
          <>
            <EarlyUser>
              <Tabs value={tab} onChange={handleChangeTab} aria-label="customisation tabs">
                <Tab
                  icon={<Iconify fontSize={24} icon="mdi:user-box" />}
                  label={t('videoCustomisation.subtitles.tab.edit')}
                />
                <Tab
                  icon={<Iconify fontSize={24} icon="mdi:cards-heart" />}
                  label={t('videoCustomisation.subtitles.tab.customise')}
                />
              </Tabs>
            </EarlyUser>
            <Stack display={tab === 0 ? 'inherit' : 'none'} mt={3} spacing={4}>
              <Box>
                <SubtitleEditor
                  setCurrentTime={(time: number) => playerRef.current?.seekTo(time * 30)}
                  displayIndex={displayIndex}
                  timeFrames={subs}
                  onChange={handleChange}
                />
              </Box>
            </Stack>
            <Stack display={tab === 1 ? 'inherit' : 'none'} mt={3} spacing={4}>
              <Stack>
                <Typography variant="subtitle1">
                  {t('videoCustomisation.subtitles.text.color')}
                </Typography>
                <ColorPicker
                  key="fontColor"
                  color={fontColor}
                  onChange={(color) => setFontColor(color as string)}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">
                  {t('videoCustomisation.subtitles.background.color')}
                </Typography>
                <ColorPicker
                  key="backgroundColor"
                  color={backgroundColor}
                  onChange={(color) => setBackgroundColor(color as string)}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">
                  {t('videoCustomisation.subtitles.text.size')}
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={fontSize}
                  label="Age"
                  onChange={(event) => setFontSize(event.target.value as number)}
                >
                  <MenuItem value={50}>Small</MenuItem>
                  <MenuItem value={60}>Medium</MenuItem>
                  <MenuItem value={80}>Large</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </>
        ) : (
          renderNoSubtitles()
        )}
      </Box>
      <Box width={'10%'} />
      <Box width={'30%'} sx={{ position: 'relative' }}>
        <Box style={{ position: 'sticky', top: HEADER.DASHBOARD_DESKTOP_HEIGHT }}>
          <Box sx={{ position: 'relative' }}>
            {/* <MuxPlayer
              ref={ref}
              playbackId={playbackId}
              style={{
                // @ts-ignore
                '--media-object-fit': 'cover',
                '--media-background-color': 'transparent',
                '--captions-button': 'none',
                '--fullscreen-button': 'none',
                '--volume-range': 'none',
                '--cast-button': 'none',
                '--pip-button': 'none',
                '--playback-rate-button': 'none',
                // '--controls': 'none',
                aspectRatio: '16:9',
                height: 'inherit',
              }}
              currentTime={currentTime}
              onTimeUpdate={handleTimeUpdate}
            /> */}

            <Player
              ref={playerRef}
              component={SimpleSubtitles}
              durationInFrames={1110}
              compositionWidth={1080}
              compositionHeight={1920}
              inputProps={{
                videoUrl: `https://stream.mux.com/${playbackId}/low.mp4`,
                fontColor,
                fontSize,
                backgroundColor,
                subtitles: subs,
              }}
              controls
              style={{
                width: 300,
                borderRadius: 8,
                // height: 1280,
              }}
              // videoUrl=
              fps={30}
            />

            {/* {displayIndex > -1 && (
              <Box
                sx={{
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: 60, // 20 when no controls
                  left: 12,
                  right: 12,
                  textShadow: '0 0 5px rgba(0,0,0,0.5)',
                  fontSize: 16,
                  backgroundColor: 'rgba(0,0,0,0.9)',
                  // px: 1,
                  py: 0.5,
                  // borderRadius: 1,
                }}
              >
                <Typography color="common.white">{subs[displayIndex]?.captions}</Typography>
              </Box>
            )} */}
          </Box>
          <Box sx={{ position: 'fixed', right: 40, bottom: 40 }}>
            <LoadingButton
              variant="contained"
              size="large"
              fullWidth
              loading={saving}
              disabled={!isDirty}
              onClick={saveSubtitles}
            >
              {t('videoCustomisation.captions.save')}
              {/* Save subtitles */}
            </LoadingButton>
            <EarlyUser>
              <LoadingButton
                variant="contained"
                size="large"
                loading={isRendering}
                fullWidth
                onClick={renderVideo}
              >
                Test render
                {/* Save subtitles */}
              </LoadingButton>
            </EarlyUser>
          </Box>
        </Box>
      </Box>
      <Box width={'10%'} />
    </Stack>
  );
}
