import React from 'react';
import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface HoverCardProps extends CardProps {
  hightlight?: boolean;
}

const StyledHoverCard = styled(Card)<HoverCardProps>(({ theme, hightlight }) => {
  const transition = hightlight ? 'background-color 1.5s' : null;
  const transitions = [transition, 'box-shadow 0.3s ease-in-out'].filter(Boolean).join(', ');
  return {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[200]}`,
    transition: transitions,
    '&:hover': {
      boxShadow: theme.shadows[10], // '0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)', //,
      borderColor: 'transparent',
    },
  };
});

export default function HoverCard(props: HoverCardProps) {
  return <StyledHoverCard {...props}>{props.children}</StyledHoverCard>;
}
