import React, { useEffect, useState } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch } from '../redux/store';
import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { Onboarding, setOnboarding } from '../redux/slices/onboarding';

export default function useUserOnboarding() {
  const [isLoading, setisLoading] = useState(true);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const userId = user?.id;
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      try {
        const q = doc(DB, getCollection[Collection.ONBOARDING](userId), 'details');
        unsubscribe = onSnapshot(q, (doc) => {
          const onboardingData = {
            ...doc.data(),
          } as Onboarding;
          dispatch(setOnboarding(onboardingData));
          setisLoading(false);
        });
      } catch (error) {
        console.error('Error fetching onboarding data', error);
      }
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoading };
}
