import { createSelector } from '@reduxjs/toolkit';
import { PlatformDisplayNames, Platforms } from '../slices/platform';
import { RootState } from '../store';

const platform = (state: RootState) => state.user.platform;

export const selectPlatformDisplayName = createSelector(platform, (platform) => {
  switch (platform) {
    case Platforms.SIMPLY_FLOW:
      return PlatformDisplayNames.SIMPLY_FLOW;

    case Platforms.SIMPLY_REVIEW:
      return PlatformDisplayNames.SIMPLY_REVIEW;

    case Platforms.SIMPLY_LEADS:
      return PlatformDisplayNames.SIMPLY_LEADS;

    default:
      return PlatformDisplayNames.SIMPLY_REVIEW;
  }
});
