import { noCase } from 'change-case';
import { useState, Dispatch } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useNotifications from '../../../hooks/useNotifications';
import { useSelector } from '../../../redux/store';
import { Notification, NotificationType } from '../../../redux/slices/notificationSlice';
import useLocales from '../../../hooks/useLocales';
import i18n from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Collection } from '../../../enum';
import { doc, setDoc, writeBatch } from 'firebase/firestore';
import { getCollection } from '../../../api';
import { DB } from '../../../contexts/FirebaseContext';
import useAuth from '../../../hooks/useAuth';
import { AutomationType } from '../../../redux/slices/automationsSlice';
import { Stack } from '@mui/material';
import { getInitials } from 'src/utils/names';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  useNotifications();
  const { translate } = useLocales();
  const { user } = useAuth();
  const notifications = useSelector((state) => state.notifications.list);


  // const [notifications, setNotifications] = useState(_notifications);

  const totalUnRead = notifications.filter((n) => !n.seen).length;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    if (!user?.id) return alert(translate('general.error.default'));
    const batch = writeBatch(DB);
    notifications.forEach((n) => {
      batch.update(doc(DB, getCollection[Collection.USERS_NOTIFICATIONS](user?.id), n.id), {
        seen: true,
      });
    });
    await batch.commit();
    handleClose();
    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     seen: false,
    //   }))
    // );
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notifications.title')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('notifications.description', { totalUnRead })}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 500 } }}>
          <List
            disablePadding
            // subheader={
            //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            //     {translate('notifications.new')}
            //   </ListSubheader>
            // }
          >
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleClose={handleClose}
              />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))} */}
          {/* </List> */}
        </Scrollbar>

        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            {translate('notifications.viewAll')}
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

// type NotificationItemProps = {
//   id: string;
//   title: string;
//   description: string;
//   avatar: string | null;
//   type: string;
//   createTime: Date;
//   seen: boolean;
// };

function NotificationItem({
  notification,
  handleClose,
}: {
  notification: Notification;
  handleClose: () => void;
}) {
  const { user } = useAuth();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { avatar, avatarUrl, title, link } = renderContent(notification);

  const handleNotificationClick = async () => {
    if (user?.id) {
      await setDoc(
        doc(DB, getCollection[Collection.USERS_NOTIFICATIONS](user?.id), notification.id),
        { seen: true },
        { merge: true }
      );
      if (link) {
        navigate(link);
      }
      handleClose();
    }
  };
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.seen && {
          opacity: 0.5,
        }),
      }}
      onClick={handleNotificationClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }} src={avatarUrl}>
          {avatar}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {
              // @ts-ignore
              translate('notifications.date', { date: notification.createTime?.toDate() })
            }
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: Notification) {
  if (notification.type === NotificationType.NEW_REVIEW) {
    return {
      title: (
        <Typography variant="subtitle2">
          {notification.title}
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {i18n.t('notifications.newReview')}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            fontWeight="bold"
            sx={{ color: 'text.secondary' }}
          >
            {` ${notification.metadata?.reviewer?.name}`}
          </Typography>
        </Typography>
      ),
      avatar: <Iconify icon="material-symbols:reviews-outline-rounded" />,
      link: `/dashboard/reviews?id=${notification.metadata?.id}`,
    };
  }
  if (notification.type === NotificationType.ACCEPTED_TEAM_INVITE) {
    return {
      title: (
        <Typography variant="subtitle2">
          {notification.title}
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {i18n.t('notifications.team.accept')}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            fontWeight="bold"
            sx={{ color: 'text.secondary' }}
          >
            {` ${notification.metadata?.displayName}`}
          </Typography>
        </Typography>
      ),
      avatar: <Iconify icon="mdi:account-check" />,
      link: null,
    };
  }
  if (notification.type === NotificationType.VOIDED_TEAM_INVITE) {
    return {
      title: (
        <Typography variant="subtitle2">
          {notification.title}
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {i18n.t('notifications.team.decline')}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            fontWeight="bold"
            sx={{ color: 'text.secondary' }}
          >
            {` ${notification.metadata?.displayName}`}
          </Typography>
        </Typography>
      ),
      avatar: <Iconify icon="mdi:account-cancel" />,
      link: null,
    };
  }
  if (notification.type === NotificationType.REMOVE_TEAM_MEMBER) {
    return {
      title: (
        <Typography variant="subtitle2">
          {notification.title}
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {i18n.t('notifications.team.removed')}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            fontWeight="bold"
            sx={{ color: 'text.secondary' }}
          >
            {` ${notification.metadata?.companyName}`}
          </Typography>
        </Typography>
      ),
      avatar: <Iconify icon="mdi:account-cancel" />,
      link: null,
    };
  }
  if (notification.type === NotificationType.REVIEW_REMINDER_INVITE) {
    return {
      title: (
        <Typography variant="subtitle2">
          {notification.title}
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {i18n.t('notifications.invite.syncReady', { count: notification.metadata?.count })}
          </Typography>
          {/* <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {` ${notification.metadata?.count}`}
          </Typography> */}
        </Typography>
      ),
      avatar: <Iconify icon="tabler:send" />,
      link: `/dashboard/invites?tab=${AutomationType.REVIEW_REMINDER}`,
    };
  }
  if (notification.type === NotificationType.NEW_LEAD_SUBMISSION) {
    return {
      title: (
        <Stack direction="row" spacing={0.5}>
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {i18n.t('notifications.leads.new')}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            fontWeight="bold"
            sx={{ color: 'text.secondary' }}
          >
            {notification.metadata.leadName}
          </Typography>
        </Stack>
      ),
      avatar: <Iconify icon="mdi:user-box" />,
      link: `/dashboard/leads/list`,
    };
  }
  if (notification.type === NotificationType.NEW_CHAT_MESSAGE) {
    return {
      title: (
        <Stack direction="row" spacing={0.5}>
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {i18n.t('notifications.chat.new_message')}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            fontWeight="bold"
            sx={{ color: 'text.secondary' }}
          >
            {notification.metadata?.displayName}
          </Typography>
        </Stack>
      ),
      avatar: notification.metadata?.displayName
        ? getInitials(notification.metadata?.displayName)
        : null,
      avatarUrl: notification.metadata?.photoURL,
      link: `/dashboard/chat/conversations?conversation=${notification.metadata?.conversationId}`,
    };
  }

  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  return {
    // avatar: notification?.avatar ? (
    //   <img alt={notification.title} src={notification.avatar} />
    // ) : null,
    title: 'unknown',
    avatar: notification?.metadata?.reviewer?.img,
    link: null,
  };
}
