import React, { useState } from 'react';
import zenfit from '../../../../../assets/integrations/zenfit.png';
import { Card, Stack, DialogContent, Box, Dialog, Alert } from '@mui/material';
import Image from '../../../../../components/Image';
import { LoadingButton } from '@mui/lab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PulseDot from '../../../../../components/PulseDot';
import { useSelector } from 'react-redux';
import {
  selectZenfitApi,
  ApiIntegrationStatus,
} from '../../../../../redux/slices/apiIntegrationsSlice';
import { doc, writeBatch } from 'firebase/firestore';
import { DB, Functions } from '../../../../../contexts/FirebaseContext';
import { selectCurrentPlatformWorkspaceId } from '../../../../../redux/selectors/workspaces';
import { httpsCallable } from 'firebase/functions';
import { selectZenfitAutomation } from '../../../../../redux/slices/automationsSlice';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import { useNavigate } from 'react-router-dom';

export default function Disconnect() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const connectedAPIKey = useSelector(selectZenfitApi);
  const hasAutomation = useSelector(selectZenfitAutomation);
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDisconnect = async () => {
    try {
      setLoading(true);
      if (!workspaceId) {
        throw new Error('Workspace ID is missing');
      }
      const functionRef = httpsCallable(Functions, 'disconnectZenfitClientApi');
      const response: any = await functionRef({ workspaceId });

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }
      enqueueSnackbar(t('apiKey.disconnect.success'), { variant: 'success' });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar(t('general.error'), { variant: 'error' });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const integrationHasIssues = connectedAPIKey.status !== ApiIntegrationStatus.STABLE;
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Stack alignItems="center" py={3}>
            <Image src={zenfit} alt="zenfit" />
          </Stack>
          <Stack alignItems="center" direction="row">
            {integrationHasIssues ? (
              <>
                <Box mt={-2}>
                  <PulseDot size={16} color={theme.palette.error.main} />
                </Box>
                <Box ml={2}>
                  <Typography variant="body1" color="grey.700">
                    {t('integrations.zenfit.health.bad')}
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box mt={-2}>
                  <PulseDot size={16} color={theme.palette.success.main} />
                </Box>
                <Box ml={2}>
                  <Typography variant="body1" color="grey.700">
                    {t('integrations.zenfit.health.good')}
                  </Typography>
                </Box>
              </>
            )}
          </Stack>
          {!hasAutomation && (
            <Stack>
              <Alert
                severity="warning"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(PATH_DASHBOARD.general.reviewAutomations);
                }}
              >
                <Typography variant="body1" color="grey.700">
                  {t('integrations.zenfit.automation.missing.warning')}
                </Typography>
              </Alert>
            </Stack>
          )}
          <LoadingButton type="submit" variant="outlined" color="error" onClick={handleOpen}>
            Disconnect Zenfit
          </LoadingButton>
        </Stack>
      </Card>
      <Dialog keepMounted open={open} onClose={handleClose}>
        <DialogTitle mb={2}>Disconnect Zenfit</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('integrations.zenfit.disconnect.description')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('general.cancel')}</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onDisconnect}>
            Disconnect
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
