import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

const storage = getStorage();

// const metadata = {
//   contentType: 'image/jpeg',
// };

const minimumFileSize = 100;

export const upload = async (
  file: File | Blob,
  path: string,
  metadata: {}
): Promise<{ url?: string; error?: Error }> => {
  const storageRef = ref(storage, path + ((file as File)?.name || 'reviewer_video'));
  try {
    // check pre upload
    const fileSize = file.size;
    if (fileSize < minimumFileSize) {
      return { error: new Error('File size is too small (<100 bytes).') };
    }

    const snapshot = await uploadBytes(storageRef, file, { customMetadata: metadata });
    const downloadUrl = await getDownloadURL(snapshot.ref);
    if (snapshot.metadata.size < minimumFileSize) {
      Sentry.captureMessage(
        ` File is too small ${snapshot.metadata.size} json=${JSON.stringify(snapshot)}`
      );
      console.error('File is too small');
      throw new Error('File is too small');
    }
    return { url: downloadUrl };
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    return { error };
  }
};
export const uploadResumable = (file: File | Blob, path: string, metadata: {}) => {
  const storageRef = ref(storage, path + ((file as File)?.name || 'reviewer_video'));

  return uploadBytesResumable(storageRef, file, { customMetadata: metadata });
};

export default function useUpload({ file, path, metadata }: any) {
  // Upload file and metadata to the object 'images/mountains.jpg'

  useEffect(() => {
    upload(file, path, metadata);

    return () => {};
  }, [file]);

  // Listen for state changes, errors, and completion of the upload.
}
