// @mui
import { Grid } from '@mui/material';
// components
import Zenfit from '../integrations/Zenfit';
import Lenus from '../integrations/Lenus';
import Beefit from '../integrations/Beefit';
import GoogleCalendar from '../integrations/GoogleCalendar';
import AdminUser, { SuperAdminUser } from 'src/guards/UserRoles';
import Stripe from '../integrations/Stripe';

export default function AccountIntegrations() {
  // const { enqueueSnackbar } = useSnackbar();

  // const onSubmit = async (data: FormValuesProps) => {
  //   try {
  //     await new Promise((resolve) => setTimeout(resolve, 500));
  //     enqueueSnackbar('Update success!');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Zenfit />
      </Grid>
      <Grid item xs={6}>
        <Lenus />
      </Grid>
      <SuperAdminUser>
        <Grid item xs={6}>
          <Stripe />
        </Grid>
      </SuperAdminUser>
      {/* <SuperAdminUser>
        <Grid item xs={6}>
          <GoogleCalendar />
        </Grid>
      </SuperAdminUser> */}
    </Grid>
  );
}
