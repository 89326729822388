import { useState, useRef, ChangeEvent } from 'react';
// @mui
// import { MobileDateRangePicker } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
} from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
// import { DisplayTime, useDatePicker } from '../../../sections/@dashboard/kanban/KanbanTaskAdd';
import IconButtonAnimate from '../../../components/animate/IconButtonAnimate';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import CopyInviteLinkSmall from '../../../components/CopyInviteLinkSmall';
// import { Invite } from '../../../redux/slices/invites';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/redux/store';
import { LeadType, selectLeadSubmission } from 'src/redux/slices/leadFormsSubmissionSlice';
import { Card, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material';
import { InfoIcon } from 'src/theme/overrides/CustomIcons';
import Lottie, { aiLoader } from 'src/components/Lottie';
import { t } from 'i18next';
import Notes from './Notes';
import { httpsCallable } from 'firebase/functions';
import { Functions } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
//

// ----------------------------------------------------------------------

// const PRIORITIZES = ['low', 'medium', 'hight'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type Props = {
  id: string | null;
  onClose: VoidFunction;
  onDeleteLead: VoidFunction;
};

export default function SubmissionDrawer({ id, onClose, onDeleteLead }: Props) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const submission = useSelector((state) => selectLeadSubmission(state, id));
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // const [prioritize, setPrioritize] = useState('low');

  const name = submission?.name;
  const createTime = submission?.createTime;
  const email = submission?.email;
  const phone = submission?.phone;
  const utms = submission?.utms || {};
  // const status = invite?.status || '';

  // const {
  //   dueDate,
  //   startTime,
  //   endTime,
  //   isSameDays,
  //   isSameMonths,
  //   onChangeDueDate,
  //   openPicker,
  //   onOpenPicker,
  //   onClosePicker,
  // } = useDatePicker({
  //   date: due,
  // });

  const handleDelete = () => {
    onDeleteLead();
    setConfirmDelete(false);
    onClose();
  };

  // const handleChangePrioritize = (event: ChangeEvent<HTMLInputElement>) => {
  //   setPrioritize(event.target.value);
  // };

  const onGenerateLeadScript = async () => {
    try {
      if (!id) {
        throw new Error(`Invalid formSubmitId=${id}`);
      }
      setLoading(true);
      const functionRef = httpsCallable(Functions, 'generateLeadScript');
      const response: any = await functionRef({
        formSubmitId: id,
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        enqueueSnackbar(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
          { variant: 'error' }
        );
        return;
      }
    } catch (error) {
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const checkSize = 24;
  return (
    <>
      <Drawer
        open={Boolean(id)}
        onClose={onClose}
        anchor="right"
        PaperProps={{ sx: { width: { xs: 1, sm: 680 } } }}
      >
        <Stack py={2.5} px={4} direction="row" alignItems="center">
          {!isDesktop && (
            <Tooltip title="Back">
              <IconButtonAnimate onClick={onClose} sx={{ mr: 1 }}>
                <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}

          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h5">{name}</Typography>
            <Stack direction="row" spacing={0.5}>
              {Object.entries(utms).map(([key, value]) => (
                <Tooltip title={value}>
                  <Chip size="small" label={value} style={{ maxWidth: 100 }} />
                </Tooltip>
              ))}
            </Stack>
          </Stack>

          {/* <Button
          size="small"
          variant="outlined"
          disabled
          color={taskCompleted ? 'primary' : 'inherit'}
          startIcon={
            taskCompleted && <Iconify icon={'eva:checkmark-fill'} width={16} height={16} />
          }
          onClick={handleToggleCompleted}
        >
          {sentenceCase(status)}
        </Button> */}

          <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
            {/* <Tooltip title="Like this">
              <IconButtonAnimate size="small">
                <Iconify icon={'ic:round-thumb-up'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>

            <>
              <Tooltip title="Attachment">
                <IconButtonAnimate size="small" onClick={handleAttach}>
                  <Iconify icon={'eva:attach-2-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
              <input ref={fileInputRef} type="file" style={{ display: 'none' }} />
            </> */}

            <Tooltip title="Slet invitation">
              <IconButtonAnimate onClick={() => setConfirmDelete(true)} size="small">
                <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>

            {/* <Tooltip title="More actions">
              <IconButtonAnimate size="small">
                <Iconify icon={'eva:more-horizontal-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip> */}
          </Stack>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 4, py: 3 }}>
            {/* <OutlinedInput
            fullWidth
            multiline
            size="small"
            placeholder="Task name"
            value={name}
            sx={{
              typography: 'h6',
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
            }}
          /> */}
            {/* <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>{t('general.link')}</LabelStyle>
            
            <Tooltip title={link} placement="top">
              <Stack sx={{ width: '100%' }}>
                <CopyInviteLinkSmall value={link || 'Generer link...'} />
              </Stack>
            </Tooltip>
            
          </Stack> */}

            <Typography variant="subtitle1">
              {t('leadSubmissions.drawer.contact.headline')}
            </Typography>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>{t('general.email')}</LabelStyle>
              {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
              <CopyInviteLinkSmall
                startAdornment={
                  !submission?.isEmailValid && (
                    <InputAdornment
                      position="start"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 24,
                        height: 24,
                        marginLeft: -0.5,
                      }}
                    >
                      <Stack sx={{ height: 24 }}>
                        <Tooltip title={t('leads.email.validation.warning')}>
                          <Box>
                            <Iconify
                              sx={{ alignSelf: 'center' }}
                              fontSize={24}
                              color={`${theme.palette.warning.main} !important`}
                              icon="mdi:warning-octagon-outline"
                            />
                          </Box>
                        </Tooltip>
                      </Stack>
                    </InputAdornment>
                  )
                }
                value={email || ''}
              />
              {/* </Stack> */}
            </Stack>
            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5, display: 'flex' }}>{t('general.phone')}</LabelStyle>

              {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
              <CopyInviteLinkSmall
                startAdornment={
                  !submission?.isPhoneValid && (
                    <InputAdornment
                      position="start"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 24,
                        height: 24,
                        marginLeft: -0.5,
                      }}
                    >
                      <Stack sx={{ height: 24 }}>
                        <Tooltip title={t('leads.phone.validation.warning')}>
                          <Box>
                            <Iconify
                              sx={{ alignSelf: 'center' }}
                              fontSize={24}
                              color={`${theme.palette.warning.main} !important`}
                              icon="mdi:warning-octagon-outline"
                            />
                          </Box>
                        </Tooltip>
                      </Stack>
                    </InputAdornment>
                  )
                }
                value={(phone || '') as string}
              />
              {/* </Stack> */}
            </Stack>

            {/* <Stack direction="row" alignItems="center">
              <LabelStyle>Prioritize</LabelStyle>
              <TextField
                fullWidth
                select
                size="small"
                value={prioritize}
                onChange={handleChangePrioritize}
                sx={{
                  '& svg': { display: 'none' },
                  '& fieldset': { display: 'none' },
                  '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' },
                }}
              >
                {PRIORITIZES.map((option) => (
                  <MenuItem key={option} value={option} sx={{ mx: 1, my: 0.5, borderRadius: 1 }}>
                    <Box
                      sx={{
                        mr: 1,
                        width: 14,
                        height: 14,
                        borderRadius: 0.5,
                        bgcolor: 'error.main',
                        ...(option === 'low' && { bgcolor: 'info.main' }),
                        ...(option === 'medium' && { bgcolor: 'warning.main' }),
                      }}
                    />
                    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                      {option}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Stack> */}

            {submission?.type !== LeadType.MANUAL && (
              <Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle1" sx={{ mt: 1.5, mb: 1.5 }}>
                    {t('leadSubmissions.drawer.aiRecommendation')}
                    {/* {"AI's recommendation"} */}
                  </Typography>
                  <Box>
                    <Tooltip title={t('leadSubmissions.drawer.aiRecommendationTooltip')}>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
                {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
                {/* @ts-ignore */}
                {!submission?.scriptRecommendation ? (
                  <Stack
                    alignSelf="center"
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    width="100%"
                  >
                    {loading ? (
                      <>
                        <Typography variant="body1" color="primary.main">
                          {t('leads.form.ai.suggestions')}
                        </Typography>
                        <Stack sx={{ width: 100 }}>
                          <Lottie animation={aiLoader} />
                        </Stack>
                      </>
                    ) : (
                      <Button variant="contained" size="large" onClick={onGenerateLeadScript}>
                        Genereate AI smart recommendation
                      </Button>
                    )}
                  </Stack>
                ) : (
                  <CopyInviteLinkSmall multiline value={submission?.scriptRecommendation || ''} />
                )}
                {/* </Stack> */}
              </Stack>
            )}
            <Notes leadId={submission?.id as string} note={submission?.note} />
          </Stack>
        </Scrollbar>

        <Divider />
      </Drawer>
      <ConfirmationDialogRaw
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

const CardStyle = styled(Card)<{ selected?: boolean }>(({ theme, selected }) => ({
  padding: theme.spacing(1.5),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  boxShadow: selected ? '0 0px 16px 2px rgba(0,0,0,0.08)' : 'none',
  border: selected ? 'none' : `solid 1px ${theme.palette.grey.A200}`,
  backgroundColor: selected ? 'none' : theme.palette.grey[100],
  zIndex: selected ? 1 : 0,
}));

function ConfirmationDialogRaw(props: {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}) {
  const { open, onClose, onDelete } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onDelete();
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValue((event.target as HTMLInputElement).value);
  // };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      // TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>{t('general.headline.confirm')}</DialogTitle>
      {/* <DialogContent dividers>heheh</DialogContent> */}
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {t('general.cancel')}
        </Button>
        <Button variant="contained" color="error" onClick={handleOk}>
          {t('general.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
