import * as React from 'react';
import {
  CircularProgress,
  Paper,
  Stack,
  Avatar,
  IconButton,
  Typography,
  Box,
  Divider,
  MenuItem,
  Button,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import useLeadActivity from 'src/hooks/useLeadActivity';
import AddActivity, { CssTextField, getActivityTypeInput } from './AddActivity';
import { formatDistanceToNowStrict, isAfter } from 'date-fns';
import MenuPopover from 'src/components/MenuPopover';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Collection, RequestState } from 'src/enum';
import { collection, deleteDoc, doc, writeBatch } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { useSelector } from 'src/redux/store';
import { selectCurrentPlatformWorkspaceId } from 'src/redux/selectors/workspaces';
import { getCollection } from 'src/api';

export default function LeadActivity({ leadId }: { leadId: string }) {
  const { t } = useTranslation();
  const { leadActivity, isLoading } = useLeadActivity(leadId);

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <>
      <AddActivity leadId={leadId} />
      <Typography variant="overline" color="text.secondary">
        {t('leadSubmissions.activitylog.logs.headline')}
      </Typography>
      {leadActivity.map((activity) => (
        <ActivityLog
          id={activity.id}
          leadId={leadId}
          type={activity.type}
          text={activity.content}
          author={activity.author}
          createTime={activity.createTime}
          updateTime={activity.updateTime}
        />
      ))}
    </>
  );
}
function ActivityLog({
  id,
  leadId,
  type,
  text,
  author,
  createTime,
  updateTime,
}: {
  id: string;
  leadId: string;
  type: 1 | 2 | 3 | 4;
  text: string;
  author: { id: string; email: string; name?: string };
  createTime: Date;
  updateTime: Date;
}) {
  const { t } = useTranslation();
  const [state, setState] = React.useState(RequestState.IDLE);
  const { enqueueSnackbar } = useSnackbar();
  const icon = getIconByLeadActivityType(type);
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId) as string;
  const isEdited = isAfter(updateTime, createTime);
  const [open, setOpen] = React.useState<HTMLElement | null>(null);
  const [content, setContent] = React.useState<string | null>(null);
  const { placeholder, backgroundColor } = getActivityTypeInput(type);
  const loading = state === RequestState.PENDING;
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const updateLeadActivity = async () => {
    try {
      if (!workspaceId || !leadId || !id || !content) {
        throw new Error('No leadId found');
      }
      setState(RequestState.PENDING);
      const batch = writeBatch(DB);

      const leadActivityRef = doc(
        DB,
        getCollection[Collection.LEADS_FORMS_SUBMISSIONS_ACTIVITY](workspaceId, leadId),
        id
      );
      const leadSubRef = doc(
        DB,
        getCollection[Collection.LEADS_FORMS_SUBMISSIONS](workspaceId),
        leadId
      );

      batch.set(leadActivityRef, { content, updateTime: new Date() }, { merge: true });
      batch.set(
        leadSubRef,
        {
          lastestActivity: {
            content,
            updateTime: new Date(),
          },
        },
        { merge: true }
      );

      await batch.commit();

      enqueueSnackbar(t('general.success.default'));
      setState(RequestState.RESOLVED);
      setContent('');
    } catch (error) {
      enqueueSnackbar(t('general.error.default'));
      setState(RequestState.REJECTED);
      console.error('Error submitting activity', error);
    }
  };

  const onDelete = async () => {
    try {
      await deleteDoc(
        doc(DB, getCollection[Collection.LEADS_FORMS_SUBMISSIONS_ACTIVITY](workspaceId, leadId), id)
      );
      enqueueSnackbar(t('general.success.default'));
    } catch (error) {
      enqueueSnackbar(t('general.error.default'));
      console.error('Error deleting activity', error);
    }
  };
  return (
    <Stack>
      <Stack flexDirection="row" gap={2}>
        <Avatar /* sx={{ bgcolor: 'red' }} */>
          <Iconify icon={icon} fontSize={24} color={'white'} />
        </Avatar>
        {content ? (
          <>
            <CssTextField
              placeholder={placeholder}
              fullWidth
              multiline
              value={content}
              onChange={(e) => setContent(e.target.value)}
              inputProps={{ style: { borderTopLeftRadius: 0 } }}
              style={{ borderTopLeftRadius: 0, marginTop: 0, backgroundColor }}
              rows={4}
            />
          </>
        ) : (
          <>
            <Paper
              sx={{
                position: 'relative',
                flexGrow: 1,
                p: 2,
                pr: 3,
                border: `1px solid ${palette.light.grey[300]}`,
              }}
            >
              <Typography variant="body1">{text}</Typography>
              <Box sx={{ position: 'absolute', right: 0, top: 8 }}>
                <IconButton onClick={handleOpen} aria-label="settings">
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
                <MenuPopover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  arrow="right-top"
                  sx={{
                    mt: -1,
                    width: 160,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setContent(text);
                      handleClose();
                      // onView();
                    }}
                  >
                    <Iconify icon={'eva:edit-fill'} />
                    {t('general.edit.text')}
                  </MenuItem>
                  <MenuItem onClick={onDelete} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} />
                    {t('general.delete')}
                  </MenuItem>
                </MenuPopover>
              </Box>
            </Paper>
          </>
        )}
      </Stack>
      {content && (
        <Stack sx={{ pt: 1 }} gap={1} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button variant="outlined" onClick={() => setContent(null)}>
            {t('general.cancel')}
          </Button>
          <LoadingButton loading={loading} variant="contained" onClick={updateLeadActivity}>
            {t('leadSubmissions.activitylog.actions.submit')}
          </LoadingButton>
        </Stack>
      )}
      <Stack sx={{ pt: 0.5, pr: 0.5 }} gap={1} flexDirection="row" justifyContent="flex-end">
        <Typography variant="caption" color="text.secondary">
          {author?.name || author.email}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          -
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {t('general.dateToNow', { date: isEdited ? updateTime : createTime })}{' '}
          {isEdited && '(edited)'}
        </Typography>
      </Stack>
    </Stack>
  );
}

export function getIconByLeadActivityType(type: 1 | 2 | 3 | 4) {
  switch (type) {
    case 1:
      return 'mage:note';
    case 2:
      return 'ic:baseline-phone';
    case 3:
      return 'ic:baseline-email';
    case 4:
      return 'mynaui:message';
    default:
      return 'mage:note';
  }
}
