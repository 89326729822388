import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IntegrationState } from '../slices/integrationSlice';
import { WidgetType } from '../../enum';
import { Platforms } from '../slices/platform';

// ...
// SIMPLY REVIEW
const integrationsList = (state: RootState) => state.integrations.list;
const integrationsMap = (state: RootState) => state.integrations.map;
const integrationsMapType = (state: RootState) => state.integrations.mapType;
// SIMPLY FLOW
const integrationsFlowList = (state: RootState) => state.integrationsFlow.list;
const integrationsFlowMap = (state: RootState) => state.integrationsFlow.map;
const integrationsFlowMapType = (state: RootState) => state.integrationsFlow.mapType;
// SIMPLY LEADS
// const leadFormMap = (state: RootState) =>
//   state.leadForms.list.reduce(
//     (acc, item) => ({
//       ...acc,
//       [item.id]: item,
//     }),
//     {}
//   ) as { [key: string]: any };

const platform = (state: RootState) => state.user.platform;

export const selectCurrentPlatformIntegrations = createSelector(
  integrationsList,
  integrationsFlowList,
  platform,
  (integrationsList, integrationsFlowList, platform) => {
    switch (platform) {
      case Platforms.SIMPLY_FLOW:
        return integrationsFlowList;

      case Platforms.SIMPLY_REVIEW:
        return integrationsList;

      default:
        return integrationsList;
    }
  }
);

export const selectCurrentPlatformIntegrationsMap = createSelector(
  integrationsMap,
  integrationsFlowMap,
  // integrationsMapLeads,
  platform,
  (integrationsMap, integrationsFlowMap, /* integrationsMapLeads, */ platform) => {
    switch (platform) {
      case Platforms.SIMPLY_FLOW:
        return integrationsFlowMap;

      case Platforms.SIMPLY_REVIEW:
        return integrationsMap;

      // case Platforms.SIMPLY_LEADS:
      //   return integrationsMapLeads;

      default:
        return integrationsMap;
    }
  }
);
// export const selectLeadFormIntegration = createSelector(
//   leadFormMap,
//   // integrationsMapLeads,
//   platform,
//   (integrationsMap, /* integrationsMapLeads, */ platform) => {
//     switch (platform) {
//       // case Platforms.SIMPLY_FLOW:
//       //   return integrationsFlowMap;

//       // case Platforms.SIMPLY_REVIEW:
//       //   return integrationsMap;

//       // case Platforms.SIMPLY_LEADS:
//       //   return integrationsMapLeads;

//       default:
//         return integrationsMap;
//     }
//   }
// );

export const selectCurrentPlatformIntegrationsMapType = createSelector(
  integrationsMapType,
  integrationsFlowMapType,
  platform,
  (integrationsMapType, integrationsFlowMapType, platform) => {
    switch (platform) {
      case Platforms.SIMPLY_FLOW:
        return integrationsFlowMapType;

      case Platforms.SIMPLY_REVIEW:
        return integrationsMapType;

      default:
        return integrationsMapType;
    }
  }
);

export const selectHasCurrentPlatformIntegration = createSelector(
  integrationsList,
  integrationsFlowList,
  platform,
  (integrationsList, integrationsFlowList, platform) => {
    switch (platform) {
      case Platforms.SIMPLY_FLOW:
        return integrationsFlowList?.length > 0;
      case Platforms.SIMPLY_REVIEW:
        return integrationsList?.length > 0;
      default:
        return integrationsList?.length > 0;
    }
  }
);

export const selectHasCurrentPlatformIntegrationInstalled = createSelector(
  integrationsList,
  integrationsFlowList,
  platform,
  (integrationsList, integrationsFLowList, platform) => {
    switch (platform) {
      case Platforms.SIMPLY_FLOW:
        return Boolean(integrationsFLowList.find((i) => i.state === IntegrationState.ACTIVE));

      case Platforms.SIMPLY_REVIEW:
        return Boolean(integrationsList.find((i) => i.state === IntegrationState.ACTIVE));

      default:
        return Boolean(integrationsList.find((i) => i.state === IntegrationState.ACTIVE));
    }
  }
);

// const hasFloatingVideoWidet = (state: RootState) =>
//   integrationsMapType[WidgetType.FLOATING_INTRO_VIDEO];

export const selectCurrentPlatformHasFloatingVideo = createSelector(
  integrationsFlowMapType,
  platform,
  (integrationsFlowMapType, platform) => {
    if (platform === Platforms.SIMPLY_FLOW) {
      return Boolean(integrationsFlowMapType[WidgetType.FLOATING_INTRO_VIDEO]?.videoProcessed);
    }
    return false;
  }
);
