import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { RequestState } from '../../enum';
import keyBy from 'lodash.keyby';

export enum ApiIntegrationStatus {
  STABLE = 'STABLE',
  UNSTABLE = 'UNSTABLE',
}

export interface ApiIntegration {
  id: string;
  displayApiKey: string;
  createTime: Date;
  status: ApiIntegrationStatus;
  userId: string;
}
export interface GoogleCalendarApiIntegration {
  id: string;
  displayApiKey: string;
  createTime: Date;
  status: ApiIntegrationStatus;
  userId: string;
}

// Define a type for the slice state
interface WorkpsaceReviewState {
  state: RequestState;
  currentWorkspace: ApiIntegration | null;
  ids: string[];
  map: { [key: string]: ApiIntegration };
  // leads
  zenfitLeadIds: string[];
  zenfitLeadMap: { [key: string]: ApiIntegration };
  zenfitLeadState: RequestState;
  // Google calendar
  googleCalendarIds: string[];
  googleCalendarMap: { [key: string]: ApiIntegration };
  googleCalendarState: RequestState;
  // stripe
  stripeIds: string[];
  stripeMap: { [key: string]: ApiIntegration };
  stripeState: RequestState.IDLE;
}

// Define the initial state using that type
const initialState: WorkpsaceReviewState = {
  state: RequestState.IDLE,
  currentWorkspace: null,
  ids: [],
  map: {},
  // leads
  zenfitLeadIds: [],
  zenfitLeadMap: {},
  zenfitLeadState: RequestState.IDLE,
  // Google calendar
  googleCalendarIds: [],
  googleCalendarMap: {},
  googleCalendarState: RequestState.IDLE,
  // stripe
  stripeIds: [],
  stripeMap: {},
  stripeState: RequestState.IDLE,
};

export const apiIntegrationsSlice = createSlice({
  name: 'apiIntegrations',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setApiIntegrations: (state, action: PayloadAction<ApiIntegration[]>) => {
      const map = keyBy(action.payload, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        ids: action.payload.filter(Boolean).map((workspace) => workspace.id),
        map,
        state: RequestState.RESOLVED,
      };
    },
    setZenfitLeadApiIntegrations: (state, action: PayloadAction<ApiIntegration[]>) => {
      const zenfitLeadMap = keyBy(action.payload, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        zenfitLeadIds: action.payload.filter(Boolean).map((workspace) => workspace.id),
        zenfitLeadMap,
        zenfitLeadState: RequestState.RESOLVED,
      };
    },
    setRemoveZenfitApiIntegration: (state) => {
      const { zenfit, ...rest } = state.map;
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        ids: state.ids.filter((integration) => integration === 'zenfit'),
        map: {
          ...rest,
        },
      };
    },
    setGoogleCalendarApiIntegrations: (state, action: PayloadAction<ApiIntegration[]>) => {
      const googleCalendarMap = keyBy(action.payload, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        googleCalendarIds: action.payload.filter(Boolean).map((workspace) => workspace.id),
        googleCalendarMap,
        googleCalendarState: RequestState.RESOLVED,
      };
    },
  },
});

export const {
  setZenfitLeadApiIntegrations,
  setApiIntegrations,
  setGoogleCalendarApiIntegrations,
  setRemoveZenfitApiIntegration,
} = apiIntegrationsSlice.actions;

export const selectLoadingState = (state: RootState) =>
  state.apiIntegrations.state === RequestState.PENDING ||
  state.apiIntegrations.state === RequestState.IDLE;

export const selectZenfitApi = (state: RootState) => state.apiIntegrations.map.zenfit;

export const selectHasGoogleCalendarApi = (state: RootState) =>
  Boolean(state.apiIntegrations.googleCalendarMap.googleCalendar);

export const selectHasStripeApi = (state: RootState) =>
  Boolean(state.apiIntegrations.stripeMap.stripeConnect);

export default apiIntegrationsSlice.reducer;
