import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Tooltip, Stack, Box, Typography } from '@mui/material';
import { useSelector } from '../redux/store';
import { useTranslation } from 'react-i18next';
import { hasPremiumSelector } from '../redux/slices/workspaceReviewsSlice';

/**
 * Everyone has premium features, but on trial
 * @returns
 */
export function PremiumFeatureLabel() {
  const theme = useTheme();
  const { t } = useTranslation();
  const hasPremium = useSelector(hasPremiumSelector);
  const tooltipText = hasPremium
    ? 'premium.feature.general.unlocked'
    : 'premium.feature.general.locked';

  if (hasPremium) return null;
  return (
    <Tooltip sx={{ zIndex: 1000 }} title={t(tooltipText) as string}>
      <Stack sx={{ display: 'flex' }}>
        <Box
          sx={{
            px: 0.5,
            py: 0.25,
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: 0.5,
          }}
        >
          <Typography variant="body2" fontSize={10} lineHeight="14px">
            PRO
          </Typography>
        </Box>
      </Stack>
    </Tooltip>
  );
}
