import { ReactNode, useEffect, useState } from 'react';
import LoadingScreen from '../components/LoadingScreen';
// hooks
import useAuth from '../hooks/useAuth';
import ReviewPageOwnerBanner from '../pages/client/ReviewPageOwnerBanner';
import { selectIsOwner } from '../redux/slices/userSlice';
import { useSelector } from '../redux/store';
// routes
import PageSettings from '../sections/@dashboard/user/initial/PageSettings';

// ----------------------------------------------------------------------

type ReviewPageProps = {
  children: ReactNode;
};

export default function ReviewPageGuard({ children }: ReviewPageProps) {
  const { isInitialized } = useAuth();
  const isOwner = useSelector(selectIsOwner);
  const userId = useSelector((state) => state.workspaceReview.currentWorkspace?.id) as string;
  const [adminView, setAdminView] = useState(isOwner);

  // console.log({ adminView, isOwner, isAuthenticated, isInitialized });

  useEffect(() => {
    if (isOwner) {
      setAdminView(isOwner);
    }
  }, [isOwner]);

  if (!isInitialized) return <LoadingScreen />;

  if (adminView) {
    return (
      <>
        <ReviewPageOwnerBanner isAdminView toggleView={() => setAdminView(!adminView)} />
        <PageSettings userId={userId} />
      </>
    );
  }

  return (
    <>
      <ReviewPageOwnerBanner isAdminView={false} toggleView={() => setAdminView(!adminView)} />
      {children}
    </>
  );
}
