import { useEffect } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { Collection } from 'src/enum';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  StripeProduct,
  StripeSubscription,
  setProducts,
  setSubscriptions,
} from 'src/redux/slices/stripeSlice';

export default function useStripe() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.id);
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(collection(DB, getCollection[Collection.CUSTOMERS_SUBSCRIPTIONS](userId)));
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const subs = querySnapshot.docs.map((doc) => {
            const productPath = doc.data()?.product?._key?.path;
            return {
              ...doc.data(),
              id: doc.id,
              created: doc.data()?.created?.toDate().getTime(),
              product: productPath?.segments
                ?.slice(productPath.offset, productPath.offset + productPath.len)
                ?.join('/'),
            };
          }) as StripeSubscription[];
          dispatch(setSubscriptions(subs));
        },
        (error) => {
          console.error(error);
        }
      );
    }
    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  useEffect(() => {
    const q = query(
      collection(DB, getCollection[Collection.PRODUCTS]),
      where('active', '==', true)
    );
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const products = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as StripeProduct[];
        dispatch(setProducts(products));
      },
      (error) => {
        console.error(error);
      }
    );

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
}
