import { TextField, Typography } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCollection } from 'src/api';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection } from 'src/enum';
import { useSelector } from 'src/redux/store';

export default function Notes({ leadId, note }: { leadId: string; note?: string }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector((state) => state.user.id);
  const [tmpNote, setNote] = useState<string>(note || '');
  useEffect(() => {
    setNote(note || '');
  }, [note]);

  const updateNote = async () => {
    try {
      if (!userId || !leadId) throw new Error(`User ID=${userId} or leadId=${leadId} is missing`);
      await setDoc(
        doc(DB, getCollection[Collection.LEADS_FORMS_SUBMISSIONS](userId), leadId),
        { note: tmpNote },
        { merge: true }
      );

      enqueueSnackbar(t('leadSubmissions.drawer.notes.success'), { variant: 'success' });
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };

  return (
    <>
      <Typography variant="subtitle1">{t('leadSubmissions.drawer.notes.headline')}</Typography>
      <TextField
        id="standard-multiline-static"
        label=""
        multiline
        rows={4}
        onChange={(e) => setNote(e.target.value)}
        value={tmpNote}
        variant="outlined"
        onBlur={updateNote}
      />
    </>
  );
}
