import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const BrandTextField = styled(TextField)<TextFieldProps & { customColor?: any }>((props: any) => {
  const { theme, customColor } = props;
  // console.log({ customColor });

  if (!customColor) {
    return {};
  }
  return {
    '& label.Mui-focused': {
      color: customColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      // '& fieldset': {
      //   borderColor: 'red',
      // },
      // '&:hover fieldset': {
      //   borderColor: 'yellow',
      // },
      '&.Mui-focused fieldset': {
        borderColor: customColor,
      },
    },
  };
});

export default BrandTextField;
