import { useEffect } from 'react';
import { query, collection, onSnapshot, orderBy } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';
import { useDispatch, useSelector } from 'src/redux/store';
import { Booking, setBookings } from 'src/redux/slices/bookingsSlice';
import { selectCurrentPlatformWorkspaceId } from 'src/redux/selectors/workspaces';

export default function useBookingsList() {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  console.log({ workspaceId });

  useEffect(() => {
    let unsubscribe: () => void;
    if (!workspaceId) return;
    const q = query(
      collection(DB, `workspaceBookings/${workspaceId}/bookings`),
      orderBy('createTime', 'desc')
    );
    unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const bookings = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Booking[];
        console.log({ bookings });
        dispatch(setBookings(bookings));
      },
      (error) => {
        console.error(error);
      }
    );

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
}
