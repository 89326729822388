import { useEffect, useState } from 'react';
import { query, collection, where, onSnapshot, Timestamp } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';
import { addDays } from 'date-fns';

export interface BlockBooking {
  _ref: string;
  date: Timestamp;
  scheduleId: string;
  time: number;
  duration: number;
  workspaceId: string;
}

/**
 * Get anonymous bookings to filter out the available time slots
 */
export default function useBookings({
  scheduleId,
  rollingdays,
}: {
  scheduleId?: string;
  rollingdays: number;
}) {
  console.log({ scheduleId, rollingdays });
  const [blockedTimes, setBlockedTimes] = useState<BlockBooking[]>([]);
  useEffect(() => {
    let unsubscribe: () => void;
    if (scheduleId && !isNaN(rollingdays)) {
      const q = query(
        collection(DB, 'bookings'),
        where('scheduleId', '==', scheduleId),
        where('date', '>', new Date()),
        where('date', '<', addDays(new Date().getTime(), rollingdays))
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const bookings = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...(doc.data() as BlockBooking),
          }));
          console.log({ bookings });
          setBlockedTimes(bookings);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [scheduleId]);
  return blockedTimes;
}
