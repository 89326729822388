import React from 'react';

export default function SimplyReviewLogo({ width = 84, color = '#333' }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      // xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 151 66"
      style={{ width }}
      // style="enable-background:new 0 0 151 66;"
      // xml:space="preserve"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                fill={color}
                d="M46.3,27.6c-1.3-0.7-2.4-1.7-3.2-3c-0.8-1.3-1.2-2.8-1.3-4.5h4.6c0.1,1,0.3,1.8,0.7,2.5c0.4,0.7,0.9,1.3,1.6,1.6
					c0.7,0.4,1.5,0.6,2.3,0.6h2c0.7,0,1.3-0.2,2-0.5c0.7-0.3,1.2-0.8,1.6-1.4c0.4-0.6,0.6-1.4,0.6-2.2c0-0.9-0.3-1.6-0.8-2.2
					c-0.5-0.6-1.2-1-2-1.3c-0.8-0.3-1.9-0.7-3.3-1.1c-1.9-0.5-3.4-1-4.6-1.5c-1.2-0.5-2.2-1.3-3-2.4c-0.8-1.1-1.3-2.5-1.3-4.3
					c0-1.5,0.4-2.9,1.1-4c0.8-1.2,1.8-2.1,3-2.7c1.3-0.6,2.6-1,4.1-1h2c1.5,0,2.9,0.3,4.2,0.9c1.2,0.6,2.2,1.5,3,2.7
					c0.8,1.2,1.3,2.6,1.4,4.3h-4.6c-0.2-1.3-0.6-2.3-1.3-3c-0.7-0.7-1.6-1-2.8-1h-1.9c-0.6,0-1.2,0.1-1.8,0.4
					c-0.6,0.3-1,0.7-1.4,1.2c-0.3,0.5-0.5,1.1-0.5,1.9c0,0.9,0.3,1.6,0.8,2.1c0.5,0.5,1.2,1,1.9,1.3c0.8,0.3,1.8,0.6,3.3,1
					c1.9,0.5,3.4,1,4.6,1.5c1.2,0.5,2.2,1.3,3.1,2.5c0.9,1.1,1.3,2.6,1.3,4.6c0,1.7-0.4,3.1-1.2,4.3c-0.8,1.2-1.9,2.2-3.2,2.9
					c-1.4,0.7-2.9,1-4.5,1h-2C49.1,28.7,47.6,28.3,46.3,27.6z"
              />
            </g>
            <g>
              <path fill={color} d="M63.2,8.2h4.2v20.2h-4.2V8.2z" />
            </g>
            <g>
              <path
                fill={color}
                d="M70.1,8.2h4.2v3c0.5-0.9,1.1-1.7,2.1-2.4c0.9-0.7,1.9-1,2.8-1h1.5c1.3,0,2.4,0.3,3.2,0.9c0.8,0.6,1.5,1.4,2.1,2.6
					c0.5-1,1.3-1.8,2.3-2.5c1-0.7,2-1,3.1-1h1.5c1.2,0,2.3,0.3,3.2,1c0.9,0.7,1.7,1.5,2.2,2.7c0.5,1.1,0.8,2.4,0.8,3.8v13h-4.2V16.1
					c0-0.9-0.1-1.6-0.4-2.3c-0.3-0.7-0.6-1.2-1.1-1.6c-0.5-0.4-1-0.6-1.6-0.6h-1.3c-0.7,0-1.4,0.3-2,0.8c-0.6,0.5-1,1.3-1.4,2.2
					c-0.3,0.9-0.5,1.9-0.5,2.9v10.8h-4.2V16.1c0-0.9-0.1-1.6-0.4-2.3c-0.3-0.7-0.6-1.2-1.1-1.6c-0.5-0.4-1-0.6-1.6-0.6h-1.3
					c-0.7,0-1.4,0.3-2,0.8c-0.6,0.5-1,1.3-1.4,2.2c-0.3,0.9-0.5,1.9-0.5,2.9v10.8h-4.2V8.2z"
              />
            </g>
            <g>
              <path
                fill={color}
                d="M116.3,9.3c1.3,0.9,2.3,2.2,3,3.8c0.7,1.6,1.1,3.3,1.1,5.2c0,1.9-0.4,3.7-1.1,5.3c-0.7,1.6-1.7,2.8-3,3.8
					c-1.3,0.9-2.8,1.4-4.5,1.4h-1.2c-0.9,0-1.8-0.3-2.7-0.8c-0.9-0.5-1.7-1.2-2.2-2v7.1h-4.2V8.2h4.2v2.5c0.5-0.8,1.3-1.5,2.2-2
					c0.9-0.5,1.9-0.8,2.8-0.8h1.2C113.6,7.9,115,8.3,116.3,9.3z M107.7,12.4c-0.7,0.6-1.3,1.4-1.7,2.4c-0.4,1-0.6,2.2-0.6,3.5
					c0,1.3,0.2,2.5,0.6,3.5c0.4,1,1,1.8,1.7,2.4c0.7,0.6,1.5,0.9,2.4,0.9h1.4c0.8,0,1.6-0.3,2.3-0.9c0.7-0.6,1.3-1.4,1.7-2.4
					c0.4-1,0.7-2.2,0.7-3.5c0-1.3-0.2-2.4-0.7-3.5c-0.4-1-1-1.8-1.7-2.4c-0.7-0.6-1.5-0.9-2.3-0.9h-1.4
					C109.2,11.5,108.4,11.8,107.7,12.4z"
              />
            </g>
            <g>
              <path fill={color} d="M121.9,3.9h4.2v24.4h-4.2V3.9z" />
            </g>
          </g>
          <g>
            <path
              fill={color}
              d="M133.5,27.1c0.1,0.2,0.1,0.5,0.1,0.8c0,0.3,0.1,0.5,0.1,0.8c0,0.6-0.1,1.2-0.3,1.9l-1,2.4h4.1c0.2-0.4,0.4-0.8,0.5-1.3
				l8.5-23.5h-4.3L137.1,20c-0.3,0.9-0.6,2-0.9,3.1H136c-0.3-1.1-0.6-2.2-0.9-3.2l-4.2-11.8h-4.3l6.4,17.6
				C133.2,26.3,133.4,26.7,133.5,27.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill={color}
              d="M42.4,37.7h11.2c1.8,0,3.5,0.3,4.8,0.9c1.4,0.6,2.4,1.5,3.2,2.7c0.7,1.2,1.1,2.6,1.1,4.2c0,1.2-0.3,2.3-0.8,3.3
				c-0.5,1-1.3,1.9-2.2,2.6c-0.9,0.7-1.9,1.2-3,1.5l7.5,12.7h-5.5l-5-9.3c-0.5-0.9-1-1.4-1.4-1.8c-0.5-0.3-1.1-0.5-1.8-0.5H47v11.6
				h-4.5V37.7z M56.8,49c0.9-0.8,1.3-1.9,1.3-3.2c0-1.2-0.4-2.2-1.3-3c-0.9-0.8-2-1.1-3.5-1.1H47v8.6h6.2
				C54.7,50.2,55.9,49.8,56.8,49z"
            />
            <path
              fill={color}
              d="M67.6,59.1c0.4,1.1,1,1.9,1.6,2.5c0.7,0.6,1.4,0.9,2.3,0.9H73c1,0,1.8-0.3,2.6-1c0.7-0.7,1.2-1.5,1.4-2.4h4
				c-0.1,1.3-0.6,2.5-1.3,3.5c-0.7,1.1-1.7,1.9-2.9,2.5C75.7,65.7,74.4,66,73,66h-1.5c-1.6,0-3.1-0.4-4.4-1.3
				c-1.3-0.9-2.4-2.1-3.2-3.7c-0.8-1.6-1.1-3.4-1.1-5.4c0-2,0.4-3.8,1.2-5.4c0.8-1.6,1.9-2.8,3.2-3.7c1.3-0.9,2.7-1.3,4.2-1.3H73
				c1.5,0,2.9,0.4,4.2,1.3c1.2,0.9,2.2,2,2.9,3.5c0.7,1.5,1.1,3.1,1.1,4.8c0,0.4,0,0.7,0,0.9c0,0.3,0,0.5-0.1,0.7H65.2v-3.2H77
				c-0.1-0.8-0.3-1.6-0.6-2.3c-0.3-0.7-0.8-1.3-1.4-1.7c-0.6-0.4-1.2-0.6-1.9-0.6h-1.4c-0.8,0-1.5,0.3-2.2,0.8
				c-0.7,0.5-1.3,1.3-1.7,2.4c-0.4,1.1-0.6,2.3-0.6,3.6C67,56.8,67.2,58.1,67.6,59.1z"
            />
            <path
              fill={color}
              d="M79.9,45.5h4.4l4.1,12c0.3,0.9,0.6,2,0.9,3.2h0.1c0.3-1.1,0.6-2.2,0.9-3.1l4.1-12h4.4l-7.2,20.2h-4.4L79.9,45.5z"
            />
            <path fill={color} d="M99.4,45.5h4.2v20.2h-4.2V45.5z" />
            <path
              fill={color}
              d="M109.9,59.1c0.4,1.1,1,1.9,1.6,2.5c0.7,0.6,1.4,0.9,2.3,0.9h1.5c1,0,1.8-0.3,2.6-1c0.7-0.7,1.2-1.5,1.4-2.4h4
				c-0.1,1.3-0.6,2.5-1.3,3.5c-0.7,1.1-1.7,1.9-2.9,2.5c-1.2,0.6-2.4,0.9-3.8,0.9h-1.5c-1.6,0-3.1-0.4-4.4-1.3
				c-1.3-0.9-2.4-2.1-3.2-3.7c-0.8-1.6-1.1-3.4-1.1-5.4c0-2,0.4-3.8,1.2-5.4c0.8-1.6,1.9-2.8,3.2-3.7c1.3-0.9,2.7-1.3,4.2-1.3h1.6
				c1.5,0,2.9,0.4,4.2,1.3c1.2,0.9,2.2,2,2.9,3.5c0.7,1.5,1.1,3.1,1.1,4.8c0,0.4,0,0.7,0,0.9c0,0.3,0,0.5-0.1,0.7h-15.9v-3.2h11.7
				c-0.1-0.8-0.3-1.6-0.6-2.3c-0.3-0.7-0.8-1.3-1.4-1.7c-0.6-0.4-1.2-0.6-1.9-0.6h-1.4c-0.8,0-1.5,0.3-2.2,0.8
				c-0.7,0.5-1.3,1.3-1.7,2.4c-0.4,1.1-0.6,2.3-0.6,3.6C109.2,56.8,109.5,58.1,109.9,59.1z"
            />
            <path
              fill={color}
              d="M122.6,45.5h4.3l3.1,11.2c0.2,1,0.5,2,0.7,3.2h0.1c0.2-1.1,0.5-2.2,0.8-3.2l3.3-11.3h4l3.3,11.3c0.2,0.9,0.5,2,0.7,3.2
				h0.1c0.2-1.1,0.5-2.2,0.7-3.2l3-11.3h4.3l-5.8,20.2h-4.2l-3.4-11.3c-0.3-1-0.5-2-0.8-3.2h-0.2c-0.2,1.2-0.5,2.2-0.8,3.2
				l-3.4,11.3h-4.2L122.6,45.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill={color}
              d="M22,20.4h-5.6c-2,0-3.6-1.6-3.6-3.6v-5.6c0-2,1.6-3.6,3.6-3.6H22c2,0,3.6,1.6,3.6,3.6v5.6C25.6,18.8,24,20.4,22,20.4z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M9.2,33.2H3.6c-2,0-3.6-1.6-3.6-3.6L0,24c0-2,1.6-3.6,3.6-3.6h5.6c2,0,3.6,1.6,3.6,3.6v5.6C12.8,31.6,11.2,33.2,9.2,33.2
				z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M22,46h-5.6c-2,0-3.6-1.6-3.6-3.6v-5.6c0-2,1.6-3.6,3.6-3.6H22c2,0,3.6,1.6,3.6,3.6v5.6C25.6,44.4,24,46,22,46z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M9.2,58.8H3.6c-2,0-3.6-1.6-3.6-3.6v-5.6c0-2,1.6-3.6,3.6-3.6h5.6c2,0,3.6,1.6,3.6,3.6v5.6C12.8,57.2,11.2,58.8,9.2,58.8
				z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M12.8,49.1c0,0-0.1-3.1,3.7-3.1s-3.7-3.4-3.7-3.4S12.5,46,9.2,46S11,50.2,12.8,49.1z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M12.8,23.5c0,0-0.1-3.1,3.7-3.1S12.8,17,12.8,17s-0.3,3.4-3.6,3.4S11,24.6,12.8,23.5z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M12.9,38.2c0,0-0.1-5-3.9-5s3.7-3.4,3.7-3.4s0.3,3.4,3.6,3.4S14.7,39.3,12.9,38.2z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
  // return (
  //   <svg width={width} height="100%" viewBox="0 0 483 85" version="1.1">
  //     <title>Logo</title>
  //     <g
  //       id="Logo"
  //       stroke="none"
  //       strokeWidth="1"
  //       fill="none"
  //       fillRule="evenodd"
  //       fontFamily="HelveticaNeue-Medium, Helvetica Neue"
  //       fontSize="92"
  //       fontWeight="400"
  //       letterSpacing="-3.7"
  //     >
  //       <text id="simplyreview" fill="#101010">
  //         <tspan x="-2" y="66">
  //           simply
  //         </tspan>
  //         <tspan
  //           x="251.892"
  //           y="66"
  //           fontFamily="HelveticaNeue-Light, Helvetica Neue"
  //           fontWeight="300"
  //         >
  //           review
  //         </tspan>
  //       </text>
  //     </g>
  //   </svg>
  // );
}
