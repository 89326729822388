import { useState } from 'react';
// @mui
import { MenuItem, Stack, TableCell, useTheme } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { doc, setDoc } from 'firebase/firestore';
import { Collection } from 'src/enum';
import { getCollection } from 'src/api';
import { DB } from 'src/contexts/FirebaseContext';

export const statuses = [
  { value: undefined, label: 'leadSubmissions.list.status.notcontacted' },
  { value: 1, label: 'leadSubmissions.list.status.contacted' },
  { value: 2, label: 'leadSubmissions.list.status.indialog' },
  { value: 3, label: 'leadSubmissions.list.status.won' },
  { value: 4, label: 'leadSubmissions.list.status.lost' },
  { value: 5, label: 'leadSubmissions.list.status.movetocoachplatform' },
];

function getStatusColor(value?: number) {
  switch (value) {
    case 1:
      return 'warning';
    case 2:
      return 'info';
    case 3:
      return 'success';
    case 4:
      return 'default';
    case 5:
      return 'success';
    default:
      return 'error';
  }
}

// ----------------------------------------------------------------------

export default function LeadStatusCell({
  id,
  workspaceId,
  currentStatus,
}: {
  id: string;
  workspaceId?: string;
  currentStatus: number | undefined;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSelect = async (value?: number) => {
    try {
      if (!workspaceId || !id) {
        throw new Error(`Missing workspaceId or formSubmitId`);
      }
      await setDoc(
        doc(DB, getCollection[Collection.LEADS_FORMS_SUBMISSIONS](workspaceId), id),
        { status: value ?? null },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'));
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'));
    }
  };
  const ariaid = open ? `simple-popover-${id}` : undefined;

  // Not using strict as relying null and undefined to give same result
  // eslint-disable-next-line
  const selected = statuses.find((s) => s.value == currentStatus);

  return (
    <TableCell
      id={`cell-${id}`}
      align="left"
      sx={{ cursor: 'pointer' }}
      aria-describedby={ariaid}
      // onClick={() => setOpenDrawer(id)}
    >
      {/* <Tooltip title={scriptRecommendation}> */}
      <Box onClick={handleOpen}>
        <Label
          sx={{ cursor: 'pointer' }}
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={getStatusColor(selected?.value)}
        >
          {t(selected?.label || '')}
        </Label>
      </Box>
      <MenuPopover
        id={id}
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        aria-labelledby={`cell-${id}`}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 200,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {statuses.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentStatus}
              onClick={() => handleSelect(option.value)}
            >
              {t(option.label)}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </TableCell>
  );
}
