import React from 'react';
import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFlatCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.grey[200]}`,
}));

export default function FlatCard(props: CardProps) {
  return <StyledFlatCard {...props}>{props.children}</StyledFlatCard>;
}
