import { Skeleton, TextField } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCollection } from 'src/api';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection } from 'src/enum';
import { selectCurrentPlatformIntegrationsMap } from 'src/redux/selectors/integrations';
import { selectCurrentPlatformWorkspaceId } from 'src/redux/selectors/workspaces';
import { loadingIntegrationsFlowState } from 'src/redux/slices/integrationFlowSlice';
import { useSelector } from 'src/redux/store';

export default function WidgetTitle({ id }: { id: string }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId) as string;
  const loading = useSelector(loadingIntegrationsFlowState);
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = integrationMap[id];
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(currentWidget?.title || 'Intro video');
  }, [currentWidget?.title]);

  const onSetWidgetTitle = async () => {
    try {
      if (!workspaceId || !id) {
        throw new Error('No workspace id or id');
      }
      await setDoc(
        doc(DB, getCollection[Collection.WORKSPACE_INTEGRATIONS](workspaceId), id),
        { title },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };

  if (loading) {
    return <Skeleton variant="text" width={100} />;
  }
  return (
    <TextField
      value={title}
      size="small"
      //   placeholder="Title"
      onChange={(e) => setTitle(e.target.value)}
      onBlur={onSetWidgetTitle}
      //   label={t('widgets.videoSettings.title')}
    />
  );
}
