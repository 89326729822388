import React from 'react';
import Image from '../Image';
import { Typography, Box } from '@mui/material';
import Lottie, { videoOptimizingAnimation } from '../Lottie/index';
import { SxProps, Theme } from '@mui/material/styles';

export default function CoverVideoOptimzing({ text }: any) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Image alt="video thumbnail" ratio="4/3" sx={{ borderRadius: 2 }} />
      <Box sx={textOverlayStyle}>
        <Box sx={lottieWrapper}>
          <Lottie animation={videoOptimizingAnimation} />
        </Box>
      </Box>
      <Box sx={textWrapper}>
        <Typography variant="body1" color="grey.600" sx={{ textAlign: 'center' }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
}

const textOverlayStyle = {
  position: 'absolute',
  display: 'flex',
  top: -24,
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  height: '100%',
  borderRadius: 2,
} as SxProps<Theme>;

const lottieWrapper = {
  display: 'flex',
  justifyContent: 'center',
  transform: 'scale(2)',
  opacity: 0.8,
};

const textWrapper = { position: 'absolute', left: 14, bottom: 14, right: 14 } as SxProps<Theme>;
