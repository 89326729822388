import React from 'react';
import { Paper, Stack, Typography, Box } from '@mui/material';
import Iconify from '../../components/Iconify';
import { useTheme } from '@mui/material/styles';
import Logo from '../../components/SRLogo';
import SimplyReviewIcon from '../../assets/Logo/SimplyReviewIcon';
import BrandButton from '../../components/buttons/BrandButton';
// import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { Intl, LanguageCode } from '../../enum';
import { getLocalFromLngCode } from '../../locales/helper';

export default function EmailPlaceholder({
  companyName,
  language,
}: {
  companyName?: string;
  language: LanguageCode;
}) {
  const theme = useTheme();
  const { t, i18n, ready } = useTranslation();

  // const navigate = useNavigate();
  const slug = useSelector((state) => state.publicConfig.slug);
  const link = `https://app.simplyreview.com/r/${slug}`;

  const locale = getLocalFromLngCode(language);

  return (
    <Paper elevation={5}>
      <Stack
        sx={{ px: 2, py: 1, backgroundColor: theme.palette.grey[200] }}
        direction="row"
        justifyContent="space-between"
      >
        {/* <Typography>New e-mail</Typography> */}
        <Box />
        <Stack direction="row" spacing={1} alignItems="center">
          <Iconify icon="ic:sharp-minimize" />
          <Iconify icon="gg:expand" />
          <Iconify icon="ic:baseline-close" />
        </Stack>
      </Stack>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              height: 40,
              width: 40,
              backgroundColor: theme.palette.primary.lighter,
              borderRadius: 1,
            }}
          >
            <SimplyReviewIcon width={10} color="#333" />
          </Stack>

          <Stack>
            <Typography variant="h6" fontSize={12} lineHeight={1}>
              SimplyReview
            </Typography>
            <Typography variant="caption">noreply@simplyreview.com</Typography>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.darker,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          p: 8,
          pt: 6,
          textAlign: 'center',
        }}
      >
        <Stack direction="row" justifyContent="center" sx={{ pb: 4 }}>
          <Logo width={80} color={theme.palette.common.white} />
        </Stack>
        <Box sx={{ color: '#fff' }}>
          <Typography variant="caption" fontSize={40} color={theme.palette.primary.lighter}>
            {t('invites.placeholder.email.headline', { companyName, lng: locale })}
          </Typography>
        </Box>
        <Box sx={{ py: 1 }} />
        <Box sx={{ color: '#fff' }}>
          <Typography variant="body1">
            {t('invites.placeholder.email.body', { companyName, lng: locale })}
          </Typography>
          <Box sx={{ pb: 1, pt: 1.5 }} />
          <Typography variant="body1">
            {t('invites.placeholder.email.body2', { lng: locale })}
          </Typography>
        </Box>
        <Box sx={{ py: 1 }} />

        <Stack justifyContent="center" direction="row">
          <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            <BrandButton
              flatButton
              customColor={theme.palette.primary.lighter}
              variant="contained"
              sx={{
                p: 1.5,
                px: 3,
                borderRadius: 0.5,
              }}
            >
              <Typography variant="body2">
                {t('invites.placeholder.email.cta', { lng: locale })}
              </Typography>
            </BrandButton>
          </a>
        </Stack>
      </Box>
    </Paper>
  );
}
