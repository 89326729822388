import { StripeSubscriptionStatus } from 'src/redux/slices/stripeSlice';

export interface SubscriptionStatusMessage {
  title: string;
  alert?: string;
}

export function getSubscriptionStatusMessage(
  status: StripeSubscriptionStatus
): SubscriptionStatusMessage {
  switch (status) {
    case 'active':
      return { title: 'Your subscription is active' };
    case 'canceled':
      return {
        title: 'Your subscription is canceled',
        alert:
          'Your subscription has been canceled. Contact support for any questions or to reactivate',
      };
    case 'incomplete':
      return {
        title: 'Your subscription is incomplete',
        alert:
          'Your subscription setup is incomplete. Please contact support to resolve payment issues.',
      };
    case 'incomplete_expired':
      return {
        title: 'Your subscription is incomplete and expired',
        alert: 'Your subscription setup has expired. Contact support to start a new subscription',
      };
    case 'past_due':
      return {
        title: 'Your subscription is past due',
        alert:
          'Your subscription payment is past due. Please contact support to update your payment details.',
      };
    case 'trialing':
      return {
        title: 'Your subscription is in trial',
        alert: 'Your trial period is active. Contact support for any questions or to upgrade',
      };
    case 'unpaid':
      return {
        title: 'Your subscription is unpaid',
        alert:
          'Your subscription is unpaid. Please contact support immediately to resolve payment issues',
      };
    default:
      // Assume the subscription is expired if there is not status on the subscription
      return { title: 'general.trial.expired' };
  }
}
