import React from 'react';
import Page from '../../../components/Page';
import { Container, Grid, Stack, Typography } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import { useTranslation } from 'react-i18next';
import ZenfitAutomation from './ZenfitAutomation';

export default function CreateScreen() {
  const { t } = useTranslation();
  const { themeStretch } = useSettings();

  return (
    <Page title="Automations" sx={{ height: '100%' }}>
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4">{t('reviewAutomation.headline.titleInitial')}</Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ height: '100%' }}>
            <Stack justifyContent="center" alignItems="center" alignSelf="center" height="100%">
              <ZenfitAutomation />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
