import React from 'react';
import phone from './iphone.png';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './styles.css';
import { useSelector } from '../../redux/store';
import { LanguageCode } from '../../enum';
import { useTranslation } from 'react-i18next';
import { getLocalFromLngCode } from '../../locales/helper';

export default function SmsPlaceholder({
  companyName,
  language,
}: {
  companyName?: string;
  language: LanguageCode;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const slug = useSelector((state) => state.publicConfig.slug);
  const link = `https://app.simplyreview.com/r/${slug}`;
  const locale = getLocalFromLngCode(language);

  return (
    <Stack direction="row" justifyContent="center" sx={{ position: 'relative' }}>
      <Box
        className="tail"
        sx={{
          position: 'absolute',
          zIndex: 1,
          mx: '22%',
          bottom: '46%',
          px: 1.5,
          py: 1,
          backgroundColor: theme.palette.grey[100],
          borderRadius: 2,
        }}
      >
        <Typography>{t('invites.placeholder.sms.line1', { lng: locale })}</Typography>
        <Box sx={{ py: 1 }} />
        <Typography>{t('invites.placeholder.sms.line2', { companyName, lng: locale })}</Typography>
        <Box sx={{ py: 1 }} />
        <Typography>
          {t('invites.placeholder.sms.line3', { lng: locale })}:{' '}
          <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            {t('invites.placeholder.sms.link', { lng: locale })}
          </a>
        </Typography>
      </Box>
      <img src={phone} style={{ width: '70%' }} alt="sms-template-preview" />
    </Stack>
  );
}
