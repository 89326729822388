import React, { useEffect } from 'react';
import { Conversation, setConversations, setConversationsFailed } from '../redux/slices/chat';
import { useDispatch, useSelector } from 'src/redux/store';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

export default function useConversationsExternal() {
  const dispatch = useDispatch();
  const workspaceId = 'simplyLabs'; // useSelector(selectCurrentPlatformWorkspaceId);
  const userId = useSelector((state) => state.user.id as string);
  const isAdmin = useSelector((state) => state.user.email?.includes('@simply'));

  useEffect(() => {
    let unsubscribe: () => void;
    if (isAdmin) return;
    if (!workspaceId || !userId) {
      console.log('workspaceId or userId is not defined');
      return;
    }

    const q = query(
      collection(DB, `workspaceChats/${workspaceId}/conversations`),
      where(`participantsList`, 'array-contains', userId),
      orderBy('updateTime', 'desc')
    );
    unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const conversations = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            createTime: data.createTime.toDate(),
            updateTime: data.updateTime.toDate(),
          };
        }) as Conversation[];
        dispatch(
          setConversations({
            currentId: conversations[0]?.id,
            conversations,
          })
        );
      },
      (error) => {
        console.error(error);
        dispatch(setConversationsFailed());
      }
    );

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId, userId]);
}
