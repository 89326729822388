import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Iconify from '../Iconify';
import { useSelector } from 'src/redux/store';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import useMessages, { Message } from 'src/hooks/useMessages';
import { grey } from '@mui/material/colors';
import SimplySupportIcon from 'src/assets/SupportLogo';
import { Collection } from 'src/enum';
import useConversationsExternal from 'src/hooks/useConversationExternal';
import { collection, setDoc, doc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { Badge } from '@mui/material';
import SubmitMessage from './SubmitMessage';
import MessageScrollView from './MessageScrollView';
import Hint from '../hint/index';
import { getCollection } from 'src/api';
import { selectShouldDisplayOnboardingInitialChatHint } from 'src/redux/slices/onboarding';

const ChatWidgetContainer = styled('div')`
  .chat-widget {
    padding: 0px;
    width: 48px;
    position: fixed;
    bottom: 8px;
    left: 8px;
    background-color: #fff;
    border: 1px solid ${grey[200]};
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    // transform: translateY(30%); /* Update this for initial state */
    overflow: hidden;
    z-index: 100000;
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    .chat-container {
    }
  }

  .chat-widget.open {
    width: 400px;
    transform: translateY(0);
    .chat-container {
      height: 50vh; /* Adjust based on input height */
    }
    .message-input {
      height: calc(100% - 1px);
      border-top: 1px solid ${grey[200]};
      textarea {
        font-size: 16px;
      }
    }
  }

  .chat-toggle {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .chat-container {
    position: relative;
    height: 0vh; /* Adjust based on input height */
    transition: height 0.3s ease-in-out;
    overflow-y: auto;
  }

  .message-list {
    padding: 16px;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    overflow-y: auto;
    padding-bottom: 16px;
  }

  .message {
    background-color: ${grey[100]};
    padding: 16px;
    font-size: 14px;
    border-radius: 8px;
    align-self: flex-start;
  }
  .message.me {
    align-self: flex-end;
    background-color: #001d06;
    color: #fff;
  }

  .message-input {
    transition: width 0.2s ease-in-out;
    background-color: #fff;
    // position: absolute;
    height: 0vh;
    bottom: 0;
    left: 0;
    width: 100%;
    textarea {
      transition: font-size 0.2s ease-in-out;
      font-size: 2px;
    }
  }
`;

function updateParticipantLastSeenMessage({
  workspaceId,
  conversationId,
  userId,
  lastSeenMessageCreateTime,
}: {
  workspaceId: string;
  userId: string;
  conversationId: string | null;
  lastSeenMessageCreateTime: number | null;
}) {
  try {
    if (!workspaceId || !userId || !conversationId || !lastSeenMessageCreateTime) {
      throw new Error(
        `[updateParticipantLastSeenMessage]: Invalid parameters ${workspaceId} ${conversationId}`
      );
    }
    const conversationDocRef = doc(
      collection(DB, 'workspaceChats', workspaceId, 'conversations'),
      conversationId
    );
    setDoc(
      conversationDocRef,
      {
        participants: {
          [userId]: {
            lastSeenMessageTime: lastSeenMessageCreateTime,
          },
        },
      },
      { merge: true }
    );
  } catch (error) {
    console.error(error);
  }
}

const getCountUnseenMessages = (
  messages: Message[],
  userId: string | null,
  lastSeenMessageTime: Date | null
): number => {
  // assume you then have seen all messages
  if (!lastSeenMessageTime) {
    return 0; // messages.length;
  }
  if (!userId) return 0;

  const unseenMessages = messages.filter(
    (message) => message.createTime > lastSeenMessageTime && message.sender.uid !== userId
  );
  return unseenMessages.length;
};

export default function ChatWidget() {
  useConversationsExternal();
  // usePageVisibility({
  //   pageTitle: 'New Message',
  //   nTitle: 'New Message',
  //   options: { body: 'New Message' },
  // });
  const [isOpen, setIsOpen] = useState(false);
  const shouldDisplayOnboardingInitialChatHint = useSelector(
    selectShouldDisplayOnboardingInitialChatHint
  );
  const currentId = useSelector((state) => state.conversations.currentId);
  const userId = useSelector((state) => state.user.id);
  const isAdmin = useSelector((state) => state.user.email?.includes('@simply'));
  const lastSeenMessageByUser = useSelector(
    (state) =>
      // @ts-ignore
      state.conversations?.map[currentId]?.participants[userId]?.lastSeenMessageTime
  );
  const { messages, isLoadingMessages, hasErrorMsg } = useMessages(currentId);
  const toggleChat = () => {
    if (shouldDisplayOnboardingInitialChatHint) {
      setOnboardingChat();
    }
    setIsOpen(!isOpen);
  };
  // select number of messages not seen since lastSeenMessageTime

  const countUnseenMessages = getCountUnseenMessages(messages, userId, lastSeenMessageByUser);

  useEffect(() => {
    if (userId && currentId && isOpen && messages.length > 0) {
      const firstNonUserMessage = messages.find((message) => message?.sender?.uid !== userId);
      if (!firstNonUserMessage) return;
      const lastCreateTime = firstNonUserMessage.createTime?.getTime();
      if (!lastCreateTime) return;
      if (lastCreateTime <= lastSeenMessageByUser) return;
      // console.log('USER HAS UNSEEN MESSAGES', 'UPDATING IN 0.5s');
      // adding short delay to not interupt animation
      setTimeout(() => {
        updateParticipantLastSeenMessage({
          workspaceId: 'simplyLabs',
          userId,
          conversationId: currentId,
          lastSeenMessageCreateTime: lastCreateTime,
        });
      }, 500);
      // update conversation participant with latest seen message
      // updateConversationParticipant({
      // Reset unread count
    }
  }, [userId && currentId && isOpen && messages.length]);
  if (!userId || isAdmin) return null;

  const setOnboardingChat = async () => {
    try {
      if (!userId) throw new Error(`userId invalid`);
      await setDoc(
        doc(DB, getCollection[Collection.ONBOARDING](userId), 'details'),
        { initialChatHint: true },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ChatWidgetContainer>
      <Hint
        placement="right-start"
        content={
          <Stack>
            <Stack spacing={1}>
              <Typography variant="h6">Have a question?</Typography>
              <Typography variant="body2">
                Ask us anything and we'll be happy to help you out.
              </Typography>
              <Typography variant="body2" sx={{ color: grey[500], fontSize: 11 }}>
                (If you previously chatted with us, the chat history is still with us)
              </Typography>
              <Button variant="contained" onClick={setOnboardingChat}>
                Got it👍
              </Button>
            </Stack>
          </Stack>
        }
        // if it is not open and the user has not seen the initial chat hint, show the hint
        open={!isOpen && shouldDisplayOnboardingInitialChatHint} //
      >
        <div className={`chat-widget ${isOpen ? 'open' : ''}`}>
          {/* {!isOpen && (
          <Stack
            onClick={toggleChat}
            direction="row"
            justifyContent="center"
            sx={{ cursor: 'pointer', position: 'absolute', top: 0, left: 0, width: '100%' }}
          >
            <Iconify icon="ph:caret-up-bold" />
          </Stack>
        )} */}
          <Stack
            onClick={toggleChat}
            sx={{
              height: 48,
              paddingLeft: 1,
              paddingRight: 1,
              cursor: 'pointer',
              borderBottom: isOpen ? `1px solid ${grey[200]}` : 'none',
            }}
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Badge color="error" badgeContent={countUnseenMessages}>
                <SimplySupportIcon />
              </Badge>
              <Typography fontSize={14}>Simply Support</Typography>
            </Stack>
            <Box>
              {isOpen ? (
                <IconButton onClick={toggleChat}>
                  <Iconify icon="carbon:close" style={{ fontSize: 24 }} />
                </IconButton>
              ) : (
                <IconButton onClick={toggleChat}>
                  <Iconify icon="ph:caret-up-light" style={{ fontSize: 24 }} />
                </IconButton>
              )}
            </Box>
          </Stack>
          <MessageScrollView
            messages={messages}
            isLoadingMessages={isLoadingMessages}
            hasErrorMsg={hasErrorMsg}
            conversationId={currentId}
          />
          <SubmitMessage isOpen={isOpen} conversationId={currentId} workspaceId="simplyLabs" />
        </div>
      </Hint>
    </ChatWidgetContainer>
  );
}

function groupMessages(messages: Message[]): Message[][] {
  const grouped: Message[][] = [];
  let currentGroup: Message[] = [];

  messages.forEach((message, index) => {
    if (index === 0) {
      // If it's the first message, start a new group
      currentGroup.push(message);
    } else {
      // If the current message is from the same sender as the last one,
      // add it to the current group
      // eslint-disable-next-line
      if (message.sender.uid === messages[index - 1].sender.uid) {
        currentGroup.push(message);
      } else {
        // If the sender is different, start a new group
        grouped.push(currentGroup);
        currentGroup = [message];
      }
    }
  });

  // Push the last group if it exists
  if (currentGroup.length > 0) {
    grouped.push(currentGroup);
  }

  return grouped;
}
