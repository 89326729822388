import React from 'react';
import Widget from '../Integrations/leads/Widget';
import { useSelector } from 'src/redux/store';
import { Stack } from '@mui/material';
import { countries } from 'src/components/PhoneField';
import { Form } from '@simplytech/simply.widgets.leads.form';

export default function LeadFormPreview() {
  const leadForm = useSelector((state) => state.leadForms.list[0]);
  const country = countries.find((c) => c.languageCode === leadForm?.language);
  const defaultCountryCode = country?.phone || '';
  const questionKeys = leadForm?.form?.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: ' ',
    }),
    {}
  );
  const [answers, setAnswers] = React.useState({
    ...questionKeys,
    'fitness-5': {
      'fitness-5-option-3': { code: defaultCountryCode },
    },
  });
  if (!leadForm?.form) return <div>no lead form</div>;
  return (
    <Form
      questions={leadForm.form}
      answers={answers}
      initialActive={leadForm.form[0].id}
      customColor={leadForm.color || '#000000'}
      updateAnswer={() => console.log('swefwef')}
      handleSubmit={() => console.log('submitted')}
      defaultCountryCode={defaultCountryCode}
      previousText={leadForm.previousText}
      submitText={leadForm.submitText}
      nextText={leadForm.nextText}
      submitting={false}
    />
  );
}
