import getStripe from './init';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Collection } from '../enum';
import { getCollection } from '../api';
import { DB, Functions } from '../contexts/FirebaseContext';

export async function createCheckoutSession(uid: string, priceId: string, workspaceId?: string) {
  if (!workspaceId) {
    console.error('No workspaceId provided');
    return alert('No workspaceId provided');
  }
  const checkoutSessionRef = await addDoc(
    collection(DB, getCollection[Collection.CUSTOMERS_CHECKOUTSESSION](uid)),
    {
      price: priceId,
      // This can be removed if you don't want promo codes
      allow_promotion_codes: true,
      automatic_tax: {
        enabled: true,
      },
      tax_id_collection: {
        enabled: true,
      },
      // dynamic_tax_rates: ['txr_1N5UzrCgcqfr3d92SlV7F0OV'],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      metadata: {
        workspaceId,
      },
    }
  );

  onSnapshot(checkoutSessionRef, async (snap) => {
    const { sessionId } = snap.data() as { sessionId: string };

    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    }
  });
}

export async function goToBillingPortal() {
  const functionRef = httpsCallable(Functions, 'ext-firestore-stripe-payments-createPortalLink');

  const { data } = (await functionRef({
    returnUrl: `${window.location.origin}/dashboard/user/account`,
  })) as { data: { url: string } };

  window.location.assign(data.url);
}
