import React, { useEffect, useState } from 'react';
import { selectCurrentPlatformWorkspaceId } from '../redux/selectors/workspaces';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { useSelector } from 'src/redux/store';
import { RequestState } from 'src/enum';

export interface Message {
  id: string;
  createTime: Date;
  updateTime: Date;
  message: string;
  sender: {
    uid: string;
    displayName: string | null;
    photoURL: string | null;
  };
  image?: {
    original: string;
    thumbnail: string;
  };
}
type Permission = 'default' | 'denied' | 'granted';

export default function useMessages(conversationId: string | null) {
  const workspaceId = 'simplyLabs'; // useSelector(selectCurrentPlatformWorkspaceId);
  const [state, setState] = useState<RequestState>(RequestState.IDLE);
  const [messages, setMessages] = useState<Message[]>([]);
  // const [permission, setPermission] = useState<Permission>(Notification.permission);
  useEffect(() => {
    let unsubscribe: () => void;
    if (workspaceId && conversationId) {
      setState(RequestState.PENDING);
      const q = query(
        collection(DB, `workspaceChats/${workspaceId}/conversations/${conversationId}/messages`),
        orderBy('updateTime', 'asc')
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          setMessages(
            querySnapshot.docs.map((doc) => {
              const data = doc.data();
              return {
                ...data,
                id: doc.id,
                createTime: data.createTime.toDate(),
                updateTime: data.updateTime.toDate(),
              };
            }) as Message[]
          );
          setState(RequestState.RESOLVED);
        },
        (error) => {
          console.error(error);
          setState(RequestState.REJECTED);
          //   dispatch();
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId, conversationId]);
  // useEffect(() => {
  //   if (Notification.permission === 'default') {
  //     Notification.requestPermission().then(setPermission);
  //   }
  // }, []);

  // const sendNotification = ({
  //   title,
  //   options,
  // }: {
  //   title: string;
  //   options: NotificationOptions;
  // }) => {
  //   if (permission === 'granted') {
  //     // eslint-disable-next-line no-new
  //     new Notification(title, options);
  //   }
  // };

  // useEffect(() => {
  //   sendNotification({
  //     title: 'New Message',
  //     options: { body: 'New Message' },
  //   });
  // }, [messages.length]);
  return {
    messages,
    isLoadingMessages: state === RequestState.PENDING || state === RequestState.IDLE,
    hasErrorMsg: state === RequestState.REJECTED,
  };
}
