import { format, isToday, isYesterday } from 'date-fns';

export function formatMessageDate(date: Date): string {
  const now = new Date();

  if (isToday(date)) {
    // For messages sent earlier today, only show relative time
    return format(date, 'HH:mm');
  }
  if (isYesterday(date)) {
    // For messages from yesterday, include the time
    return `Yesterday at ${format(date, 'HH:mm')}`;
  }
  if (now.getTime() - date.getTime() < 7 * 24 * 60 * 60 * 1000) {
    // For messages within the current week, day like 'Monday', 'Tuesday', etc.
    return format(date, 'EEEE HH:mm');
  }
  if (now.getFullYear() === date.getFullYear()) {
    // For messages from earlier this year, show month, day, and weekday
    return format(date, 'MMM d, HH:mm');
  }
  // For messages older than a week, include the full date
  return format(date, 'MMM dd, yyyy');
}
