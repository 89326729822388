import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

type SnackType = 'success' | 'error' | 'warning' | 'info';
// Define a type for the slice state
interface SnackbarState {
  type: SnackType;
  message: string | null;
}

// Define the initial state using that type
const initialState: SnackbarState = {
  type: 'error',
  message: null,
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSnackbar: (state, action: PayloadAction<SnackbarState | undefined>) => ({
      ...state,
      type: action.payload?.type || initialState.type,
      message: action.payload?.message || null,
    }),
  },
});

export const { setSnackbar } = snackbarSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSnackbar = (state: RootState) => state.snackbar;

export default snackbarSlice.reducer;
