// @mui
import { Grid, Typography, Box, Stack, Radio, Chip, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from '../../../redux/store';
import { useTranslation } from 'react-i18next';
import { HEADER } from '../../../config';
import { WidgetType, Collection } from '../../../enum';
import { selectCurrentPlatformIntegrationsMap } from '../../../redux/selectors/integrations';
import FloatingVideo from '@simplytech/simply.widgets.video.floating';
import { selectCurrentPlatformWorkspaceId } from '../../../redux/selectors/workspaces';
import WidgetPreviewBlock from '../Steps/components/WidgetPreviewBlock';
import { useCallback, useState } from 'react';
import UploadSingleFile from '../../../components/upload/UploadSingleFile';
import CoverVideoOptimzing from '../../../components/Review/CoverVideoOptimzing';
import { uploadResumable } from '../../../hooks/useUpload';
import { useSnackbar } from 'notistack';
import { Platforms } from '../../../redux/slices/platform';
import { setDoc, doc } from 'firebase/firestore';
import { getCollection } from '../../../api';
import { DB } from '../../../contexts/FirebaseContext';
import { WidgetTypeConfig } from '../../../types';
import Play from '../../../assets/Play';
import { AspectRatio, Position } from '@simplytech/simply.widgets.video.floating';
import VideothumbnailPicker from '../../../components/VideothumbnailPicker';
import WidgetTitle from './WidgetTitle';
import { FlowVideo } from '@simplylabs_/shared';
import Android12Switch from 'src/components/customSwitch';
import ActionButton from './ActionButton';

// export function getPreviewWidgetComponent(type: WidgetType) {
//   switch (type) {
//     case WidgetType.FLOATING_INTRO_VIDEO:
//       return (

//       );
//     default:
//       return '';
//   }
// }

type FormValuesProps = {
  video: File;
};
/**
 * isSetup is a flag for the widget setup flow because of fixed container
 * @param param0
 * @returns
 */
export default function VideoSettings({ currentWidgetId, widgetType, isSetup }: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [video, setvideo] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId) as string;
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = integrationMap[currentWidgetId];

  const topOffset = HEADER.DASHBOARD_DESKTOP_HEIGHT + 20;
  // const widgetComponent = getPreviewWidgetComponent(widgetType);

  const handleDrop = useCallback(
    (acceptedFiles: any[]) => {
      try {
        const file = acceptedFiles[0];
        if (!currentWidgetId || !workspaceId) {
          enqueueSnackbar(t('general.error.default'), { variant: 'error' });
          throw new Error('Missing widget id or workspace id');
        }
        setIsUploading(true);
        const uploadTask = uploadResumable(
          file as Blob,
          `workspaceFlow/${workspaceId}/integrations/${currentWidget.id}/`,
          {
            workspaceId,
            widgetId: currentWidget.id,
            platform: Platforms.SIMPLY_FLOW,
          }
        );
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress);
          },
          (error) => {
            enqueueSnackbar(t('general.error.default'), { variant: 'error' });
            console.error(error);
          },
          async () => {
            // SUCCESS
            await setDoc(
              doc(
                DB,
                getCollection[Collection.WORKSPACE_INTEGRATIONS](workspaceId),
                currentWidget.id
              ),
              { videoProcessed: false },
              { merge: true }
            );
            setIsUploading(false);
          }
        );
        if (file) {
          setvideo(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
        }
      } catch (error) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        console.error(error);
      }
    },
    [setvideo]
  );

  const onSelectFrame = async (frame: number) => {
    try {
      await setDoc(
        doc(DB, getCollection[Collection.WORKSPACE_INTEGRATIONS](workspaceId), currentWidget.id),
        { config: { gifFrame: frame, thumbFrame: frame } },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };
  const onSetPosition = async (position: Position) => {
    try {
      await setDoc(
        doc(DB, getCollection[Collection.WORKSPACE_INTEGRATIONS](workspaceId), currentWidget.id),
        { config: { position } },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };
  const onSetAspectRatio = async (aspectRatio: AspectRatio) => {
    try {
      await setDoc(
        doc(DB, getCollection[Collection.WORKSPACE_INTEGRATIONS](workspaceId), currentWidget.id),
        { config: { aspectRatio } },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };

  const isVideoProcessing = currentWidget?.videoProcessed === false;
  const isVideoReady = currentWidget?.videoProcessed === true;
  const videoConfig = currentWidget?.config as WidgetTypeConfig<WidgetType.FLOATING_INTRO_VIDEO>;

  return (
    <>
      <Grid item xs={12} sm={12} md={7}>
        <Box sx={{ py: 2, pr: 4 }}>
          <Box sx={{ pb: 2 }}>
            <Typography variant="h6">{t('widgets.videoSettings.title')}</Typography>
          </Box>
          <Stack pb={2}>
            <WidgetTitle id={currentWidget.id} />
          </Stack>
          <Box sx={{ pb: 4 }}>
            <Typography variant="h6">{t('widgets.videoSettings.headline')}</Typography>
          </Box>

          {isUploading || isVideoProcessing ? (
            <CoverVideoOptimzing
              text={
                isVideoProcessing
                  ? t('general.optimizingVideo')
                  : t(`general.uploadingVideo`, { progress: progress?.toFixed(0) })
              } // Uploading video...
              // Optimizing video...
            />
          ) : (
            <UploadSingleFile
              // name="video"
              file={video}
              accept={{ 'video/*': [] }}
              // maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  {t('general.videoUploadHelperText')}
                </Typography>
              }
            />
          )}
        </Box>
        {videoConfig && isVideoReady && (
          <>
            <Stack direction="row" mb={2}>
              <Typography variant="h6">{t('widgets.videoSettings.thumbnail.headline')}</Typography>
            </Stack>
            <VideothumbnailPicker
              thumbFrame={videoConfig.thumbFrame}
              videoPlaybackId={videoConfig?.videoPlaybackId}
              onSelectFrame={(frame: number) => onSelectFrame(frame)}
            />

            <Box mt={3} />
            <Stack direction="row" mb={2}>
              <Typography variant="h6">{t('widgets.videoSettings.position.headline')}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" columnGap={4}>
              <Stack
                sx={{ width: '50%', cursor: 'pointer' }}
                role="button"
                onClick={() => onSetPosition(Position.bottomLeft)}
              >
                <Box
                  sx={{
                    backgroundColor: 'grey.200',
                    borderRadius: 1,
                    border: `1px dashed ${theme.palette.grey[300]}`,
                    p: 1,
                    pt: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                      backgroundColor: 'grey.400',
                      aspectRatio: '10/16',
                      height: 100,
                    }}
                  >
                    <Play />
                  </Box>
                </Box>
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                  <Box>
                    <Radio
                      // @ts-ignore
                      checked={Position.bottomLeft === videoConfig?.position}
                      value={Position.bottomLeft}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  </Box>
                  <Typography variant="body2">
                    {t('widgets.videoSettings.position.left')}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                sx={{ width: '50%', cursor: 'pointer' }}
                role="button"
                onClick={() => onSetPosition(Position.bottomRight)}
              >
                <Box
                  sx={{
                    backgroundColor: 'grey.200',
                    borderRadius: 1,
                    border: `1px dashed ${theme.palette.grey[300]}`,
                    p: 1,
                    pt: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      float: 'right',
                      borderRadius: 1,
                      backgroundColor: 'grey.400',
                      aspectRatio: '10/16',
                      height: 100,
                    }}
                  >
                    <Play />
                  </Box>
                </Box>
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                  <Box>
                    <Radio
                      // @ts-ignore
                      checked={Position.bottomRight === videoConfig?.position}
                      value={Position.bottomRight}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  </Box>
                  <Typography variant="body2">
                    {t('widgets.videoSettings.position.right')}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Box mt={3} />
            <Stack direction="row" mb={2}>
              <Typography variant="h6">
                {t('widgets.videoSettings.aspectRatio.headline')}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" columnGap={4}>
              <Stack
                sx={{ width: '50%', cursor: 'pointer' }}
                role="button"
                onClick={() => onSetAspectRatio(AspectRatio.IPHONE)}
              >
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'grey.200',
                    borderRadius: 1,
                    border: `1px dashed ${theme.palette.grey[300]}`,
                    p: 2.5,
                    pt: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                      backgroundColor: 'grey.400',
                      aspectRatio: '10/16',
                      height: 100,
                    }}
                  >
                    <Play />
                  </Box>
                </Box>
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                  <Box>
                    <Radio
                      // @ts-ignore
                      checked={AspectRatio.IPHONE === videoConfig?.aspectRatio}
                      value={AspectRatio.IPHONE}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  </Box>
                  <Typography variant="body2">
                    {t('widgets.videoSettings.aspectRatio.portraitMode')}
                  </Typography>
                  <Chip label="9:16" size="small" />
                </Stack>
              </Stack>
              <Stack
                sx={{ width: '50%', cursor: 'pointer' }}
                role="button"
                onClick={() => onSetAspectRatio(AspectRatio.WIDE)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'grey.200',
                    borderRadius: 1,
                    border: `1px dashed ${theme.palette.grey[300]}`,
                    p: 3.5,
                    pt: 3.5,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                      backgroundColor: 'grey.400',
                      aspectRatio: '16/9',
                      height: 80,
                    }}
                  >
                    <Play />
                  </Box>
                </Box>
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                  <Box>
                    <Radio
                      checked={AspectRatio.WIDE === videoConfig?.aspectRatio}
                      value={AspectRatio.WIDE}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  </Box>
                  <Typography variant="body2">
                    {t('widgets.videoSettings.aspectRatio.landscapeMode')}
                  </Typography>
                  <Chip label="16:9" size="small" />
                </Stack>
              </Stack>
            </Stack>
            <Box mt={3} />
            <Stack mb={2} spacing={2}>
              <ActionButton
                // @ts-ignore
                videoConfig={videoConfig}
                widgetId={currentWidgetId}
              />
            </Stack>
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={5} sx={{ position: 'relative' }}>
        <Stack sx={{ pt: 4 }} alignItems="center">
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              right: 0,
              marginTop: isSetup ? -12 : 0,
              // textAlign: 'center',
              // backgroundColor: theme.palette.grey[900],
              paddingTop: `${topOffset}px`,
              pr: 8,
              height: '100vh',
            }}
          >
            <WidgetPreviewBlock>
              <div
                style={{
                  position: 'absolute',
                  right: videoConfig?.position !== Position.bottomLeft ? 76 : 'inherit',
                  bottom: 10,
                }}
              >
                {videoConfig && isVideoReady && (
                  <FlowVideo
                    token="test-token"
                    videoPlaybackId={videoConfig?.videoPlaybackId}
                    position={videoConfig?.position}
                    aspectRatio={videoConfig?.aspectRatio}
                    gifFrame={videoConfig?.gifFrame}
                    height={250}
                    thumbFrame={videoConfig?.thumbFrame}
                    // @ts-ignore
                    buttonText={videoConfig?.buttonText}
                    // @ts-ignore
                    buttonLink={videoConfig?.buttonLink}
                    // @ts-ignore
                    buttonColor={videoConfig?.buttonColor}
                  />
                )}
              </div>
            </WidgetPreviewBlock>
          </Box>
        </Stack>
      </Grid>
    </>
  );
}
