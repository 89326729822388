import { Box, Card, Grid, Radio, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { MiniReviewCount } from '@simplylabs_/shared';

type Props = {
  isLightMode: boolean;
  isDarkMode: boolean;
  setLightMode: () => void;
  setDarkMode: () => void;
  children: JSX.Element;
};

export default function Customise({
  isLightMode,
  isDarkMode,
  setLightMode,
  setDarkMode,
  children,
}: Props) {
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      <Grid xs={12} sm={6} item sx={{ position: 'relative' }}>
        <Card sx={{ mt: 2, pr: 4, cursor: 'pointer' }} onClick={setLightMode}>
          <Box
            sx={{
              position: 'absolute',
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
              width: '100%',
              height: 136,
            }}
          />
          <Box
            sx={{
              p: 0,
              pt: 4,
              pb: 2,
              ml: '50%',
              width: '100%',
              transform: 'scale(1.4)',
            }}
          >
            {children}
          </Box>
          <Stack px={2} pt={4} pb={1} flexDirection="row" alignItems="center">
            <Radio checked={isLightMode} />
            <Typography variant="body2">Light mode</Typography>
          </Stack>
        </Card>
      </Grid>
      <Grid xs={12} sm={6} item sx={{ position: 'relative' }}>
        <Card sx={{ mt: 2, pr: 4, cursor: 'pointer' }} onClick={setDarkMode}>
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: '#000',
              width: '100%',
              height: 136,
            }}
          />
          <Box
            sx={{
              p: 0,
              pt: 4,
              pb: 2,
              ml: '50%',
              width: '100%',
              transform: 'scale(1.4)',
            }}
          >
            <MiniReviewCount textColor="#fff" reviewCount={100} rating={4.5} slug="simply-review" />
          </Box>

          <Stack px={2} pt={4} pb={1} flexDirection="row" alignItems="center">
            <Radio checked={isDarkMode} />
            <Typography variant="body2">Dark mode</Typography>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
