import React from 'react';
import { Stack, TextField, Typography, TextFieldProps } from '@mui/material';
import Box from '@mui/material/Box';
import Iconify from '@simplytech/simply.general.iconify';
import { useTheme, styled } from '@mui/material/styles';
import { convertTimeStingToNumber } from '../../utils/time';

export interface SubTitle {
  startTime: string;
  endTime: string;
  captions: string;
}

interface SubtitleEditorProps {
  displayIndex: number;
  timeFrames: SubTitle[];
  onChange: (value: string, index: number) => void;
  setCurrentTime: React.Dispatch<number | number>;
}

export default function SubtitleEditor({
  displayIndex,
  timeFrames,
  onChange,
  setCurrentTime,
}: SubtitleEditorProps) {
  const theme = useTheme();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    onChange(event.target.value, index);
  };
  return (
    <>
      {timeFrames.map((timeFrame, index) => (
        <Stack direction="row" spacing={2} alignItems="center" mb={1}>
          <HighlightTextField
            size="small"
            value={timeFrame.captions}
            highlight={displayIndex === index}
            fullWidth
            onChange={(e) => handleChange(e, index)}
            multiline
            onFocus={() => {
              const startTime = convertTimeStingToNumber(timeFrame.startTime) + 0.5;
              setCurrentTime(startTime);
            }}
          />

          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Iconify icon="carbon:time" color={theme.palette.grey[500]} fontSize={16} />
              <Typography variant="body2" fontSize={12}>
                {timeFrame.startTime}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Iconify icon="carbon:time" color={theme.palette.grey[500]} fontSize={16} />
              <Typography variant="body2" fontSize={12}>
                {timeFrame.endTime}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      ))}
    </>
  );
}

const HighlightTextField = styled(TextField)<TextFieldProps & { highlight?: boolean }>(
  (props: any) => {
    const { theme, highlight } = props;

    if (!highlight) {
      return;
    }
    return {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
    };
  }
);
