import React, { useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { Integration } from '../redux/slices/integrationSlice';
import {
  setIntegrationsFlow,
  setIntegrationsFlowLoadingState,
} from '../redux/slices/integrationFlowSlice';

export default function useFlowIntegrations() {
  const dispatch = useDispatch();
  const workspaceId = useSelector((state) => state.workspaceFlow.currentWorkspace?.id);
  useEffect(() => {
    let unsubscribe: () => void;
    if (workspaceId) {
      dispatch(setIntegrationsFlowLoadingState(RequestState.PENDING));

      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_INTEGRATIONS](workspaceId)),
        orderBy('createTime', 'desc')
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const integrations = querySnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              } as Integration)
          );

          dispatch(setIntegrationsFlow(integrations));
        },
        (error) => {
          console.error(error, workspaceId);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
}
