import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Iconify from '../../../components/Iconify';
import { useDispatch, useSelector } from '../../../redux/store';
import { setDoc, doc, collection } from 'firebase/firestore';
import {
  selectWorkspaces,
  setCurrentWorkspaceReviews,
  setWorkspaceReviews,
} from '../../../redux/slices/workspaceReviewsSlice';
import { DB } from '../../../contexts/FirebaseContext';
import { useTranslation } from 'react-i18next';
import { WorkspaceReview } from '../../../redux/slices/workspaceReviewsSlice';
import { Platforms } from '../../../redux/slices/platform';
import { selectPlatform } from '../../../redux/slices/userSlice';
import {
  selectCurrentPlatformWorkspaceName,
  selectCurrentPlatformWorkspaces,
} from '../../../redux/selectors/workspaces';
import { setCurrentWorkspaceFlow } from '../../../redux/slices/workspaceFlowSlice';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

// TODO: hide if there is only one workspace

export default function WorkspaceSwitcher() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const currentWorkspaceName = useSelector(selectCurrentPlatformWorkspaceName);
  const userId = useSelector((state) => state.user.id);
  const workspaces = useSelector(selectCurrentPlatformWorkspaces);
  const platform = useSelector(selectPlatform);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectWorkspace = async (id: string) => {
    if (!userId) return alert(t('general.error.default'));
    try {
      if (platform === Platforms.SIMPLY_FLOW) {
        dispatch(setCurrentWorkspaceFlow(id));
        const userRef = doc(collection(DB, 'users'), userId);
        await setDoc(userRef, { workspaceFlowCurrent: id }, { merge: true });
      } else {
        dispatch(setCurrentWorkspaceReviews(id));
        const userRef = doc(collection(DB, 'users'), userId);
        await setDoc(userRef, { workspaceReviewsCurrent: id }, { merge: true });
      }
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  if (workspaces.length < 2) return null;

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<Iconify icon="ph:caret-down-bold" width={14} />}
      >
        {currentWorkspaceName}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {workspaces.map((ws) => (
          <MenuItem key={ws.id} onClick={() => onSelectWorkspace(ws.id)} disableRipple>
            {(ws as WorkspaceReview)?.companyName ||
              `${ws.members[ws.uid]?.displayName} workspace` ||
              ws.id}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
