import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import keyBy from 'lodash.keyby';
import { Dictionary } from 'lodash';
import { RequestState, WidgetType } from '../../enum';
import { Platforms } from './platform';
import { WidgetTypeConfig } from '../../types';

export enum IntegrationState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Integration {
  id: string;
  type: WidgetType;
  state: IntegrationState;
  title?: string;
  metadata?: any;
  createTime: Date;
  platform: Platforms;
  videoProcessed?: boolean;
  config?: WidgetTypeConfig<WidgetType>;
}

// Define a type for the slice state
interface NotificationState {
  loading: RequestState;
  list: Integration[];
  // @ts-ignore
  map: Dictionary<Integration>;

  mapType: Dictionary<Integration>;
}

// Define the initial state using that type
const initialState: NotificationState = {
  loading: RequestState.IDLE,
  list: [],
  map: {},
  mapType: {},
};

export const integrationFlowSlice = createSlice({
  name: 'integrationsFlow',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setIntegrationsFlowLoadingState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      loading: action.payload,
    }),

    setIntegrationsFlow: (state, action: PayloadAction<Integration[]>) => {
      const flowIntegrations = action.payload.map((i) => ({
        ...i,
        platform: Platforms.SIMPLY_FLOW,
      }));
      return {
        ...state,
        list: flowIntegrations,
        mapType: keyBy(flowIntegrations, 'type'),

        map: keyBy(flowIntegrations, 'id'),

        loading: RequestState.RESOLVED,
      };
    },
  },
});

export const { setIntegrationsFlowLoadingState, setIntegrationsFlow } =
  integrationFlowSlice.actions;

export const loadingIntegrationsFlowState = (state: RootState) =>
  state.integrationsFlow.loading === RequestState.IDLE ||
  state.integrationsFlow.loading === RequestState.PENDING;

export default integrationFlowSlice.reducer;
