import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Android12Switch from 'src/components/customSwitch';

type Props = {
  hasControls: boolean;
  toggleControls: () => void;
};

export default function CustomiseControls({ hasControls, toggleControls }: Props) {
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
      <Typography variant="body2">Pagination arrows</Typography>
      <Android12Switch checked={hasControls} onChange={toggleControls} />
    </Stack>
  );
}