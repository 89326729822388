import React, { useState } from 'react';
import { Functions } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { httpsCallable } from 'firebase/functions';
import { useSelector } from 'src/redux/store';
import { selectHasStripeApi } from 'src/redux/slices/apiIntegrationsSlice';

const ConnectStripe: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useSelector(selectHasStripeApi);
  const [loading, setloading] = useState(false);

  const handleSignIn = async () => {};

  return (
    <>
      <LoadingButton
        variant="contained"
        loading={loading}
        disabled={loading}
        onClick={handleSignIn}
      >
        Connect Stripe
      </LoadingButton>
    </>
  );
};

export default ConnectStripe;
