import { useEffect, useState } from 'react';
import { onSnapshot, doc, query, collection, orderBy, where, limit } from 'firebase/firestore';
import { Collection } from '../enum';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { PublicConfig } from '../redux/slices/publicConfig';
import { Stats } from '../redux/slices/reviewSlice';
import { Integration } from '../redux/slices/integrationSlice';
import { UserDoc } from '../redux/slices/userSlice';
import { Invite } from '../redux/slices/invites';
import { StripeSubscription } from '../redux/slices/stripeSlice';

export default function useUserActivity(userId: string) {
  const { loadingConfig, publicConfig } = usePublicConfig(userId);
  const { loadingStats, reviewStats } = useReviewStats(userId);
  const { loadingWidgets, widgets } = useWidgets(userId);
  const { loadingUser, user } = useUser(userId);
  const { loadingInvite, invite } = useUserInvite(userId);
  const { loadingCustomer, customer } = useCustomer(userId);
  const { loadingSubscriptions, subscriptions } = useSubscriptions(userId);
  return {
    loading:
      loadingConfig ||
      loadingStats ||
      loadingWidgets ||
      loadingUser ||
      loadingInvite ||
      loadingCustomer ||
      loadingSubscriptions,
    publicConfig,
    reviewStats,
    widgets,
    user,
    invite,
    customer,
    subscriptions,
  };
}

function usePublicConfig(userId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [publicConfig, setPublicConfig] = useState<PublicConfig | null>(null);
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      setIsLoading(true);
      const q = query(
        collection(DB, getCollection[Collection.PUBLIC]()),
        where('userId', '==', userId),
        limit(1)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const [publicConfig] = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...(doc.data() as PublicConfig),
          }));
          setPublicConfig(publicConfig);
          setIsLoading(false);
        },
        (error) => {
          console.error(error);
        }
      );
    }
    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { loadingConfig: isLoading, publicConfig };
}
function useReviewStats(userId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [reviewStats, setReviewStats] = useState<Stats | null>(null);
  useEffect(() => {
    let unsubscribe: () => void;

    if (userId) {
      setIsLoading(true);
      const q = doc(DB, getCollection[Collection.USERS_REVIEW_STATS](userId));
      unsubscribe = onSnapshot(
        q,
        (doc) => {
          const stats = {
            ...doc.data(),
          } as Stats;
          setReviewStats(stats);
          setIsLoading(false);
        },
        (error) => {
          console.error(error);

          setIsLoading(false);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { loadingStats: isLoading, reviewStats };
}

function useWidgets(userId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [widgets, setWidgets] = useState<Integration[]>([]);
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      setIsLoading(true);
      const q = query(
        collection(DB, getCollection[Collection.USERS_INTEGRATIONS](userId)),
        orderBy('createTime', 'desc')
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const integrations = querySnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              } as Integration)
          );
          setWidgets(integrations);
          setIsLoading(false);
        },
        (error) => {
          console.error(error);
          setIsLoading(false);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { loadingWidgets: isLoading, widgets };
}

function useUser(userId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserDoc | null>(null);
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = doc(DB, getCollection[Collection.USERS](), userId);
      unsubscribe = onSnapshot(q, (doc) => {
        const userData = {
          ...doc.data(),
        } as UserDoc;
        async function setUserData() {
          setUser(userData);
          setIsLoading(false);
        }
        setUserData();
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { loadingUser: isLoading, user };
}
function useUserInvite(userId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [invite, setInvite] = useState<Invite | null>(null);
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      setIsLoading(true);
      const q = query(
        collection(DB, getCollection[Collection.USERS_INVITES](userId)),
        orderBy('createTime', 'desc'),
        limit(1)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const [invite] = querySnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              } as Invite)
          );
          setInvite(invite);
          setIsLoading(false);
        },
        (error) => {
          console.error(error);
          setIsLoading(false);
        }
      );
    }
    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { loadingInvite: isLoading, invite };
}

export interface Customer {
  email: string;
  stripeId: string;
  stripeLink: string;
}

function useCustomer(userId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState<Customer | null>(null);
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = doc(DB, getCollection[Collection.CUSTOMERS], userId);
      unsubscribe = onSnapshot(q, (doc) => {
        const customerData = doc.data() as Customer;
        setCustomer(customerData);
        setIsLoading(false);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { loadingCustomer: isLoading, customer };
}

function useSubscriptions(userId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState<StripeSubscription[]>([]);
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.CUSTOMERS_SUBSCRIPTIONS](userId)),
        orderBy('created', 'desc')
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const subscriptions = querySnapshot.docs.map(
            (doc) =>
              ({
                ...doc.data(),
              } as StripeSubscription)
          );
          setSubscriptions(subscriptions);
          setIsLoading(false);
        },
        (error) => {
          console.error(error);
          setIsLoading(false);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { loadingSubscriptions: isLoading, subscriptions };
}
