import { useEffect } from 'react';
import { query, collection, where, limit, onSnapshot } from 'firebase/firestore';
import { Collection } from '../enum';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentPlatformWorkspaceId } from '../redux/selectors/workspaces';
import {
  ApiIntegration,
  setApiIntegrations,
  setRemoveZenfitApiIntegration,
} from '../redux/slices/apiIntegrationsSlice';

export default function useApiIntegrations() {
  // console.group('useApiIntegrations');
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  // const [isLoadingApis, setIsLoadingApis] = useState(true);

  useEffect(() => {
    let unsubscribe: () => void;
    if (workspaceId) {
      const q = query(
        collection(DB, getCollection[Collection.API_INTEGRATIONS_READ](workspaceId, 'zenfit')),
        where('readable', '==', true),
        where('userId', '==', workspaceId),
        limit(1)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const [zenfitIntegration] = querySnapshot.docs.map(
            (doc) =>
              ({
                id: 'zenfit',
                ...doc.data(),
              } as ApiIntegration)
          );

          dispatch(setApiIntegrations([zenfitIntegration]));
        },
        (error) => {
          console.error(error);
          dispatch(setRemoveZenfitApiIntegration());
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
  // console.groupEnd();
  return {};
}
