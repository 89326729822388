import { useState } from 'react';
// @mui
import { Grid, Stack } from '@mui/material';
// @types
import { CreditCard, UserAddressBook, UserInvoice } from '../../../../@types/user';
//
import { _pricingPlans } from '../../../../_mock';
import PricingPlanCardReview from '../../../pricing/PricingPlanCardReview';
import PricingPlanCardReviewTeam from '../../../pricing/PricingPlanCardReviewTeam';
import PricingPlanCardFlow from '../../../pricing/PricingPlanCardFlow';
import { useSelector } from '../../../../redux/store';
import { selectPlatform } from '../../../../redux/slices/userSlice';
import PricingPlanCardPremiumLeads from '../../../pricing/PricingPlanCardLeads';
import PricingPlanCardBundle from 'src/sections/pricing/PricingPlanCardBundle';
import { FLOW, LABS, LEADS, REVIEW } from 'src/theme/palette';
import SimplyReviewLogo from 'src/assets/Logo/SimplyReviewLogo';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Platforms } from 'src/redux/slices/platform';
import SimplyFlowLogo from 'src/assets/Logo/SimplyFlowLogo';
import SimplyLeadsLogo from 'src/assets/Logo/SimplyLeadsLogo';
import { selectLatestBundleSubscription } from 'src/redux/slices/stripeSlice';

// ----------------------------------------------------------------------

type Props = {
  cards: CreditCard[];
  invoices: UserInvoice[];
  addressBook: UserAddressBook[];
};

export default function AccountBilling({ cards, addressBook, invoices }: Props) {
  const platform = useSelector(selectPlatform);

  // selectWorkspaceLinksHasWorkspaces;
  // selectWorkspaceBookingsHasWorkspaces;
  // const extreme = _pricingPlans[2];
  return (
    <Grid container spacing={2}>
      {/* {platform === Platforms.SIMPLY_REVIEW && ( */}
      <ProductHeader product={Platforms.SIMPLY_REVIEW} />
      <Grid item xs={12} md={6} key={'review.subscription'}>
        <PricingPlanCardReview index={1} />
      </Grid>
      <Grid item xs={12} md={6} key={'review.subscription'}>
        <PricingPlanCardReviewTeam index={1} />
      </Grid>
      {/* )} */}
      <ProductHeader product={Platforms.SIMPLY_FLOW} />
      {/* {platform === Platforms.SIMPLY_FLOW && ( */}
      <Grid item xs={12} md={4} key={'flow.subscription'}>
        <PricingPlanCardFlow index={1} />
      </Grid>
      {/* )} */}
      {/* {platform === Platforms.SIMPLY_LEADS && ( */}
      <ProductHeader product={Platforms.SIMPLY_LEADS} />
      <Grid item xs={12} md={4} key={'leads.subscription'}>
        <PricingPlanCardPremiumLeads index={1} />
      </Grid>
      <ProductHeader product={null} />
      <Grid item xs={12} md={12} key={'bundle'}>
        <PricingPlanCardBundle />
      </Grid>
      {/* )} */}
      {/* <Grid item xs={12} md={4} key={extreme.subscription}>
        <PricingPlanCard
          card={extreme}
          index={1}
          action={user?.stripeRole === Subscription.FREE ? <Subscribe /> : <ManageSubscription />}
        />
      </Grid> */}
    </Grid>
  );
}

function getColor(platform: Platforms | null) {
  switch (platform) {
    case Platforms.SIMPLY_REVIEW:
      return {
        logo: <SimplyReviewLogo />,
        colors: REVIEW,
      };
    case Platforms.SIMPLY_FLOW:
      return {
        logo: <SimplyFlowLogo />,
        colors: FLOW,
      };
    case Platforms.SIMPLY_LEADS:
      return {
        logo: <SimplyLeadsLogo />,
        colors: LEADS,
      };

    default:
      return { logo: null, colors: LABS };
  }
}

function ProductHeader({ product }: { product: Platforms | null }) {
  const bundleSub = useSelector(selectLatestBundleSubscription);
  const { logo, colors } = getColor(product);
  if (!bundleSub && !product) return null;
  return (
    <Grid item xs={12} md={12} key={'review.subscription.headline'}>
      <Stack
        direction="row"
        alignItems="center"
        gap={4}
        sx={{ p: 4, color: REVIEW.darker, borderRadius: 1, backgroundColor: colors.lighter }}
      >
        <Box>{logo}</Box>
        <Box>
          <Typography variant="h5">Pick the plan that is right for you</Typography>
        </Box>
      </Stack>
    </Grid>
  );
}
