import React from 'react';

export default function SimplySiteIcon({ width = 12, color = '#86D3FF' }) {
  return (
    <svg style={{ width }} viewBox="6 5 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5454 20.707V22.4422C25.5454 23.7691 24.4839 24.8307 23.1569 24.8307H21.4217C20.0948 24.8307 19.0332 23.7691 19.0332 22.4422V20.707C19.0332 19.38 20.0948 18.3185 21.4217 18.3185H23.1569C24.4634 18.3185 25.5454 19.38 25.5454 20.707Z"
        fill={color}
      />
      <path
        d="M14.5814 9.74445V11.4797C14.5814 12.8066 13.5199 13.8682 12.193 13.8682H10.4577C9.11038 13.8886 8.04883 12.8066 8.04883 11.4797V9.74445C8.04883 8.41751 9.11038 7.35596 10.4373 7.35596H12.1725C13.4995 7.35596 14.5814 8.41751 14.5814 9.74445Z"
        fill={color}
      />
      <path
        d="M21.3977 7.35596H23.1533C24.4802 7.35596 25.5418 8.41751 25.5418 9.74445V11.4797C25.5418 12.8066 24.4802 13.8682 23.1533 13.8682H21.4181C20.0911 13.8682 19.0296 12.8066 19.0296 11.4797V9.74445C19.0092 8.41751 20.0707 7.35596 21.3977 7.35596Z"
        fill={color}
      />
      <path
        d="M10.4373 18.3185H12.1725C13.4995 18.3185 14.561 19.38 14.561 20.707V22.4422C14.561 23.7691 13.4995 24.8307 12.1725 24.8307H10.4373C9.11038 24.8511 8.04883 23.7691 8.04883 22.4422V20.707C8.04883 19.38 9.11038 18.3185 10.4373 18.3185Z"
        fill={color}
      />
      {/* <path
        d="M47.8277 22.9606C46.978 22.5107 46.3032 21.8859 45.8033 21.0611C45.3034 20.2363 45.0285 19.3116 44.9785 18.2369H47.8527C47.8777 18.8367 48.0277 19.3616 48.2776 19.8114C48.5275 20.2613 48.8774 20.5862 49.3023 20.8362C49.7272 21.0861 50.2271 21.211 50.7769 21.211H52.0016C52.4265 21.211 52.8264 21.1111 53.2512 20.9111C53.6511 20.7112 54.001 20.4363 54.276 20.0364C54.5509 19.6615 54.6759 19.1866 54.6759 18.6368C54.6759 18.0619 54.5009 17.612 54.151 17.2371C53.8011 16.8872 53.4012 16.5873 52.9013 16.3874C52.4015 16.1874 51.7267 15.9625 50.8269 15.6876C49.6522 15.3627 48.7025 15.0377 47.9527 14.7128C47.2029 14.3879 46.5781 13.8881 46.0532 13.2132C45.5284 12.5384 45.2534 11.6387 45.2534 10.514C45.2534 9.56424 45.5034 8.71448 45.9782 7.98968C46.4531 7.26488 47.0779 6.69003 47.8777 6.29014C48.6525 5.89025 49.5273 5.69031 50.452 5.69031H51.7267C52.7014 5.69031 53.5512 5.89025 54.3259 6.26515C55.1007 6.64005 55.7256 7.1899 56.2254 7.93969C56.7253 8.68948 57.0252 9.58924 57.1252 10.6639H54.276C54.176 9.83917 53.9011 9.21434 53.4512 8.78946C53.0013 8.36457 52.4265 8.16463 51.6767 8.16463H50.477C50.0771 8.16463 49.7022 8.2646 49.3273 8.43955C48.9774 8.61451 48.6775 8.88943 48.4526 9.21434C48.2276 9.53925 48.1277 9.93914 48.1277 10.389C48.1277 10.9389 48.3026 11.3887 48.6275 11.7386C48.9524 12.0886 49.3523 12.3385 49.8272 12.5384C50.3021 12.7384 50.9769 12.9383 51.8766 13.1883C53.0513 13.5132 54.026 13.8381 54.7758 14.163C55.5256 14.4879 56.1754 15.0127 56.7003 15.7126C57.2252 16.4124 57.5001 17.3621 57.5001 18.5618C57.5001 19.5865 57.2501 20.5112 56.7503 21.286C56.2504 22.0608 55.5756 22.6607 54.7258 23.0855C53.8761 23.5104 52.9263 23.7104 51.9016 23.7104H50.627C49.6022 23.6354 48.6525 23.4104 47.8277 22.9606Z"
        fill="#222222"
      />
      <path d="M58.4258 10.8141H61.0751V23.4356H58.4258V10.8141Z" fill="#222222" />
      <path
        d="M62.6758 10.8139H65.3001V12.7134C65.575 12.1385 66.0249 11.6387 66.5997 11.2138C67.1745 10.8139 67.7744 10.589 68.3742 10.589H69.324C70.1487 10.589 70.8235 10.7639 71.3484 11.1388C71.8483 11.5137 72.2981 12.0386 72.673 12.7384C72.9979 12.1135 73.4978 11.6137 74.1226 11.1888C74.7475 10.7889 75.3973 10.564 76.0471 10.564H76.9968C77.7466 10.564 78.4215 10.7639 79.0213 11.1888C79.6211 11.5887 80.071 12.1635 80.3959 12.8633C80.7208 13.5631 80.8958 14.3629 80.8958 15.2627V23.3854H78.2715V15.7875C78.2715 15.2627 78.1965 14.7628 78.0216 14.3379C77.8466 13.913 77.6217 13.5881 77.3467 13.3382C77.0718 13.0883 76.7219 12.9633 76.347 12.9633H75.5472C75.0974 12.9633 74.6975 13.1383 74.3226 13.4632C73.9477 13.7881 73.6728 14.2629 73.4478 14.8378C73.2479 15.4126 73.1229 16.0375 73.1229 16.6873V23.4354H70.4986V15.7875C70.4986 15.2627 70.4237 14.7628 70.2487 14.3379C70.0987 13.913 69.8738 13.5631 69.5739 13.3382C69.274 13.1133 68.9491 12.9883 68.5742 12.9883H67.7494C67.2995 12.9883 66.8996 13.1633 66.5247 13.5132C66.1498 13.8631 65.8749 14.3129 65.65 14.8878C65.45 15.4626 65.3251 16.0625 65.3251 16.7123V23.4604H62.7008V10.8139H62.6758Z"
        fill="#222222"
      />
      <path
        d="M91.5674 11.5137C92.3671 12.1136 93.017 12.8884 93.4668 13.8881C93.9167 14.8878 94.1667 15.9625 94.1667 17.1372C94.1667 18.3369 93.9417 19.4366 93.4918 20.4363C93.042 21.436 92.4171 22.2108 91.5924 22.7856C90.7926 23.3605 89.8428 23.6604 88.7931 23.6604H88.0183C87.4685 23.6604 86.8937 23.4854 86.3188 23.1355C85.744 22.7856 85.2691 22.3858 84.9442 21.8859V26.2847H82.2949V10.8139H84.9442V12.3885C85.2691 11.8886 85.744 11.4637 86.3438 11.1138C86.9436 10.7639 87.5185 10.589 88.1183 10.589H88.8931C89.8678 10.614 90.7676 10.9139 91.5674 11.5137ZM86.1938 13.4382C85.744 13.7881 85.3941 14.288 85.1441 14.9378C84.8942 15.5876 84.7442 16.3124 84.7442 17.1372C84.7442 17.962 84.8692 18.6868 85.1441 19.3366C85.3941 19.9864 85.744 20.4863 86.1938 20.8362C86.6437 21.1861 87.1436 21.386 87.6934 21.386H88.5682C89.0681 21.386 89.5429 21.2111 89.9928 20.8362C90.4427 20.4863 90.8176 19.9614 91.0925 19.3116C91.3674 18.6618 91.5174 17.937 91.5174 17.1372C91.5174 16.3374 91.3674 15.6126 91.0925 14.9628C90.8176 14.313 90.4427 13.8131 89.9928 13.4382C89.5429 13.0883 89.0681 12.8884 88.5682 12.8884H87.6934C87.1186 12.8884 86.6437 13.0883 86.1938 13.4382Z"
        fill="#222222"
      />
      <path d="M95.0645 8.16467H97.6887V23.4355H95.0645V8.16467Z" fill="#222222" />
      <path
        d="M102.315 22.6608C102.34 22.8108 102.39 22.9608 102.415 23.1357C102.44 23.3107 102.44 23.4606 102.44 23.6356C102.44 23.9855 102.365 24.3854 102.24 24.8102L101.59 26.3098H104.139C104.264 26.0599 104.364 25.785 104.489 25.4851L109.788 10.8141H107.114L104.564 18.2121C104.389 18.7869 104.189 19.4367 103.989 20.1615H103.914C103.739 19.4367 103.54 18.7869 103.34 18.1871L100.74 10.8141H98.041L102.04 21.8111C102.14 22.136 102.24 22.4109 102.315 22.6608Z"
        fill="#222222"
      /> */}
      {/* <path
        d="M112.832 18.3118H115.006C115.156 20.4363 116.556 21.7109 118.381 21.7109H119.73C121.03 21.7109 122.804 20.7862 122.804 18.6867C122.804 14.188 113.132 16.4873 113.132 10.289C113.132 7.48981 115.456 5.69031 118.056 5.69031H119.405C122.129 5.69031 124.254 7.21489 124.604 10.464H122.479C122.204 8.51453 121.13 7.56479 119.355 7.56479H118.081C116.731 7.56479 115.356 8.51453 115.356 10.1891C115.356 14.5879 125.029 12.0386 125.029 18.6118C125.029 21.7609 122.329 23.5854 119.68 23.5854H118.381C115.331 23.6104 113.007 21.5609 112.832 18.3118Z"
        fill="#222222"
        stroke="#222222"
        strokeWidth="0.374897"
        strokeMiterlimit="10"
      />
      <path
        d="M126.555 6.04028H128.829V8.58958H126.555V6.04028ZM126.705 10.789H128.704V23.4105H126.705V10.789Z"
        fill="#222222"
        stroke="#222222"
        strokeWidth="0.374897"
        strokeMiterlimit="10"
      />
      <path
        d="M134.401 6.54004V10.7889H138.4V12.5134H134.401V20.1113C134.401 21.211 134.901 21.6609 135.826 21.6609H138.2V23.3854H135.426C133.426 23.3854 132.402 22.4357 132.402 20.2613V12.5134H129.652V10.7889H132.402V7.68972L134.401 6.54004Z"
        fill="#222222"
        stroke="#222222"
        strokeWidth="0.374897"
        strokeMiterlimit="10"
      />
      <path
        d="M143.65 10.589H144.7C147.549 10.589 149.648 13.3382 149.648 16.4624C149.648 16.9372 149.623 17.1872 149.598 17.4371H140.426C140.576 20.1114 142.001 21.8109 143.675 21.8109H144.65C146.224 21.8109 147.299 20.6612 147.549 19.3366H149.474C149.224 21.636 147.274 23.5854 144.675 23.5854H143.65C140.701 23.5854 138.352 20.8112 138.352 17.0122C138.377 13.1383 141.076 10.589 143.65 10.589ZM147.599 15.7126C147.549 13.9381 146.299 12.3635 144.7 12.3635H143.725C142.3 12.3635 140.976 13.6132 140.576 15.7126H147.599Z"
        fill="#222222"
        stroke="#222222"
        strokeWidth="0.374897"
        strokeMiterlimit="10"
      /> */}
    </svg>
  );
}
