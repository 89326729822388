import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ModalType {
  WIDGET = 'WIDGET',
  INVITE_MEMBER = 'INVITE_MEMBER',
  ACCEPT_INVITE_MEMBER = 'ACCEPT_INVITE_MEMBER',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
}

export interface WidgetContext {}
export interface InviteMemberContext {}
export interface RemoveMemberContext {
  inviteId: string;
  userId: string;
}

// Define a type for the slice state
interface NotificationState {
  // loading: RequestState;
  type: ModalType | null;
  context: WidgetContext | InviteMemberContext | InviteMemberContext | null;
}

// Define the initial state using that type
const initialState: NotificationState = {
  // loading: RequestState.IDLE,
  type: null,
  context: null,
};

export const modal = createSlice({
  name: 'modal',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setModal: (state, action: PayloadAction<NotificationState | null>) => ({
      ...state,
      type: action.payload?.type || null,
      context: action.payload?.context || null,
    }),
  },
});

export const { setModal } = modal.actions;

// Other code such as selectors can use the imported `RootState` type

export default modal.reducer;
