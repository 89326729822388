import React, { useState } from 'react';
import { createCheckoutSession } from '..';
import useAuth from '../../hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import { useSelector } from '../../redux/store';
import { selectCurrentPlatformWorkspaceId } from '../../redux/selectors/workspaces';
import { useTranslation } from 'react-i18next';

export default function Subscribe({ priceId }: { priceId: string }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  return (
    <LoadingButton
      size="large"
      fullWidth
      variant="contained"
      loading={checkoutLoading}
      onClick={() => {
        setCheckoutLoading(true);
        if (user?.id) {
          createCheckoutSession(user.id, priceId, workspaceId);
        }
      }}
    >
      {t('billing.upgradePlan')}
    </LoadingButton>
  );
}
