import DraggableQuestions from './components/DraggableQuestions';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

export enum QuestionType {
  RADIO = 'radio',
  TEXT = 'text',
  LONG_TEXT = 'long_text',
  NUMBER = 'number',
  CONTACT = 'contact',
}

export default function QuestionsSection({
  current,
  setCurrent,
  questions,
  setQuestions,
}: {
  current: string;
  setCurrent: (id: string) => void;
  questions: any[];
  setQuestions: (items: any[]) => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="body2">{t('leads.form.question.title')}</Typography>
      <Box mt={2} />

      <DraggableQuestions
        initialItems={questions}
        onChange={setQuestions}
        current={current}
        onChangeCurrent={(id: string) => setCurrent(id)}
      />
    </>
  );
}
