import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, onSnapshot, where } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { TeamInviteStatus, TeamInvite } from '../redux/slices/teamInviteSlice';
import { selectCurrentPlatformWorkspaceId } from '../redux/selectors/workspaces';
import {
  setWorkspaceTeamInvites,
  setWorkspaceTeamInvitesLoadingState,
} from '../redux/slices/workspaceTeamInvitesSlice';

// TODO: needs to be update to support simply flow
export default function usePendingTeamInvites() {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  useEffect(() => {
    let unsubscribe: () => void;
    if (workspaceId) {
      dispatch(setWorkspaceTeamInvitesLoadingState());
      const q = query(
        collection(DB, getCollection[Collection.TEAM_INVITES]()),
        where('workspaceId', '==', workspaceId),
        where('status', '==', TeamInviteStatus.PENDING),
        orderBy('createTime', 'desc')
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const invites = querySnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              } as TeamInvite)
          );
          dispatch(setWorkspaceTeamInvites(invites));
        },
        (error) => {
          console.error(error);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
}
