import { capitalCase } from 'change-case';
import { useState } from 'react';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import { _userPayment, _userAddressBook, _userInvoices, _userAbout } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import useLocales from '../../hooks/useLocales';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AccountIntegrations from '../../sections/@dashboard/user/account/AccountIntegrations';
// sections
import {
  AccountGeneral,
  AccountBilling,
  AccountChangePassword,
} from '../../sections/@dashboard/user/account';
import EarlyUser from '../../config/featureFlags';

// ----------------------------------------------------------------------

export enum TabType {
  GENERAL = 'general',
  BILLING = 'billing',
  CHANGE_PASSWORD = 'change_password',
  INTEGRATIONS = 'integrations',
}

export default function UserAccount() {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab') as TabType;

  const supportedTabs = [
    TabType.GENERAL,
    TabType.BILLING,
    TabType.CHANGE_PASSWORD,
    TabType.INTEGRATIONS,
  ];
  const defaultTab = tabParam && supportedTabs.includes(tabParam) ? tabParam : TabType.GENERAL;
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const ACCOUNT_TABS = [
    {
      value: TabType.GENERAL,
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <AccountGeneral />,
      label: 'account.tabs.general',
    },
    {
      value: TabType.BILLING,
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: (
        <AccountBilling
          cards={_userPayment}
          addressBook={_userAddressBook}
          invoices={_userInvoices}
        />
      ),
      label: 'account.tabs.billing',
    },
    // {
    //   value: 'notifications',
    //   icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
    //   component: <AccountNotifications />,
    // },
    {
      value: TabType.CHANGE_PASSWORD,
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      component: <AccountChangePassword />,
      label: 'account.tabs.changePassword',
    },
    {
      value: TabType.INTEGRATIONS,
      icon: <Iconify icon={'eva:share-fill'} width={20} height={20} />,
      component: (
        // <EarlyUser>
        <AccountIntegrations />
        // </EarlyUser>
      ),
      label: 'account.tabs.integrations',
    },
  ];

  return (
    <Page title="Konto">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('account.account')}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            // { name: 'User', href: PATH_DASHBOARD.user.root },
            { name: t('account.accountSettings') },
          ]}
        />

        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={t(tab.label)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
