import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  // {
  //   subscription: 'basis',
  //   icon: <PlanFreeIcon />,
  //   price: 0,
  //   caption: '',
  //   lists: [
  //     { text: 'Ubegrænset tekst anmeldelser', isAvailable: true },
  //     { text: '3 video anmeldelser', isAvailable: true },
  //     { text: 'Basis widgets', isAvailable: true },
  //     { text: 'Video intro til anmeldere', isAvailable: false },
  //     { text: 'Egne brand farver', isAvailable: false },
  //     { text: 'Sms invitationer', isAvailable: false },
  //   ],
  //   labelAction: 'current plan',
  // },
  // {
  //   subscription: 'starter',
  //   icon: <PlanStarterIcon />,
  //   price: 4.99,
  //   caption: 'saving $24 a year',
  //   lists: [
  //     { text: '3 prototypes', isAvailable: true },
  //     { text: '3 boards', isAvailable: true },
  //     { text: 'Up to 5 team members', isAvailable: true },
  //     { text: 'Advanced security', isAvailable: false },
  //     { text: 'Permissions & workflows', isAvailable: false },
  //   ],
  //   labelAction: 'choose starter',
  // },

  {
    subscription: 'premium',
    icon: <PlanPremiumIcon />,
    price: 14,
    caption: '',
    lists: [
      { text: 'Ubegrænset tekst anmeldelser', isAvailable: true },
      { text: 'Ubegrænset video anmeldelser', isAvailable: true },
      { text: 'Alle widgets', isAvailable: true },
      { text: 'Video intro til anmeldere', isAvailable: true },
      { text: 'Egne brand farver', isAvailable: true },
      { text: 'Sms invitationer', isAvailable: true },
    ],
    labelAction: 'choose premium',
  },
  // {
  //   subscription: 'extreme',
  //   icon: <PlanPremiumIcon />,
  //   price: 99.99,
  //   caption: '',
  //   lists: [
  //     { text: 'Ubegrænset tekst anmeldelser', isAvailable: true },
  //     { text: 'Ubegrænset video anmeldelser', isAvailable: true },
  //     { text: 'Alle widgets', isAvailable: true },
  //     { text: 'Video intro til anmeldere', isAvailable: true },
  //     { text: 'Egne brand farver', isAvailable: true },
  //     { text: 'Sms invitationer', isAvailable: true },
  //   ],
  //   labelAction: 'choose premium',
  // },
];
