import React from 'react';
import { Stack, Paper, Typography, Avatar } from '@mui/material';
import useReviewComments from '../../../../hooks/useReviewComments';
import { fDate } from '../../../../utils/formatTime';

export default function ReviewComments({ userId, reviewId }: { userId: string; reviewId: string }) {
  const { comments } = useReviewComments({ userId, reviewId });
  return (
    <Stack spacing={1.5}>
      {comments?.map((comment: any) => (
        <Stack key={comment.id} direction="row" spacing={2}>
          <Avatar alt={comment.author?.name} src={comment.author?.avatar} />
          <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ sm: 'center' }}
              justifyContent="space-between"
              sx={{ mb: 0.5 }}
            >
              <Typography variant="subtitle2">{comment.author?.name}</Typography>
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                {fDate(comment.createTime?.toDate())}
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {comment.message}
            </Typography>
          </Paper>
        </Stack>
      ))}
    </Stack>
  );
}
