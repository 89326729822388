import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: any;

const getStripe = () => {
  if (!stripePromise) {
    // TODO: and to .env

    stripePromise = loadStripe(
      'pk_live_51KMhqHCgcqfr3d92axmnrN3q6HaXgnAHd8zLDsSsF8PsEeuJc1zlFxhu4Sa2oU2GDCyRlaFk6lzEkhrAPdurlX0z003jHKpE9x'
    );
  }

  return stripePromise;
};

export default getStripe;
