import { WidgetType } from '../enum';

const ROOT = 'https://app.simplyreview.com/widgets';
const ROOT_FLOW = 'https://app.simplyflow.io/widgets';
const ROOT_LEADS = 'https://app.simplyleads.io/widgets';

export function getWidgetCode(
  type: WidgetType,
  { workspaceId, widgetId }: { workspaceId: string; widgetId: string }
) {
  switch (type) {
    case WidgetType.MICRO_STARS:
      return `<script defer src="${ROOT}/minireviewcount/index.js"></script>\n<div data-widget-minireviewcount="${workspaceId}_${widgetId}"></div>`;

    case WidgetType.HEADER_BAR_STARS:
      return `<script defer src="${ROOT}/headerbar/index.js"></script>\n<div data-widget-headerbar="${workspaceId}_${widgetId}"></div>`;

    case WidgetType.REVIEW_CARDS:
      return `<script defer src="${ROOT}/textcarousel/index.js"></script>\n<div data-widget-textcarousel="${workspaceId}_${widgetId}"></div>`;

    case WidgetType.VIDEO_CARDS:
      return `<script defer src="${ROOT}/videocarousel/index.js"></script>\n<div data-widget-videocarousel="${workspaceId}_${widgetId}"></div>`;

    case WidgetType.TEXT_VIDEO_CARDS:
      return `<script defer src="${ROOT}/textvideocarousel/index.js"></script>\n<div data-widget-textvideocarousel="${workspaceId}_${widgetId}"></div>`;

    case WidgetType.FLOATING_INTRO_VIDEO:
      return `<script defer src="${ROOT_FLOW}/floatingvideo/index.js"></script>\n<div data-widget-floatingvideo="${workspaceId}_${widgetId}_flow"></div>`;

    case WidgetType.LEAD_FORM:
      return `<script defer src="${ROOT_LEADS}/leadform/index.js"></script>\n<div data-widget-leadform="${workspaceId}_${widgetId}"></div>`;

    default:
      console.warn(`type=${type}: Invalid widget type`);
  }
}
