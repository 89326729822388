import { ReactElement, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Button, Typography, Box, Stack, Switch } from '@mui/material';
// components
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import { PriceId, Subscription } from '../../enum';
import Subscribe from '../../stripe/components/Subscribe';
import ManageSubscription from '../../stripe/components/ManageSubscription';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { PlanPremiumIcon } from '../../assets';
import { useSelector } from '../../redux/store';
import { selectIsSuperOwner } from '../../redux/slices/userSlice';
import {
  selectHasCurrentPlatformPremium,
  selectWorkspaceReviewHasWorskpaces,
} from '../../redux/selectors/workspaces';
import {
  StripeSubscriptionStatus,
  selectHasStripeLoaded,
  selectLatestBundleSubscription,
  selectLatestReviewSubscription,
  selectReviewSubscriptions,
} from 'src/redux/slices/stripeSlice';
import { LoadingButton } from '@mui/lab';
import SimplyReviewLogo from 'src/assets/Logo/SimplyReviewLogo';
import { REVIEW } from 'src/theme/palette';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5),
  },
}));

const lists = [
  { text: 'pricing.premium.item1', isAvailable: true },
  { text: 'pricing.premium.item2', isAvailable: true },
  { text: 'pricing.premium.item3', isAvailable: true },
  { text: 'pricing.premium.item4', isAvailable: true },
  { text: 'pricing.premium.item5', isAvailable: true },
  { text: 'pricing.premium.item6', isAvailable: true },
  { text: 'Invite your entire team', isAvailable: false },
  { text: 'AI features', isAvailable: false },
  { text: 'More content for SoMe', isAvailable: false },
  { text: 'Personalised help getting more reviews', isAvailable: false },
];

// ----------------------------------------------------------------------

type Props = {
  index: number;
};

export default function PricingPlanCardPremium({ index }: Props) {
  const { user } = useAuth();
  const [priceId, setPriceId] = useState(PriceId.SIMPLY_REVIEW_YEARLY);
  const { t } = useTranslation();
  const isSuperOwner = useSelector(selectIsSuperOwner);
  const stripeHasLoaded = useSelector(selectHasStripeLoaded);
  const hasSubscription = useSelector(selectReviewSubscriptions);
  const bundleSubscription = useSelector(selectLatestBundleSubscription);
  const reviewSub = useSelector(selectLatestReviewSubscription);
  const hasReview = useSelector(selectWorkspaceReviewHasWorskpaces);

  const hasReviewPremium = useSelector(selectHasCurrentPlatformPremium);
  const togglePrice = () => {
    if (priceId === PriceId.SIMPLY_REVIEW_MONTHLY) {
      setPriceId(PriceId.SIMPLY_REVIEW_YEARLY);
    } else {
      setPriceId(PriceId.SIMPLY_REVIEW_MONTHLY);
    }
  };

  const latestSubscriptionIsCancelled = reviewSub?.status === StripeSubscriptionStatus.Canceled;

  const renderSubscription = () => {
    if (!stripeHasLoaded) {
      return <LoadingButton loading size="large" fullWidth variant="contained" />;
    }
    if (!isSuperOwner) {
      return (
        <Button variant="contained" disabled fullWidth size="large">
          Only the owner can upgrade billing
        </Button>
      );
    }
    if (
      bundleSubscription?.status &&
      bundleSubscription?.status !== StripeSubscriptionStatus.Canceled
    ) {
      return (
        <Button variant="contained" disabled fullWidth size="large">
          You are Simply Bundle so this is included
        </Button>
      );
    }
    if (!hasReview) {
      return (
        <Button variant="contained" disabled fullWidth size="large">
          Setup your workspace (click the product in left menu)
        </Button>
      );
    }
    if (hasReviewPremium && hasSubscription?.length < 1) {
      return (
        <LoadingButton size="large" disabled fullWidth variant="contained">
          Seems like you got this one for free, lucky you
        </LoadingButton>
      );
    }
    // ONLY allow new subscription if not premium and no subscription or latest subscription is cancelled
    if ((!hasReviewPremium && hasSubscription?.length < 1) || latestSubscriptionIsCancelled) {
      return <Subscribe priceId={priceId} />;
    }
    return <ManageSubscription />;
  };

  return (
    <RootStyle>
      {priceId === PriceId.SIMPLY_REVIEW_YEARLY && (
        <Label
          color="info"
          sx={{
            top: 16,
            right: 16,
            position: 'absolute',
          }}
        >
          {t('general.popular')}
        </Label>
      )}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {t('pricing.premium.subHeader')}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t('general.yearly')}</Typography>
        <Switch
          value={priceId === PriceId.SIMPLY_REVIEW_YEARLY}
          onChange={togglePrice}
          inputProps={{ 'aria-label': 'ant design' }}
        />
        <Typography>{t('general.monthly')}</Typography>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        {index === 1 || index === 2 ? (
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            €
          </Typography>
        ) : (
          ''
        )}
        <Typography variant="h2" sx={{ mx: 1 }}>
          {PriceId.SIMPLY_REVIEW_MONTHLY === priceId ? 21 : 17.5}
        </Typography>
        {index === 1 || index === 2 ? (
          <Typography
            gutterBottom
            component="span"
            variant="subtitle2"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary',
            }}
          >
            /{t('general.date.month.short')}
          </Typography>
        ) : (
          ''
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
        }}
      >
        {priceId === PriceId.SIMPLY_REVIEW_YEARLY ? (
          `(${t('pricing.premium.priceSubText')})`
        ) : (
          <span style={{ visibility: 'hidden' }}>_</span>
        )}
      </Typography>

      {/* <Box sx={{ width: 80, height: 80, mt: 3 }}>
        <PlanPremiumIcon />
      </Box> */}

      <Stack component="ul" spacing={1.5} sx={{ my: 2, mb: 3, width: 1 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{ typography: 'body2', color: item.isAvailable ? 'text.primary' : 'text.disabled' }}
          >
            {item.isAvailable ? (
              <Box>
                <Iconify icon={'eva:checkmark-fill'} sx={{ width: 20, height: 20 }} />
              </Box>
            ) : (
              <Iconify icon={'bi:x'} sx={{ width: 20, height: 20 }} />
            )}
            <Typography variant="body2">{t(item.text)}</Typography>
          </Stack>
        ))}
      </Stack>
      <Box flexGrow={1} />
      {renderSubscription()}
    </RootStyle>
  );
}
