import React from 'react';
import { LEADS } from 'src/theme/palette';

export default function SimplyLeadsIcon({ width = 12, color = LEADS.main }) {
  return (
    <svg style={{ width }} width="23" height="24" viewBox="0 0 23 24" fill="none">
      <path
        d="M14.2433 21.0838L13.0074 22.3235C12.0599 23.2739 10.5356 23.2739 9.58811 22.3235L8.35223 21.0838C7.40472 20.1334 7.40472 18.6045 8.35223 17.6541L9.58811 16.4145C10.5356 15.4641 12.0599 15.4641 13.0074 16.4145L14.2433 17.6541C15.1908 18.6045 15.1908 20.1334 14.2433 21.0838Z"
        fill={color}
      />
      <path
        d="M22.1114 13.1912L20.8755 14.4309C19.928 15.3813 18.4038 15.3813 17.4563 14.4309L16.2204 13.1912C15.2729 12.2408 15.2729 10.7119 16.2204 9.76154L17.4563 8.52188C18.4038 7.57148 19.928 7.57148 20.8755 8.52188L22.1114 9.76154C23.0589 10.7119 23.0589 12.2408 22.1114 13.1912Z"
        fill={color}
      />
      <path
        d="M6.60166 13.2747L5.36578 14.5144C4.41827 15.4648 2.89402 15.4648 1.94651 14.5144L0.710631 13.2747C-0.236877 12.3243 -0.236877 10.7954 0.710631 9.84503L1.94651 8.60538C2.89402 7.65498 4.41827 7.65498 5.36578 8.60538L6.60166 9.84503C7.54916 10.7954 7.54916 12.3243 6.60166 13.2747Z"
        fill={color}
      />
      <path
        d="M14.4698 5.38216L13.2339 6.62182C12.2864 7.57222 10.7622 7.57222 9.81467 6.62182L8.57879 5.38216C7.63129 4.43176 7.63129 2.90286 8.57879 1.95245L9.81467 0.712801C10.7622 -0.2376 12.2864 -0.2376 13.2339 0.712801L14.4698 1.95245C15.3967 2.90286 15.3967 4.45242 14.4698 5.38216Z"
        fill={color}
      />
    </svg>
  );
}
