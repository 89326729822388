import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { RequestState } from 'src/enum';

export interface Onboarding {}

// Define a type for the slice state
interface UserState {
  dashboard?: boolean;
  completedStartReviewCollection?: boolean;
  hasInitialSettings?: boolean;
  initialChatHint?: boolean;
  latestAppMessage: number; // time
  isModalOpen: boolean;
  requestState: RequestState;
}

// Define the initial state using that type
const initialState: UserState = {
  requestState: RequestState.IDLE,
  latestAppMessage: new Date('1970-01-01').getTime(),
  isModalOpen: false,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setOnboarding: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload,
      latestAppMessage:
        action.payload?.latestAppMessage?.toDate().getTime() || state.latestAppMessage,
      requestState: RequestState.RESOLVED,
    }),
  },
});

export const { setOnboarding } = onboardingSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectOnboarding = (state: RootState) => state.onboarding;
export const selectShouldDisplayOnboardingInitialChatHint = (state: RootState) =>
  state.onboarding.requestState === RequestState.RESOLVED &&
  state.onboarding.initialChatHint !== true;

export default onboardingSlice.reducer;
