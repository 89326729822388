import { Intl, LanguageCode } from '../enum';
import { da, enGB, enUS, nn, sv } from 'date-fns/locale';

export function getLocalFromLngCode(lngCode: 'EN' | 'DK' | 'NO' | 'SV') {
  switch (lngCode) {
    case LanguageCode.DK:
      return Intl.DA;
    case LanguageCode.EN:
      return Intl.EN;
    case LanguageCode.NO:
      return Intl.NN;
    case LanguageCode.SV:
      return Intl.SV;

    default:
      return Intl.EN;
  }
}
export function getDateLocalFromIntl(intl: Intl) {
  switch (intl) {
    case Intl.DA:
      return da;
    case Intl.EN:
      return enUS;
    case Intl.NO:
      return nn;
    case Intl.SV:
      return sv;

    default:
      return enUS;
  }
}
export function getLngCodeFromLocal(locale?: Intl) {
  switch (locale) {
    case Intl.DA:
      return LanguageCode.DK;
    case Intl.EN:
      return LanguageCode.EN;
    case Intl.NO:
      return LanguageCode.NO;
    case Intl.SV:
      return LanguageCode.SV;
    default:
      return LanguageCode.EN;
  }
}

/**
 *
 * USING THIS BECAUSE LOCIZE only uses e.g. "nn" for Norwegian Nynorsk, and not "no"
 * @returns
 */
export function getIntl(intl: Intl) {
  switch (intl) {
    case Intl.DA:
      return Intl.DA;
    case Intl.EN:
    case Intl.US:
      return Intl.EN;
    case Intl.NO:
    case Intl.NN:
      return Intl.NN;

    case Intl.DE:
      return Intl.DE;
    case Intl.FR:
      return Intl.FR;
    case Intl.FI:
      return Intl.FI;
    case Intl.SV:
      return Intl.SV;
    case Intl.IT:
      return Intl.IT;
    case Intl.ES:
      return Intl.ES;
    case Intl.CH:
      return Intl.CH;

    case Intl.CY:
      return Intl.CY;
    case Intl.PT:
      return Intl.PT;
    case Intl.ID:
      return Intl.ID;
    case Intl.NL:
      return Intl.NL;

    default:
      return Intl.EN;
  }
}

// function that converts Intl to date-fns locale such as enGb, da, nn
export function getIntlLocale(intl: Intl) {
  switch (intl) {
    case Intl.DA:
      return da;
    case Intl.EN:
      return enGB;
    case Intl.NO:
      return nn;
    case Intl.SV:
      return sv;

    default:
      return enGB;
  }
}
