import { Box } from '@mui/material';
import { Fade, Stack } from '@mui/material';
import { Button } from '@mui/material';
import { Badge, Slide, alpha, useTheme } from '@mui/material';
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import {
  addDays,
  getDate,
  getDay,
  isAfter,
  isBefore,
  isFuture,
  isSameDay,
  isToday,
} from 'date-fns';
import React, { useMemo } from 'react';
import BrandButton from 'src/components/buttons/BrandButton';
import { m, useAnimation } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { WeekAvailability, convertMinutesToHHmm, createTimeIntervals } from 'src/utils/time';
import { useTranslation } from 'react-i18next';
import { BlockBooking } from 'src/hooks/useBookings';

const initialValue = new Date();

interface BookingCalendarProps {
  customColor?: string;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  selectedTime: number | null;
  setSelectedTime: (time: number | null) => void;
  setStep: () => void;
  weekAvailability: WeekAvailability;
  duration: number;
  rollingdays: number;
  blockedTimes: BlockBooking[];
}

function isDateAvailable(
  weekAvailability: WeekAvailability,
  day: Date,
  blockedTimesToday: number[],
  duration: number
) {
  console.log({ weekAvailability, day, duration });

  // check if all weekAvailability is blocked
  const timeSlots = createTimeIntervals(weekAvailability, day, duration);
  console.log({ timeSlots, blockedTimesToday });
  const filteredTimes = timeSlots.filter((time) => !blockedTimesToday.includes(time));

  return filteredTimes.length > 0;
}

export default function BookingCalendar({
  blockedTimes,
  weekAvailability,
  rollingdays,
  duration,
  customColor,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  setStep,
}: BookingCalendarProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  // const firstButtonAnimation = useAnimation();
  const requestAbortController = React.useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const blockedDayTimes = useMemo(
    () =>
      selectedDate
        ? blockedTimes.filter((b) => isSameDay(b.date?.toDate(), selectedDate)).map((b) => b.time)
        : [],
    [selectedDate, blockedTimes]
  );
  function ServerDay(props: PickersDayProps<Date> & { highlightedDays?: number[] }) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    if (outsideCurrentMonth) {
      return (
        <PickersDay
          key={props.day.toString()}
          {...other}
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
        />
      );
    }
    // const daysOfWeek = [
    //   'sunday',
    //   'monday',
    //   'tuesday',
    //   'wednesday',
    //   'thursday',
    //   'friday',
    //   'saturday',
    // ];

    if (!weekAvailability) return null;

    const blockedTimesToday = blockedTimes
      .filter((b) => isSameDay(b.date?.toDate(), day))
      .map((b) => b.time);

    // Check if the day is highlighted based on highlightedDays and weekAvailability
    const isDayAvailable = isDateAvailable(weekAvailability, day, blockedTimesToday, duration); // Checks
    const isHighlighted =
      isDayAvailable &&
      !props.outsideCurrentMonth &&
      isFuture(props.day) &&
      !isBefore(addDays(new Date(), rollingdays), day); // props.outsideCurrentMonth && highlightedDays.indexOf(dayofMonth) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        // badgeContent={isHighlighted ? '🌚' : undefined}
      >
        <PickersDay
          {...other}
          style={{
            backgroundColor: isHighlighted
              ? alpha(customColor || theme.palette.primary.main, props.selected ? 1 : 0.08)
              : undefined,
          }}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  const shouldBlockDate = (day: Date) => {
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];

    if (!weekAvailability) return true;
    const dayOfWeek = daysOfWeek[day.getDay()]; // Convert day index to lowercase day name

    // Check if the day is highlighted based on highlightedDays and weekAvailability
    const isDayAvailable = weekAvailability[dayOfWeek]?.length > 0; // Checks
    if (!isFuture(day) || isBefore(addDays(new Date(), rollingdays), day)) {
      return true;
    }
    if (isDayAvailable) {
      return false;
    }
    return true;
  };

  const handleMonthChange = (date: Date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    // setIsLoading(true);
    // setHighlightedDays([]);
    // fetchHighlightedDays(date);
  };

  const times = createTimeIntervals(weekAvailability, selectedDate, duration);
  const filteredTimes = times.filter((time) => !blockedDayTimes.includes(time));
  return (
    <Stack direction="row">
      <DateCalendar
        //   defaultValue={initialValue}
        onChange={(value) => {
          setSelectedTime(null);
          setSelectedDate(value);
        }}
        value={selectedDate || initialValue}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        shouldDisableDate={shouldBlockDate}
        slots={{
          day: ServerDay,
        }}
      />
      <Slide direction="left" in={Boolean(selectedDate)} mountOnEnter unmountOnExit>
        <Box sx={{ width: 200, maxHeight: 380, overflowY: 'scroll' }}>
          {filteredTimes?.map((n) => {
            const isSelected = n === selectedTime;

            return (
              <>
                <Stack key={`${selectedDate?.toString()}-${n}`} direction="row" width={'100%'}>
                  <BrandButton
                    customColor={isSelected ? theme.palette.grey.A700 : customColor}
                    flatButton
                    // fullWidth
                    style={{ width: isSelected ? 100 - 8 : 200, transition: 'width 0.3s' }}
                    variant={isSelected ? 'contained' : 'outlined'}
                    onClick={async () => {
                      setSelectedTime(n);
                    }}
                  >
                    {convertMinutesToHHmm(n)}
                  </BrandButton>

                  {isSelected && <Box sx={{ mr: 1 }} />}
                  {/* <Slide direction="left" in={isSelected} mountOnEnter unmountOnExit> */}
                  <BrandButton
                    variant="contained"
                    customColor={customColor}
                    flatButton
                    onClick={setStep}
                    style={{
                      width: isSelected ? 100 : 0,
                      display: isSelected ? 'block' : 'none',
                      transition: 'width 0.3s',
                    }}
                  >
                    {t('booking.event.time.next')}
                  </BrandButton>
                  {/* </Slide> */}
                </Stack>
                <Box sx={{ mt: 1 }} />
              </>
            );
          })}
        </Box>
      </Slide>
    </Stack>
  );
}
