import React from 'react';

export default function SimplyBookingLogo({ width = 84, color = '#333' }) {
  return (
    <svg
      style={{ width }}
      viewBox="0 0 361 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2579_898)">
        <path
          d="M128.797 64.5015C125.647 62.8188 123.132 60.4444 121.263 57.3876C119.393 54.3308 118.365 50.8066 118.178 46.8056H128.947C129.068 49.0491 129.601 51.0029 130.536 52.6575C131.471 54.3121 132.733 55.5928 134.331 56.4995C135.921 57.4063 137.753 57.855 139.809 57.855H144.399C145.96 57.855 147.503 57.4998 149.036 56.78C150.569 56.0602 151.831 54.9852 152.831 53.5549C153.831 52.1153 154.327 50.3766 154.327 48.3106C154.327 46.2447 153.682 44.4593 152.411 43.1131C151.13 41.7764 149.569 40.7294 147.727 39.9815C145.886 39.2337 143.315 38.355 140.006 37.3641C135.64 36.1769 132.06 34.9803 129.283 33.7557C126.507 32.5405 124.133 30.6802 122.17 28.1843C120.207 25.6884 119.225 22.3231 119.225 18.0697C119.225 14.5081 120.113 11.3578 121.889 8.61883C123.665 5.8705 126.021 3.7485 128.956 2.25281C131.892 0.757121 135.107 0.00927734 138.594 0.00927734H143.371C146.989 0.00927734 150.232 0.710381 153.102 2.11259C155.972 3.5148 158.328 5.60876 160.169 8.38513C162.011 11.1615 163.114 14.5455 163.488 18.5371H152.813C152.439 15.4149 151.42 13.0778 149.774 11.5167C148.12 9.9556 145.923 9.17971 143.175 9.17971H138.678C137.183 9.17971 135.762 9.52559 134.416 10.208C133.069 10.8998 131.994 11.8439 131.19 13.0592C130.377 14.2744 129.975 15.7233 129.975 17.4153C129.975 19.4719 130.583 21.1452 131.798 22.4259C133.013 23.7066 134.509 24.7068 136.295 25.4173C138.071 26.1371 140.613 26.9317 143.923 27.801C148.353 28.9882 151.971 30.1848 154.785 31.4094C157.599 32.634 159.992 34.541 161.992 37.1678C163.993 39.7852 164.984 43.3468 164.984 47.8432C164.984 51.7133 164.058 55.0973 162.226 57.9952C160.384 60.8931 157.87 63.1273 154.691 64.6884C151.513 66.2495 147.98 67.0254 144.109 67.0254H139.333C135.463 67.0254 131.948 66.1841 128.797 64.5015Z"
          fill="#00061D"
        />
        <path d="M168.442 19.0979H178.361V66.2775H168.442V19.0979Z" fill="#00061D" />
        <path
          d="M184.353 19.0979H194.178V26.2118C195.234 24.0244 196.842 22.1735 199.001 20.6404C201.151 19.1166 203.357 18.3501 205.601 18.3501H209.163C212.285 18.3501 214.79 19.0419 216.697 20.4067C218.604 21.7808 220.24 23.772 221.614 26.3988C222.857 24.0898 224.652 22.1735 226.999 20.6404C229.336 19.1166 231.729 18.3501 234.159 18.3501H237.721C240.525 18.3501 243.04 19.1166 245.255 20.6404C247.471 22.1735 249.191 24.2581 250.406 26.9129C251.621 29.5678 252.229 32.5498 252.229 35.8496V66.2682H242.404V37.6258C242.404 35.6253 242.096 33.8305 241.469 32.2413C240.843 30.6521 240.002 29.3995 238.945 28.4927C237.88 27.586 236.636 27.1373 235.197 27.1373H232.205C230.523 27.1373 228.99 27.7636 227.616 29.0069C226.241 30.2595 225.166 31.9515 224.39 34.1109C223.605 36.261 223.222 38.5606 223.222 40.9911V66.2682H213.397V37.6258C213.397 35.6253 213.098 33.8211 212.509 32.1946C211.911 30.568 211.07 29.3247 209.985 28.446C208.891 27.5766 207.657 27.1373 206.283 27.1373H203.198C201.516 27.1373 199.983 27.7823 198.609 29.0536C197.234 30.3343 196.159 32.045 195.383 34.2044C194.598 36.3544 194.215 38.6167 194.215 40.9911V66.2682H184.39V19.0979H184.353Z"
          fill="#00061D"
        />
        <path
          d="M292.426 21.6687C295.454 23.8841 297.819 26.8475 299.54 30.5586C301.26 34.2698 302.11 38.3175 302.11 42.6831C302.11 47.0486 301.269 51.2833 299.586 54.9945C297.904 58.7056 295.548 61.6409 292.519 63.791C289.49 65.941 286.013 67.016 282.077 67.016H279.179C277.123 67.016 275.001 66.371 272.813 65.0997C270.626 63.819 268.915 62.2766 267.663 60.4631V76.9437H257.744V19.0979H267.663V24.9966C268.906 23.127 270.645 21.5471 272.86 20.2664C275.076 18.9858 277.272 18.3501 279.46 18.3501H282.358C286.041 18.3501 289.397 19.4625 292.416 21.6687H292.426ZM272.299 28.876C270.645 30.2221 269.336 32.0917 268.364 34.4942C267.392 36.8966 266.915 39.6263 266.915 42.6831C266.915 45.7399 267.401 48.4695 268.364 50.872C269.327 53.2744 270.645 55.144 272.299 56.4901C273.954 57.8363 275.805 58.5 277.871 58.5H281.152C283.022 58.5 284.807 57.8269 286.49 56.4901C288.172 55.1534 289.528 53.2651 290.565 50.8252C291.594 48.3947 292.108 45.6744 292.108 42.6831C292.108 39.6917 291.594 36.9714 290.565 34.5409C289.537 32.1104 288.182 30.2221 286.49 28.876C284.807 27.5392 283.022 26.8662 281.152 26.8662H277.871C275.814 26.8662 273.954 27.5392 272.299 28.876Z"
          fill="#00061D"
        />
        <path d="M305.485 9.17041H315.31V66.2776H305.485V9.17041Z" fill="#00061D" />
        <path
          d="M332.631 63.3703C332.753 63.9312 332.865 64.5295 332.959 65.1465C333.052 65.7728 333.099 66.3898 333.099 67.0161C333.099 68.3248 332.846 69.7924 332.351 71.419L329.939 77.0372H339.502C339.96 76.093 340.39 75.0647 340.783 73.943L360.629 19.0886H350.514L340.97 46.7962C340.278 48.9837 339.568 51.4142 338.82 54.0971H338.539C337.848 51.4142 337.137 48.9463 336.389 46.7028L326.658 19.0886H316.543L331.519 60.1826C331.958 61.3698 332.332 62.4262 332.641 63.361L332.631 63.3703Z"
          fill="#00061D"
        />
        <path
          d="M153.691 135.051L151.822 140.24L149.952 135.051L131.882 87.7783L131.714 87.3296H119.674V154.262H128.844V107.409C128.844 106.474 128.826 105.128 128.807 103.857L130.013 107.194L147.97 154.262H155.393L173.537 107.194C173.883 106.306 174.379 104.997 174.837 103.763C174.818 105.063 174.799 106.446 174.799 107.409V154.262H183.876V87.3296H171.929L153.691 135.051Z"
          fill="#00061D"
        />
        <path
          d="M213.323 104.923H209.387C199.553 104.923 188.934 114.392 188.934 129.686C188.934 144.119 197.767 155.009 209.481 155.009H213.323C222.942 155.009 230.972 147.896 231.991 138.473L232.075 137.697H223.503L223.391 138.267C222.577 142.595 219.109 146.961 213.22 146.961H209.565C203.498 146.961 198.871 140.838 198.151 131.976H232.299L232.365 131.35C232.458 130.415 232.552 129.452 232.552 127.629C232.552 115.112 223.923 104.932 213.313 104.932L213.323 104.923ZM198.74 124.114C200.282 117.412 204.601 112.971 209.668 112.971H213.323C218.539 112.971 222.96 117.907 223.447 124.114H198.749H198.74Z"
          fill="#00061D"
        />
        <path
          d="M258.81 104.923H254.875C245.041 104.923 234.421 114.392 234.421 129.686C234.421 144.119 243.255 155.009 254.968 155.009H258.81C268.429 155.009 276.459 147.896 277.478 138.473L277.562 137.697H268.99L268.878 138.267C268.065 142.595 264.597 146.961 258.707 146.961H255.052C248.985 146.961 244.358 140.838 243.638 131.976H277.787L277.852 131.35C277.946 130.415 278.039 129.452 278.039 127.629C278.039 115.112 269.411 104.932 258.801 104.932L258.81 104.923ZM244.227 124.114C245.77 117.412 250.088 112.971 255.155 112.971H258.81C264.026 112.971 268.448 117.907 268.934 124.114H244.237H244.227Z"
          fill="#00061D"
        />
        <path
          d="M310.72 113.532V105.67H295.744V89.2458L286.854 94.3592V105.67H276.553V113.532H286.854V141.847C286.854 150.195 290.79 154.252 298.885 154.252H309.981V146.39H300.39C297.137 146.39 295.754 144.867 295.754 141.286V113.532H310.729H310.72Z"
          fill="#00061D"
        />
        <path
          d="M39.9163 86.1236V94.1068C39.9163 100.158 44.8214 105.063 50.8722 105.063H58.8554C64.9062 105.063 69.8113 100.158 69.8113 94.1068V86.1236C69.8113 80.0728 64.9062 75.1677 58.8554 75.1677H50.8722C44.8214 75.1677 39.9163 80.0728 39.9163 86.1236Z"
          fill="#00061D"
        />
        <path
          d="M0 60.3604L0 68.3436C0 74.3944 4.90513 79.2996 10.9559 79.2996H18.9391C24.9899 79.2996 29.8951 74.3944 29.8951 68.3436V60.3604C29.8951 54.3096 24.9899 49.4045 18.9391 49.4045H10.9559C4.90513 49.4045 0 54.3096 0 60.3604Z"
          fill="#00061D"
        />
        <path
          d="M50.8626 53.5269H58.8458C64.8966 53.5269 69.8018 48.6217 69.8018 42.5709V34.5877C69.8018 28.5369 64.8966 23.6318 58.8458 23.6318L50.8626 23.6318C44.8118 23.6318 39.9067 28.5369 39.9067 34.5877V42.5709C39.9067 48.6217 44.8118 53.5269 50.8626 53.5269Z"
          fill="#00061D"
        />
        <path
          d="M10.9559 130.835H18.9391C24.9899 130.835 29.895 125.93 29.895 119.879L29.895 111.896C29.895 105.845 24.9899 100.94 18.9391 100.94H10.9559C4.90509 100.94 -4.19617e-05 105.845 -4.19617e-05 111.896L-4.19617e-05 119.879C-4.19617e-05 125.93 4.90509 130.835 10.9559 130.835Z"
          fill="#00061D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2579_898">
          <rect width="360.62" height="155" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
