import { useEffect } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import {
  LeadFormSuggestion,
  selectLeadForm,
  setLeadFormSuggestions,
  setLeadFormSuggestionsLoadingState,
} from 'src/redux/slices/leadFormsSlice';

export default function useLeadFormSuggestions() {
  const dispatch = useDispatch();
  const workspaceId = useSelector((state) => state.workspaceLeads.currentWorkspace?.id) as string;
  const form = useSelector(selectLeadForm);
  const formId = form?.id;
  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId && formId) {
      dispatch(setLeadFormSuggestionsLoadingState(RequestState.PENDING));
      const q = doc(DB, getCollection[Collection.LEADS_FORMS_SUGGESTIONS](workspaceId), formId);

      unsubscribe = onSnapshot(q, (doc) => {
        const formSuggestion = {
          id: doc.id,
          ...doc.data(),
        } as LeadFormSuggestion;
        if (Array.isArray(formSuggestion?.suggestions)) {
          dispatch(setLeadFormSuggestions(formSuggestion));
        } else {
          dispatch(setLeadFormSuggestions({ id: formSuggestion.id, suggestions: [] }));
        }
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId, formId]);
}
