import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { realtimeDB } from '../contexts/FirebaseContext';

export default function useUserPresence() {
  const [userPresence, setUserPresence] = useState<{
    [key: string]: { state: 'offline' | 'online'; online_at?: number; offline_at?: number };
  }>({});
  useEffect(() => {
    const starCountRef = ref(realtimeDB, 'users');
    const unsubscribe = onValue(
      starCountRef,
      (snapshot) => {
        const users = snapshot.val();

        setUserPresence(users);
      },
      (error) => {
        console.error(error);
      }
    );

    return () => unsubscribe();
  }, []);
  return { userPresenceMap: userPresence };
}
