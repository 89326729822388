import {
  collection,
  collectionGroup,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { DB } from '../../contexts/FirebaseContext';
import { Collection } from '../../enum';
import { Review } from '../../redux/slices/reviewSlice';
import { getCollection } from 'src/api';
import { addDays, subDays } from 'date-fns';

export default function useRecentDistanceReviews({
  workspaceId,
  createTime,
}: {
  workspaceId: string;
  createTime: Date;
}) {
  const [compareReviews, setCompareReviews] = useState<(Review & { workspaceId: string })[]>([]);

  useEffect(() => {
    if (!workspaceId || !createTime) {
      console.error(`Missing workspaceId createTime`);
      return;
    }

    const q = query(
      collection(DB, getCollection[Collection.USERS_REVIEWS](workspaceId)),
      where('createTime', '>', subDays(createTime, 4)),
      where('createTime', '<', addDays(createTime, 4)),
      orderBy('createTime', 'desc')
    );

    const unsub = onSnapshot(
      q,
      (snapshot) => {
        const reviews = snapshot.docs.map((doc) => ({
          ...doc.data(),
          workspaceId: doc.ref.parent?.parent?.id,
          id: doc.id,
          createTime: doc.data().createTime.toDate(),
        })) as (Review & { workspaceId: string })[];

        setCompareReviews(reviews);
      },
      (error) => {
        console.error(error);
      }
    );

    // Clean up the onSnapshot listener when the component is unmounted
    return unsub;
  }, []); // re-run the effect if db or collectionName changes

  return { list: compareReviews };
}
