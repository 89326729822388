import { useState, useRef, ChangeEvent, useEffect } from 'react';
import * as Yup from 'yup';
// @mui
// import { MobileDateRangePicker } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
// import { DisplayTime, useDatePicker } from '../../sections/@dashboard/kanban/KanbanTaskAdd';
import IconButtonAnimate from '../../components/animate/IconButtonAnimate';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import CopyInviteLinkSmall from '../../components/CopyInviteLinkSmall';
import useInvite from '../../hooks/useInvite';
// import { Invite } from '../../redux/slices/invites';
import { sentenceCase } from 'change-case';
import InviteActivity from './InviteActivity';
import useInviteLink from '../../hooks/useInviteLink';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { DialogContent } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { PhoneCountryField, PhoneField, countries, phoneCustom } from 'src/components/PhoneField';
import { FormProvider, RHFCheckbox } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDoc, collection, doc, setDoc, writeBatch } from '@firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { Collection, InviteStatus } from 'src/enum';
import { useSelector } from 'src/redux/store';
import {
  selectCurrentPlatformWorkspace,
  selectCurrentPlatformWorkspaceId,
  selectWorkspaceReview,
} from 'src/redux/selectors/workspaces';
import { useSnackbar } from 'notistack';

//

// ----------------------------------------------------------------------

// const PRIORITIZES = ['low', 'medium', 'hight'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type Props = {
  inviteId: string | null;
  onClose: VoidFunction;
  onDeleteInvite: VoidFunction;
};

export type NewInvites = {
  // TODO: language code
  smsReminder: boolean;
  emailReminder: boolean;
  phone: string;
  code: string;
};
export default function InviteDrawer({ inviteId, onClose, onDeleteInvite }: Props) {
  const inviteLink = useInviteLink();
  const isDesktop = useResponsive('up', 'sm');
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const invite = useInvite(inviteId);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const smsLimit = useSelector((state) => state.workspaceReview?.currentWorkspace?.smsLimit);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [taskCompleted, setTaskCompleted] = useState(true);

  // const [prioritize, setPrioritize] = useState('low');

  const name = invite?.name;
  const link = `${inviteLink}/${invite?.linkId}`;
  const createTime = invite?.createTime;
  const email = invite?.email;
  const phone = `${invite?.countryCode}${invite?.phone}`;
  const status = invite?.status || '';
  const defaultValues: NewInvites = {
    smsReminder: Boolean(invite?.phone),
    emailReminder: true,
    phone: '',
    code: '',
  };

  const ReminderSchema = Yup.object().shape({
    smsReminder: Yup.boolean(),
    remailReminder: Yup.boolean(),
    phone: Yup.string().when('smsReminder', {
      is: true,
      then: Yup.string().required('Must enter phone number'),
    }),
    code: Yup.string().when('smsReminder', {
      is: true,
      then: Yup.string().required('Must enter phone number'),
    }),
  });

  const methods = useForm<NewInvites>({
    resolver: yupResolver(ReminderSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();
  useEffect(() => {
    setValue('smsReminder', Boolean(invite?.phone));
    setValue('code', invite?.countryCode || '');
    setValue('phone', invite?.phone ? `${invite?.phone}` : '');
  }, [invite?.phone]);

  // const {
  //   dueDate,
  //   startTime,
  //   endTime,
  //   isSameDays,
  //   isSameMonths,
  //   onChangeDueDate,
  //   openPicker,
  //   onOpenPicker,
  //   onClosePicker,
  // } = useDatePicker({
  //   date: due,
  // });

  const handleAttach = () => {
    fileInputRef.current?.click();
  };

  const handleToggleCompleted = () => {
    setTaskCompleted((prev) => !prev);
  };

  // const handleChangePrioritize = (event: ChangeEvent<HTMLInputElement>) => {
  //   setPrioritize(event.target.value);
  // };

  const onSubmit = async (data: NewInvites) => {
    try {
      if (!workspaceId || !inviteId) throw new Error('WorkspaceId or InviteId is not defined');

      if (data.smsReminder && Number(smsLimit) <= 0) {
        enqueueSnackbar(t('invites.add.smsLimitExceeded'), { variant: 'error' });
        return;
      }
      const inviteRemindersResend = doc(
        collection(
          DB,
          getCollection[Collection.REVIEW_INVITES_RESEND_REMINDERS](workspaceId, inviteId)
        )
      );
      const workspaceReviewRef = doc(
        collection(DB, getCollection[Collection.WORKSPACE_REVIEWS]()),
        workspaceId
      );
      const batch = writeBatch(DB);
      batch.set(
        inviteRemindersResend,
        {
          createTime: new Date(),
          smsReminder: data.smsReminder,
          emailReminder: data.emailReminder,
          phone: data.phone || null,
          countryCode: data.code || null,
          email: invite?.email || null,
          shortLink: invite?.shortLink || null,
          linkId: invite?.linkId || null,
          name: invite?.name || null,
          status: InviteStatus.PENDING,
          language: invite?.language || null,
        },
        { merge: true }
      );
      const newSmsLimit = Number(smsLimit) - 1;
      batch.set(workspaceReviewRef, { smsLimit: newSmsLimit }, { merge: true });
      await batch.commit();
      if (data.smsReminder) {
        enqueueSnackbar(t('invites.reminder.sendwithsms.success', { smsLeft: newSmsLimit }), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t('invites.reminder.send.success'), { variant: 'success' });
      }
      handleClose();
    } catch (error) {
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <>
      <Drawer
        open={Boolean(inviteId)}
        onClose={onClose}
        anchor="right"
        PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}
      >
        <Stack p={2.5} direction="row" alignItems="center">
          {!isDesktop && (
            <Tooltip title="Back">
              <IconButtonAnimate onClick={onClose} sx={{ mr: 1 }}>
                <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}

          <Button
            size="small"
            variant="outlined"
            disabled
            color={taskCompleted ? 'primary' : 'inherit'}
            startIcon={
              taskCompleted && <Iconify icon={'eva:checkmark-fill'} width={16} height={16} />
            }
            onClick={handleToggleCompleted}
          >
            {sentenceCase(status)}
          </Button>

          <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
            {/* <Tooltip title="Like this">
              <IconButtonAnimate size="small">
                <Iconify icon={'ic:round-thumb-up'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>

            <>
              <Tooltip title="Attachment">
                <IconButtonAnimate size="small" onClick={handleAttach}>
                  <Iconify icon={'eva:attach-2-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
              <input ref={fileInputRef} type="file" style={{ display: 'none' }} />
            </> */}

            <Tooltip title="Slet invitation">
              <IconButtonAnimate onClick={onDeleteInvite} size="small">
                <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>

            {/* <Tooltip title="More actions">
              <IconButtonAnimate size="small">
                <Iconify icon={'eva:more-horizontal-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip> */}
          </Stack>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
            <OutlinedInput
              fullWidth
              multiline
              size="small"
              placeholder="Task name"
              value={name}
              sx={{
                typography: 'h6',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
              }}
            />
            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>{t('general.link')}</LabelStyle>
              {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
              <Tooltip title={link} placement="top">
                <Stack sx={{ width: '100%' }}>
                  <CopyInviteLinkSmall value={link || 'Generer link...'} />
                </Stack>
              </Tooltip>
              {/* </Stack> */}
            </Stack>
            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>{t('general.email')}</LabelStyle>
              {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
              <CopyInviteLinkSmall value={email || ''} />
              {/* </Stack> */}
            </Stack>
            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>{t('general.phone')}</LabelStyle>
              {/* <Stack direction="row" flexWrap="wrap" alignItems="center"> */}
              <CopyInviteLinkSmall value={(phone || '') as string} />
              {/* </Stack> */}
            </Stack>

            {/* <Stack direction="row" alignItems="center">
              <LabelStyle>Prioritize</LabelStyle>
              <TextField
                fullWidth
                select
                size="small"
                value={prioritize}
                onChange={handleChangePrioritize}
                sx={{
                  '& svg': { display: 'none' },
                  '& fieldset': { display: 'none' },
                  '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' },
                }}
              >
                {PRIORITIZES.map((option) => (
                  <MenuItem key={option} value={option} sx={{ mx: 1, my: 0.5, borderRadius: 1 }}>
                    <Box
                      sx={{
                        mr: 1,
                        width: 14,
                        height: 14,
                        borderRadius: 0.5,
                        bgcolor: 'error.main',
                        ...(option === 'low' && { bgcolor: 'info.main' }),
                        ...(option === 'medium' && { bgcolor: 'warning.main' }),
                      }}
                    />
                    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                      {option}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Stack> */}

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>{t('general.activity')}</LabelStyle>
            </Stack>
            <InviteActivity inviteId={inviteId} />

            {/* <Tooltip title="This will send the same message as the first invite but modified slighty as it is a reminder"> */}
            <Button
              variant="contained"
              onClick={handleOpen}
              disabled={invite?.status === InviteStatus.REVIEWED}
            >
              {t('invites.reminder.modal.send')}
            </Button>
            {/* </Tooltip> */}
          </Stack>
        </Scrollbar>

        <Divider />
      </Drawer>
      <Dialog keepMounted maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle mb={2}>{t('invites.reminder.modal.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormProvider
              style={{ height: 'calc(100% - 64px)' }}
              methods={methods}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl
                required
                // error={error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
              >
                <RHFCheckbox name="emailReminder" label="Email reminder" />

                <RHFCheckbox name="smsReminder" label="Sms reminder" />
                {values.smsReminder && (!invite?.phone || !invite?.countryCode) && (
                  <>
                    <Box mt={1} />
                    <PhoneField
                      name="phone"
                      label={t('review.form.phonePlaceholder')}
                      placeholder="11 22 33 44"
                      InputProps={{
                        inputComponent: phoneCustom as any,
                        startAdornment: (
                          <InputAdornment sx={{ width: 200 }} position="start">
                            <PhoneCountryField id="outlined-select-currency" name={'countryCode'}>
                              {countries.map((option) => (
                                <option key={option.phone} value={option.phone}>
                                  {option.flag} {option.phone}
                                </option>
                              ))}
                            </PhoneCountryField>
                          </InputAdornment>
                        ),
                      }}
                      // error={!!errors.phone}
                      // helperText={errors?.phone?.message || ''}
                    />
                  </>
                )}
              </FormControl>
            </FormProvider>

            {t('invites.reminder.modal.text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('general.cancel')}</Button>
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t('invites.reminder.modal.send')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
