import React, { useEffect, useState } from 'react';
import { Intl } from '../../../enum';
import { useSelector } from '../../../redux/store';
import { selectCurrentBookingSchedule } from 'src/redux/slices/bookingSchedules';
import { WeekAvailability } from 'src/utils/time';

// export const initialQuestions = [
//   { id: '1', content: 'Who are you (short intro)' },
//   { id: '2', content: 'How has my service helped you?' },
//   { id: '3', content: 'What is the best experience you had working with me?' },
// ];

export default function useBookingSchedulePageSettings() {
  const currentSchedule = useSelector(selectCurrentBookingSchedule);
  const id = currentSchedule?.id;
  const titleInit = currentSchedule?.title;
  const descriptionInit = currentSchedule?.description;
  const durationInit = currentSchedule?.duration;
  const rollingdaysInit = currentSchedule?.rollingdays;
  const weekAvailabilityInit = currentSchedule?.weekAvailability;
  const logoInit = useSelector(
    (state) => currentSchedule?.logo?.original || state.publicConfig?.logo?.original
  );
  // const questionsInit = useSelector((state) => state.publicConfig?.questions);
  const state = useSelector((state) => state.bookingSchedules.currentIdState);
  const colorInit = currentSchedule?.color;
  // const onlyVideoInit = useSelector((state) => state.publicConfig?.onlyVideo);
  // const videoIntroInit = useSelector((state) => state.publicConfig?.videoIntro);
  const language = currentSchedule?.language;
  // default to user's language
  const intl = useSelector((state) => state.user.intl);

  const [avatar, setavatar] = useState<string | undefined>(logoInit);
  const [title, setTitle] = useState<string>(titleInit || '');
  const [description, setDescription] = useState<string>(descriptionInit || '');
  const [duration, setDuration] = useState<number>(durationInit || 60);
  const [rollingdays, setRollingdays] = useState<number>(rollingdaysInit || 60);
  const [weekAvailability, setWeekAvailability] = useState<WeekAvailability | null>(
    weekAvailabilityInit || null
  );

  // const [questions, setQuestions] = useState<any>(questionsInit || initialQuestions);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [color, setColor] = useState<string | undefined>(colorInit || undefined);
  // const [onlyVideo, setOnlyVideo] = useState<boolean>(onlyVideoInit || false);
  // const [videoIntroPreview, setVideoIntroPreview] = useState<string | undefined>(undefined);
  const [pageLanguage, setPageLanguage] = useState<Intl | undefined>(language || intl);
  useEffect(() => {
    if (id) {
      setTitle(currentSchedule?.title || '');
      setDescription(currentSchedule?.description || '');
      setDuration(currentSchedule?.duration || 60);
      setavatar(currentSchedule?.logo?.original || logoInit || undefined);
      setColor(currentSchedule?.color || undefined);
      setPageLanguage(currentSchedule?.language || undefined);
      setRollingdays(currentSchedule?.rollingdays || 60);
      setWeekAvailability(currentSchedule?.weekAvailability);
    }
  }, [id]);
  return {
    id,
    // init
    titleInit,
    descriptionInit,
    logoInit,
    durationInit,
    rollingdaysInit,
    // questionsInit,
    // configStatus,
    state,
    colorInit,
    // onlyVideoInit,
    // videoIntroInit,
    // reviewerPageLanguageInit,
    // state
    avatar,
    title,
    description,
    duration,
    rollingdays,
    // questions,
    showModal,
    color,
    weekAvailability,
    // onlyVideo,
    // videoIntroPreview,
    pageLanguage,
    // set state
    setavatar,
    setTitle,
    setDescription,
    setDuration,
    setRollingdays,
    setWeekAvailability,
    // setQuestions,
    setShowModal,
    setColor,
    // setOnlyVideo,
    // setVideoIntroPreview,
    setPageLanguage,
  };
}
