import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import useLocales from '../../../../hooks/useLocales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function AccountChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required(
      translate('changePassword.validation.oldRequired') as string
    ),
    newPassword: Yup.string()
      .min(6, translate('changePassword.validation.min6Chars') as string)
      .required(translate('changePassword.validation.newRequired') as string),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      translate('changePassword.validation.mustMatch') as string
    ),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <RHFTextField
            name="oldPassword"
            type="password"
            label={translate('changePassword.oldPass')}
          />

          <RHFTextField
            name="newPassword"
            type="password"
            label={translate('changePassword.newPass')}
          />

          <RHFTextField
            name="confirmNewPassword"
            type="password"
            label={translate('changePassword.confirmPass')}
          />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {translate('general.form.saveChanges')}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
