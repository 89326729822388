import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
// @mui
import { Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// type
import { UploadProps } from './type';
//
import Image from '../Image';
import Iconify from '../Iconify';
import RejectionFiles from './RejectionFiles';
import VideoPlayer from '../Review/VideoPlayer';
import BrandButton from '../buttons/BrandButton';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // width: 144,
  // height: 144,
  // margin: 'auto',
  // borderRadius: '50%',
  // padding: theme.spacing(1),
  // border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
  // zIndex: 0,
  // width: '100%',
  // height: '100%',
  // outline: 'none',
  // display: 'flex',
  // overflow: 'hidden',
  // borderRadius: '50%',
  // position: 'relative',
  // alignItems: 'center',
  // justifyContent: 'center',
  // '& > *': { width: '100%', height: '100%' },
  // '&:hover': {
  //   cursor: 'pointer',
  //   '& .placeholder': {
  //     zIndex: 9,
  //   },
  // },
});

// ----------------------------------------------------------------------

export default function SRUploadButton({
  error,
  text,
  helperText,
  fullWidth,
  customColor,
  sx,
  ...other
}: UploadProps & { fullWidth?: boolean; text: string; customColor?: string }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          {/* @ts-ignore */}
          <input {...getInputProps()} />

          {/* {file && (
            // <VideoPlayer
            //   //@ts-ignore
            //   video={isString(file) ? file : file.preview}
            //   hasControls={true}
            // />
            // <video width="400" controls>
            //   <source
            //     // @ts-ignore
            //     src={file.preview}
            //     id="video_here"
            //   />
            //   Your browser does not support HTML5 video.
            // </video>
          )} */}

          <BrandButton
            variant="contained"
            size="large"
            fullWidth={fullWidth}
            customColor={customColor}
            disabled={other.disabled}
          >
            {text}
          </BrandButton>
        </DropZoneStyle>
      </RootStyle>

      {helperText && helperText}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  );
}
