import React, { useState } from 'react';
import { Typography, Stack, IconButton, Alert, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Iconify from '../../../components/Iconify';
import { getTypeConfig } from '../types';
import Suggestions from '../components/Suggestions';
import { QuestionType } from '../QuestionsSection';
import Lottie, { aiLoader } from 'src/components/Lottie';
import { useSelector } from 'src/redux/store';
import { selectLeadFormSuggestions } from '../../../redux/slices/leadFormsSlice';
import { useTranslation } from 'react-i18next';

export default function OptionsSection({
  currentQuestion,
  openEditModal,
  children,
}: {
  currentQuestion: any;
  openEditModal: VoidFunction;
  children: any;
}) {
  const { t } = useTranslation();
  const typeConfig = getTypeConfig(currentQuestion?.type);
  const suggestions = useSelector(selectLeadFormSuggestions);
  const currentSuggestion = suggestions?.[currentQuestion.id];
  return (
    <Box sx={{ backgroundColor: '' }}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle1">{currentQuestion?.content}</Typography>
          {currentQuestion?.type !== QuestionType.CONTACT && (
            <IconButton size="small" onClick={openEditModal}>
              <Iconify icon="ic:outline-edit" />
            </IconButton>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {currentQuestion?.required ? (
            <Typography variant="body2" color="grey.500">
              ({t('leads.form.question.required')})
            </Typography>
          ) : (
            <>
              <Iconify icon={typeConfig.icon as string} color="grey.500" />
              <Typography variant="body2" color="grey.500">
                {typeConfig.label}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>

      {!currentSuggestion && currentQuestion?.type === QuestionType.RADIO ? <AiLoader /> : children}
      <Box mt={2} />
      <Suggestions question={currentQuestion} />
    </Box>
  );
}

function AiLoader() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Alert
      severity="info"
      icon={false}
      sx={{
        p: 2,
        pt: 1.5,
        width: '100%',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      <Stack
        alignSelf="center"
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        width="100%"
      >
        <Typography variant="body1" color="primary.main">
          {t('leads.form.ai.suggestions')}
        </Typography>
        <Stack sx={{ width: 100 }}>
          <Lottie animation={aiLoader} />
        </Stack>
      </Stack>
    </Alert>
  );
}
