import React, { useEffect, useState } from 'react';
import { onSnapshot, query, collection, where, limit } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import {
  setPublicConfig,
  setPublicConfigLoading,
  setPublicConfigError,
  PublicConfig,
} from '../redux/slices/publicConfig';
import {
  selectCurrentPlatformWorkspaceId,
  selectHasCurrentPlatformLoaded,
} from 'src/redux/selectors/workspaces';

export default function usePublic() {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const userId = useSelector((state) => state.user.id);
  const hasPlatformLoaded = useSelector(selectHasCurrentPlatformLoaded);

  useEffect(() => {
    let unsubscribe: () => void;
    if (hasPlatformLoaded) {
      try {
        dispatch(setPublicConfigLoading());
        const q = query(
          collection(DB, getCollection[Collection.PUBLIC]()),
          where('userId', '==', hasPlatformLoaded && !workspaceId ? userId : workspaceId),
          limit(1)
        );

        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const [publicConfig] = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...(doc.data() as PublicConfig),
          }));
          dispatch(setPublicConfig(publicConfig));
        });
      } catch (error) {
        console.error(error);

        dispatch(setPublicConfigError());
      }
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId, hasPlatformLoaded, dispatch]);
}
