// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, useTheme } from '@mui/material';
// utils
import cssStyles from '../utils/cssStyles';
import Iconify from './Iconify';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import { getWidgetCode } from '../utils/getWidgetCode';
import { useSelector } from '../redux/store';
import { selectCurrentPlatformWorkspaceId } from '../redux/selectors/workspaces';
import { StyledWidgetCard } from './WidgetCard';

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 0.05, color: theme.palette.common.white }),
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
}));

// ----------------------------------------------------------------------

export default function WidgetCardSelect({
  widget,
  onClick,
  onSelect,
  selected,
  stateLabel,
  comingSoon,
}: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId) as string;
  const { cover, title } = widget;
  const { enqueueSnackbar } = useSnackbar();
  const widgetCode = getWidgetCode(widget.type, { workspaceId, widgetId: widget.id });
  const onCopy = () => {
    if (widgetCode) {
      enqueueSnackbar(t('general.copied'));
    }
  };

  return (
    <StyledWidgetCard
      sx={{ height: '100%', cursor: 'pointer' }}
      selected={selected}
      comingSoon={comingSoon}
      onClick={onSelect || onClick}
    >
      <Box sx={{ position: 'relative' }}>
        {comingSoon && <OverlayStyle>{t('widgets.placeholder.comingsoon')}</OverlayStyle>}

        <img
          src={cover}
          alt={cover}
          style={{ objectFit: 'contain', minHeight: '100%', ...widget?.coverStyle }}
        />
        {selected && (
          <Box sx={{ zIndex: 12, right: 8, bottom: 0, position: 'absolute' }}>
            <Iconify
              icon={'ic:round-verified'}
              width={32}
              height={32}
              style={{
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                borderRadius: '50%',
                border: '1px solid #fff',
                // backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
              }}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ px: 2 }} />

      <Stack
        direction={'column'}
        // alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          // height: '100%',
          py: 2,
          // display: 'flex',
          px: 2,
          borderTop: `1px solid ${
            selected ? theme.palette.primary.light : theme.palette.grey[200]
          }`,
        }}
      >
        <Stack width="100%" flexDirection="row" justifyContent="space-between" pb={1.5}>
          <Typography variant="body1">{title}</Typography>
          {stateLabel}
        </Stack>
      </Stack>
    </StyledWidgetCard>
  );
}
