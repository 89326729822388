import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormDialog({
  email,
  open,
  handleClose,
  onSubmitted,
}: {
  email: string;
  open: boolean;
  handleClose: () => void;

  onSubmitted: (email: string) => void;
}) {
  const [password, setpassword] = React.useState('');
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          // @ts-ignore
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const password = formJson.password;

            onSubmitted(password);
            handleClose();
          },
        }}
      >
        <DialogTitle>Link account with email/password</DialogTitle>
        <DialogContent>
          <DialogContentText>Choose a password to link login with email/password</DialogContentText>
          <TextField
            margin="dense"
            id="name"
            name="email"
            type="email"
            value={email}
            fullWidth
            disabled
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="name"
            name="password"
            type="password"
            placeholder="password"
            value={password}
            fullWidth
            autoFocus
            onChange={(e) => setpassword(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Link account</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
