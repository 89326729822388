import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash.keyby';
import { RequestState } from '../../enum';
import { RootState } from '../store';
import { Platforms } from './platform';

export enum TeamInviteStatus {
  PENDING = 'PENDING',
  VOIDED = 'VOIDED',
  COMPLETED = 'COMPLETED',
}

export interface TeamInvite {
  id: string;
  createTime: Date;
  email: string;
  displayName: string;
  status: TeamInviteStatus;
  platform: Platforms;
  workspaceId: string;
  companyName: string;
  invitedBy: string;
  inviteId: string;
}

// Define a type for the slice state
interface UserState {
  state: RequestState;
  teamInviteIds: string[];
  teamInviteMap: { [key: string]: TeamInvite };
}

// Define the initial state using that type
const initialState: UserState = {
  state: RequestState.IDLE,
  teamInviteIds: [],
  teamInviteMap: {},
};

export const teamInvitesSlice = createSlice({
  name: 'teamInvites',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTeamInvitesLoadingState: (state) => ({
      ...state,
      state: RequestState.PENDING,
    }),
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTeamInvites: (state, action: PayloadAction<TeamInvite[]>) => ({
      ...state,
      teamInviteIds: action.payload.map((workspace) => workspace.id),
      teamInviteMap: keyBy(action.payload, 'id'),
      state: RequestState.RESOLVED,
    }),
  },
});

export const { setTeamInvitesLoadingState, setTeamInvites } = teamInvitesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectTeamInvites = (state: RootState) =>
  Object.keys(state.teamInvites.teamInviteMap).map((id) => ({
    ...state.teamInvites.teamInviteMap[id],
  }));

export default teamInvitesSlice.reducer;
