import { collectionGroup, getDocs, onSnapshot, query, where, orderBy } from 'firebase/firestore';

import React, { useEffect, useState } from 'react';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection } from 'src/enum';
import { Review } from 'src/redux/slices/reviewSlice';

export type UnverifiedReview = Review & { workspaceId: string; createTime: { toDate: () => Date } };

export default function useUnverifiedReviews() {
  const [unverified, setunverified] = useState<UnverifiedReview[]>([]);

  useEffect(() => {
    const q = query(
      collectionGroup(DB, Collection.USERS_REVIEWS),
      where('verified', '==', null),
      orderBy('createTime', 'desc')
    );

    const unsub = onSnapshot(q, (snapshot) => {
      const reviews = snapshot.docs.map((doc) => ({
        workspaceId: doc.ref.parent?.parent?.id,
        id: doc.id,
        ...doc.data(),
      })) as UnverifiedReview[];
      setunverified(reviews);
    });

    // Clean up the onSnapshot listener when the component is unmounted
    return unsub;
  }, []); // re-run the effect if db or collectionName changes

  return { list: unverified };
}
