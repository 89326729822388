// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { useEffect } from 'react';
import { Segment } from './utils/segment';
import Joyrider from './components/joyride';
import useUser from './hooks/useUser';
import useUserOnboarding from './hooks/useUserOnboarding';
import useReviewIntegrations from './hooks/useReviewIntegrations';
import useReviewStats from './hooks/useReviewStats';
// import usePublic from './hooks/usePublic';
import './App.css';
import WidgetModalContainer from './pages/Integrations/Modal';
import ModalContainer from './modals';
// import InitialSettings from './sections/@dashboard/user/initial/InitialSettings';
import useWorkspaceReviews from './hooks/useWorkspaceReviews';
import useTeamInvites from './hooks/useTeamInvites';
import useWorkspaceFlow from './hooks/useWorkspaceFlow';
import useFlowIntegrations from './hooks/useFlowIntegrations';
import ModalFlowInitialContainer from './modals/flow/ModalFlowInitialContainer';
import ModalReviewInitialContainer from './modals/ModalReviewInitialContainer';
import useApiIntegrations from './hooks/useApiIntegrations';
import ModalLeadsInitialContainer from './modals/leads/ModalLeadsInitialContainer';
import useWorkspaceLeads from './hooks/useWorkspaceLeads';
import SubscriptionModal from './modals/SubscriptionModal';
import useWorkspaceLinks from './hooks/useWorkspaceLinks';
import ModalLinksInitialContainer from './modals/links/ModalLinksInitialContainer';
import useZenfitLeadApiIntegrations from './hooks/useZenfitLeadApiIntegrations';
import useGoogleCalendarIntegrations from './hooks/useGoogleCalendarIntegration';
import useWorkspaceBooking from './hooks/useWorkspaceBooking';
import ModalBookingsInitialContainer from './modals/bookings/ModalBookingInitialContainer';
import ChatWidget from './components/support';
import useStripe from './hooks/useStripe';
import InAppMessages from './components/InAppMessages';
// ----------------------------------------------------------------------

export default function App() {
  const { isLoadingUser } = useUser();
  const { isLoadingWorkspaceReviews } = useWorkspaceReviews();
  const { isLoadingWorkspaceFlow } = useWorkspaceFlow();
  const { isLoadingWorkspaceLeads } = useWorkspaceLeads();
  const { isLoadingWorkspaceLinks } = useWorkspaceLinks();
  const { isLoadingWorkspaceBookings } = useWorkspaceBooking();
  const { isLoading } = useUserOnboarding();
  useApiIntegrations();
  useZenfitLeadApiIntegrations();
  useGoogleCalendarIntegrations();
  useReviewIntegrations();
  useFlowIntegrations();
  useTeamInvites();
  useReviewStats();
  useStripe();
  useEffect(() => {
    Segment.init();
    return () => {};
  }, []);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <Joyrider />
                <ProgressBarStyle />
                <ChartStyle />
                {/* <Settings /> */}
                <ScrollToTop />
                {!isLoading && !isLoadingUser && !isLoadingWorkspaceReviews && (
                  <>
                    <WidgetModalContainer />
                    <ModalContainer />
                  </>
                )}
                <SubscriptionModal />
                <ModalFlowInitialContainer />
                <ModalReviewInitialContainer />
                <ModalLeadsInitialContainer />
                <ModalLinksInitialContainer />
                <ModalBookingsInitialContainer />
                <ChatWidget />
                <InAppMessages />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
