// @mui
import { Grid } from '@mui/material';
// sections

import Step2 from '../Steps/Step2';
import { useSelector } from 'react-redux';
import { Integration } from '../../../redux/slices/integrationSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import { WidgetType } from '../../../enum';
import { selectCurrentPlatformIntegrationsMap } from '../../../redux/selectors/integrations';
import IntegrationsFlowNew from './New';
import { loadingIntegrationsFlowState } from 'src/redux/slices/integrationFlowSlice';
import LoadingScreen from 'src/components/LoadingScreen';

/**
 *
 * @returns Loading all widgets from else where so it will always be present
 */
export default function Widget() {
  const { widgetId } = useParams<{ widgetId: string }>();
  const [searchParams] = useSearchParams();
  const setup = searchParams.get('setup');

  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const loading = useSelector(loadingIntegrationsFlowState);
  const currentWidget = (widgetId && integrationMap[widgetId]) as Integration;

  const widgetType = currentWidget?.type as WidgetType;

  if (loading) {
    return <LoadingScreen />;
  }
  // if (setup) {
  return <IntegrationsFlowNew />;
  // }
  // return (
  //   <Grid container spacing={3} sx={{ pl: 2 }}>
  //     <Grid item xs={12} sm={12} md={12} sx={{ pb: 12 }}>
  //       <Grid container spacing={3}>
  //         {step === '2' ? (
  //           <Step2 currentWidgetId={widgetId} widgetType={widgetType} />
  //         ) : (
  //           <VideoSettings currentWidgetId={widgetId} widgetType={widgetType} />
  //         )}
  //       </Grid>
  //     </Grid>
  //   </Grid>
  // );
}
