import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import palette, { REVIEW, FLOW, LEADS, LINKS, BOOKINGS } from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { useSelector } from '../redux/store';
import { Platforms } from '../redux/slices/platform';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const platform = useSelector((state) => state.user.platform);

  const paletteOverride = getPalette(platform);
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? paletteOverride.light : paletteOverride.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection, paletteOverride]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

export function getPlatformPalette(platform: Platforms) {
  if (platform === Platforms.SIMPLY_FLOW) {
    return FLOW;
  }
  if (platform === Platforms.SIMPLY_REVIEW) {
    return REVIEW;
  }
  if (platform === Platforms.SIMPLY_LEADS) {
    return LEADS;
  }
  if (platform === Platforms.SIMPLY_LINKS) {
    return LINKS;
  }
  if (platform === Platforms.SIMPLY_BOOKING) {
    return BOOKINGS;
  }
  return REVIEW;
}

export function getPalette(platform: Platforms) {
  const platformPalette = getPlatformPalette(platform);
  return {
    light: {
      ...palette.light,
      primary: {
        ...palette.light.primary,
        ...platformPalette,
      },
    },
    dark: {
      ...palette.dark,
      primary: {
        ...palette.dark.primary,
        ...platformPalette,
      },
    },
  };
}
