import React from 'react';
import { useSelector } from '../../../redux/store';
import { selectAutomationLoadingState } from '../../../redux/slices/automationsSlice';
import { selectLoadingState } from '../../../redux/slices/apiIntegrationsSlice';
import ReviewAutomations from '.';
import CreateScreen from './CreateScreen';
import LoadingScreen from './LoadingScreen';

export default function ReviewAutomationsCotainer() {
  const isLoadingApiIntegrations = useSelector(selectLoadingState);
  const isLoadingAutomation = useSelector(selectAutomationLoadingState);

  const hasAnyAutomations = useSelector((state) => state.automations.list?.length > 0);
  if (isLoadingAutomation || isLoadingApiIntegrations) return <LoadingScreen />;
  if (!hasAnyAutomations) return <CreateScreen />;
  return <ReviewAutomations />;
}
