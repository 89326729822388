import React from 'react';
import Iconify from '../../../../components/Iconify';
import { IconButton, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';

interface Props {
  code: string;
}

export default function WidgetCodeBlock({ code }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Box>
        <Typography variant="h6">{t('general.copyCode')}</Typography>
      </Box>
      <Box sx={{ mt: 2 }} />
      {code && (
        <CopyToClipboard text={code} onCopy={(text) => enqueueSnackbar(t('general.copied'))}>
          <pre
            style={{
              backgroundColor: theme.palette.grey[200],
              borderRadius: 8,
              position: 'relative',
              fontSize: 12,
              whiteSpace: 'break-spaces',
              cursor: 'pointer',
            }}
          >
            <Box sx={{ py: 1, px: 2 }}>
              <Typography variant="body2">{code}</Typography>
            </Box>

            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
              <Tooltip title={t('general.copy') as string}>
                <IconButton>
                  <Iconify
                    style={{
                      color: theme.palette.primary.main,
                      // backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
                    }}
                    icon={'eva:copy-fill'}
                    width={24}
                    height={24}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </pre>
        </CopyToClipboard>
      )}
    </>
  );
}
