import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { ProductId, RequestState } from '../../enum';
import { keyBy } from 'lodash';

export interface SubscriptionItemPriceRecurring {
  aggregate_usage: null;
  interval: 'day' | 'month' | 'week' | 'year';
  interval_count: number;
  usage_type: 'licensed' | 'metered';
  tax_behavior: 'exclusive' | 'inclusive';
  tiers_mode: null;
  transform_quantity: null;
  type: 'recurring';
  unit_amount: number;
  unit_amount_decimal: string;
}
export interface SubscriptionItemPriceProduct {
  active: boolean;
  attributes: string[];
  created: number; // timestamp
  default_price: string;
  description: string;
  id: string;
  images: string[];
  livemode: boolean;
  metadata: {
    stripeRole: 'free' | 'premium';
  };
  name: string;
  object: 'product';
  package_dimensions: null;
  shippable: boolean;
  statement_descriptor: null;
  tax_code: string | null;
  type: 'good' | 'service';
  unit_label: null;
  updated: number; // timestamp
  url: null;
}
export interface SubscriptionItemPrice {
  active: boolean;
  billing_scheme: 'per_unit' | 'tiered' | 'volume';
  created: number; // timestamp
  currency: string;
  custom_unit_amount: null;
  id: string;
  livemode: boolean;
  lookup_key: null;
  metadata: {};
  nickname: string;
  object: 'price';
  product: SubscriptionItemPriceProduct;
  recurring: SubscriptionItemPriceRecurring;
  tax_behavior: 'exclusive' | 'inclusive' | 'unspecified';
  tiers_mode: null;
  transform_quantity: null;
  type: 'one_time' | 'recurring';
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface SubscriptionItemPlan {
  active: boolean;
  aggregate_usage: null;
  amount: number;
  amount_decimal: string;
  billing_scheme: 'per_unit' | 'tiered' | 'volume';
  created: number; // timestamp
  currency: string;
  id: string;
  interval: 'day' | 'week' | 'month' | 'year';
  interval_count: number;
  livemode: boolean;
  metadata: {};
  nickname: string;
  object: 'plan';
  product: string;
  tiers_mode: null;
  transform_usage: null;
  usage_type: 'licensed' | 'metered';
}

export interface SubscriptionItem {
  billing_thresholds: null;
  created: number; // timestamp
  id: string;
  metadata: {};
  object: 'subscription_item';
  plan: SubscriptionItemPlan;
  price: SubscriptionItemPrice;
  quantity: number;
  subscription: string;
  tax_rates: [];
}

export enum StripeSubscriptionStatus {
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  Active = 'active',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
}
export interface StripeSubscription {
  id: string;
  cancel_at: Date | null;
  cancel_at_period_end: boolean;
  canceled_at: Date | null;
  current_period_end: Date;
  current_period_start: Date;
  ended_at: Date | null;
  created: number; // Date;
  price: string;
  product: string;
  quantity: number;
  role: 'premium' | null;
  status: StripeSubscriptionStatus;
  stripeLink: string;
  trial_end: Date | null;
  trial_start: Date | null;
  metadata: {};
  prices: string[];
  items: SubscriptionItem[];
}

export interface StripeProduct {
  id: string;
  active: boolean;
  description: string;
  images: string[];
  name: string;
  metadata: any;
  role: string | null;
  tax_code: string | null;
}
// Define a type for the slice state
interface StripeState {
  productIds: string[];
  productMap: { [id: string]: StripeProduct };
  subscriptionIds: string[];
  subscriptionMap: { [id: string]: StripeSubscription };
  productState: RequestState;
  subscriptionState: RequestState;
}

// Define the initial state using that type
const initialState: StripeState = {
  productIds: [],
  productMap: {},
  subscriptionIds: [],
  subscriptionMap: {},
  productState: RequestState.IDLE,
  subscriptionState: RequestState.IDLE,
};

export const stripeSlice = createSlice({
  name: 'stripe',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSubscriptions: (state, action: PayloadAction<StripeSubscription[]>) => ({
      ...state,
      subscriptionIds: action.payload.map((s) => s.id),
      subscriptionMap: keyBy(action.payload, 'id'),
      subscriptionState: RequestState.RESOLVED,
    }),
    setProducts: (state, action: PayloadAction<StripeProduct[]>) => ({
      ...state,
      productIds: action.payload.map((p) => p.id),
      productMap: keyBy(action.payload, 'id'),
      productState: RequestState.RESOLVED,
    }),
  },
});

export const { setSubscriptions, setProducts } = stripeSlice.actions;

export const selectHasStripeLoaded = (state: RootState) =>
  state.stripe.productState === RequestState.RESOLVED &&
  state.stripe.subscriptionState === RequestState.RESOLVED;

export const selectReviewSubscriptions = (state: RootState) =>
  Object.entries(state.stripe.subscriptionMap)
    .filter(([id, value]) => value.product.includes(ProductId.SIMPLY_REVIEW))
    .map(([id, value]) => ({ id, value }));

export const selectFlowSubscriptions = (state: RootState) =>
  Object.entries(state.stripe.subscriptionMap)
    .filter(([id, value]) => value.product.includes(ProductId.SIMPLY_FLOW))
    .map(([id, value]) => ({ id, value }));

export const selectLeadsSubscriptions = (state: RootState) =>
  Object.entries(state.stripe.subscriptionMap)
    .filter(([id, value]) => value.product.includes(ProductId.SIMPLY_LEADS))
    .map(([id, value]) => ({ id, value }));

// Get LATEST subscription
export const selectLatestReviewSubscription = (state: RootState) =>
  Object.entries(state.stripe.subscriptionMap)
    .filter(([id, value]) => value.product.includes(ProductId.SIMPLY_REVIEW))
    .map(([id, value]) => ({ id, value }))
    .sort((a, b) => b.value?.created - a.value?.created)?.[0]?.value;

export const selectLatestFlowSubscription = (state: RootState) =>
  Object.entries(state.stripe.subscriptionMap)
    .filter(([id, value]) => value.product.includes(ProductId.SIMPLY_FLOW))
    .map(([id, value]) => ({ id, value }))
    .sort((a, b) => b.value?.created - a.value?.created)?.[0]?.value;

export const selectLatestLeadsSubscription = (state: RootState) =>
  Object.entries(state.stripe.subscriptionMap)
    .filter(([id, value]) => value.product.includes(ProductId.SIMPLY_LEADS))
    .map(([id, value]) => ({ id, value }))
    .sort((a, b) => b.value?.created - a.value?.created)?.[0]?.value;

export const selectLatestBundleSubscription = (state: RootState) =>
  Object.entries(state.stripe.subscriptionMap)
    .filter(([id, value]) => value.product.includes(ProductId.SIMPLY_BUNDLE))
    .map(([id, value]) => ({ id, value }))
    .sort((a, b) => b.value?.created - a.value?.created)?.[0]?.value;

const selectSubscriptionMap = (state: RootState) => state.stripe.subscriptionMap;

export const selectIsBundleUser = createSelector(selectSubscriptionMap, (map) => {
  const hasBundle = Object.entries(map).some(
    ([_, value]) =>
      // bundle id
      value.product === 'products/prod_Od5zQsOx6T54Vq' &&
      value.status !== StripeSubscriptionStatus.Canceled
  );
  return hasBundle;
});

export default stripeSlice.reducer;
