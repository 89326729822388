import { createSelector } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import { RequestState, Subscription } from '../../enum';
import { Platforms } from '../slices/platform';
import { RootState } from '../store';

// ...

export const selectWorkspaceReviewHasWorskpaces = (state: RootState) =>
  state.workspaceReview.workspaceIds.length > 0;
export const selectWorkspaceFlowHasWorskpaces = (state: RootState) =>
  state.workspaceFlow.workspaceIds.length > 0;
export const selectWorkspaceLeadsHasWorkspaces = (state: RootState) =>
  state.workspaceLeads.workspaceIds.length > 0;
export const selectWorkspaceLinksHasWorkspaces = (state: RootState) =>
  state.workspaceLinks.workspaceIds.length > 0;
export const selectWorkspaceBookingsHasWorkspaces = (state: RootState) =>
  state.workspaceBookings.workspaceIds.length > 0;

const selectWorkspaceReviewHasLoaded = (state: RootState) =>
  state.workspaceReview.state === RequestState.RESOLVED ||
  state.workspaceReview.state === RequestState.REJECTED;
const selectWorkspaceFlowHasLoaded = (state: RootState) =>
  state.workspaceFlow.state === RequestState.RESOLVED ||
  state.workspaceFlow.state === RequestState.REJECTED;
const selectWorkspaceLeadsHasLoaded = (state: RootState) =>
  state.workspaceLeads.state === RequestState.RESOLVED ||
  state.workspaceLeads.state === RequestState.REJECTED;
const selectWorkspaceLinksHasLoaded = (state: RootState) =>
  state.workspaceLinks.state === RequestState.RESOLVED ||
  state.workspaceLinks.state === RequestState.REJECTED;
const selectWorkspaceBookingsHasLoaded = (state: RootState) =>
  state.workspaceBookings.state === RequestState.RESOLVED ||
  state.workspaceBookings.state === RequestState.REJECTED;

export const selectWorkspaceReview = (state: RootState) => state.workspaceReview.currentWorkspace;
const selectWorkspaceFlow = (state: RootState) => state.workspaceFlow.currentWorkspace;
const selectWorkspaceLeads = (state: RootState) => state.workspaceLeads.currentWorkspace;
const selectWorkspaceLinks = (state: RootState) => state.workspaceLinks.currentWorkspace;
const selectWorkspaceBookings = (state: RootState) => state.workspaceBookings.currentWorkspace;

const selectWorkspacesReview = (state: RootState) =>
  Object.keys(state.workspaceReview.workspaceMap).map(
    (key) => state.workspaceReview.workspaceMap[key]
  );
const selectWorkspacesReviewMap = (state: RootState) => state.workspaceReview.workspaceMap;
const selectWorkspacesFlow = (state: RootState) =>
  Object.keys(state.workspaceFlow.workspaceMap).map((key) => state.workspaceFlow.workspaceMap[key]);
const selectWorkspacesLeads = (state: RootState) =>
  Object.keys(state.workspaceLeads.workspaceMap).map(
    (key) => state.workspaceLeads.workspaceMap[key]
  );

const selectWorkspacesLinks = (state: RootState) =>
  Object.keys(state.workspaceLinks.workspaceMap).map(
    (key) => state.workspaceLinks.workspaceMap[key]
  );
const platform = (state: RootState) => state.user.platform;

export const selectCurrentPlatformWorkspace = createSelector(
  selectWorkspaceReview,
  selectWorkspaceFlow,
  selectWorkspaceLeads,
  selectWorkspaceLinks,
  selectWorkspaceBookings,
  platform,
  (
    currentWorkspaceReview,
    currentWorkspaceFlow,
    currentWorkspaceLeads,
    currentWorkspaceLinks,
    currentWorkspaceBookings,
    platform
  ) => {
    switch (platform) {
      case Platforms.SIMPLY_REVIEW:
        return currentWorkspaceReview;

      case Platforms.SIMPLY_FLOW:
        return currentWorkspaceFlow;

      case Platforms.SIMPLY_LEADS:
        return currentWorkspaceLeads;

      case Platforms.SIMPLY_LINKS:
        return currentWorkspaceLinks;

      case Platforms.SIMPLY_BOOKING:
        return currentWorkspaceBookings;

      default:
        console.error(`selectCurrentPlatformWorkspace: Platform not found: platform=${platform}`);
    }
  }
);
export const selectCurrentPlatformWorkspaces = createSelector(
  selectWorkspacesReview,
  selectWorkspacesFlow,
  selectWorkspacesLeads,
  selectWorkspacesLinks,
  platform,
  (workspacesReview, workspacesFlow, workspacesLeads, workspacesLinks, platform) => {
    switch (platform) {
      case Platforms.SIMPLY_REVIEW:
        return workspacesReview;

      case Platforms.SIMPLY_FLOW:
        return workspacesFlow.map((w) => ({
          ...w,
          companyName: workspacesReview.find((wr) => wr.id === w.id)?.companyName,
        }));
      case Platforms.SIMPLY_LEADS:
        return workspacesLeads.map((w) => ({
          ...w,
          companyName: workspacesReview.find((wr) => wr.id === w.id)?.companyName,
        }));
      case Platforms.SIMPLY_LINKS:
        return workspacesLinks.map((w) => ({
          ...w,
          companyName: workspacesReview.find((wr) => wr.id === w.id)?.companyName,
        }));
      case Platforms.SIMPLY_BOOKING:
        return workspacesLinks.map((w) => ({
          ...w,
          companyName: workspacesReview.find((wr) => wr.id === w.id)?.companyName,
        }));

      default:
        console.error(`selectCurrentPlatformWorkspace: Platform not found: platform=${platform}`);
        return [];
    }
  }
);
export const selectCurrentPlatformWorkspaceId = createSelector(
  selectWorkspaceReview,
  selectWorkspaceFlow,
  selectWorkspaceLeads,
  selectWorkspaceLinks,
  selectWorkspaceBookings,
  platform,
  (
    currentWorkspaceReview,
    currentWorkspaceFlow,
    currentWorkspaceLeads,
    currentWorkspaceLinks,
    currentWorkspaceBookings,
    platform
  ) => {
    switch (platform) {
      case Platforms.SIMPLY_REVIEW:
        return currentWorkspaceReview?.id;

      case Platforms.SIMPLY_FLOW:
        return currentWorkspaceFlow?.id;

      case Platforms.SIMPLY_LEADS:
        return currentWorkspaceLeads?.id;

      case Platforms.SIMPLY_LINKS:
        return currentWorkspaceLinks?.id;

      case Platforms.SIMPLY_BOOKING:
        return currentWorkspaceBookings?.id;

      default:
        console.error(`selectCurrentPlatformWorkspaceId: Platform not found: platform=${platform}`);
    }
  }
);
export const selectCurrentPlatformWorkspaceName = createSelector(
  selectWorkspaceReview,
  selectWorkspaceFlow,
  selectWorkspaceLeads,
  selectWorkspaceLinks,
  selectWorkspaceBookings,
  selectWorkspacesReviewMap,
  platform,
  (
    currentWorkspaceReview,
    currentWorkspaceFlow,
    currentWorkspaceLeads,
    currentWorkspaceLinks,
    currentWorkspaceBookings,
    workspaceReviewMap,
    platform
  ) => {
    switch (platform) {
      case Platforms.SIMPLY_REVIEW:
        return currentWorkspaceReview?.companyName || currentWorkspaceReview?.id;

      case Platforms.SIMPLY_FLOW:
        return (
          (currentWorkspaceFlow?.id && workspaceReviewMap[currentWorkspaceFlow?.id]?.companyName) ||
          currentWorkspaceFlow?.members[currentWorkspaceFlow?.uid]?.displayName ||
          currentWorkspaceFlow?.members[currentWorkspaceFlow?.uid]?.email
        );
      case Platforms.SIMPLY_LEADS:
        return (
          (currentWorkspaceLeads?.id &&
            workspaceReviewMap[currentWorkspaceLeads?.id]?.companyName) ||
          currentWorkspaceLeads?.members[currentWorkspaceLeads?.uid]?.displayName ||
          currentWorkspaceLeads?.members[currentWorkspaceLeads?.uid]?.email
        );
      case Platforms.SIMPLY_LINKS:
        return (
          (currentWorkspaceLinks?.id &&
            workspaceReviewMap[currentWorkspaceLinks?.id]?.companyName) ||
          currentWorkspaceLinks?.members[currentWorkspaceLinks?.uid]?.displayName ||
          currentWorkspaceLinks?.members[currentWorkspaceLinks?.uid]?.email
        );
      case Platforms.SIMPLY_BOOKING:
        return (
          (currentWorkspaceBookings?.id &&
            workspaceReviewMap[currentWorkspaceBookings?.id]?.companyName) ||
          currentWorkspaceBookings?.members[currentWorkspaceBookings?.uid]?.displayName ||
          currentWorkspaceBookings?.members[currentWorkspaceBookings?.uid]?.email
        );

      default:
        console.error(`selectCurrentPlatformWorkspaceId: Platform not found: platform=${platform}`);
        return null;
    }
  }
);
export const selectHasCurrentPlatformPremium = createSelector(
  selectWorkspaceReview,
  selectWorkspaceFlow,
  selectWorkspaceLeads,
  platform,
  (currentWorkspaceReview, currentWorkspaceFlow, currentWorkspaceLeads, platform) => {
    switch (platform) {
      case Platforms.SIMPLY_REVIEW:
        return currentWorkspaceReview?.subscription === Subscription.PREMIUM;

      case Platforms.SIMPLY_FLOW:
        return currentWorkspaceFlow?.subscription === Subscription.PREMIUM;

      case Platforms.SIMPLY_LEADS:
        return currentWorkspaceLeads?.subscription === Subscription.PREMIUM;

      case Platforms.SIMPLY_LINKS:
        return true; // currentWorkspaceLinks?.subscription === Subscription.PREMIUM;

      case Platforms.SIMPLY_BOOKING:
        return true; // currentWorkspaceLinks?.subscription === Subscription.PREMIUM;

      default:
        console.error(`selectHasCurrentPlatformPremium: Platform not found: platform=${platform}`);
    }
  }
);
export const selectHasCurrentPlatformWorkspaces = createSelector(
  selectWorkspaceReviewHasWorskpaces,
  selectWorkspaceFlowHasWorskpaces,
  selectWorkspaceLeadsHasWorkspaces,
  selectWorkspaceLinksHasWorkspaces,
  selectWorkspaceBookingsHasWorkspaces,
  platform,
  (
    hasWorkspaceReview,
    hasWorkspaceFlow,
    hasWorkspaceLeads,
    hasWorkspaceLinks,
    hasWorkspaceBookings,
    platform
  ) => {
    switch (platform) {
      case Platforms.SIMPLY_REVIEW:
        return hasWorkspaceReview;

      case Platforms.SIMPLY_FLOW:
        return hasWorkspaceFlow;

      case Platforms.SIMPLY_LEADS:
        return hasWorkspaceLeads;

      case Platforms.SIMPLY_LINKS:
        return hasWorkspaceLinks;

      case Platforms.SIMPLY_BOOKING:
        return hasWorkspaceBookings;

      default:
        console.error(`selectHasCurrentPlatformLoaded: Platform not found: platform=${platform}`);
    }
  }
);
export const selectHasCurrentPlatformLoaded = createSelector(
  selectWorkspaceReviewHasLoaded,
  selectWorkspaceFlowHasLoaded,
  selectWorkspaceLeadsHasLoaded,
  selectWorkspaceLinksHasLoaded,
  selectWorkspaceBookingsHasLoaded,
  platform,
  (
    hasWorkspaceReviewLoaded,
    hasWorkspaceFlowLoaded,
    hasWorkspaceLeadsLoaded,
    hasWorkspaceLinksLoaded,
    hasWorkspaceBookingsLoaded,
    platform
  ) => {
    switch (platform) {
      case Platforms.SIMPLY_REVIEW:
        return hasWorkspaceReviewLoaded;

      case Platforms.SIMPLY_FLOW:
        return hasWorkspaceFlowLoaded;

      case Platforms.SIMPLY_LEADS:
        return hasWorkspaceLeadsLoaded;

      case Platforms.SIMPLY_LINKS:
        return hasWorkspaceLinksLoaded;

      case Platforms.SIMPLY_BOOKING:
        return hasWorkspaceBookingsLoaded;

      default:
        console.error(`selectHasCurrentPlatformLoaded: Platform not found: platform=${platform}`);
    }
  }
);

export const selectCurrentPlatformIsTrailing = createSelector(
  selectWorkspaceReview,
  selectWorkspaceFlow,
  selectWorkspaceLeads,
  selectWorkspaceLinks,
  selectWorkspaceBookings,
  platform,
  (
    currentWorkspaceReview,
    currentWorkspaceFlow,
    currentWorkspaceLeads,
    currentWorkspaceLinks,
    currentWorkspaceBookings,
    platform
  ) => {
    switch (platform) {
      case Platforms.SIMPLY_REVIEW:
        return getTrial(currentWorkspaceReview?.trialStart?.toDate());

      case Platforms.SIMPLY_FLOW:
        return getTrial(currentWorkspaceFlow?.trialStart?.toDate());

      case Platforms.SIMPLY_LEADS:
        return getTrial(currentWorkspaceLeads?.trialStart?.toDate());

      case Platforms.SIMPLY_LINKS:
        return getTrial(currentWorkspaceLinks?.trialStart?.toDate());

      case Platforms.SIMPLY_BOOKING:
        return getTrial(currentWorkspaceBookings?.trialStart?.toDate());

      default:
        console.error(`selectCurrentPlatformIsTrailing: Platform not found: platform=${platform}`);
    }
  }
);

function getTrial(trialStart?: Date) {
  if (!trialStart) return null;
  const monthFromCreation = addDays(trialStart, 30);

  return {
    trialStart,
    trialEndDate: monthFromCreation,
    // if trialend is in the future, then the user is still trialing
    isTrialing: monthFromCreation.getTime() >= new Date().getTime(),
  };
}
