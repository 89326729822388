import { httpsCallable } from 'firebase/functions';
import { DB, Functions } from '../../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';

import {
  IconButton,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import Iconify from '../../../components/Iconify';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Platforms } from 'src/redux/slices/platform';
import { resolvePlatformToProductCollection } from '../../../api/index';
import { collection, doc, setDoc } from 'firebase/firestore';

export default function UpgradeUser({
  userId,
  product,
  displayName,
}: {
  userId: string;
  product: Platforms;
  displayName: string;
}) {
  const [loading, setloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectUserId, setSelectUserId] = useState<string | null>(null);

  const handleSubmit = async (userId: string, product: Platforms) => {
    try {
      const collectionPath = resolvePlatformToProductCollection(product);
      if (!userId || !product || !collectionPath) return alert('Something is not working!');
      setloading(true);

      const productRef = doc(collection(DB, collectionPath), userId);
      await setDoc(productRef, { subscription: 'premium' }, { merge: true });

      enqueueSnackbar('User upgraded', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });

      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const handleClose = () => {
    setSelectUserId(null);
  };

  const handleOk = () => {
    handleSubmit(userId, product);
    handleClose();
  };

  return (
    <>
      <Tooltip title={`Upgrade ${displayName} to premium plan`}>
        <LoadingButton
          loading={loading}
          variant="outlined"
          startIcon={<Iconify icon="material-symbols:trending-up-rounded" />}
          onClick={() => setSelectUserId(userId)}
        >
          Upgrade
        </LoadingButton>
      </Tooltip>
      <Dialog
        open={Boolean(selectUserId)}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">🚨You are about to Upgrade🚨</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Upgrade {displayName} to premium plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleOk} variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
