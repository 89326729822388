import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intl, InviteStatus, LanguageCode, RequestState } from '../../enum';
import type { RootState } from '../store';

export interface Invite {
  id: string;
  name: string;
  email: string;
  countryCode: string;
  phone: number;
  status: InviteStatus;
  createTime: Date;
  updateTime: Date;
  linkId: string;
  shortLink: string;
  sendEmail: boolean;
  sendSms: boolean;
  language: LanguageCode;
}

// Define a type for the slice state
interface InviteState {
  list: Invite[];
  loading: RequestState;
}

// Define the initial state using that type
const initialState: InviteState = {
  list: [],
  loading: RequestState.IDLE,
};

export const inviteSlice = createSlice({
  name: 'invites',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setInviteLoadingState: (state) => ({
      ...state,
      loading: RequestState.PENDING,
    }),
    setInvites: (state, action: PayloadAction<Invite[]>) => ({
      ...state,
      list: action.payload,
      loading: RequestState.RESOLVED,
    }),
  },
});

export const { setInvites, setInviteLoadingState } = inviteSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectInvites = (state: RootState) => state.invites.list;

export default inviteSlice.reducer;
