import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import { hex2rgb } from '../../utils/colors';

const ProductButton = styled(Button)<ButtonProps & { backgroundColor: any; flatButton?: boolean }>(
  (props: any) => {
    const { theme, backgroundColor, flatButton } = props;
    if (!backgroundColor) {
      return {};
    }
    const [r, g, b] = hex2rgb(backgroundColor);
    if (props.variant === 'contained') {
      return {
        color: theme.palette.getContrastText(backgroundColor),
        backgroundColor,
        boxShadow: flatButton ? 'none' : `0 8px 16px 0 rgb(${r} ${g} ${b} / 24%)`,
        '&:hover': {
          backgroundColor,
        },
      };
    }
    if (props.variant === 'outlined') {
      return {
        color: backgroundColor,
        borderColor: backgroundColor,
        '&:hover': {
          borderColor: backgroundColor,
          backgroundColor: flatButton ? 'none' : `rgba(${r}, ${g}, ${b}, 0.08)`,
        },
      };
    }
    return {
      color: backgroundColor,
      // borderColor: customColor,
      '&:hover': {
        borderColor: backgroundColor,
        backgroundColor: flatButton ? 'none' : `rgba(${r}, ${g}, ${b}, 0.08)`,
      },
    };
  }
);

export default ProductButton;
