import { useEffect, useState } from 'react';
import { onSnapshot, doc, query, collection, where, limit, getDocs } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch } from '../redux/store';
import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB, getStripeRole } from '../contexts/FirebaseContext';
import { setUser, UserDoc } from '../redux/slices/userSlice';
import { PublicConfig } from 'src/redux/slices/publicConfig';

export default function useUser() {
  const dispatch = useDispatch();
  const [isLoadingUser, setisLoadingUser] = useState(true);
  const { user } = useAuth();
  const userId = user?.id;
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = doc(DB, getCollection[Collection.USERS](), userId);
      unsubscribe = onSnapshot(q, (doc) => {
        const userData = {
          ...doc.data(),
        } as UserDoc;
        async function setUserData() {
          // const stripeRole = await getStripeRole();
          dispatch(setUser({ ...user, ...userData /* stripeRole */ }));
          setisLoadingUser(false);
        }
        setUserData();
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingUser };
}

export function useUserLocally(userId?: string) {
  const [isLoadingUser, setisLoadingUser] = useState(true);
  const [userLocally, setUserLocally] = useState<any>(null);
  const [configLocally, setConfigLocally] = useState<any>(null);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = doc(DB, getCollection[Collection.USERS](), userId);
      unsubscribe = onSnapshot(q, (doc) => {
        const userData = {
          ...doc.data(),
        } as UserDoc;

        // const stripeRole = await getStripeRole();
        setUserLocally(userData);
        setisLoadingUser(false);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);

  useEffect(() => {
    let unsubscribe: () => void;
    const getConfig = async () => {
      const q = query(
        collection(DB, getCollection[Collection.PUBLIC]()),
        where('userId', '==', userId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const [publicConfig] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as PublicConfig),
      }));
      setConfigLocally(publicConfig);
    };
    if (userId) {
      getConfig();
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { configLocally, userLocally, isLoadingUser };
}
