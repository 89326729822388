import React from 'react';
import { REVIEW } from 'src/theme/palette';

export default function SimplyReviewIcon({ width = 12, color = REVIEW.main }) {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 26 66" style={{ width }}>
      <g>
        <g>
          <g>
            <path
              fill={color}
              d="M22,20.4h-5.6c-2,0-3.6-1.6-3.6-3.6v-5.6c0-2,1.6-3.6,3.6-3.6H22c2,0,3.6,1.6,3.6,3.6v5.6C25.6,18.8,24,20.4,22,20.4z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M9.2,33.2H3.6c-2,0-3.6-1.6-3.6-3.6L0,24c0-2,1.6-3.6,3.6-3.6h5.6c2,0,3.6,1.6,3.6,3.6v5.6C12.8,31.6,11.2,33.2,9.2,33.2
				z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M22,46h-5.6c-2,0-3.6-1.6-3.6-3.6v-5.6c0-2,1.6-3.6,3.6-3.6H22c2,0,3.6,1.6,3.6,3.6v5.6C25.6,44.4,24,46,22,46z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M9.2,58.8H3.6c-2,0-3.6-1.6-3.6-3.6v-5.6c0-2,1.6-3.6,3.6-3.6h5.6c2,0,3.6,1.6,3.6,3.6v5.6C12.8,57.2,11.2,58.8,9.2,58.8
				z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M12.8,49.1c0,0-0.1-3.1,3.7-3.1s-3.7-3.4-3.7-3.4S12.5,46,9.2,46S11,50.2,12.8,49.1z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M12.8,23.5c0,0-0.1-3.1,3.7-3.1S12.8,17,12.8,17s-0.3,3.4-3.6,3.4S11,24.6,12.8,23.5z"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M12.9,38.2c0,0-0.1-5-3.9-5s3.7-3.4,3.7-3.4s0.3,3.4,3.6,3.4S14.7,39.3,12.9,38.2z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
