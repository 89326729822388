import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestState } from '../../enum';
import { UserDoc } from './userSlice';

export interface UserDocWithId extends UserDoc {
  id: string;
}

// Define a type for the slice state
interface UserState {
  users: UserDocWithId[];
  requestState: RequestState;
}

// Define the initial state using that type
const initialState: UserState = {
  users: [],
  requestState: RequestState.IDLE,
};

export const usersSlice = createSlice({
  name: 'users',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoading: (state) => ({
      ...state,
      requestState: RequestState.PENDING,
    }),
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUsers: (state, action: PayloadAction<UserDocWithId[]>) => ({
      ...state,
      users: action.payload,
      requestState: RequestState.RESOLVED,
    }),
    setError: (state, action: PayloadAction<string>) => ({
      ...state,
      requestState: RequestState.REJECTED,
    }),
  },
});

export const { setUsers, setError, setLoading } = usersSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default usersSlice.reducer;
