import React, { Fragment } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from '../redux/store';
import {
  Grid,
  Box,
  InputAdornment,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import RHFTextField from '../components/hook-form/RHFTextField';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../components/hook-form';
import { useSnackbar } from 'notistack';
import { DB } from '../contexts/FirebaseContext';
import Iconify from '../components/Iconify';
import { InviteMembers } from '../@types/blog';
import { DialogActions } from '@mui/material';
import { doc, collection, writeBatch } from 'firebase/firestore';
import { TeamInviteStatus } from '../redux/slices/teamInviteSlice';
import { Collection } from '../enum';
import { getCollection } from '../api';
import uuidv4 from '../utils/uuidv4';
import { selectCurrentPlatformWorkspace } from '../redux/selectors/workspaces';
import { WorkspaceReview } from '../redux/slices/workspaceReviewsSlice';

const nameGridSize = 5;
const emailGridSize = 6;

export default function TeamMemberInviteForm({ handleClose }: { handleClose: VoidFunction }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector((state) => state.user.id);
  const workspace = useSelector(selectCurrentPlatformWorkspace);
  const workspaceId = workspace?.id;
  const platform = useSelector((state) => state.user.platform);
  const companyName =
    (workspace as WorkspaceReview)?.companyName ||
    `${workspace?.members[workspace.uid]?.displayName} team`;
  const InviteMemberSchema = Yup.object().shape({
    invites: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('invites.validation.name') as string),
        email: Yup.string()
          .email(t('invites.validation.email') as string)
          .required(t('invites.validation.email') as string),
      })
    ),
  });
  const defaultValues = {
    teamInvites: [
      {
        name: '',
        email: '',
      },
    ],
  };

  const methods = useForm<InviteMembers>({
    resolver: yupResolver(InviteMemberSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'teamInvites', // unique name for your Field Array
  });
  // const values = watch();

  const onSubmit = async (data: InviteMembers) => {
    try {
      // // await new Promise((resolve) => setTimeout(resolve, 500));
      // const inviteType = resolveInviteType(type);
      if (!userId || !workspaceId || !platform) {
        return alert(t('general.error.default'));
      }
      const batch = writeBatch(DB);
      const inviteId = uuidv4();
      data.teamInvites.forEach((invite) => {
        const payload = {
          inviteId,
          createTime: new Date(),
          email: invite.email,
          displayName: invite.name,
          status: TeamInviteStatus.PENDING,
          platform,
          workspaceId,
          companyName: companyName || 'team',
          invitedBy: userId,
        };

        batch.set(doc(collection(DB, getCollection[Collection.TEAM_INVITES]())), payload);
      });

      await batch.commit();
      enqueueSnackbar(t('invites.invitesSent'), { variant: 'success' });
      reset();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <DialogTitle>{t('team.inviteForm.title')}</DialogTitle>
      <DialogContent>
        <FormProvider
          style={{ height: 'calc(100% - 64px)' }}
          methods={methods}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={2}
            sx={{ height: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sx={{ height: '100%' }}>
              <Box sx={{ p: 0, pt: 4, position: 'relative', height: '100%' }}>
                <Box sx={{ py: 2 }}>
                  <Grid container spacing={2}>
                    {fields.map((item, i) => (
                      <Fragment key={item.id}>
                        <Grid item xs={nameGridSize} md={nameGridSize}>
                          <RHFTextField
                            // {...register(`invites.${i}.name`)}
                            name={`teamInvites.${i}.name`}
                            label={t('invites.form.fields.name')}
                            autoFocus={i === 0}
                          />
                        </Grid>
                        <Grid item xs={emailGridSize} md={emailGridSize}>
                          <RHFTextField
                            // {...register(`invites.${i}.email`)}
                            name={`teamInvites.${i}.email`}
                            label={t('invites.form.fields.email')}
                          />
                        </Grid>

                        <Grid item xs={1} md={1} alignItems="center" justifyContent="center">
                          <Tooltip title={t('invites.form.deleteRow') as string}>
                            <Stack
                              sx={{
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Box>
                                <IconButton aria-label="delete" onClick={() => remove(i)}>
                                  <Iconify icon="ep:remove" />
                                </IconButton>
                              </Box>
                            </Stack>
                          </Tooltip>
                        </Grid>
                      </Fragment>
                    ))}
                    <Grid item xs={12} md={12}>
                      {/* <Stack flexDirection="row" justifyContent="space-between"></Stack> */}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<Iconify icon={'eva:plus-fill'} />}
          onClick={() => append({ name: '', email: '' })}
        >
          {t('invites.form.addMore')}
        </Button>

        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          disabled={isSubmitting}
          // type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {t('invites.form.invite')}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
