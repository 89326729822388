import React from 'react';

export default function SimplyLinksLogo({ width = 84, color = '#333' }) {
  return (
    <svg viewBox="0 0 385 165" fill="none" style={{ width }}>
      <g clipPath="url(#clip0_2102_4894)">
        <path
          d="M136.7 69C133.3 67.2 130.6 64.7 128.6 61.4C126.6 58.1 125.5 54.4 125.3 50.1H136.8C136.9 52.5 137.5 54.6 138.5 56.4C139.5 58.2 140.9 59.5 142.6 60.5C144.3 61.5 146.3 62 148.5 62H153.4C155.1 62 156.7 61.6 158.4 60.8C160 60 161.4 58.9 162.5 57.3C163.6 55.8 164.1 53.9 164.1 51.7C164.1 49.4 163.4 47.6 162 46.1C160.6 44.7 159 43.5 157 42.7C155 41.9 152.3 41 148.7 39.9C144 38.6 140.2 37.3 137.2 36C134.2 34.7 131.7 32.7 129.6 30C127.5 27.3 126.4 23.7 126.4 19.2C126.4 15.4 127.4 12 129.3 9.1C131.2 6.2 133.7 3.9 136.9 2.3C140 0.8 143.5 0 147.2 0H152.3C156.2 0 159.6 0.8 162.7 2.3C165.8 3.8 168.3 6 170.3 9C172.3 12 173.5 15.6 173.9 19.9H162.5C162.1 16.6 161 14.1 159.2 12.4C157.4 10.7 155.1 9.9 152.1 9.9H147.3C145.7 9.9 144.2 10.3 142.7 11C141.3 11.7 140.1 12.8 139.2 14.1C138.3 15.4 137.9 17 137.9 18.8C137.9 21 138.6 22.8 139.9 24.2C141.2 25.6 142.8 26.6 144.7 27.4C146.6 28.2 149.3 29 152.9 30C157.6 31.3 161.5 32.6 164.5 33.9C167.5 35.2 170.1 37.3 172.2 40.1C174.3 42.9 175.4 46.7 175.4 51.5C175.4 55.6 174.4 59.3 172.4 62.4C170.4 65.5 167.7 67.9 164.3 69.6C160.9 71.3 157.1 72.1 153 72.1H148C143.9 71.7 140.1 70.8 136.7 69Z"
          fill={color}
        />
        <path d="M179.1 20.4H189.7V70.9H179.1V20.4Z" fill={color} />
        <path
          d="M196.2 20.4H206.7V28C207.8 25.7 209.6 23.7 211.9 22C214.2 20.4 216.6 19.5 219 19.5H222.8C226.1 19.5 228.8 20.2 230.9 21.7C232.9 23.2 234.7 25.3 236.2 28.1C237.5 25.6 239.5 23.6 242 21.9C244.5 20.3 247.1 19.4 249.7 19.4H253.5C256.5 19.4 259.2 20.2 261.6 21.9C264 23.5 265.8 25.8 267.1 28.6C268.4 31.4 269.1 34.6 269.1 38.2V70.7H258.6V40.3C258.6 38.2 258.3 36.2 257.6 34.5C256.9 32.8 256 31.5 254.9 30.5C253.8 29.5 252.4 29 250.9 29H247.7C245.9 29 244.3 29.7 242.8 31C241.3 32.3 240.2 34.2 239.3 36.5C238.5 38.8 238 41.3 238 43.9V70.9H227.5V40.3C227.5 38.2 227.2 36.2 226.5 34.5C225.9 32.8 225 31.4 223.8 30.5C222.6 29.6 221.3 29.1 219.8 29.1H216.5C214.7 29.1 213.1 29.8 211.6 31.2C210.1 32.6 209 34.4 208.1 36.7C207.3 39 206.8 41.4 206.8 44V71H196.3V20.4H196.2Z"
          fill={color}
        />
        <path
          d="M311.8 23.2C315 25.6 317.6 28.7 319.4 32.7C321.2 36.7 322.2 41 322.2 45.7C322.2 50.5 321.3 54.9 319.5 58.9C317.7 62.9 315.2 66 311.9 68.3C308.7 70.6 304.9 71.8 300.7 71.8H297.6C295.4 71.8 293.1 71.1 290.8 69.7C288.5 68.3 286.6 66.7 285.3 64.7V82.3H274.7V20.4H285.3V26.7C286.6 24.7 288.5 23 290.9 21.6C293.3 20.2 295.6 19.5 298 19.5H301.1C305 19.6 308.5 20.8 311.8 23.2ZM290.3 30.9C288.5 32.3 287.1 34.3 286.1 36.9C285.1 39.5 284.5 42.4 284.5 45.7C284.5 49 285 51.9 286.1 54.5C287.1 57.1 288.5 59.1 290.3 60.5C292.1 61.9 294.1 62.7 296.3 62.7H299.8C301.8 62.7 303.7 62 305.5 60.5C307.3 59.1 308.8 57 309.9 54.4C311 51.8 311.6 48.9 311.6 45.7C311.6 42.5 311 39.6 309.9 37C308.8 34.4 307.3 32.4 305.5 30.9C303.7 29.5 301.8 28.7 299.8 28.7H296.3C294 28.7 292 29.5 290.3 30.9Z"
          fill={color}
        />
        <path d="M325.7 9.79999H336.2V70.9H325.7V9.79999Z" fill={color} />
        <path
          d="M354.8 67.8C354.9 68.4 355.1 69 355.2 69.7C355.3 70.4 355.3 71 355.3 71.7C355.3 73.1 355 74.7 354.5 76.4L351.9 82.4H362.1C362.6 81.4 363 80.3 363.5 79.1L384.7 20.4H373.9L363.7 50C363 52.3 362.2 54.9 361.4 57.8H361.1C360.4 54.9 359.6 52.3 358.8 49.9L348.4 20.4H337.6L353.6 64.4C354.1 65.7 354.5 66.8 354.8 67.8Z"
          fill={color}
        />
        <path
          d="M127.8 94.2H136.4V156.8H169.3V164.3H127.7V94.2H127.8Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M173.5 94.8H182.6V105H173.5V94.8ZM174.1 113.8H182.1V164.3H174.1V113.8Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M191.9 113.8H199.9V122.3C202.3 117.2 207.9 113 213.7 113H218C227.4 113 234.2 121 234.2 132.2V164.2H226.2V132.9C226.2 125.6 222.3 120.2 216.5 120.2H212.5C204.8 120.2 199.9 128.6 199.9 137.1V164.2H191.9V113.8Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M243.5 164.3V93.1H251.5V135.1L273.6 113.9H284.1L263.9 133L286 164.2H276.4L258 138.9L251.5 145.1V164.2H243.5V164.3Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M36.2 71.8L26 82C22.4 85.6 16.5 85.6 12.9 82L2.7 71.8C-0.9 68.2 -0.9 62.3 2.7 58.7L12.9 48.5C16.5 44.9 22.4 44.9 26 48.5L36.2 58.7C39.8 62.3 39.8 68.2 36.2 71.8Z"
          fill={color}
        />
        <path
          d="M36.2 118.3L26 128.5C22.4 132.1 16.5 132.1 12.9 128.5L2.7 118.3C-0.9 114.7 -0.9 108.8 2.7 105.2L12.9 95C16.5 91.4 22.4 91.4 26 95L36.2 105.2C39.8 108.8 39.8 114.7 36.2 118.3Z"
          fill={color}
        />
        <path
          d="M25.4 94.5C25.4 94.5 19.2 88.7 26.2 81.7C33.2 74.7 13.2 82.2 13.2 82.2C13.2 82.2 18.9 89 12.9 95C7 101 24.1 99.8 25.4 94.5Z"
          fill={color}
        />
        <path
          d="M71.9 95.4L82.1 105.6C85.7 109.2 85.7 115.1 82.1 118.7L71.9 128.9C68.3 132.5 62.4 132.5 58.8 128.9L48.6 118.7C45 115.1 45 109.2 48.6 105.6L58.8 95.4C62.4 91.8 68.3 91.8 71.9 95.4Z"
          fill={color}
        />
        <path
          d="M71.9 48.4L82.1 58.6C85.7 62.2 85.7 68.1 82.1 71.7L71.9 81.9C68.3 85.5 62.4 85.5 58.8 81.9L48.6 71.7C45 68.1 45 62.2 48.6 58.6L58.8 48.4C62.4 44.8 68.3 44.8 71.9 48.4Z"
          fill={color}
        />
        <path
          d="M48.6 105.6C48.6 105.6 42.6 112.4 35.5 104.5C28.9 97.1 35.8 118.3 35.8 118.3C35.8 118.3 42.6 112.6 48.5 118.5C54.4 124.4 53.9 107 48.6 105.6Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2102_4894">
          <rect width="384.7" height="165" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
