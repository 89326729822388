// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import i18n from 'i18next';
import Iconify from '../../../components/Iconify';
import { Platforms } from '../../../redux/slices/platform';
import { isEarlyUser } from '../../../config/featureFlags';
import { Badge } from '@mui/material';
import InvitePendingBadge from '../../../components/badges/InvitePendingBadge';
import MissingAutomationSetup from '../../../components/badges/MissingAutomation';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: <Iconify icon="mingcute:dashboard-3-line" />,
  booking: getIcon('ic_booking'),
  invites: <Iconify icon="tabler:send" />,
  reviews: <Iconify icon="material-symbols:reviews-outline-rounded" />,
  integrations: <Iconify icon="material-symbols:widgets-outline-rounded" />,
  imageModule: <Iconify icon="bi:layout-split" />,
  videoFlow: <Iconify icon="ic:baseline-featured-video" sx={{ transform: 'rotate(180deg)' }} />,
  bookingSchedules: <Iconify icon="carbon:calendar-add" />,
  bookingList: <Iconify icon="material-symbols-light:event" />,
  companySettings: <Iconify icon="akar-icons:settings-horizontal" />,
  social: <Iconify icon="typcn:social-instagram" />,
  videoCustomisation: <Iconify icon="mdi:subtitles-outline" />,
  reviewAutomations: <Iconify icon="mdi:robot-happy-outline" />,
  //
  leadsForm: <Iconify icon="solar:checklist-minimalistic-outline" />,
  leadSubmissions: <Iconify icon="icon-park-outline:sales-report" />,
  leadCustomisePage: <Iconify icon="mdi:user-box" />,
  // LINKS
  linksCustomise: <Iconify icon="material-symbols:inbox-customize-outline-sharp" />,
};

export const navConfigReviewExternal = (slug?: string) => [
  {
    subheader: '', // general
    items: [
      {
        title: i18n.t('nav.reviewPage'),
        path: `/r/${slug}`,
        // icon: ICONS.dashboard,
        rightIcon: 'mdi:page-next-outline',
      },
      {
        title: i18n.t('nav.companyPage'),
        path: `/c/${slug}`,
        // icon: ICONS.reviews,
        // joyrideId: 'dashboard-nav-reviews',
        rightIcon: 'fluent:preview-link-16-regular',
      },
      // {
      //   title: i18n.t('nav.beforeAfter'),
      //   path: PATH_DASHBOARD.general.imageModule,
      //   icon: ICONS.imageModule,
      // },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
];
export const navConfigBookingExternal = (slug?: string) => [
  // {
  //   subheader: '', // general
  //   items: [
  //     {
  //       title: i18n.t('nav.reviewPage'),
  //       path: `/b/${slug}`,
  //       // icon: ICONS.dashboard,
  //       rightIcon: 'mdi:page-next-outline',
  //     },
  //     // {
  //     //   title: i18n.t('nav.beforeAfter'),
  //     //   path: PATH_DASHBOARD.general.imageModule,
  //     //   icon: ICONS.imageModule,
  //     // },
  //     // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },
];
export const navConfigChatExternal = (slug?: string) => [
  // {
  //   subheader: '', // general
  //   items: [
  //     {
  //       title: i18n.t('nav.reviewPage'),
  //       path: `/b/${slug}`,
  //       // icon: ICONS.dashboard,
  //       rightIcon: 'mdi:page-next-outline',
  //     },
  //     // {
  //     //   title: i18n.t('nav.beforeAfter'),
  //     //   path: PATH_DASHBOARD.general.imageModule,
  //     //   icon: ICONS.imageModule,
  //     // },
  //     // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },
];
export const navConfigLeadsExternal = (slug?: string) => [
  {
    subheader: '', // general
    items: [
      {
        title: i18n.t('nav.leadsPage'),
        path: `/l/${slug}`,
        // icon: ICONS.dashboard,
        rightIcon: 'mdi:page-next-outline',
      },

      // {
      //   title: i18n.t('nav.beforeAfter'),
      //   path: PATH_DASHBOARD.general.imageModule,
      //   icon: ICONS.imageModule,
      // },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
];
export const navConfigLinksExternal = (slug?: string) => [
  {
    subheader: '', // general
    items: [
      {
        title: i18n.t('nav.linkPage'),
        path: `/i/${slug}`,
        // icon: ICONS.dashboard,
        rightIcon: 'mdi:page-next-outline',
      },

      // {
      //   title: i18n.t('nav.beforeAfter'),
      //   path: PATH_DASHBOARD.general.imageModule,
      //   icon: ICONS.imageModule,
      // },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
];

export const navConfigReview = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu', // general
    items: [
      { title: 'nav.dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      {
        title: 'nav.invites',
        path: PATH_DASHBOARD.general.invites,
        icon: ICONS.invites,
        countComponent: () => <InvitePendingBadge />,
      },
      {
        title: 'nav.reviews',
        path: PATH_DASHBOARD.general.reviews,
        icon: ICONS.reviews,
        joyrideId: 'dashboard-nav-reviews',
      },
      {
        title: 'nav.integrations',
        path: PATH_DASHBOARD.general.integrations,
        icon: ICONS.integrations,
        joyrideId: 'dashboard-nav-integrations',
      },
      {
        title: 'nav.social',
        path: PATH_DASHBOARD.general.social,
        icon: ICONS.social,
        joyrideId: 'dashboard-nav-social',
      },
      {
        title: 'nav.videoCustomisation',
        path: PATH_DASHBOARD.general.videoCustomisation,
        icon: ICONS.videoCustomisation,
      },
      {
        title: 'nav.automations',
        path: PATH_DASHBOARD.general.reviewAutomations,
        icon: ICONS.reviewAutomations,
        countComponent: () => <MissingAutomationSetup />,
      },
      // {
      //   title: 'nav.companySettings',
      //   path: '',
      //   icon: ICONS.companySettings,
      //   joyrideId: 'dashboard-nav-customise',
      // },
      // {
      //   title: i18n.t('nav.beforeAfter'),
      //   path: PATH_DASHBOARD.general.imageModule,
      //   icon: ICONS.imageModule,
      // },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         // { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // MANAGEMENT : E-COMMERCE
  //     {
  //       title: 'e-commerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
  //       ],
  //     },

  //     // MANAGEMENT : BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
  //       ],
  //     },
  //   ],
  // },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];
export const navConfigPics = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu', // general
    items: [
      { title: 'nav.dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

      {
        title: 'nav.beforeAfter',
        path: PATH_DASHBOARD.general.imageEditor,
        icon: ICONS.imageModule,
        joyrideId: 'dashboard-nav-reviews',
      },
    ],
  },
];
export const navConfigFlow = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu', // general
    items: [
      { title: 'nav.dashboard', path: PATH_DASHBOARD.general.appFlow, icon: ICONS.dashboard },

      {
        title: 'nav.videoFlow',
        path: PATH_DASHBOARD.general.videoFlow,
        icon: ICONS.videoFlow,
        joyrideId: 'dashboard-nav-reviews',
      },
    ],
  },
];
export const navConfigBooking = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu', // general
    items: [
      { title: 'nav.dashboard', path: PATH_DASHBOARD.general.appBooking, icon: ICONS.dashboard },

      {
        title: 'nav.bookingPages',
        path: PATH_DASHBOARD.general.bookingSchedules,
        icon: ICONS.bookingSchedules,
      },
      {
        title: 'nav.bookingList',
        path: PATH_DASHBOARD.general.bookingList,
        icon: ICONS.bookingList,
      },
    ],
  },
];
export const navConfigLeads = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu', // general
    items: [
      { title: 'nav.dashboard', path: PATH_DASHBOARD.general.appLeads, icon: ICONS.dashboard },

      {
        title: 'nav.leadsForm',
        path: PATH_DASHBOARD.general.leadsForm,
        icon: ICONS.leadsForm,
        // joyrideId: 'dashboard-nav-reviews',
      },
      {
        title: 'nav.leadSubmissions',
        path: PATH_DASHBOARD.general.leadSubmissions,
        icon: ICONS.leadSubmissions,
        // joyrideId: 'dashboard-nav-reviews',
      },
      {
        title: 'nav.leadCustomisePage',
        path: PATH_DASHBOARD.general.leadCustomisePage,
        icon: ICONS.leadCustomisePage,
        // joyrideId: 'dashboard-nav-reviews',
      },
      {
        title: 'nav.leadEmbedOnWebsite',
        path: PATH_DASHBOARD.general.leadEmbedOnWebsite,
        icon: ICONS.integrations,
        // joyrideId: 'dashboard-nav-reviews',
      },
    ],
  },
];
export const navConfigLinks = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu', // general
    items: [
      // { title: 'nav.dashboard', path: PATH_DASHBOARD.general.appLeads, icon: ICONS.dashboard },

      {
        title: 'nav.linksCustomisePage',
        path: PATH_DASHBOARD.general.linksCustomisePage,
        icon: ICONS.linksCustomise,
        // joyrideId: 'dashboard-nav-reviews',
      },
      // {
      //   title: 'nav.leadSubmissions',
      //   path: PATH_DASHBOARD.general.leadSubmissions,
      //   icon: ICONS.leadSubmissions,
      //   // joyrideId: 'dashboard-nav-reviews',
      // },
      // {
      //   title: 'nav.leadCustomisePage',
      //   path: PATH_DASHBOARD.general.leadCustomisePage,
      //   icon: ICONS.leadCustomisePage,
      //   // joyrideId: 'dashboard-nav-reviews',
      // },
    ],
  },
];

function filterEarlyUserNav(item: { title: string }, userId: string) {
  if (!isEarlyUser(userId)) {
    return !['nav.social'].includes(item.title);
  }
  return true;
}

export function getNavigation(platform: Platforms, userId: string) {
  switch (platform) {
    case Platforms.SIMPLY_REVIEW:
      return {
        navConfig: [
          {
            ...navConfigReview[0],
            items: navConfigReview[0].items.filter((item) => filterEarlyUserNav(item, userId)),
          },
        ],
        navConfigExternal: navConfigReviewExternal,
      };
    case Platforms.SIMPLY_FLOW:
      return {
        navConfig: navConfigFlow,
        navConfigExternal: () => [],
      };
    case Platforms.SIMPLY_LEADS:
      return {
        navConfig: navConfigLeads,
        navConfigExternal: navConfigLeadsExternal,
      };
    case Platforms.SIMPLY_LINKS:
      return {
        navConfig: navConfigLinks,
        navConfigExternal: navConfigLinksExternal,
      };
    case Platforms.SIMPLY_CONTENT:
      return {
        navConfig: navConfigLinks,
        navConfigExternal: navConfigLinksExternal,
      };
    case Platforms.SIMPLY_BOOKING:
      return {
        navConfig: navConfigBooking,
        navConfigExternal: navConfigBookingExternal,
      };
    case Platforms.SIMPLY_CHAT:
      return {
        navConfig: [],
        navConfigExternal: navConfigChatExternal,
      };
    default:
      return {
        navConfig: navConfigReview,
        navConfigExternal: navConfigReviewExternal,
      };
  }
}
