import React from 'react';
import { LINKS } from 'src/theme/palette';

export default function SimplyLinksIcon({ width = 12, color = LINKS.main }) {
  return (
    <svg viewBox="0 0 154 155" fill="none" style={{ width }}>
      <g filter="url(#filter0_d_2102_6171)">
        <path
          d="M65.9488 44.6648L48.4936 62.1199C42.3329 68.2806 32.2363 68.2806 26.0757 62.1199L8.62049 44.6648C2.45984 38.5041 2.45984 28.4075 8.62049 22.2468L26.0757 4.79163C32.2363 -1.36902 42.3329 -1.36902 48.4936 4.79163L65.9488 22.2468C72.1094 28.4075 72.1094 38.5041 65.9488 44.6648Z"
          fill={color}
        />
        <path
          d="M65.9488 124.24L48.4936 141.695C42.3329 147.856 32.2363 147.856 26.0757 141.695L8.62049 124.24C2.45984 118.079 2.45984 107.983 8.62049 101.822L26.0757 84.3667C32.2363 78.2061 42.3329 78.2061 48.4936 84.3667L65.9488 101.822C72.1094 107.983 72.1094 118.079 65.9488 124.24Z"
          fill={color}
        />
        <path
          d="M47.4669 83.511C47.4669 83.511 36.8569 73.5855 48.8359 61.6065C60.815 49.6275 26.5891 62.4621 26.5891 62.4621C26.5891 62.4621 36.3435 74.0989 26.0757 84.3667C15.9791 94.6344 45.2422 92.5809 47.4669 83.511Z"
          fill={color}
        />
        <path
          d="M127.042 85.0513L144.497 102.506C150.658 108.667 150.658 118.764 144.497 124.924L127.042 142.38C120.881 148.54 110.785 148.54 104.624 142.38L87.1688 124.924C81.0082 118.764 81.0082 108.667 87.1688 102.506L104.624 85.0513C110.785 78.8906 120.881 78.8906 127.042 85.0513Z"
          fill={color}
        />
        <path
          d="M127.042 4.62049L144.497 22.0757C150.658 28.2363 150.658 38.3329 144.497 44.4936L127.042 61.9488C120.881 68.1094 110.785 68.1094 104.624 61.9488L87.1688 44.4936C81.0082 38.3329 81.0082 28.2363 87.1688 22.0757L104.624 4.62049C110.785 -1.54016 120.881 -1.54016 127.042 4.62049Z"
          fill={color}
        />
        <path
          d="M87.1688 102.506C87.1688 102.506 76.9011 114.143 64.7509 100.624C53.4564 87.9604 65.2643 124.24 65.2643 124.24C65.2643 124.24 76.9011 114.485 86.9977 124.582C97.0943 134.679 96.2387 104.902 87.1688 102.506Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2102_6171"
          x="0"
          y="0"
          width="153.118"
          height="155"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2102_6171" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2102_6171"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
