import { Stack, Tooltip, Typography, Box } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/redux/store';
import { selectNotificationPreferences, selectUserId } from 'src/redux/slices/userSlice';
import Android12Switch from 'src/components/customSwitch';
import { useSnackbar } from 'notistack';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

const notificationSettingsMap = {
  newReview: 'notifications.settings.newReview',
  newLead: 'notifications.settings.newLead',
};

export default function NotificationSettings() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector(selectUserId);
  const notificationSettings = useSelector(selectNotificationPreferences);

  const onUpdateNotificationSettings = async (
    key: keyof typeof notificationSettings,
    value: boolean
  ) => {
    try {
      if (!userId) {
        throw new Error('User not found');
      }
      const userRef = doc(collection(DB, 'users'), userId);
      await setDoc(
        userRef,
        {
          notificationPreferences: {
            [key]: value,
          },
        },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="caption" color="text.secondary">
          Notifications
        </Typography>
        <Stack flexDirection="row" gap={4.5} pr={2}>
          <Tooltip title={t('notifications.settings.emailNotificationTooltip')} placement="top">
            <Box>
              <Iconify icon="mdi:email" color="grey" fontSize={24} />
            </Box>
          </Tooltip>
          <Tooltip title={t('notifications.settings.pushNotificationTooltip')} placement="top">
            <Box>
              <Iconify icon="mdi:push-notification" color="grey" fontSize={24} />
            </Box>
          </Tooltip>
        </Stack>
      </Stack>
      {Object.entries(notificationSettingsMap).map(([key, value]) => {
        const emailKey = `${key}Email`;
        const pushKey = `${key}Push`;
        const emailValue = notificationSettings[emailKey as keyof typeof notificationSettings];
        const pushValue = notificationSettings[pushKey as keyof typeof notificationSettings];

        console.log({
          emailKey,
          pushKey,
          emailValue,
          pushValue,
        });

        return (
          <Stack
            key={key}
            flexDirection="row"
            justifyContent="space-between"
            sx={{ width: '100%' }}
            alignItems="center"
          >
            <Typography variant="body2" sx={{ mb: 0 }}>
              {t(value)}
            </Typography>
            <Box>
              <Android12Switch
                name={emailKey}
                checked={emailValue}
                onChange={(e) =>
                  onUpdateNotificationSettings(
                    emailKey as keyof typeof notificationSettings,
                    e.target.checked
                  )
                }
              />
              <Android12Switch
                name={pushKey}
                checked={pushValue}
                onChange={(e) =>
                  onUpdateNotificationSettings(
                    pushKey as keyof typeof notificationSettings,
                    e.target.checked
                  )
                }
              />
            </Box>
          </Stack>
        );
      })}
    </>
  );
}
