import React, { useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page';
import {
  Container,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  Dialog,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';
import useSettings from '../../hooks/useSettings';
import QuestionsSection, { QuestionType } from './QuestionsSection';
import Options from './Options';
import ColorBlockPicker from '../../components/colorPicker/ColorBlockPicker';
import Iconify from '../../components/Iconify';
import { Switch } from '@mui/material';
import AddForm from './forms/AddForm';
import OptionsSection from './OptionsSection';
import { CountryField, countries, supportedLeadFormLanguages } from 'src/components/PhoneField';
import { FormProvider } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Collection, Intl } from 'src/enum';
import { useSelector } from 'src/redux/store';
import { selectCurrentPlatformWorkspaceId } from 'src/redux/selectors/workspaces';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import {
  LeadForm,
  Question,
  QuestionOption,
  loadingLeadFormState,
  selectLeadForm,
  selectLeadFormColor,
} from 'src/redux/slices/leadFormsSlice';
import Lottie, { aiLoader } from 'src/components/Lottie';
import useLeadFormSuggestions from 'src/hooks/useLeadFormSuggestions';
import EditForm from './forms/EditForm';
import { isEqual } from 'lodash';
import { usePrompt } from 'src/hooks/usePrompt';
// import { Form } from '@simplytech/simply.widgets.leads.form';
import { Form } from '@simplylabs_/shared';
import { selectISPublicConfigLoading } from 'src/redux/slices/publicConfig';

export default function Leads() {
  useLeadFormSuggestions();
  const { t } = useTranslation();
  const isLoadingPublicConfig = useSelector(selectISPublicConfigLoading);
  const isLoading = useSelector(loadingLeadFormState);
  const leadForm = useSelector(selectLeadForm);
  const { themeStretch } = useSettings();

  const renderContent = () => {
    if (isLoading || isLoadingPublicConfig) return <CircularProgress />;
    if (!leadForm?.language) {
      return <LeadFormInitConfig />;
    }
    if (!leadForm?.hasInitSetup) {
      return (
        <Stack justifyContent="center" alignItems="center" height="75%">
          <Box sx={{ width: 200 }}>
            <Lottie animation={aiLoader} />
          </Box>
          <Typography variant="subtitle1">Our AI is setting up your Lead form</Typography>
        </Stack>
      );
    }
    return (
      <LeadFormEditor
        initialQuestions={leadForm?.form}
        leadFormId={leadForm.id}
        leadForm={leadForm}
      />
    );
  };

  return (
    <Page title="Leads" height="100%">
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            mb: 5,
          }}
        >
          <Typography variant="h4">{t('leads.headline.title')}</Typography>
        </Box>
        {renderContent()}
      </Container>
    </Page>
  );
}

type FormValuesProps = {
  language: Intl;
};

const UpdateLeadFormConfigSchema = Yup.object().shape({
  language: Yup.mixed(),
});

function LeadFormInitConfig() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const defaultValues = { language: Intl.DA };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateLeadFormConfigSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    // reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!workspaceId) return enqueueSnackbar(t('general.error.default'), { variant: 'error' });

      // write firstore query for workspaceLeads.doc.forms add:

      await addDoc(collection(DB, getCollection[Collection.LEADS_FORMS](workspaceId)), {
        createTime: new Date(),
        language: data.language,
      });

      // TODO: create correct translation
      enqueueSnackbar(t('leads.form.init.setup'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };
  return (
    <Grid container spacing={3} height="100%">
      <Grid item xs={12} md={12}>
        <Stack justifyContent="center" alignItems="center" height={'75%'}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h4" mb={2}>
              {t('leads.form.language.headline')}
            </Typography>
            <CountryField
              // size="small"
              variant="outlined"
              // label={t('reviewPageSettings.form.template.langLabel')}
              // placeholder={t('reviewPageSettings.form.template.langPlaceholder') as string}
              id="outlined-select-currency"
              name="language"
              onChange={(e: any) => {
                setValue('language', e.target.value);
              }}
            >
              {countries
                .filter((c) => c.languageCode !== Intl.NN)
                .filter((c) => supportedLeadFormLanguages.includes(c.code as Intl))
                // dual NN language (just using NO)
                .map((option) => (
                  <option key={option.languageCode} value={option.languageCode}>
                    {option.flag} {option.language}
                  </option>
                ))}
            </CountryField>
            <Typography variant="subtitle2" color="grey.500" mt={2}>
              {t('leads.form.language.explainer')}
            </Typography>
            <Box mt={2} />
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              fullWidth
              size="large"
            >
              {t('lead.form.init.button')}
            </LoadingButton>
          </FormProvider>
        </Stack>
      </Grid>
    </Grid>
  );
}

function LeadFormEditor({
  initialQuestions,
  leadFormId,
  leadForm,
}: {
  initialQuestions: Question[];
  leadFormId: string;
  leadForm: LeadForm;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const [text, setText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [questions, setQuestions] = useState<Question[]>(initialQuestions);
  const brandColor = useSelector(selectLeadFormColor);

  const [number, setNumber] = useState<string>('');
  const [hex, setHex] = useState<string>(brandColor);
  const [current, setCurrent] = useState<any>(questions[0]?.id);

  const currentQuestion = questions.find((q) => q.id === current);
  const [open, setOpen] = useState(false);
  // const [openContactOptions, setOpenContactOptions] = useState<'OPEN' | 'CLOSED' | 'INVALID'>(
  //   'CLOSED'
  // );
  const [editId, setEditId] = useState<string | null>(null);
  const country = countries.find((c) => c.languageCode === leadForm?.language);
  const defaultCountryCode = country?.phone || '';
  /**
   * Used to fake the answers object
   */
  const questionKeys = questions.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: ' ',
    }),
    {}
  );

  const [answers, setAnswers] = React.useState({
    ...questionKeys,
    'fitness-5': {
      'fitness-5-option-3': { code: defaultCountryCode },
    },
  });
  const isDirty = useMemo(
    () => !isEqual(initialQuestions, questions) || hex !== leadForm?.color,
    [initialQuestions, questions, hex, leadForm?.color]
  );

  usePrompt(t('captions.unsaved'), isDirty);

  useEffect(() => {
    setQuestions(initialQuestions);
  }, [initialQuestions?.length]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setEditId(null);
  };

  const onSaveForm = async () => {
    try {
      setSubmitting(true);
      if (!workspaceId || !leadFormId) {
        throw new Error('No workspaceId or leadFormId');
      }
      await setDoc(
        doc(DB, getCollection[Collection.LEADS_FORMS](workspaceId), leadFormId),
        {
          form: questions,
          color: hex,
        },
        { merge: true }
      );
      // TODO: create correct translation
      enqueueSnackbar(t('general.success.default'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleAddQuestion = (question: Question) => {
    setQuestions((prevQuestions) => {
      const last = prevQuestions[prevQuestions.length - 1];
      if (last?.type === QuestionType.CONTACT) {
        const questionsWithoutContact = prevQuestions.filter(
          (q) => q.type !== QuestionType.CONTACT
        );
        return [...questionsWithoutContact, question, last];
      }
      return [...prevQuestions, question];
    });
    setCurrent(question.id);
    setOpen(false);
  };
  const handleUpdateQuestionOptions = (questionId: string, options: QuestionOption[]) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) => (q.id === questionId ? { ...q, options } : q))
    );
  };

  const handleEditQuestion = (question: Question) => {
    setQuestions((prev) =>
      prev.map((q) => {
        if (q.id === question.id) {
          return question;
        }
        return q;
      })
    );
    setEditId(null);
  };

  const handleDeleteQuestion = () => {
    if (!editId) return;
    const updatedQuestions = questions.filter((q) => q.id !== editId);
    if (updatedQuestions.length - 1 < 0) {
      setCurrent(null);
    } else {
      setCurrent(updatedQuestions[updatedQuestions.length - 1]?.id);
    }

    setQuestions(updatedQuestions);
    handleCloseEdit();
  };

  console.log({ questions });

  return (
    <>
      <Grid container spacing={3} height="100%">
        <Grid item xs={12} md={3}>
          <Stack sx={{ backgroundColor: '' }}>
            <QuestionsSection
              questions={questions}
              setQuestions={setQuestions}
              current={current}
              setCurrent={setCurrent}
            />
            <Button
              fullWidth
              startIcon={<Iconify icon="material-symbols:add-circle-outline" />}
              onClick={() => setOpen(true)}
            >
              {t('leads.form.questionadd.button')}
            </Button>
            <Box mt={8} />
            <Typography variant="subtitle1">{t('leads.form.color.brandColor')}</Typography>
            <ColorBlockPicker hex={hex} setHex={setHex} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <OptionsSection
            currentQuestion={currentQuestion}
            openEditModal={() => setEditId(currentQuestion?.id as string)}
          >
            {currentQuestion?.type === QuestionType.RADIO && (
              <Options
                key={currentQuestion.id}
                id={currentQuestion.id}
                options={currentQuestion.options as QuestionOption[]}
                setOptions={(options: any[]) => {
                  setQuestions(
                    questions.map((q) =>
                      q.id === currentQuestion.id
                        ? {
                            ...q,
                            options,
                          }
                        : q
                    )
                  );
                }}
              />
            )}
            {currentQuestion?.type === QuestionType.NUMBER && (
              <>
                <TextField
                  InputProps={{ sx: { color: 'grey.500' } }}
                  fullWidth
                  type="text"
                  // label="Question"
                  placeholder={currentQuestion.content}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <Typography
                  variant="body2"
                  color="grey.600"
                  sx={{ mt: 1, px: 2, fontStyle: 'italic' }}
                >
                  {t('leads.form.tip.editPlaceholder')}
                </Typography>
              </>
            )}
            {currentQuestion?.type === QuestionType.LONG_TEXT && (
              <>
                <TextField
                  InputProps={{ sx: { color: 'grey.500' } }}
                  fullWidth
                  multiline
                  placeholder={currentQuestion.content}
                  minRows={4}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <Typography
                  variant="body2"
                  color="grey.600"
                  sx={{ mt: 1, px: 2, fontStyle: 'italic' }}
                >
                  {t('leads.form.tip.editPlaceholder')}
                </Typography>
              </>
            )}
            {currentQuestion?.type === QuestionType.CONTACT && (
              <>
                {(currentQuestion.options as any[]).map((option) => (
                  <>
                    <Stack direction="row">
                      <TextField
                        InputProps={{ sx: { color: 'grey.500' } }}
                        fullWidth
                        size="small"
                        label={option.label}
                        placeholder={option.content}
                        value={text}
                        onChange={(e) => {}}
                      />
                      {option.label === 'Phone' && <Switch />}
                    </Stack>
                    <Box mt={2} />
                  </>
                ))}
                <Typography
                  variant="body2"
                  color="grey.600"
                  sx={{ mt: 1, mb: 1, px: 2, fontStyle: 'italic' }}
                >
                  {t('leads.form.tip.editPlaceholder')}
                </Typography>
                {/* {openContactOptions === 'CLOSED' && (
                  <Button
                    fullWidth
                    startIcon={<Iconify icon="material-symbols:add-circle-outline" />}
                    onClick={() => setOpenContactOptions('OPEN')}
                  >
                    {t('leads.form.questionadd.button')}
                  </Button>
                )}

                {openContactOptions === 'OPEN' && (
                  <Select
                    fullWidth
                    size="small"
                    onChange={() => {
                      setQuestions(
                        questions.map((q) =>
                          q.type === QuestionType.CONTACT
                            ? {
                                ...q,
                                options: [
                                  // @ts-ignore
                                  ...q.options,
                                  {
                                    id: 'fitness-5-option-4',
                                    content: 'Instgram handle',
                                    label: 'Instagram',
                                  },
                                ],
                              }
                            : q
                        )
                      );
                      setOpenContactOptions('INVALID');
                    }}
                  >
                    <MenuItem value="instagram" sx={{ alignItems: 'center' }}>
                      {t('leads.form.contact.instagram')}
                    </MenuItem>
                  </Select>
                )} */}
              </>
            )}
          </OptionsSection>
        </Grid>
        <Grid item xs={12} md={3}>
          <Stack sx={{ height: '100%', backgroundColor: 'grey.100', mr: -5 }} alignItems="center">
            <Stack px={2} pt={4} justifyContent="center" alignItems="center">
              <Typography variant="subtitle1">{t('leads.form.preview.title')}</Typography>
            </Stack>
            <Stack sx={{ transform: 'scale(0.75)' }}>
              <Form
                questions={questions}
                answers={answers}
                initialActive={current}
                customColor={hex}
                updateAnswer={() => console.log('swefwef')}
                handleSubmit={() => console.log('submitted')}
                defaultCountryCode={defaultCountryCode}
                previousText={leadForm.previousText}
                submitText={leadForm.submitText}
                nextText={leadForm.nextText}
                submitting={false}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        sx={{ position: 'fixed', right: 24, bottom: 24 }}
        direction="row"
        justifyContent="flex-end"
      >
        <LoadingButton
          variant="contained"
          size="large"
          disabled={!isDirty}
          onClick={onSaveForm}
          loading={submitting}
        >
          {isDirty ? t('general.form.saveChanges') : t('general.success.button.saved')}
        </LoadingButton>
      </Stack>
      <Dialog keepMounted open={open} onClose={handleClose}>
        <AddForm
          handleClose={handleClose}
          addQuestion={handleAddQuestion}
          updateQuestionOptions={handleUpdateQuestionOptions}
        />
      </Dialog>
      <Dialog keepMounted open={Boolean(editId)} onClose={handleCloseEdit}>
        <EditForm
          questionId={editId}
          handleClose={handleCloseEdit}
          updateQuestion={handleEditQuestion}
          updateQuestionOptions={handleUpdateQuestionOptions}
          deleteQuestion={handleDeleteQuestion}
        />
      </Dialog>
    </>
  );
}
