import {
  addDays,
  startOfWeek,
  setHours,
  setMinutes,
  getDay,
  differenceInMinutes,
  startOfDay,
} from 'date-fns';
import uuidv4 from './uuidv4';

// format '00:00:00.000'
export function convertTimeStingToNumber(timeString: string) {
  const [hours, minutes, seconds] = timeString.split(':');
  const time = Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds?.replace(',', '.'));
  return time;
}

export function convertMinutesToHHmm(minutes: number) {
  // Calculate hours and minutes from total minutes
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  // Format hours and minutes to HH:mm format
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
}
export function convertMinutesToHoursAndMinutes(minutes: number): { hours: number; mins: number } {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  return { hours, mins };
}

export interface WeekAvailability {
  [key: string]: { from: number; to: number }[];
}

export interface EventFormat {
  id: string;
  title: string;
  start: Date;
  end: Date;
  custom: string;
  image: string;
}

export function convertAvailabilityToEvents(weekAvailability: WeekAvailability): EventFormat[] {
  const days = Object.keys(weekAvailability);
  const currentWeekMonday = startOfWeek(new Date(), { weekStartsOn: 1 });

  let events: EventFormat[] = [];

  days.forEach((day) => {
    const dayOffset = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ].indexOf(day.toLowerCase());

    let baseDate = addDays(currentWeekMonday, dayOffset);

    weekAvailability[day].forEach((period) => {
      const fromHours = Math.floor(period.from / 60);
      const fromMinutes = period.from % 60;
      let startTime = setHours(setMinutes(baseDate, fromMinutes), fromHours);

      const toHours = Math.floor(period.to / 60);
      const toMinutes = period.to % 60;
      let endTime = setHours(setMinutes(baseDate, toMinutes), toHours);

      events.push({
        id: uuidv4(),
        title: 'Available',
        start: startTime,
        end: endTime,
        custom: 'Custom information here', // Replace as needed
        image: '', // Add image URL if needed
      });
    });
  });

  return events;
}

export function createTimeIntervals(
  weekAvailability: WeekAvailability,
  selectedDate: Date | null,
  duration: number
): number[] {
  if (!selectedDate || !weekAvailability) {
    return []; // No selected date
  }
  // Get the day of the week from the selectedDate
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const dayOfWeek = daysOfWeek[selectedDate.getDay()];

  const dayAvailabilities = weekAvailability[dayOfWeek];
  if (!dayAvailabilities) {
    return []; // No availability for this day
  }

  const intervals: number[] = [];
  dayAvailabilities.forEach(({ from, to }) => {
    for (let minute = from; minute < to; minute += duration) {
      intervals.push(minute);
    }
  });

  return intervals;
}

export function convertEventsToWeekAvailability(events: EventFormat[]): WeekAvailability {
  // Initialize an empty object for the WeekAvailability structure
  let weekAvailability: WeekAvailability = {};

  // Convert each event back into availability periods
  events.forEach((event) => {
    // Determine the day of the week for the event
    const eventDayOfWeek = getDay(event.start); // Sunday = 0, Monday = 1, ..., Saturday = 6
    const daysOfWeek = [
      'sunday', // Ensure alignment with getDay()
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const day = daysOfWeek[eventDayOfWeek]; // This should correctly align with the output from getDay()

    // Calculate the minutes from the start of the day for both start and end times
    const eventStartOfDay = startOfDay(event.start); // This sets the reference to midnight of the event's day
    const from = differenceInMinutes(event.start, eventStartOfDay); // Minutes from midnight
    const to = differenceInMinutes(event.end, eventStartOfDay); // Minutes from midnight

    // If the day doesn't exist in the weekAvailability object, add it
    if (!weekAvailability[day]) {
      weekAvailability[day] = [];
    }

    // Add the new availability period to the day
    weekAvailability[day].push({ from, to });
  });

  // Sort each day's availability periods by their start time
  Object.keys(weekAvailability).forEach((day) => {
    weekAvailability[day].sort((a, b) => a.from - b.from);
  });

  return weekAvailability;
}

// // Usage example
// const weekAvailability = {
//   monday: {
//     from: 480,
//     to: 1020,
//   },
//   tuesday: {
//     from: 480,
//     to: 1020,
//   },
//   wednesday: {
//     from: 480,
//     to: 1020,
//   },
//   thursday: {
//     from: 480,
//     to: 1020,
//   },
//   friday: {
//     from: 480,
//     to: 1020,
//   },
// };

// const selectedDate = new Date('2024-03-04'); // Example date, assuming it's a Monday
// const duration = 30; // Duration in minutes

// const timeIntervals = createTimeIntervals(weekAvailability, selectedDate, duration);
// console.log(timeIntervals);
