import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Intl, InviteStatus, Subscription, RequestState } from '../../enum';
import keyBy from 'lodash.keyby';
import { Timestamp } from 'firebase/firestore';

export enum contenttatus {
  STABLE = 'STABLE',
  UNSTABLE = 'UNSTABLE',
}

export interface Content {
  id: string;
  title: string;
  description: string;
  estimatedTime: number;
  image: string;
  imagePrompt: string;
  contentType: string;
  tip: string;
  type: string;
  unsplashKeyword: string;
  unsplashUrl: string;
  whenToPublish: string;
  tags: string[];
}

// Define a type for the slice state
interface ContentState {
  state: RequestState;
  ids: string[];
  map: { [key: string]: Content };
}

// Define the initial state using that type
const initialState: ContentState = {
  state: RequestState.IDLE,
  ids: [],
  map: {},
};

export const contentSlice = createSlice({
  name: 'content',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setContentLoadingState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      state: RequestState.PENDING,
    }),
    // Use the PayloadAction type to declare the contents of `action.payload`
    setContent: (state, action: PayloadAction<Content[]>) => {
      const map = keyBy(action.payload, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        ids: action.payload.filter(Boolean).map((content) => content.id),
        map,
        state: RequestState.RESOLVED,
      };
    },
  },
});

export const { setContent, setContentLoadingState } = contentSlice.actions;

export const selectLoadingState = (state: RootState) => ({
  isLoading:
    state.content.state === RequestState.PENDING || state.content.state === RequestState.IDLE,
  hasError: state.content.state === RequestState.REJECTED,
});

export const selectContentIds = (state: RootState) => state.content.ids;

export default contentSlice.reducer;
