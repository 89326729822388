import { useEffect } from 'react';

import { selectPlatform, setUserPlaform } from '../redux/slices/userSlice';
import { dispatch, useSelector } from '../redux/store';
// routes
import { useLocation } from 'react-router-dom';
import { Platforms } from '../redux/slices/platform';

// ----------------------------------------------------------------------

type ReviewPageProps = {
  children: any;
};
/**
 * Checks if it is a path of simply content and if the platform is already set to simply content
 * else it will set the platform to simply content
 * to avoid bugs
 */
export default function SimplyChatGuard({ children }: ReviewPageProps) {
  const platform = useSelector(selectPlatform);

  const { pathname } = useLocation();
  useEffect(() => {
    if (platform !== Platforms.SIMPLY_CHAT) {
      dispatch(setUserPlaform(Platforms.SIMPLY_CHAT));
    }

    return () => {};
  }, [pathname]);

  return children;
}
