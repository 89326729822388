import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intl, RequestState } from '../../enum';
import type { RootState } from '../store';
import { keyBy } from 'lodash';
import { Timestamp } from 'firebase/firestore';

type BookingStatus = 'confirmed';

type Attendee = {
  name: string;
  email: string;
  rsvp: boolean;
};
type Organizer = {
  name: string;
  email: string;
};

export interface Booking {
  id: string;
  _ref: string;
  createTime: Timestamp;
  startTime: Timestamp;
  endTime: Timestamp;
  consent: Timestamp;
  lastModified: Timestamp;
  note: string;
  status: BookingStatus;
  summary: string;
  attendees: Attendee[];
  organizer: Organizer;
}

// Define a type for the slice state
interface BookingScheduleState {
  list: string[];
  map: { [key: string]: Booking };
  loading: RequestState;
  currentIdState: RequestState;
  currentId: string | null;
}

// Define the initial state using that type
const initialState: BookingScheduleState = {
  list: [],
  map: {},
  loading: RequestState.IDLE,
  currentIdState: RequestState.IDLE,
  currentId: null,
};

export const bookingsSlice = createSlice({
  name: 'bookings',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setBookingsLoadingState: (state) => ({
      ...state,
      loading: RequestState.PENDING,
    }),
    setBookings: (state, action: PayloadAction<Booking[]>) => ({
      ...state,
      list: action.payload.map((i) => i.id),
      map: keyBy(action.payload, 'id'),
      loading: RequestState.RESOLVED,
    }),
  },
});

export const { setBookingsLoadingState, setBookings } = bookingsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const loadingBookingState = (state: RootState) =>
  state.bookings.loading === RequestState.IDLE || state.bookings.loading === RequestState.PENDING;

export const selectHasLeads = (state: RootState) => state.bookings.list.length > 0;

export const selectCurrentBooking = (state: RootState) =>
  state.bookings.currentId ? state.bookings.map[state.bookings.currentId] : null;

export const selectBookings = (state: RootState) =>
  Object.entries(state.bookings.map).map(([, b]) => ({
    id: b.id,
    attendee: b.attendees.find((a) => a.email !== b.organizer.email),
    summary: b.summary,
    createTime: b.createTime.toDate(),
    startTime: b.startTime.toDate(),
    endTime: b.endTime.toDate(),
    status: b.status,
    note: b.note,
  }));

export default bookingsSlice.reducer;
