import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import React, { ReactElement } from 'react';

export default function Hint({
  open,
  placement = 'top',
  content,
  handleClose,
  handleOpen,
  children,
}: {
  open?: boolean;
  placement?: TooltipProps['placement'];
  content: React.ReactNode;
  handleClose?: () => void;
  handleOpen?: () => void;
  children: ReactElement<any, any>;
}) {
  return (
    <HtmlTooltip
      placement={placement}
      arrow
      title={content}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      {children}
    </HtmlTooltip>
  );
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: 'rgba(255, 255, 255, 0.2)', // Adjust background color and opacity
    borderRadius: '10px', // Rounded corners
    backdropFilter: 'blur(10px)', // Frosted glass effect
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)', // Optional: Add a subtle shadow for depth
    padding: '20px', // Space inside the div
    border: '1px solid rgba(255, 255, 255, 0.3)', // Optional: Add a light border
    color: 'rgba(0, 0, 0, 0.87)',
    // maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9',
    span: {
      color: 'white',
    },
  },
}));
