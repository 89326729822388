import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export enum Platforms {
  SIMPLY_REVIEW = 'SIMPLY_REVIEW',
  SIMPLY_FLOW = 'SIMPLY_FLOW',
  SIMPLY_LEADS = 'SIMPLY_LEADS',
  SIMPLY_LINKS = 'SIMPLY_LINKS',
  SIMPLY_CONTENT = 'SIMPLY_CONTENT',
  SIMPLY_BOOKING = 'SIMPLY_BOOKING',
  SIMPLY_CHAT = 'SIMPLY_CHAT',
}
export enum PlatformDisplayNames {
  SIMPLY_REVIEW = 'SimplyReview',
  SIMPLY_FLOW = 'SimplyFlow',
  SIMPLY_LEADS = 'SimplyLeads',
  SIMPLY_LINKS = 'SimplyLink',
  SIMPLY_BOOKING = 'SimplyBooking',
  SIMPLY_CHAT = 'SimplyChat',
}

// Define a type for the slice state
interface PlatformState {
  id: Platforms;
}

// Define the initial state using that type
const initialState: PlatformState = {
  id: Platforms.SIMPLY_REVIEW,
};
// TODO: MAYBE SWTICH TO this later? currenty not used
export const platformSlice = createSlice({
  name: 'platform',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // setPlatform: (state, action: PayloadAction<any>) => ({
    //   ...state,
    //   id: action.payload,
    // }),
  },
});

// export const { setPlatform } = platformSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectUser = (state: RootState) => state.user;

export default platformSlice.reducer;
