import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Button, Typography, Box, Stack, Switch } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import { PriceId } from '../../enum';
import Subscribe from '../../stripe/components/Subscribe';
import ManageSubscription from '../../stripe/components/ManageSubscription';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../redux/store';
import { selectIsSuperOwner } from '../../redux/slices/userSlice';
import { hasLeadsPremiumSelector } from '../../redux/slices/workspaceLeadsSlice';
import {
  StripeSubscriptionStatus,
  selectHasStripeLoaded,
  selectLatestBundleSubscription,
  selectLatestLeadsSubscription,
  selectLatestReviewSubscription,
  selectLeadsSubscriptions,
} from 'src/redux/slices/stripeSlice';
import { LoadingButton } from '@mui/lab';
import SimplyLeadsLogo from 'src/assets/Logo/SimplyLeadsLogo';
import { LEADS } from 'src/theme/palette';
import { selectWorkspaceLeadsHasWorkspaces } from 'src/redux/selectors/workspaces';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5),
  },
}));

const lists = [
  { text: 'pricing.premiumleads.item1', isAvailable: true },
  { text: 'pricing.premiumleads.item2', isAvailable: true },
  { text: 'pricing.premiumleads.item3', isAvailable: true },
  { text: 'pricing.premiumleads.item4', isAvailable: true },
  { text: 'pricing.premiumleads.item5', isAvailable: true },
  { text: 'pricing.premiumleads.item6', isAvailable: true },
];

// ----------------------------------------------------------------------

type Props = {
  index: number;
};

export default function PricingPlanCardPremiumLeads({ index }: Props) {
  const { user } = useAuth();
  const [priceId, setPriceId] = useState(PriceId.SIMPLY_LEADS_MONTHLY_EARLYBIRD);
  const { t } = useTranslation();
  const isSuperOwner = useSelector(selectIsSuperOwner);
  const stripeHasLoaded = useSelector(selectHasStripeLoaded);
  const hasSubscription = useSelector(selectLeadsSubscriptions);
  const hasLeadsPremium = useSelector(hasLeadsPremiumSelector);
  const leadsSub = useSelector(selectLatestLeadsSubscription);
  const bundleSubscription = useSelector(selectLatestBundleSubscription);
  // const hasReview = useSelector(selectWorkspaceReviewHasWorskpaces);
  // const hasFlow = useSelector(selectWorkspaceFlowHasWorskpaces);
  const hasLeads = useSelector(selectWorkspaceLeadsHasWorkspaces);
  // const hasLinks = useSelector(selectWorkspaceLinksHasWorkspaces);

  const togglePrice = () => {
    if (priceId === PriceId.SIMPLY_LEADS_MONTHLY_EARLYBIRD) {
      setPriceId(PriceId.SIMPLY_LEADS_YEARLY);
    } else {
      setPriceId(PriceId.SIMPLY_LEADS_MONTHLY_EARLYBIRD);
    }
  };

  const latestSubscriptionIsCancelled = leadsSub?.status === StripeSubscriptionStatus.Canceled;

  const renderSubscription = () => {
    if (!stripeHasLoaded) {
      return <LoadingButton loading size="large" fullWidth variant="contained" />;
    }
    if (!isSuperOwner) {
      return (
        <Button variant="contained" disabled fullWidth size="large">
          Only the owner can upgrade billing
        </Button>
      );
    }
    if (
      bundleSubscription?.status &&
      bundleSubscription?.status !== StripeSubscriptionStatus.Canceled
    ) {
      return (
        <Button variant="contained" disabled fullWidth size="large">
          You are Simply Bundle so this is included
        </Button>
      );
    }
    if (!hasLeads) {
      return (
        <Button variant="contained" disabled fullWidth size="large">
          Setup your workspace (click the product in left menu)
        </Button>
      );
    }
    if (hasLeadsPremium && hasSubscription?.length < 1) {
      return (
        <LoadingButton size="large" disabled fullWidth variant="contained">
          Seems like you got this one for free, lucky you
        </LoadingButton>
      );
    }
    // only allow if not premium and no subscription or latest subscription is cancelled
    if ((!hasLeadsPremium && hasSubscription?.length < 1) || latestSubscriptionIsCancelled) {
      return <Subscribe priceId={priceId} />;
    }

    return <ManageSubscription />;
  };

  return (
    <RootStyle>
      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {t('pricing.premiumleads.subHeader')}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t('general.yearly')}</Typography>
        <Switch
          checked={priceId === PriceId.SIMPLY_LEADS_MONTHLY_EARLYBIRD}
          onChange={togglePrice}
          inputProps={{ 'aria-label': 'ant design' }}
        />
        <Typography>{t('general.monthly')}</Typography>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        {index === 1 || index === 2 ? (
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            €
          </Typography>
        ) : (
          ''
        )}
        <Typography variant="h2" sx={{ mx: 1 }}>
          {priceId === PriceId.SIMPLY_LEADS_MONTHLY_EARLYBIRD ? 9 : 14}
        </Typography>

        {index === 1 || index === 2 ? (
          <Typography
            gutterBottom
            component="span"
            variant="subtitle2"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary',
            }}
          >
            /{t('general.date.month.short')}
          </Typography>
        ) : (
          ''
        )}
        {priceId === PriceId.SIMPLY_LEADS_MONTHLY_EARLYBIRD && (
          <>
            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
              €
            </Typography>

            <Typography variant="h2" sx={{ mx: 1 }}>
              <span style={{ textDecoration: 'line-through' }}>19</span>
            </Typography>
            <Typography
              gutterBottom
              component="span"
              variant="subtitle2"
              sx={{
                alignSelf: 'flex-end',
                color: 'text.secondary',
              }}
            >
              /{t('general.date.month.short')}
            </Typography>
          </>
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
        }}
      >
        {priceId !== PriceId.SIMPLY_LEADS_MONTHLY_EARLYBIRD ? (
          `(${t('pricing.premium.priceSubText')})`
        ) : (
          <span style={{ visibility: 'hidden' }}>_</span>
        )}
      </Typography>

      {/* <Box sx={{ width: 80, height: 80, mt: 3 }}>
        <PlanPremiumIcon />
      </Box> */}

      <Stack component="ul" spacing={1.5} sx={{ my: 2, mb: 3, width: 1 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{ typography: 'body2', color: item.isAvailable ? 'text.primary' : 'text.disabled' }}
          >
            {item.isAvailable ? (
              <Box>
                <Iconify icon={'eva:checkmark-fill'} sx={{ width: 20, height: 20 }} />
              </Box>
            ) : (
              <Iconify icon={'bi:x'} sx={{ width: 20, height: 20 }} />
            )}
            <Typography variant="body2">{t(item.text)}</Typography>
          </Stack>
        ))}
      </Stack>
      <Box flexGrow={1} />
      {renderSubscription()}
    </RootStyle>
  );
}
