import {
  collection,
  doc,
  getCountFromServer,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { getCollection } from 'src/api';
import Iconify from 'src/components/Iconify';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection, ReviewType } from 'src/enum';
import { Review } from 'src/redux/slices/reviewSlice';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, Card, CardActions, DialogActions, Stack, Typography } from '@mui/material';

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';

import { httpsCallable } from 'firebase/functions';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Transition } from '../../../sections/@dashboard/user/initial/InitialSettings';
import Grid from '@mui/system/Unstable_Grid/Grid';
import ReviewItemVideo from 'src/pages/reviews/ReviewItemVideo';
import DisplayVideo from 'src/pages/reviews/DisplayVideo';
import HoverCard from 'src/components/simply/Cards/HoverCard';
import { ReviewerDetails } from 'src/sections/@dashboard/user/profile/ProfilePostCard';

export default function UnverifiedReviews({
  slug,
  workspaceId,
}: {
  slug: string;
  workspaceId: string;
}) {
  const [unverified, setUnverified] = useState<Review[]>([]);
  const [modal, setModal] = useState(false);
  const handleCloseModal = () => setModal(false);

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId) {
      const q = query(
        collection(DB, getCollection[Collection.USERS_REVIEWS](workspaceId)),
        where('verified', '==', null),
        orderBy('createTime', 'desc')
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const reviews = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Review)
        );

        setUnverified(reviews);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setModal(true);
        }}
        endIcon={<Iconify icon="tabler:external-link" />}
      >
        {unverified.length} reviews
      </Button>
      <Dialog
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={handleCloseModal}
        aria-describedby="handle-reviews"
        maxWidth="lg"
      >
        <HandleReviews
          workspaceId={workspaceId}
          slug={slug}
          reviews={unverified}
          handleClose={handleCloseModal}
        />
      </Dialog>
    </>
  );
}

export function HandleReviews({
  slug,
  reviews,
  workspaceId,
  handleClose,
}: {
  workspaceId: string;
  slug: string;
  reviews: Review[];
  handleClose: () => void;
}) {
  return (
    <>
      <DialogTitle id="alert-dialog-title">{'Unverified reviews'}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }} />
        <Stack sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            {reviews.map((r) => (
              <Grid xs={3}>
                {r.type === ReviewType.VIDEO ? (
                  <Card sx={{ p: 1 }}>
                    <Box sx={{ overflow: 'hidden', objectFit: 'cover' }}>
                      <DisplayVideo
                        rating={r?.rating}
                        videoProcessed={r?.videoProcessed}
                        videoPlaybackId={r?.videoPlaybackId}
                        thumbFrame={r?.thumbFrame}
                        reviewerName={r?.reviewer?.name}
                      />
                    </Box>
                    <ReviewActions
                      workspaceId={workspaceId}
                      slug={slug}
                      reviewId={r.id}
                      inviteId={r?.inviteId}
                    />
                  </Card>
                ) : (
                  <HoverCard sx={{ p: 2, pb: 1, height: '100%' }}>
                    <Stack justifyContent="space-between">
                      <Stack>
                        {r.title && <Typography variant="h5">{r.title}</Typography>}
                        {r.description && <Typography>{r.description}</Typography>}
                      </Stack>
                      <ReviewerDetails name={r?.reviewer?.name as string} rating={r.rating} />
                    </Stack>
                    <ReviewActions
                      workspaceId={workspaceId}
                      slug={slug}
                      reviewId={r.id}
                      inviteId={r?.inviteId}
                    />
                  </HoverCard>
                )}
              </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
    </>
  );
}

export function ReviewActions({
  workspaceId,
  slug,
  reviewId,
  inviteId,
}: {
  workspaceId: string;
  slug: string;
  reviewId: string;
  inviteId: string | null;
}) {
  const [loading, setloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <CardActions sx={{ justifyContent: 'space-between' }}>
      <LoadingButton
        onClick={async () => {
          try {
            if (!workspaceId) return alert('Something is not working!');
            setloading(true);

            const productRef = doc(
              DB,
              getCollection[Collection.USERS_REVIEWS](workspaceId),
              reviewId
            );

            await setDoc(productRef, { verified: false }, { merge: true });

            enqueueSnackbar('Review rejected', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });

            console.error(error);
          } finally {
            setloading(false);
          }
        }}
        variant="contained"
        color="error"
        size="small"
      >
        {'Afvis'}
      </LoadingButton>
      <Button
        onClick={() => {
          if (inviteId) {
            return window.open(
              `https://app.simplyreview.com/r/${slug}/${inviteId}?r=${reviewId}`,
              '_blank'
            );
          }
          return window.open(`https://app.simplyreview.com/r/${slug}?r=${reviewId}`, '_blank');
        }}
        variant="contained"
        size="small"
      >
        {'Godkend review'}
      </Button>
    </CardActions>
  );
}
