import { useState } from 'react';
import Iconify from '../Iconify';
import Image from '../Image';
import { useTheme, Typography, Box, Stack, Rating } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import Verified from '@simplytech/simply.widgets.verified';

export default function CoverImage({ thumbnail, onClick, text, hoverThumbnail, rating }: any) {
  const theme = useTheme();
  const [isHovering, setisHovering] = useState(false);
  return (
    <Box
      sx={wrapper}
      role="button"
      onClick={onClick}
      onMouseEnter={() => setisHovering(true)}
      onMouseLeave={() => setisHovering(false)}
    >
      <Image
        src={hoverThumbnail ? (isHovering ? hoverThumbnail : thumbnail) : thumbnail}
        alt="video thumbnail"
        // ratio="4/3"
        sx={{ borderRadius: 1, minHeight: 200, height: '100%', maxHeight: 500, mt: 0 }}
      />
      <Box sx={iconWrapper}>
        <Iconify icon="bi:play-fill" color={theme.palette.common.white} sx={iconSize} />
      </Box>
      <Stack sx={textWrapper}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="subtitle1" color="#fff">
            {text}
          </Typography>
          <Verified />
        </Stack>
        <Stack>
          <Rating value={rating} precision={0.5} readOnly size="small" />
        </Stack>
      </Stack>
    </Box>
  );
}

const wrapper = {
  position: 'relative',
  minHeight: 200,
  maxHeight: 500,
  marginTop: 0,
} as SxProps<Theme>;

const iconSize = { height: 80, width: 80 };

const iconWrapper = {
  position: 'absolute',
  display: 'flex',
  top: 0,
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  height: '100%',
  cursor: 'pointer',
  borderRadius: 1,
  background: 'linear-gradient(0deg, rgba(0,0,0,0.5004595588235294) 0%, rgba(255,255,255,0) 24%)',
} as SxProps<Theme>;

const textWrapper = {
  textAlign: 'left',
  position: 'absolute',
  left: 14,
  bottom: 14,
} as SxProps<Theme>;
