import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useSelector } from '../../redux/store';
import { Platforms } from '../../redux/slices/platform';
import { styled } from '@mui/material/styles';
import Logo from '../../components/SRLogo';
import { Stack, Typography } from '@mui/material';
import reviewPlatform from '../../assets/widget/review_visual.png';
import Image from '../../components/Image';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { Functions } from '../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { isLoadingWorkspaceBookings } from '../../redux/slices/workspaceBookingsSlice';

export default function ModalBookingsInitialContainer() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const open = useSelector(
    (state) =>
      state.workspaceBookings.workspaceIds.length <= 0 &&
      state.user.platform === Platforms.SIMPLY_BOOKING &&
      !isLoadingWorkspaceBookings(state) &&
      state.user.id
  );

  const onSetupBookingsWorkspace = async () => {
    try {
      setLoading(true);
      const functionRef = httpsCallable(Functions, 'setupBookingsWorkspace');
      const response: any = await functionRef();

      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }

      enqueueSnackbar(t('leads.setup.workspace.success'));
      // window.location.reload();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog fullWidth maxWidth={'lg'} open={Boolean(open)} sx={{ marginLeft: 8 }}>
      {/* <DialogTitle>Optional sizes</DialogTitle> */}
      <DialogContent>
        <Stack justifyContent="center" alignItems="center">
          <Logo />
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="h2">{t('leads.modal.initial.title')}</Typography>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Paper elevation={10} sx={{ borderRadius: 2 }}>
            <Image sx={{ width: 250 }} src={reviewPlatform} />
          </Paper>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <DialogContentText textAlign="center" width="60%">
            {t('leads.modal.initial.description')}
          </DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          onClick={() => window.open('https://simplybooking.io', '_blank')}
        >
          {t('general.linkVisitWebsite')}
        </Button>
        <LoadingButton
          variant="contained"
          size="large"
          onClick={onSetupBookingsWorkspace}
          loading={loading}
        >
          {t('flow.initial.startTrial')}
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(({ theme }) => ({}));
