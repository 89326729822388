import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useSelector } from '../../../../redux/store';
import { Grid, Box, InputAdornment, Typography } from '@mui/material';
import RHFTextField from '../../../../components/hook-form/RHFTextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { ROOTS, RequestState } from '../../../../enum';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { FormProvider } from '../../../../components/hook-form';
import { httpsCallable } from 'firebase/functions';
import { Functions } from '../../../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { Platforms } from '../../../../redux/slices/platform';

const rootUrl = process.env.NODE_ENV === 'development' ? ROOTS.DEV : ROOTS.PROD;

type InitialSettingsProps = { slug: string; companyName: string };

export const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

function getPrefixLink(platform: Platforms) {
  if (platform === Platforms.SIMPLY_REVIEW) {
    return `${rootUrl}/r/`;
  }
  if (platform === Platforms.SIMPLY_LEADS) {
    return `https://app.simplyleads.io/l/`;
  }
  if (platform === Platforms.SIMPLY_LINKS) {
    return `https://simplylink.io/`;
  }
  // fallback simplyreview
  return `${rootUrl}/r/`;
}

export default function InitialSettings() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const config = useSelector((state) => state.publicConfig);
  const platform = useSelector((state) => state.user.platform);

  const open = useSelector(
    (state) =>
      state.publicConfig.id &&
      (!state.publicConfig.companyName || !state.publicConfig.slug) &&
      (state.user.platform === Platforms.SIMPLY_REVIEW ||
        state.user.platform === Platforms.SIMPLY_LEADS ||
        state.user.platform === Platforms.SIMPLY_LINKS)
    // state.user.platform === Platforms.SIMPLY_REVIEW
  );

  const userId = useSelector((state) => state.user.id);
  const id = useSelector((state) => state.publicConfig?.id);

  const companyName = useSelector((state) => state.publicConfig.companyName);
  const slug = useSelector((state) => state.publicConfig.slug);

  const handleConfirmModal = () => {};
  const UpdateUserSchema = Yup.object().shape({
    slug: Yup.string()
      .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, t('validation.slug.formatError') as string)
      .required(t('validation.slug.required') as string),
    companyName: Yup.string().required(t('validation.companyName') as string),
  });
  const defaultValues = { companyName, slug };

  const methods = useForm<InitialSettingsProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });
  const {
    // watch,
    // setValue,
    handleSubmit,
    // reset,
    formState: { isSubmitting },
  } = methods;
  // const values = watch();
  const onSubmitSlug = async (data: any) => {
    try {
      if (!userId) throw new Error(`userId invalid`);

      const functionRef = httpsCallable(Functions, 'setSlug');
      const response: any = await functionRef({
        id,
        slug: data.slug?.trim(),
        userId,
        companyName: data.companyName,
      });
      // const configId = response?.data?.id;

      // @ts-ignore
      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        enqueueSnackbar(t('validation.slug.format'), { variant: 'error' });
        return;
      }
      // if (!configId) {
      //   throw new Error('No id returned');
      // }

      // await setDoc(
      //   doc(DB, getCollection[Collection.ONBOARDING](userId), 'details'),
      //   { hasInitialSettings: true },
      //   { merge: true }
      // );

      enqueueSnackbar(t('form.save.success'));
      // onClose();
      // setStep(Steps.INDUSTRY);
      // setOpen(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };
  const onSubmit = async (data: InitialSettingsProps) => {
    try {
      await onSubmitSlug(data);
    } catch (error) {
      console.error(error);
    }
  };

  const baseUrl = getPrefixLink(platform);
  return (
    <Dialog
      open={Boolean(open)}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleConfirmModal}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{t('form.initialSettings.header')}</DialogTitle>

      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <Box>
              <Typography variant="h6">{t('form.companyName.header')}</Typography>
              <Box sx={{ mt: 3 }} />
            </Box>
            <Box>
              <RHFTextField
                name="companyName"
                // value={SLUG}
                // onChange={(e) => {
                //   // onChangeCompanyName(e.target.value);
                //   // setValue('companyName', e.target.value);
                // }}
                label={t('form.companyName.placeholder')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <Box>
              <Typography variant="h6">{t('form.link.header')}</Typography>
              <Typography variant="body2" color={theme.palette.grey[400]}>
                {t('form.link.description')}
              </Typography>
              <Box sx={{ mt: 3 }} />
            </Box>
            <Box>
              <RHFTextField
                name="slug"
                // value={slug}
                // onChange={(e) => {
                //   // onChangeSlug(e.target.value);
                //   // setValue('slug', e.target.value);
                // }}
                disabled={Boolean(defaultValues?.slug)}
                label={t('form.companyslug.placeholder', { baseUrl })}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{baseUrl}</InputAdornment>,
                }}
              />
            </Box>

            {/* <TextField
          label="With normal TextField"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          
        /> */}
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
              {t('form.saveBtn')}
            </LoadingButton>
          </Grid>
        </FormProvider>
      </DialogContent>

      {/* <Button onClick={handleClose}>Disagree</Button> */}
    </Dialog>
  );
}
