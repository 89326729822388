import { Platforms } from 'src/redux/slices/platform';
import { Collection } from '../enum';
import axios from 'axios';

export const getCollection = {
  [Collection.PUBLIC]: () => 'public',
  [Collection.USERS]: () => 'users',
  [Collection.API_INTEGRATIONS_READ]: (
    workspaceId: string,
    partner: 'zenfit' | 'zenfitLead' | 'googleCalendar'
  ) => `integrations/${workspaceId}/${partner}`,
  [Collection.API_INTEGRATIONS]: (
    workspaceId: string,
    partner: 'zenfit',
    doc: 'readable' | 'protected'
  ) => `integrations/${workspaceId}/${partner}/${doc}`,
  [Collection.TEAM_INVITES]: () => `teamInvites`,
  [Collection.WORKSPACE_REVIEWS]: () => 'workspaceReviews',
  [Collection.WORKSPACE_FLOW]: () => 'workspaceFlow',
  [Collection.WORKSPACE_LEADS]: () => 'workspaceLeads',
  [Collection.WORKSPACE_LINKS]: () => 'workspaceLinks',
  [Collection.WORKSPACE_BOOKINGS]: () => 'workspaceBookings',
  [Collection.WORKSPACE_BOOKINGS_SCHEDULES]: (uid: string) => `workspaceBookings/${uid}/schedules`,
  [Collection.ONBOARDING]: (uid: string) => `users/${uid}/onboarding`,
  [Collection.USERS_NOTIFICATIONS]: (uid: string) => `users/${uid}/notifications`,
  [Collection.USERS_REVIEWS]: (uid: string) => `workspaceReviews/${uid}/reviews`,
  [Collection.USERS_REVIEW_COMMENTS]: (uid: string, reviewId: string) =>
    `workspaceReviews/${uid}/reviews/${reviewId}/comments`,
  [Collection.USERS_REVIEW_TRANSCRIPT]: (uid: string, reviewId: string) =>
    `workspaceReviews/${uid}/reviews/${reviewId}/transcripts`,
  [Collection.USERS_REVIEW_RENDERS]: (uid: string, reviewId: string) =>
    `workspaceReviews/${uid}/reviews/${reviewId}/renders`,
  [Collection.USERS_INVITES]: (uid: string) => `workspaceReviews/${uid}/invites`,
  [Collection.USERS_INVITES_REMINDERS]: (uid: string) => `workspaceReviews/${uid}/inviteReminders`,
  [Collection.REVIEW_INVITES_RESEND_REMINDERS]: (uid: string, inviteId: string) =>
    `workspaceReviews/${uid}/invites/${inviteId}/reminders`,
  [Collection.USERS_REVIEW_STATS]: (uid: string) => `workspaceReviews/${uid}/stats/summary`,
  [Collection.USERS_INTEGRATIONS]: (uid: string) => `workspaceReviews/${uid}/integrations`,
  [Collection.WORKSPACE_INTEGRATIONS]: (uid: string) => `workspaceFlow/${uid}/integrations`,
  [Collection.PRODUCTS]: 'products',
  [Collection.CUSTOMERS]: 'customers',
  [Collection.PRODUCTS_PRICE]: 'price',
  [Collection.CUSTOMERS_CHECKOUTSESSION]: (uid: string) => `customers/${uid}/checkout_sessions`,
  [Collection.CUSTOMERS_SUBSCRIPTIONS]: (uid: string) => `customers/${uid}/subscriptions`,
  [Collection.USERS_INVITE_ACTIVITY]: (uid: string, inviteId: string) =>
    `workspaceReviews/${uid}/invites/${inviteId}/activity`,
  // LEADS
  [Collection.LEADS_FORMS]: (workspaceId: string) => `workspaceLeads/${workspaceId}/forms`,
  [Collection.LEADS_FORMS_SUGGESTIONS]: (workspaceId: string) =>
    `workspaceLeads/${workspaceId}/formSuggestions`,
  [Collection.LEADS_FORMS_SUBMISSIONS]: (workspaceId: string) =>
    `workspaceLeads/${workspaceId}/formSubmissions`,
  [Collection.LEADS_FORMS_SUBMISSIONS_ACTIVITY]: (workspaceId: string, leadId: string) =>
    `workspaceLeads/${workspaceId}/formSubmissions/${leadId}/leadActivity`,
  [Collection.AUTOMATIONS]: () => `automations`,
  // Content
  [Collection.CONTENT]: () => 'TEST_CONTENT',
  // bookings
  [Collection.BOOKINGS]: (uid: string) => `workspaceBookings/${uid}/bookings`,
};

export async function verifyReview({
  token,
  reviewId,
  userId,
  code,
}: {
  token: string;
  reviewId: string;
  userId: string;
  code: string;
}) {
  return axios.post('https://europe-west1-simply-review-b6fb2.cloudfunctions.net/verifyReview', {
    code,
    token,
    userId,
    reviewId,
  });
}

export async function submitLeadForm({
  workspaceId,
  formId,
  answers,
  origin,
}: {
  workspaceId: string;
  formId: string;
  answers: { [key: string]: any };
  origin: {
    url: string;
    hostname: string;
    protocol: string;
  };
}) {
  return axios.post(
    'https://europe-west1-simply-review-b6fb2.cloudfunctions.net/submitSimplyForm',
    {
      workspaceId,
      formId,
      answers,
      origin,
    }
  );
}

export function resolvePlatformToProductCollection(product: Platforms) {
  switch (product) {
    case Platforms.SIMPLY_REVIEW:
      return Collection.WORKSPACE_REVIEWS;

    case Platforms.SIMPLY_LEADS:
      return Collection.WORKSPACE_LEADS;

    case Platforms.SIMPLY_FLOW:
      return Collection.WORKSPACE_FLOW;

    case Platforms.SIMPLY_LINKS:
      return Collection.WORKSPACE_LINKS;

    default:
      throw new Error(`Invalid platform - ${product}`);
  }
}
