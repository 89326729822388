import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  Container,
  Divider,
  Fade,
  Grid,
  Slide,
  Tab,
  Tabs,
  TextField,
  ToggleButtonGroup,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';
import useSettings from '../../hooks/useSettings';
import Iconify from '../../components/Iconify';
import { useSelector } from '../../redux/store';
// import { LinkPage, SuccessLinkForm } from 'src/pages/linkPage';
import { Stack, ToggleButton } from '@mui/material';
import { Card } from '@mui/material';
import {
  LinkPageType,
  LinkType,
  selectISPublicConfigLoading,
  selectPublicConfig,
} from '../../redux/slices/publicConfig';
import { LoadingButton } from '@mui/lab';
import ColorBlockPicker from '../../components/colorPicker/ColorBlockPicker';
import { UploadAvatar } from '../../components/upload';
import { usePrompt } from '../../hooks/usePrompt';
import { getIntl } from '../../locales/helper';
import { upload } from '../../hooks/useUpload';
import { selectCurrentPlatformWorkspaceId } from '../../redux/selectors/workspaces';
import { IMAGE_METADATA_TYPE, Intl } from '../../enum';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from '../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import LinksList from './LinksList';
import { Button } from '@mui/material';
import uuidv4 from 'src/utils/uuidv4';
import { LinksPage } from '../linksPage';
import PatternSelect from '../leadsCustomisePage/PatternSelect';
import PhonePlaceholder from './PhonePlaceholder';
import { LEADS, REVIEW } from 'src/theme/palette';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Page from 'src/components/Page';
import { FormProvider, RHFTextField } from 'src/components/hook-form';

export default function LinksCustomiseContainer() {
  const isLoadingPublicConfig = useSelector(selectISPublicConfigLoading);
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  if (isLoadingPublicConfig || !workspaceId) return null;

  return <LinksCustomisePage />;
}

function LinksCustomisePage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const {
    id,
    color,
    companyPageLogo,
    linkPageLogo,
    linkPageName,
    linkPageColor,
    linkPageLinks,
    linkPagePattern,
    slug,
    companyName,
  } = useSelector((state) => state.publicConfig);
  const { themeStretch } = useSettings();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId);
  const config = useSelector(selectPublicConfig);
  const intl = useSelector((state) => state.user.intl || Intl.EN);
  const [submitting, setSubmitting] = useState(false);
  const [expand, setExpand] = useState(false);
  const [tab, setTab] = useState(0);
  const initialName = linkPageName || companyName || '';
  const [linkName, setLinkName] = useState(initialName);
  const initialLogo = linkPageLogo?.original || companyPageLogo?.thumbnail || null;
  const [linkLogo, setLinkLogo] = useState<File | string | null>(initialLogo);

  const initialColor = linkPageColor || color || theme.palette.primary.main;
  const [hex, setHex] = useState(initialColor);
  const initialPattern = linkPagePattern || null;
  const [pattern, setPattern] = useState<string | null>(initialPattern);
  const [option, setOption] = useState<'DESKTOP' | 'MOBILE'>('DESKTOP');
  const [links, setLinks] = useState<LinkPageType[]>(linkPageLinks || []);

  /**
   * Make sure the all is reset when the initial values after upload and save
   */
  useEffect(() => setLinkLogo(initialLogo), [initialLogo]);
  useEffect(() => setLinkName(initialName), [initialName]);
  useEffect(() => setHex(initialColor), [initialColor]);
  useEffect(() => setLinks(linkPageLinks || []), [linkPageLinks]);
  useEffect(() => setPattern(initialPattern), [initialPattern]);

  const isDirty = useMemo(
    () =>
      linkName !== initialName ||
      hex !== initialColor ||
      linkLogo !== initialLogo ||
      pattern !== initialPattern ||
      JSON.stringify(links) !== JSON.stringify(linkPageLinks),
    [
      hex,
      linkName,
      initialName,
      linkLogo,
      initialLogo,
      initialColor,
      links,
      linkPageLinks,
      pattern,
      initialPattern,
    ]
  );

  usePrompt(t('captions.unsaved'), isDirty);
  const handleChange = (_: any, newValue: number) => {
    setTab(newValue);
  };

  const handleLinkNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkName(e.target.value);
  };

  const handleLinkLogoChange = useCallback((acceptedFiles: any[]) => {
    const file = acceptedFiles[0];

    if (file) {
      setLinkLogo(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      // setavatar(URL.createObjectURL(file));
    }
  }, []);

  const handleSuccessTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setSuccessText(e.target.value);
  };

  const handleSave = async () => {
    try {
      let defaultSavedString = 'general.success.default';
      let logoUrl;
      setSubmitting(true);
      if (!id || !workspaceId) {
        throw new Error('No config id or workspace id');
      }

      if (linkLogo !== initialLogo && linkLogo instanceof File) {
        const response = await upload(
          linkLogo as Blob,
          `workspaceLinks/${workspaceId}/config/${id}/`,
          {
            userId: workspaceId,
            configId: id,
            type: IMAGE_METADATA_TYPE.LINK_PAGE_LOGO,
          }
        );

        if (response?.url) {
          logoUrl = response.url;
          defaultSavedString = 'general.changes.saved.withLaterUpdates';
        }
      }

      const linksResponses = await Promise.allSettled(
        links.map(async (link) => {
          if (link.type === LinkType.CUSTOM && (link?.thumbnail as any) instanceof File) {
            const response = await upload(
              link.thumbnail as Blob,
              `workspaceLinks/${workspaceId}/links/${link.id}/`,
              {
                userId: workspaceId,
                configId: id,
                linkId: link.id,
                type: IMAGE_METADATA_TYPE.LINK_IMAGE,
              }
            );

            if (response?.url) {
              return {
                ...link,
                thumbnail: response.url,
              };
            }
          }
          return { ...link, thumbnail: link.thumbnail || null };
        })
      );

      const linksWithUpload =
        linksResponses
          // @ts-ignore
          .map((link) => link.value || link) || links;

      const configRef = doc(collection(DB, 'public'), id);
      if (logoUrl) {
        await setDoc(
          configRef,
          {
            linkPageName: linkName,
            linkPageColor: hex,
            linkPageLogo: {
              thumbnail: logoUrl,
            },
            linkPagePattern: pattern,
            linkPageLinks: linksWithUpload,
          },
          { merge: true }
        );
      } else {
        await setDoc(
          configRef,
          {
            linkPageName: linkName,
            linkPageColor: hex,
            linkPagePattern: pattern,
            linkPageLinks: linksWithUpload,
          },
          { merge: true }
        );
      }
      enqueueSnackbar(t(defaultSavedString), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };
  const defaultValues = { url: '' };
  const methods = useForm<{ url: string }>({
    resolver: yupResolver(
      Yup.object().shape({
        url: Yup.string().required(t('validation.link') as string),
      })
    ),
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onAddLink = (type: LinkType) => {
    setLinks([
      ...links,
      {
        id: uuidv4(),
        type,
        title: getLinkTypeDefaultTitle(type, intl),
        link: '',
        visible: false,
      },
    ]);
    setExpand(false);
  };
  const onAddCustomLink = async (data: { url: string }) => {
    const domain = new URL(data.url).hostname?.replace('www.', '');

    setLinks([
      ...links,
      {
        id: uuidv4(),
        type: LinkType.CUSTOM,
        title: domain,
        link: data.url,
        visible: false,
      },
    ]);
    setExpand(false);
    reset();
  };

  return (
    <Page title="Links" height="100%" mr={-2} mb={-14}>
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%', width: '100%' }}>
        <Grid container spacing={3} height={'100%'} ml={0}>
          <Grid
            item
            md={5}
            height={'calc(100vh - 64px)'}
            position="relative"
            sx={{ overflowY: 'scroll', paddingLeft: '0px !important' }}
          >
            {/* <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                mb: 5,
                overflowX: 'hidden',
              }}
            >
              <Typography variant="h4">{t('links.headline.title')}</Typography>
            </Box> */}
            <Tabs value={tab} onChange={handleChange} aria-label="customisation tabs">
              <Tab
                icon={<Iconify fontSize={24} icon="mdi:user-box" />}
                label={t('links.customise.tab.page')}
              />
              <Tab
                icon={<Iconify fontSize={24} icon="mdi:cards-heart" />}
                label={t('links.customise.tab.customise')}
              />
            </Tabs>
            <Stack pr={2} position="relative" height="80%">
              <Fade key="slide-edit-thank-you" in={tab === 0}>
                <Stack display={tab === 0 ? 'flex' : 'none'} mt={3} spacing={4}>
                  <Stack spacing={2}>
                    {/* <TextField value={successText} onChange={handleSuccessTextChange} multiline /> */}
                    <LinksList
                      initialItems={links}
                      onChange={setLinks}
                      onToggle={(id: string, value: boolean) => {
                        setLinks(
                          links.map((link) => {
                            if (link.id === id) {
                              return { ...link, visible: value };
                            }
                            return link;
                          })
                        );
                      }}
                    />
                    <Card sx={{ marginLeft: '4px !important' }}>
                      {!expand && (
                        <Button
                          variant="contained"
                          size="large"
                          fullWidth
                          onClick={
                            () => setExpand(true)
                            // setLinks([...links, { id: uuidv4(), thumbnail: null, title: '', link: '' }])
                          }
                        >
                          {t('links.add.customLink.button')}
                        </Button>
                      )}
                      {expand && (
                        <Stack p={2}>
                          <FormProvider methods={methods} onSubmit={handleSubmit(onAddCustomLink)}>
                            <Stack direction="row" spacing={1}>
                              <RHFTextField
                                size="small"
                                name="url"
                                sx={{ flexGrow: 1 }}
                                placeholder={t('links.add.customLink.placeholder') as string}
                                // errors={errors}
                              />
                              <Button variant="contained" type="submit">
                                {t('general.add')}
                              </Button>
                            </Stack>
                          </FormProvider>
                          <Divider sx={{ mt: 4, mb: 2 }} />
                          <Stack>
                            <Stack>
                              <Typography variant="body2">{t('links.social.headline')}</Typography>
                            </Stack>
                            <Stack direction="row" width="100%" spacing={1} overflow="scroll" p={2}>
                              <SoMe
                                type={LinkType.FACEBOOK}
                                onClick={() => onAddLink(LinkType.FACEBOOK)}
                              />
                              <SoMe
                                type={LinkType.INSTAGRAM}
                                onClick={() => onAddLink(LinkType.INSTAGRAM)}
                              />
                              <SoMe
                                type={LinkType.TIKTOK}
                                onClick={() => onAddLink(LinkType.TIKTOK)}
                              />
                              <SoMe
                                type={LinkType.TWITTER}
                                onClick={() => onAddLink(LinkType.TWITTER)}
                              />
                              <SoMe
                                type={LinkType.SPOTIFY}
                                onClick={() => onAddLink(LinkType.SPOTIFY)}
                              />
                              <SoMe
                                type={LinkType.YOUTUBE}
                                onClick={() => onAddLink(LinkType.YOUTUBE)}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </Card>
                  </Stack>
                </Stack>
              </Fade>
              <Fade key="slide-edit-page" in={tab === 1}>
                <Stack display={tab === 1 ? 'flex' : 'none'} mt={3} spacing={4}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">{t('general.media.uploadLogo')}</Typography>
                    <UploadAvatar
                      accept={{
                        'image/*': [],
                      }}
                      file={linkLogo}
                      onDrop={handleLinkLogoChange}
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">{t('general.edit.name')}</Typography>
                    <TextField value={linkName} onChange={handleLinkNameChange} />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">
                      {t('general.colors.backgroundColor')}
                    </Typography>
                    <ColorBlockPicker hex={hex} setHex={setHex} />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1">
                      {t('general.pattern.backgroundPattern')}
                    </Typography>
                    <PatternSelect color={hex} pattern={pattern} setPattern={setPattern} />
                  </Stack>
                </Stack>
              </Fade>

              <Box flexGrow={1} pb={8} />
              <Stack
                justifyContent={'flex-end'}
                alignItems="flex-end"
                sx={{ position: 'sticky', zIndex: 2, right: 0, bottom: 16 }}
              >
                <Box>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    loading={submitting}
                    disabled={!isDirty || submitting}
                    onClick={handleSave}
                  >
                    {t('general.form.saveChanges')}
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <StyledGridItem
            item
            md={7}
            height={'calc(100vh - 64px)'}
            style={{ paddingLeft: '0px !important' }}
            sx={{
              position: 'relative',
              backgroundColor: 'grey.100',
              paddingLeft: '0px !important',
              pl: 0,
              pt: 0,
              alignItems: 'center',
            }}
          >
            <Stack sx={{ position: 'absolute', left: 24, top: 24 }}>
              <ToggleButtonGroup
                color="primary"
                value={option}
                exclusive
                // size="small"
                onChange={(_, newOption) => newOption && setOption(newOption)}
                aria-label="Platform"
              >
                <ToggleButton sx={{ px: 4 }} value={'DESKTOP'}>
                  Web
                </ToggleButton>
                <ToggleButton sx={{ px: 4 }} value={'MOBILE'}>
                  Mobile
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Slide key="slide-form" direction="up" in={option === 'DESKTOP'}>
              <Stack
                height="100%"
                alignItems={'center'}
                justifyContent={'center'}
                display={option === 'DESKTOP' ? 'flex' : 'none'}
              >
                <Card
                  key="form"
                  sx={{ overflow: 'hidden', m: 2, mt: 4, width: '90%', transform: 'scale(0.8)' }}
                >
                  <WebsiteBar />
                  <Stack
                    height={700}
                    sx={{
                      transform: 'translate3d(0,0,0)',
                      overflowY: 'scroll',
                      overflow: 'hidden',
                    }}
                  >
                    <LinksPage
                      linkPageName={linkName}
                      // @ts-ignore
                      linkPageLogo={linkLogo?.preview as string}
                      linkPageColor={hex}
                      linkPagePattern={pattern}
                      linkPageLinks={links}
                      preview
                    />
                  </Stack>
                </Card>
              </Stack>
            </Slide>
            <Slide key="slide-phone" direction="up" in={option === 'MOBILE'}>
              <Stack
                display={option === 'MOBILE' ? 'flex' : 'none'}
                height="100%"
                alignItems={'center'}
                justifyContent={'center'}
              >
                {/* <Card key="phone" sx={{ m: 2, width: '90%' }}> */}
                {/* <WebsiteBar /> */}
                <Stack height={600} justifyContent={'flex-end'}>
                  <PhonePlaceholder>
                    <Box
                      sx={{
                        position: 'absolute',
                        height: '160%',
                        top: '-24%',
                        // borderRadius: '8%',
                        overflow: 'hidden',
                        width: '66%',
                        transform: 'scale(0.6)',
                      }}
                    >
                      <LinksPage
                        linkPageName={linkName}
                        // @ts-ignore
                        linkPageLogo={linkLogo?.preview as string}
                        linkPageColor={hex}
                        linkPagePattern={pattern}
                        linkPageLinks={links}
                        preview
                      />
                    </Box>
                  </PhonePlaceholder>
                </Stack>
                {/* </Card> */}
              </Stack>
            </Slide>
          </StyledGridItem>
        </Grid>
      </Container>
    </Page>
  );
}

const StyledGridItem = styled(Grid)(({ theme }) => ({
  paddingTop: '0px !important',
  paddingLeft: '0px !important',
}));

function WebsiteBar() {
  const theme = useTheme();
  return (
    <Stack
      sx={{ px: 2, py: 1, backgroundColor: theme.palette.grey[300] }}
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      {/* <Typography>New e-mail</Typography> */}
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: '#E96E4C',
          }}
        />
        <Box
          sx={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: '#E6A935',
          }}
        />
        <Box
          sx={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: '#85C33D',
          }}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          height: 32,
          width: '100%',
          borderRadius: 1,
          backgroundColor: 'common.white',
        }}
      >
        <Box
          sx={{
            height: 12,
            width: '80%',
            borderRadius: 0.25,
            backgroundColor: 'grey.300',
          }}
        />
      </Box>
    </Stack>
  );
}

function SoMe({ type, onClick }: { type: LinkType; onClick: VoidFunction }) {
  // const theme = useTheme();
  const someConfig = getLinkTypeConfig(type);
  const transparent = alpha('#161618', 0.16);
  return (
    <Stack spacing={1} onClick={onClick}>
      <Box
        sx={{
          p: 1.5,
          borderRadius: 1,
          backgroundColor: 'grey.200',
          width: 80,
          height: 80,
          transition: 'all .2s ease-in-out',
          '&:hover': {
            boxShadow: `0 0px 16px 2px ${transparent}`,
            cursor: 'pointer',
          },
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            p: 1,
            borderRadius: 1,
            background: someConfig.background,
            height: '100%',
            width: '100%',
          }}
        >
          <Iconify icon={someConfig.icon} color="white" height={28} width={28} />
        </Stack>
      </Box>
      <Typography textAlign="center" fontSize={12}>
        {someConfig.name}
      </Typography>
    </Stack>
  );
}

export function getLinkTypeConfig(type: LinkType) {
  switch (type) {
    case LinkType.INSTAGRAM:
      return {
        background:
          'linear-gradient(315deg, #FBE18A 0.96%, #FCBB45 21.96%, #F75274 38.96%, #D53692 52.96%, #8F39CE 74.96%, #5B4FE9 100.96%)',
        icon: 'mdi:instagram',
        name: 'Instagram',
      };
    case LinkType.FACEBOOK:
      return { background: '#337FFF', icon: 'ri:facebook-fill', name: 'Facebook' };
    case LinkType.TIKTOK:
      return { background: '#0C0C0C', icon: 'logos:tiktok-icon', name: 'TikTok' };
    case LinkType.SPOTIFY:
      return { background: '#10BC4C', icon: 'mdi:spotify', name: 'Spotify' };
    case LinkType.TWITTER:
      return { background: '#33CCFF', icon: 'mdi:twitter', name: 'Twitter' };
    case LinkType.YOUTUBE:
      return { background: '#FF3000', icon: 'mdi:youtube', name: 'YouTube' };
    case LinkType.CUSTOM:
      return { background: '#FF3000', icon: 'mdi:youtube', name: 'YouTube' };
    case LinkType.REVIEW_COMPANY_PAGE:
      return { background: '#FF3000', icon: 'mdi:youtube', name: 'YouTube' };
    case LinkType.REVIEW_PAGE:
      return { background: REVIEW.lighter, icon: 'mdi:youtube', name: 'YouTube' };
    case LinkType.LEADS_PAGE:
      return { background: LEADS.main, icon: 'mdi:youtube', name: 'YouTube' };

    default:
      throw new Error(`Unknown type=${type}`);
  }
}
export function getLinkTypeDefaultTitle(type: LinkType, intl: Intl) {
  switch (type) {
    case LinkType.INSTAGRAM:
      switch (intl) {
        case Intl.DA:
          return 'Check min Instagram';
        default:
          return 'Checkout my Instagram';
      }
    case LinkType.FACEBOOK:
      switch (intl) {
        case Intl.DA:
          return 'Check min Facebook';
        default:
          return 'Checkout my Facebook';
      }
    case LinkType.TIKTOK:
      switch (intl) {
        case Intl.DA:
          return 'Check min TikTok';
        default:
          return 'Checkout my TikTok';
      }
    case LinkType.SPOTIFY:
      switch (intl) {
        case Intl.DA:
          return 'Check min Spotify';
        default:
          return 'Checkout my Spotify';
      }
    case LinkType.TWITTER:
      switch (intl) {
        case Intl.DA:
          return 'Check min Twitter';
        default:
          return 'Checkout my Twitter';
      }
    case LinkType.YOUTUBE:
      switch (intl) {
        case Intl.DA:
          return 'Check min YouTube';
        default:
          return 'Checkout my YouTube';
      }
    case LinkType.CUSTOM:
      switch (intl) {
        case Intl.DA:
          return 'Check min YouTube';
        default:
          return 'Checkout my YouTube';
      }
    default:
      return '';
  }
}
