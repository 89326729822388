import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestState, ReviewType } from '../../enum';
import { getRatingDecimalScore } from '../../utils/getRating';
import type { RootState } from '../store';

export enum ReviewFlagType {
  FAKE = 'FAKE',
  INAPPROPRIATE = 'INAPPROPRIATE',
  SPAM = 'SPAM',
  OTHER = 'OTHER',
}

export interface Review {
  id: string;
  title: string;
  description: string;
  rating: number;
  createTime: Date;
  avatar?: string;
  image?: {
    path: string;
    thumbnail: string;
  };
  type: ReviewType;
  approved?: boolean;
  reviewer: {
    id?: string;
    img?: string;
    name?: string;
  };
  inviteId: string | null;
  verified: boolean | null;
  videoPlaybackId?: string;
  videoProcessed?: boolean;
  thumbFrame?: number;
  flag?: ReviewFlagType;
}

export interface Stats {
  reviewAverage: number;
  reviewCount: number;
  reviewTotalScore: number;
  initRatingFactor: number;
  initRatingWeight: number;
}

// Define a type for the slice state
interface ReviewState {
  list: Review[];
  approvedList: Review[];
  loading: RequestState;
  approvedLoading: RequestState;
  stats: Stats;
  publicStats: Stats;
}

// Define the initial state using that type
const initialState: ReviewState = {
  list: [],
  approvedList: [],
  loading: RequestState.IDLE,
  approvedLoading: RequestState.IDLE,
  publicStats: {
    reviewAverage: 0,
    reviewCount: 0,
    reviewTotalScore: 0,
    initRatingFactor: 0,
    initRatingWeight: 0,
  },
  stats: {
    reviewAverage: 0,
    reviewCount: 0,
    reviewTotalScore: 0,
    initRatingFactor: 0,
    initRatingWeight: 0,
  },
};

export const reviewSlice = createSlice({
  name: 'reviews',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setReviewLoadingState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      loading: action.payload,
    }),
    setReviews: (state, action: PayloadAction<Review[]>) => ({
      ...state,
      list: action.payload,
      loading: RequestState.RESOLVED,
    }),
    setReview: (state, action: PayloadAction<Review>) => {
      // check if exists then update else add
      const reviewExists = state.list.find((review) => review.id === action.payload.id);
      if (reviewExists) {
        return {
          ...state,
          list: state.list.map((review) =>
            review.id === action.payload.id ? { ...review, ...action.payload } : review
          ),
          loading: RequestState.RESOLVED,
        };
      }
      return {
        ...state,
        list: [...state.list, action.payload],
        loading: RequestState.RESOLVED,
      };
    },
    setReviewStats: (state, action: PayloadAction<Stats>) => ({
      ...state,
      stats: action.payload,
    }),
    setPublicReviewStats: (state, action: PayloadAction<Stats>) => ({
      ...state,
      publicStats: action.payload,
    }),
    setApprovedReviewLoadingState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      approvedLoading: action.payload,
    }),
    setApprovedReviews: (state, action: PayloadAction<Review[]>) => ({
      ...state,
      approvedList: action.payload,
      approvedLoading: RequestState.RESOLVED,
    }),
  },
});

export const {
  setReview,
  setReviews,
  setReviewStats,
  setPublicReviewStats,
  setReviewLoadingState,
  setApprovedReviews,
  setApprovedReviewLoadingState,
} = reviewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectReviews = (state: RootState) => state.reviews.list;
export const hasNoReviews = (state: RootState) =>
  state.reviews.list.length < 1 && state.reviews.loading === RequestState.RESOLVED;
// divide by number of rating
export const selectTotalRating = (state: RootState) =>
  getRatingDecimalScore(state.reviews.stats.reviewAverage);

export const selectPublicTotalRating = (state: RootState) =>
  getRatingDecimalScore(state.reviews.publicStats.reviewAverage);

export const selectTotal = (state: RootState) => state.reviews.stats.reviewCount;

export const hasReviewSelector = (state: RootState) => state.reviews.stats.reviewCount > 0;

export const loadingReviewState = (state: RootState) =>
  state.reviews.loading === RequestState.IDLE || state.reviews.loading === RequestState.PENDING;

export default reviewSlice.reducer;
