import React from 'react';

export default function SimplyFlowLogo({ width = 84, color = '#333' }) {
  return (
    <svg viewBox="0 0 65 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width }}>
      <g clipPath="url(#clip0_1696_29885)">
        <path
          d="M25.8219 10.2201C25.545 10.3548 25.2508 10.4221 24.9566 10.4221H24.1087C23.7279 10.4221 23.3818 10.3379 23.0876 10.1696C22.7934 10.0012 22.5512 9.78232 22.3781 9.47925C22.205 9.17619 22.1012 8.82261 22.0839 8.41852H20.0938C20.1284 9.14251 20.3187 9.76548 20.6648 10.3211C21.0109 10.8767 21.4782 11.2977 22.0666 11.6007C22.655 11.9038 23.2953 12.0553 24.0221 12.0553H24.9047C25.6142 12.0553 26.2719 11.9206 26.8603 11.6344C27.4486 11.3482 27.9159 10.9441 28.262 10.4221C28.6081 9.90018 28.7812 9.29405 28.7812 8.58689C28.7812 7.77871 28.5908 7.13891 28.2274 6.66747C27.864 6.19603 27.414 5.84245 26.8949 5.62357C26.3757 5.40469 25.7008 5.18581 24.8874 4.96693C24.2817 4.81539 23.7972 4.66386 23.4684 4.52916C23.1395 4.39447 22.8627 4.2261 22.6377 3.99038C22.4127 3.75466 22.2916 3.45159 22.2916 3.08118C22.2916 2.77811 22.3608 2.50872 22.5165 2.28984C22.6723 2.07096 22.8627 1.90259 23.1222 1.76789C23.3645 1.65003 23.6414 1.58268 23.9183 1.58268H24.749C25.2508 1.58268 25.6662 1.71738 25.9777 2.00361C26.2892 2.28984 26.4795 2.71076 26.5488 3.26639H28.5216C28.4524 2.54239 28.2447 1.93626 27.8986 1.43115C27.5525 0.926037 27.1198 0.555622 26.5834 0.303067C26.0469 0.134696 25.4412 0 24.7836 0H23.901C23.2607 0 22.655 0.134696 22.1185 0.404089C21.582 0.673482 21.1321 1.06073 20.8033 1.54901C20.4745 2.03728 20.3014 2.60974 20.3014 3.24955C20.3014 4.00722 20.4918 4.61335 20.8552 5.06795C21.2186 5.52255 21.6513 5.85929 22.1704 6.07817C22.6896 6.29705 23.3472 6.51593 24.1606 6.73482C24.7663 6.92002 25.2508 7.07156 25.5969 7.20625C25.9431 7.34095 26.2199 7.52616 26.4622 7.77871C26.7045 8.01443 26.8256 8.33434 26.8256 8.72159C26.8256 9.092 26.7391 9.41191 26.5488 9.66446C26.3411 9.90018 26.1161 10.1022 25.8219 10.2201Z"
          fill={color}
        />
        <path d="M31.2543 3.43457H29.4199V11.9373H31.2543V3.43457Z" fill={color} />
        <path
          d="M34.1804 7.39121C34.1804 6.97028 34.2496 6.54935 34.4053 6.1621C34.5438 5.77485 34.7515 5.47178 35.011 5.23606C35.2706 5.00035 35.5475 4.88249 35.859 4.88249H36.4301C36.6897 4.88249 36.9147 4.96667 37.1223 5.1182C37.33 5.26974 37.4858 5.50546 37.5896 5.79169C37.6934 6.07792 37.7626 6.41466 37.7626 6.76824V11.9204H39.5797V7.37437C39.5797 6.93661 39.649 6.51568 39.8047 6.12843C39.9432 5.74118 40.1508 5.43811 40.4104 5.20239C40.67 4.98351 40.9469 4.86565 41.2584 4.86565H41.8122C42.0717 4.86565 42.314 4.94983 42.5044 5.1182C42.6948 5.28658 42.8505 5.50546 42.9716 5.79169C43.0928 6.07792 43.1447 6.39782 43.1447 6.76824V11.9204H44.9618V6.46517C44.9618 5.87587 44.8406 5.33709 44.6157 4.84881C44.3907 4.37738 44.0792 3.99012 43.6639 3.72073C43.2485 3.45134 42.7813 3.2998 42.2621 3.2998H41.6045C41.1545 3.2998 40.7046 3.4345 40.272 3.72073C39.8393 3.99012 39.5105 4.3437 39.2682 4.76463C39.0086 4.29319 38.7145 3.93961 38.351 3.68706C38.0049 3.4345 37.5377 3.31664 36.9493 3.31664H36.2917C35.8763 3.31664 35.461 3.45134 35.063 3.73757C34.6649 4.00696 34.3707 4.3437 34.1631 4.74779V3.46818H32.3633V11.9709H34.1804V7.39121Z"
          fill={color}
        />
        <path
          d="M52.3674 3.90594C51.8136 3.50185 51.1906 3.2998 50.4984 3.2998H49.9619C49.5639 3.2998 49.1485 3.41766 48.7332 3.65338C48.3179 3.8891 48.0064 4.17533 47.7641 4.51207V3.45134H45.9297V13.8735H47.7641V10.8933C47.9891 11.2132 48.3179 11.4994 48.7159 11.7352C49.1139 11.9709 49.512 12.0887 49.8927 12.0887H50.4292C51.156 12.0887 51.7963 11.8867 52.3674 11.4994C52.9212 11.1122 53.3711 10.5902 53.6826 9.91676C53.9941 9.24328 54.1499 8.50245 54.1499 7.69427C54.1499 6.90293 53.9941 6.17894 53.6653 5.50546C53.3711 4.83198 52.9385 4.31003 52.3674 3.90594ZM52.0213 9.1591C51.8309 9.59686 51.5713 9.9336 51.2598 10.1862C50.9483 10.4219 50.6195 10.5566 50.2734 10.5566H49.6677C49.287 10.5566 48.9409 10.4387 48.6294 10.1862C48.3179 9.95044 48.0756 9.6137 47.9025 9.17593C47.7295 8.73817 47.6256 8.24989 47.6256 7.69427C47.6256 7.13865 47.7122 6.65038 47.9025 6.21261C48.0756 5.77485 48.3179 5.43811 48.6294 5.20239C48.9409 4.96667 49.287 4.83198 49.6677 4.83198H50.2734C50.6195 4.83198 50.9483 4.94983 51.2598 5.20239C51.5713 5.43811 51.8309 5.79169 52.0213 6.22945C52.2116 6.66721 52.3155 7.15549 52.3155 7.69427C52.3155 8.23306 52.2116 8.72133 52.0213 9.1591Z"
          fill={color}
        />
        <path d="M56.6081 1.65039H54.791V11.9378H56.6081V1.65039Z" fill={color} />
        <path
          d="M63.1316 3.43457L61.3664 8.41833C61.2452 8.80559 61.1068 9.24335 60.9684 9.73162H60.9164C60.7953 9.24335 60.6569 8.80559 60.5184 8.4015L58.7186 3.43457H56.8496L59.6185 10.8429C59.705 11.0617 59.7743 11.247 59.8262 11.4153C59.8435 11.5164 59.8781 11.6174 59.8954 11.7352C59.9127 11.8531 59.9127 11.9541 59.9127 12.072C59.9127 12.3077 59.8608 12.5771 59.7743 12.8633L59.3243 13.8735H61.0895C61.176 13.7052 61.2452 13.52 61.3318 13.3179L65.0006 3.43457H63.1316Z"
          fill={color}
        />
        <path
          d="M20.3184 27.8653H22.2393V22.2754H28.1578V20.5917H22.2393V17.3253H28.4174V15.6416H20.3184V27.8653Z"
          fill={color}
        />
        <path d="M30.528 15.4561H28.7109V27.865H30.528V15.4561Z" fill={color} />
        <path
          d="M35.5804 18.8066H34.9747C32.725 18.8066 31.0117 20.7766 31.0117 23.4032C31.0117 26.0297 32.7077 27.9997 34.9747 27.9997H35.5977C37.8474 27.9997 39.5434 26.0297 39.5434 23.4032C39.5434 20.7766 37.8301 18.8066 35.5804 18.8066ZM35.615 26.3833H34.9574C33.7806 26.3833 32.8807 25.1037 32.8807 23.4032C32.8807 21.7026 33.7633 20.423 34.9574 20.423H35.615C36.7745 20.423 37.6917 21.7363 37.6917 23.4032C37.6917 25.07 36.7745 26.3833 35.615 26.3833Z"
          fill={color}
        />
        <path
          d="M48.1807 24.431C48.1461 24.5826 48.0941 24.7846 48.0422 24.9866C47.9903 24.7846 47.9384 24.5826 47.8865 24.431L46.1732 18.959H44.4426L42.7121 24.4479C42.6602 24.6162 42.6082 24.8183 42.5563 25.0203L42.4006 24.431L40.8258 18.959H38.9395L41.5699 27.8826H43.3351L45.1176 22.3769C45.1695 22.2085 45.2387 21.9897 45.2906 21.7708C45.3425 21.9897 45.4118 22.2085 45.4637 22.3937L47.2289 27.8826H48.9767L51.6245 18.959H49.7555L48.1807 24.431Z"
          fill={color}
        />
        <path
          d="M4.93322 7.93042L5.55622 7.32429C6.35228 6.54979 6.35228 5.30385 5.55622 4.54618L4.51789 3.53596C3.72183 2.76145 2.44121 2.76145 1.66245 3.53596L0.624116 4.54618C-0.171944 5.32068 -0.171944 6.56662 0.624116 7.32429L1.24712 7.93042L1.38556 8.06512C2.90846 9.63097 1.57593 11.1631 1.28173 11.4662L0.60681 12.1228C-0.189249 12.8974 -0.189249 14.1433 0.60681 14.901L1.64515 15.9112C2.44121 16.6857 3.72183 16.6857 4.50058 15.9112L5.53892 14.901C6.33498 14.1265 6.33498 12.8805 5.53892 12.1228L4.91592 11.5167C4.70825 11.3147 3.20266 9.71515 4.81208 8.06512L4.93322 7.93042Z"
          fill={color}
        />
        <path
          d="M10.2106 17.7803H8.7396C7.61474 17.7803 6.71484 18.6558 6.71484 19.7502V21.1814C6.71484 22.2758 7.61474 23.1513 8.7396 23.1513H10.2106C11.3355 23.1513 12.2353 22.2758 12.2353 21.1814V19.7502C12.2353 18.6558 11.3355 17.7803 10.2106 17.7803Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1696_29885">
          <rect width="65" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
