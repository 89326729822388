// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Typography,
  Stack,
  useTheme,
  IconButton,
  Tooltip,
  CardProps,
  IconButtonProps,
  Color,
  Palette,
  alpha,
  darken,
} from '@mui/material';
// utils
import cssStyles from '../utils/cssStyles';
import Iconify from './Iconify';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { getWidgetCode } from '../utils/getWidgetCode';
import { useSelector } from '../redux/store';
import { selectCurrentPlatformWorkspaceId } from '../redux/selectors/workspaces';
import { PaletteColor } from '@mui/material';

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 0.05, color: theme.palette.common.white }),
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
}));

interface StyledWidgetCardProps extends CardProps {
  selected?: boolean;
  comingSoon?: boolean;
}

export const StyledWidgetCard = styled(Card)<StyledWidgetCardProps>(
  ({ theme, selected, comingSoon }) => {
    const shadow = theme.shadows[10]; // '0 0 2px 0 rgb(117 1 165 / 10%), 0 12px 24px -4px rgb(117 1 165 / 12%)';
    return {
      textAlign: 'center',
      cursor: comingSoon ? 'not-allowed' : 'inherit',
      border: selected
        ? `1px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.grey[200]}`,
      boxShadow: selected ? shadow : 'none',
      opacity: comingSoon ? 0.5 : 1,
      '&:hover': {
        boxShadow: shadow,
      },
    };
  }
);

export const WidgetActionIconButton = styled(IconButton)<{ customColor?: PaletteColor }>(
  ({ theme, customColor }) => ({
    backgroundColor: customColor?.light || theme.palette.grey[200],
    '&:hover': {
      backgroundColor: customColor?.light
        ? darken(customColor?.light, 0.1)
        : theme.palette.grey[300],
    },
  })
);

// ----------------------------------------------------------------------

export default function WidgetCard({
  widget,
  onClick,
  onSelect,
  selected,
  stateLabel,
  comingSoon,
  goToImplementStep,
}: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId) as string;
  const { cover, title } = widget;
  const { enqueueSnackbar } = useSnackbar();
  const widgetCode = getWidgetCode(widget.type, { workspaceId, widgetId: widget.id });
  const onCopy = () => {
    if (widgetCode) {
      enqueueSnackbar(t('general.copied'));
    }
  };

  return (
    <StyledWidgetCard
      sx={{ height: '100%', cursor: 'pointer' }}
      selected={selected}
      comingSoon={comingSoon}
      onClick={onSelect || onClick}
    >
      <Box sx={{ position: 'relative' }}>
        {comingSoon && <OverlayStyle>{t('widgets.placeholder.comingsoon')}</OverlayStyle>}

        <img
          src={cover}
          alt={cover}
          style={{ objectFit: 'contain', minHeight: '100%', ...widget?.coverStyle }}
        />
        {selected && (
          <Box sx={{ zIndex: 12, right: 8, bottom: 0, position: 'absolute' }}>
            <Iconify
              icon={'ic:round-verified'}
              width={32}
              height={32}
              style={{
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                borderRadius: '50%',
                border: '1px solid #fff',
                // backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
              }}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ px: 2 }} />

      <Stack
        direction={'column'}
        // alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          // height: '100%',
          py: 2,
          // display: 'flex',
          px: 2,
          borderTop: `1px solid ${
            selected ? theme.palette.primary.light : theme.palette.grey[200]
          }`,
        }}
      >
        <Stack width="100%" flexDirection="row" justifyContent="space-between" pb={1.5}>
          <Typography variant="body1">{title}</Typography>
          {stateLabel}
        </Stack>

        <Stack direction="column" alignItems="flex-start" spacing={1}>
          <Stack flexDirection="row" gap={1} alignItems="center" onClick={onClick}>
            <Box>
              {widget?.id && (
                <WidgetActionIconButton customColor={theme.palette.primary} size="small">
                  <Iconify icon="solar:settings-outline" color="white" />
                </WidgetActionIconButton>
              )}
            </Box>
            <Typography variant="body2" color="text.secondary">
              {t('integrations.steps.settings')}
            </Typography>
          </Stack>
          <Stack
            role="button"
            flexDirection="row"
            gap={1}
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation();
              if (widgetCode) {
                onCopy();
              }
            }}
          >
            <Box>
              {goToImplementStep ? (
                <WidgetActionIconButton size="small" customColor={theme.palette.primary}>
                  <Iconify icon="ph:code-bold" color="white" />
                </WidgetActionIconButton>
              ) : (
                widgetCode && (
                  <CopyToClipboard text={widgetCode} onCopy={onCopy}>
                    <Tooltip title={t('integrations.steps.copy') as string}>
                      <WidgetActionIconButton size="small" customColor={theme.palette.primary}>
                        <Iconify icon="ph:code-bold" color="white" />
                      </WidgetActionIconButton>
                    </Tooltip>
                  </CopyToClipboard>
                )
              )}
            </Box>
            <Typography variant="body2" color="text.secondary">
              {t('integrations.steps.code')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </StyledWidgetCard>
  );
}
