import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Iconify from './Iconify';
import { TextField } from '@mui/material';

// // fake data generator
// const getItems = (count: number) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k}`,
//     content: `Question ${k} blablbabla`,
//   }));

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  display: 'flex',
  alignItems: 'center',
  margin: `0 0 ${grid}px 0`,

  opacity: isDragging ? 0.5 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // padding: grid,
  // width: 250,
});

export default class DraggableList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: props.initialItems,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.state.items, result.source.index, result.destination.index);

    this.setState({
      items,
    });
    this.props.onChange(items);
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item: any, index: number) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      // style={{ display: 'flex' }}
                    >
                      <div
                        {...provided.dragHandleProps}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Iconify icon="ic:baseline-drag-indicator" sx={{ height: 32, width: 32 }} />
                      </div>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Question..."
                        value={item.content}
                        onChange={(e) => {
                          const items = this.state.items.map((it: any) => {
                            if (it.id === item.id) {
                              return {
                                ...it,
                                content: e.target.value,
                              };
                            }
                            return it;
                          });
                          this.setState({ items });
                          this.props.onChange(items);
                        }}
                      />
                      {/* {item.content} */}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
