import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { resolvePlatformToProductCollection } from 'src/api';
import { Platforms } from 'src/redux/slices/platform';
import { useSnackbar } from 'notistack';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

export default function ChangeTrialStart({
  userId,
  product,
  trialStart,
}: {
  userId: string;
  product: Platforms;
  trialStart: Date;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const handleChange = async (newValue: Date | null) => {
    try {
      if (!newValue) {
        throw new Error('No date selected');
      }
      const collectionPath = resolvePlatformToProductCollection(product);
      if (!userId || !product || !collectionPath) return alert('Something is not working!');
      setLoading(true);

      const productRef = doc(collection(DB, collectionPath), userId);
      await setDoc(productRef, { trialStart: newValue }, { merge: true });

      enqueueSnackbar('Trail start updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });

      console.error(error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      <DatePicker
        disabled={loading}
        value={trialStart}
        format="dd/MM/yyyy"
        onChange={handleChange}
        // @ts-ignore TODO fix this
        renderInput={(params: any) => <TextField size="small" {...params} />}
      />
    </div>
  );
}
