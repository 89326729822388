import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import {
  Box,
  Link,
  Card,
  Stack,
  Paper,
  Avatar,
  Checkbox,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  AvatarGroup,
  InputAdornment,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
// @types
import { UserPost } from '../../../../@types/user';
// hooks
import useAuth from '../../../../hooks/useAuth';
// utils
import { fDate } from '../../../../utils/formatTime';
// components

import Iconify from '../../../../components/Iconify';
import MyAvatar from '../../../../components/MyAvatar';
import EmojiPicker from '../../../../components/EmojiPicker';
import { useTheme } from '@mui/material/styles';
import { Rating } from '@mui/material';
import ReactPlayer from 'react-player/lazy';
import VideoPlayer from '../../../../components/Review/VideoPlayer';
import CoverImage from '../../../../components/Review/CoverImage';
import SimplyVerifiedAvatar from '../../../../components/SimplyVerifiedAvatar';
import { addDoc, collection, doc, writeBatch } from 'firebase/firestore';
import { getCollection } from '../../../../api';
import { Collection, ReviewType } from '../../../../enum';
import { DB } from '../../../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import ReviewCommentsContainer from './ReviewCommentsContainer';
import HoverCard from '../../../../components/simply/Cards/HoverCard';
import FlatCard from '../../../../components/simply/Cards/FlatCard';
import { useTranslation } from 'react-i18next';
import ReviewItemVideo from '../../../../pages/reviews/ReviewItemVideo';
import DisplayVideo from '../../../../pages/reviews/DisplayVideo';

// ----------------------------------------------------------------------

export function ReviewerDetails({
  name,
  rating,
  textColor = 'inherit',
}: {
  name: string | React.ReactNode;
  rating: number;
  textColor?: string;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack>
      <Stack sx={{ pt: 4 }} direction="row" spacing={0.5} alignItems="center">
        <Typography variant="subtitle2" color={textColor}>
          {name}
        </Typography>
        <Tooltip title={t('general.verifiedBySimply') as string}>
          <Box sx={{ display: 'flex' }}>
            <Iconify
              icon={'ic:round-verified'}
              width={20}
              height={20}
              style={{
                color: theme.palette.primary.main,
                // backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
              }}
            />
          </Box>
        </Tooltip>
      </Stack>
      <Rating value={rating} precision={0.5} readOnly size="small" />
    </Stack>
  );
}

interface Props {
  // TODO: make review type
  post: any;
  userId: string | undefined;
  logo?: string;
  title?: string;
}

export default function ProfilePostCard({ post, userId, logo, title }: Props) {
  const { user } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation();

  // const theme = useTheme();
  const commentInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [toggleVideo, setToggleVideo] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const [isLiked, setLiked] = useState(post.isLiked);
  // const [likes, setLikes] = useState(post?.personLikes?.length);
  const [message, setMessage] = useState('');

  const handleChangeMessage = (value: string) => {
    setMessage(value);
  };

  // const handleClickAttach = () => {
  //   fileInputRef.current?.click();
  // };

  // const handleClickComment = () => {
  //   commentInputRef.current?.focus();
  // };

  const submitComment = async () => {
    try {
      if (!userId) throw new Error('user not defined');
      const batch = writeBatch(DB);

      batch.set(
        doc(DB, getCollection[Collection.USERS_REVIEWS](user?.id), post.id),
        {
          commentCount: post.commentCount ? post.commentCount + 1 : 1,
        },
        { merge: true }
      );

      batch.set(
        doc(collection(DB, getCollection[Collection.USERS_REVIEW_COMMENTS](userId, post.id))),
        {
          createTime: new Date(),
          message,
          author: {
            id: userId,
            avatar: logo || null,
            name: title || null,
          },
        }
      );
      await batch.commit();
      enqueueSnackbar(`Kommentar sendt til ${post.reviewer?.name}`);
      setMessage('');
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <FlatCard sx={{ borderColor: theme.palette.grey[200] /* height: '100%' */ }}>
      {/* <CardHeader
        disableTypography
        avatar={<SimplyVerifiedAvatar avatar={post?.reviewer?.img} name={post?.reviewer?.name} />}
        title={
          <Link to="#" variant="subtitle2" color="text.primary" component={RouterLink}>
            {post?.reviewer?.name}
          </Link>
        }
        subheader={
          <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
            {fDate(post.createTime?.toDate())}
          </Typography>
        }
        action={<Rating value={post.rating} precision={1} readOnly />}
      /> */}

      <Stack spacing={3} sx={{ p: 2, height: '100%' }}>
        {post.type === ReviewType.VIDEO && (
          <DisplayVideo
            rating={post?.rating}
            videoProcessed={post?.videoProcessed}
            videoPlaybackId={post?.videoPlaybackId}
            thumbFrame={post?.thumbFrame}
            reviewerName={post?.reviewer?.name}
          />
        )}
        {post.title && (
          <HoverCard sx={{ p: 2, height: '100%' }}>
            <Stack justifyContent="space-between">
              <Stack>
                {post.title && <Typography variant="h5">{post.title}</Typography>}
                {post.description && <Typography>{post.description}</Typography>}
              </Stack>
              <ReviewerDetails name={post?.reviewer?.name} rating={post.rating} />
            </Stack>
          </HoverCard>
        )}

        {/* <Image alt="post media" src={post.media} ratio="16/9" sx={{ borderRadius: 1 }} /> */}

        {/* <Stack direction="row" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="error"
                checked={isLiked}
                icon={<Iconify icon={'eva:heart-fill'} />}
                checkedIcon={<Iconify icon={'eva:heart-fill'} />}
                onChange={isLiked ? handleUnlike : handleLike}
              />
            }
            label={fShortenNumber(likes)}
            sx={{ minWidth: 72, mr: 0 }}
          />
          <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
            {post.personLikes?.map((person: any) => (
              <Avatar key={person.name} alt={person.name} src={person.avatarUrl} />
            ))}
          </AvatarGroup>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClickComment}>
            <Iconify icon={'eva:message-square-fill'} width={20} height={20} />
          </IconButton>
          <IconButton>
            <Iconify icon={'eva:share-fill'} width={20} height={20} />
          </IconButton>
        </Stack> */}

        <ReviewCommentsContainer
          userId={userId}
          reviewId={post.id}
          commentCount={post.commentCount}
        />

        {user?.id && (
          <Stack sx={{ mt: 1 }} direction="row" alignItems="center">
            <Avatar src={logo} />
            <TextField
              fullWidth
              size="small"
              value={message}
              multiline
              inputRef={commentInputRef}
              placeholder="Skriv en kommentar"
              onChange={(event) => handleChangeMessage(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EmojiPicker alignRight value={message} setValue={setMessage} />
                  </InputAdornment>
                ),
              }}
              sx={{
                ml: 2,
                mr: 1,
                '& .MuiOutlinedInput-input': {
                  fontSize: 14,
                },
                '& fieldset': {
                  borderWidth: `1px !important`,
                  borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
                },
              }}
            />
            <IconButton onClick={submitComment}>
              <Iconify icon={'ic:round-send'} width={24} height={24} />
            </IconButton>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
          </Stack>
        )}
      </Stack>
    </FlatCard>
  );
}
