import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
interface DrawerState {
  isOpen: boolean;
}

// Define the initial state using that type
const initialState: DrawerState = {
  isOpen: false,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDrawer: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setDrawer } = drawerSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectDrawer = (state: RootState) => state.drawer.isOpen;

export default drawerSlice.reducer;
