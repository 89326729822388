import React from 'react';
import { Box } from '@mui/material';
import Simply from '../../assets/Logo/simply';

export default function Logo({ width = 120, color }: { width?: number; color?: string }) {
  return (
    <Box sx={{ width, height: 30 }}>
      <Simply width={80} color={color} />
      {/* <img src={logo} alt="SimplyReviewLogo" style={{ width: 120 }} /> */}
    </Box>
  );
}
