import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { InviteStatus, Subscription, RequestState } from '../../enum';
import keyBy from 'lodash.keyby';
import { Timestamp } from 'firebase/firestore';

export interface Member {
  uid: string;
  email: string;
  displayName: string;
  status: InviteStatus;
}

export interface WorkspaceChats {
  uid: string;
  id: string;
  companyName?: string;
  members: {
    [key: string]: Member;
  };
  subscription: Subscription;
  trialStart: Timestamp;
  smsLimit: number;
}

// Define a type for the slice state
interface WorkpsaceLinksState {
  state: RequestState;
  currentWorkspace: WorkspaceChats | null;
  workspaceIds: string[];
  workspaceMap: { [key: string]: WorkspaceChats };
}

// Define the initial state using that type
const initialState: WorkpsaceLinksState = {
  state: RequestState.IDLE,
  currentWorkspace: null,
  workspaceIds: [],
  workspaceMap: {},
};

export const workspaceChatsSlice = createSlice({
  name: 'workspaceChats',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setWorkspaceChats: (
      state,
      action: PayloadAction<{ workspaces: WorkspaceChats[]; current?: string | null }>
    ) => {
      const workspaceMap = keyBy(action.payload.workspaces, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        currentWorkspace:
          action.payload.current && workspaceMap[action.payload.current]
            ? workspaceMap[action.payload.current]
            : action.payload.workspaces[0],
        workspaceIds: action.payload.workspaces.map((workspace) => workspace.id),
        workspaceMap,
        state: RequestState.RESOLVED,
      };
    },
    setCurrentWorkspaceChats: (state, action: PayloadAction<string>) => ({
      ...state,
      currentWorkspace: state.workspaceMap[action.payload],
    }),
  },
});

export const { setWorkspaceChats, setCurrentWorkspaceChats } = workspaceChatsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentWorkspaceMembers = (state: RootState) =>
  (state.workspaceChats.currentWorkspace?.members
    ? Object.keys(state.workspaceChats.currentWorkspace?.members).map(
        (key) => state.workspaceChats.currentWorkspace?.members[key]
      )
    : []) as Member[];

export const selectWorkspaces = (state: RootState) =>
  Object.keys(state.workspaceChats.workspaceMap).map(
    (key) => state.workspaceChats.workspaceMap[key]
  );
/**
 * Everyone has premium for now but only on trial
 * @param state
 * @returns
 */
export const hasLinksPremiumSelector = (state: RootState) =>
  state.workspaceChats.currentWorkspace?.subscription === Subscription.PREMIUM;

export const hasSmsLimitExceeded = (state: RootState) =>
  // @ts-ignore
  state.workspaceChats?.currentWorkspace?.smsLimit <= 0;
// state.WorkspaceChats.currentWorkspace?.subscription === Subscription.PREMIUM;

export const isLoadingWorkspaceChats = (state: RootState) =>
  state.workspaceChats.state === RequestState.IDLE ||
  state.workspaceChats.state === RequestState.PENDING;

export default workspaceChatsSlice.reducer;
