import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { DB } from 'src/contexts/FirebaseContext';
import { convertFStoDate } from 'src/utils/formatTime';

export default function useAdminAppMessages() {
  const [appMessages, setAppMessages] = React.useState<any[]>([]);

  useEffect(() => {
    let unsubscribe: () => void;
    const q = query(collection(DB, 'appMessages'), orderBy('createTime', 'desc'), limit(100));
    unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            createTime: convertFStoDate(data.createTime),
          };
        });

        setAppMessages(messages);
      },
      (error) => {
        console.error(error);
      }
    );

    return () => {
      unsubscribe?.();
    };
  }, []);
  return {
    appMessages,
  };
}
