import React from 'react';
import { useSelector } from 'src/redux/store';
import { selectHasStripeApi } from 'src/redux/slices/apiIntegrationsSlice';
import GoogleCalendars from './Calendars';
import ConnectGoogleCalendar from './Connect';
import StripeLogo from '../../../../../assets/integrations/stripe.png';
import { Card, Stack } from '@mui/material';
import Image from 'src/components/Image';

const Stripe: React.FC = () => {
  const calendar = useSelector(selectHasStripeApi);

  if (calendar) {
    return (
      <Wrapper>
        <GoogleCalendars />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <ConnectGoogleCalendar />
    </Wrapper>
  );
};

export default Stripe;

function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Stack alignItems="center" py={3}>
          <Image src={StripeLogo} alt="stripe" sx={{ height: 43 }} />
        </Stack>
        {children}
      </Stack>
    </Card>
  );
}
