import React from 'react';

export default function SimplySupportIcon({ width = 12, color = '#598FF8' }) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#FFBF42" />
      <path
        d="M12.7122 15.8764V16.9828C12.7122 17.8214 13.3921 18.5012 14.2306 18.5012H15.337C16.1756 18.5012 16.8554 17.8214 16.8554 16.9828V15.8764C16.8554 15.0378 16.1756 14.358 15.337 14.358H14.2306C13.3921 14.358 12.7122 15.0378 12.7122 15.8764Z"
        fill="#001D06"
      />
      <path
        d="M19.019 18.4986H20.1254C20.964 18.4986 21.6438 17.8188 21.6438 16.9802V15.8738C21.6438 15.0352 20.964 14.3554 20.1254 14.3554H19.019C18.1804 14.3554 17.5006 15.0352 17.5006 15.8738V16.9802C17.5006 17.8188 18.1804 18.4986 19.019 18.4986Z"
        fill="#001D06"
      />
      <path
        d="M8.18176 12.7684V13.8748C8.18176 14.7134 8.86156 15.3932 9.70014 15.3932H10.8065C11.6451 15.3932 12.3249 14.7134 12.3249 13.8748V12.7684C12.3249 11.9298 11.6451 11.25 10.8065 11.25H9.70014C8.86156 11.25 8.18176 11.9298 8.18176 12.7684Z"
        fill="#001D06"
      />
    </svg>
  );
}
