import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import { hex2rgb } from '../../utils/colors';

const BrandButton = styled(Button)<ButtonProps & { customColor: any; flatButton?: boolean }>(
  (props: any) => {
    const { theme, customColor, flatButton } = props;
    if (!customColor) {
      return {};
    }
    const [r, g, b] = hex2rgb(customColor);
    if (props.variant === 'contained') {
      return {
        color: theme.palette.getContrastText(customColor),
        backgroundColor: customColor,
        boxShadow: flatButton ? 'none' : `0 8px 16px 0 rgb(${r} ${g} ${b} / 24%)`,
        '&:hover': {
          backgroundColor: customColor,
        },
      };
    }
    if (props.variant === 'outlined') {
      return {
        color: customColor,
        borderColor: customColor,
        '&:hover': {
          borderColor: customColor,
          backgroundColor: flatButton ? 'none' : `rgba(${r}, ${g}, ${b}, 0.08)`,
        },
      };
    }
    return {
      color: customColor,
      // borderColor: customColor,
      '&:hover': {
        borderColor: customColor,
        backgroundColor: flatButton ? 'none' : `rgba(${r}, ${g}, ${b}, 0.08)`,
      },
    };
  }
);

export default BrandButton;
