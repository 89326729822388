import { Stack } from '@mui/system';
import React from 'react';
import image from './inc.png';
import useInvoice from 'src/hooks/useInvoice';

export default function Invoices() {
  const { isloading, invoice } = useInvoice();

  return (
    <Stack m={8}>
      <h1>Invoices</h1>
      <Stack direction="row">
        <Stack width="50%">
          {invoice &&
            invoice.entities.map((e: any) => (
              <div>
                {e.type}:{' '}
                {JSON.stringify(
                  e.normalizedValue?.text || e.properties[0]?.normalizedValue?.text || e.mentionText
                )}
              </div>
            ))}
        </Stack>
        <Stack width="50%" position="relative">
          {/* {invoice &&
            invoice.blocks.map((e: any) =>
              e.layout.boundingPoly.vertices.map((v: any) => (
                <div
                  style={{
                    position: 'absolute',
                    top: v.y,
                    left: v.x,
                    height: 4,
                    width: 4,
                    backgroundColor: 'red',
                  }}
                />
              ))
            )} */}
          {/* <img src={image} alt="placeholder" /> */}
          <ImageWithBoxes imageUrl={image} blocks={invoice?.blocks || []} />
        </Stack>
      </Stack>
    </Stack>
  );
}

function ImageWithBoxes({ imageUrl, blocks }: any) {
  // Extract bounding boxes from the Document AI response
  // const extractBoundingBoxes = () => {
  //   let boxes: any[] = [];
  //   if (blocks) {
  //     blocks.forEach((block: any) => {
  //       boxes.push(block.boundingPoly.vertices);
  //     });
  //   }
  //   return boxes;
  // };

  const boxes = blocks?.map((block: any) => block.layout.boundingPoly.vertices);

  return (
    <div style={{ position: 'relative' }}>
      <img src={imageUrl} alt="Document" style={{ width: '100%', height: 'auto' }} />
      <svg style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        {boxes?.map((box: any, index: number) => (
          <polygon
            key={index}
            points={box.map((vertex: any) => `${vertex.x / 2.91},${vertex.y / 2.93}`).join(' ')}
            // fill="none"
            stroke="red"
            strokeWidth="1"
            fill="red"
            fillOpacity="0.2"
          />
        ))}
      </svg>
    </div>
  );
}
