import React from 'react';
import { Platforms } from 'src/redux/slices/platform';
import { useSelector } from 'src/redux/store';
import { getColorsByPlatform } from 'src/theme/palette';

export default function BetaLabel({ platform }: { platform: Platforms }) {
  // const platform = useSelector((state) => state.user.platform);

  const colors = getColorsByPlatform(platform);

  return (
    <div
      style={{
        backgroundColor: colors.lighter,
        color: colors.darker,
        borderRadius: 4,
        fontSize: 10,
        padding: '0px 4px',
      }}
    >
      Beta
    </div>
  );
}
