import { useEffect } from 'react';
import { onSnapshot, query, collection } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch } from '../redux/store';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { setUsers, setError, setLoading, UserDocWithId } from '../redux/slices/usersSlice';

export default function useUsers() {
  const dispatch = useDispatch();
  useEffect(() => {
    let unsubscribe: () => void;

    dispatch(setLoading());
    const q = query(
      collection(DB, getCollection[Collection.USERS]())
      // where('verified', '==', true),
      // orderBy('createTime', 'desc'),
      // limit(100)
    );
    unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const users = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as UserDocWithId[];

        dispatch(setUsers(users));
      },
      (error) => {
        dispatch(setError(error.message));
      }
    );

    return () => {
      unsubscribe?.();
    };
  }, []);
}
