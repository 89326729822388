import React, { useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, where } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch } from '../redux/store';
import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import {
  setTeamInvites,
  TeamInviteStatus,
  TeamInvite,
  setTeamInvitesLoadingState,
} from '../redux/slices/teamInviteSlice';
import { ModalType, setModal } from '../redux/slices/modal';

export default function useTeamInvites() {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const email = user?.email;
  useEffect(() => {
    let unsubscribe: () => void;
    if (email) {
      dispatch(setTeamInvitesLoadingState());
      const q = query(
        collection(DB, getCollection[Collection.TEAM_INVITES]()),
        where('email', '==', email),
        where('status', '==', TeamInviteStatus.PENDING),
        orderBy('createTime', 'desc')
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const invites = querySnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              } as TeamInvite)
          );
          dispatch(setTeamInvites(invites));
          if (invites.length > 0) {
            dispatch(setModal({ type: ModalType.ACCEPT_INVITE_MEMBER, context: null }));
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [email]);
}
