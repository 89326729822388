import React from 'react';
import DummyReview from './DummyReview';
import { Grid } from '@mui/material';

export default function Container() {
  return (
    <div>
      <h1>Container</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={5}>
          <DummyReview />
        </Grid>
      </Grid>
    </div>
  );
}
