import useAuth from '../hooks/useAuth';
import { useSelector } from 'src/redux/store';
import { selectIsBundleUser } from 'src/redux/slices/stripeSlice';

export function SuperAdminUser({ children }: { children: JSX.Element }) {
  const { user } = useAuth();
  if (user?.email?.includes('niels@simplyreview.com')) {
    return children;
  }
  return null;
}
export default function AdminUser({ children }: { children: JSX.Element }) {
  const { user } = useAuth();
  if (user?.email?.includes('@simplyreview.com')) {
    return children;
  }
  return null;
}

// A BetaUser user is someone who is on the Simply Bundle OR Admins
export function BetaUser({ children }: { children: JSX.Element }) {
  const { user } = useAuth();
  const hasBundle = useSelector(selectIsBundleUser);

  if (user?.email?.includes('@simplyreview.com')) return children;

  if (hasBundle) return children;

  return null;
}
