import React, { useState, useContext, createContext } from 'react';

interface State {
  title: string | null;
  description: string | null;
  name: string | null;

  rating: number | null;
  video: File | null;
}

const defaultValues: State = {
  title: null,
  description: null,
  name: null,

  rating: null,
  video: null,
};

interface Context {
  tmpStorage: State;
  setTmpStorage: (data: Partial<State>) => void;
  resetTmpStorage: () => void;
}

export const TmpReviewDataContext = createContext<Context>({
  tmpStorage: defaultValues,
  setTmpStorage: () => {},
  resetTmpStorage: () => {},
});

export default function TmpReviewDataProvider({ children }: { children: React.ReactNode }) {
  const [tmpStorage, setTmpStorage] = useState<State>(defaultValues);

  const setPartialTmpStorage = (data: Partial<State>) => {
    setTmpStorage({ ...tmpStorage, ...data });
  };

  const resetTmpStorage = () => setTmpStorage(defaultValues);

  return (
    <TmpReviewDataContext.Provider
      value={{ tmpStorage, setTmpStorage: setPartialTmpStorage, resetTmpStorage }}
    >
      {children}
    </TmpReviewDataContext.Provider>
  );
}
