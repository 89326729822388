import { getContrastColor } from '@simplylabs_/shared';
import React, { useEffect, CSSProperties, useRef } from 'react';

function getWordSpacing(word: string, isFirst: boolean, isLast: boolean) {
  if (isFirst) {
    return <>{word}&nbsp;</>;
  }
  if (isLast) {
    return <>{word}&nbsp;</>;
  }
  return <>{word}&nbsp;</>;
}

export default function Word({
  highlightColor = '#183592',
  word,
  isHighlighted,
  isFirst,
  isLast,
}: {
  highlightColor?: string;
  word: string;
  isHighlighted: boolean;
  isFirst: boolean;
  isLast: boolean;
}) {
  const ref = useRef(null);
  const defaultStyles = {
    transition: 'all 0.1s',
  };

  const backgroundColor = highlightColor;
  const textColor =
    backgroundColor === 'transparent' ? 'inherit' : getContrastColor(backgroundColor, true);
  const styles = {
    backgroundColor: isHighlighted ? highlightColor : 'transparent',
    borderTopLeftRadius: isFirst ? 4 : 0,
    borderBottomLeftRadius: isFirst ? 4 : 0,
    borderTopRightRadius: isLast ? 4 : 0,
    borderBottomRightRadius: isLast ? 4 : 0,
    color: textColor,
  };
  //   React.useEffect(() => {
  //     setTimeout(() => {
  //
  //       setstyles(style);
  //       setTimeout(() => {
  //         setstyles({
  //           ...style,
  //           backgroundColor: 'transparent',
  //           color: 'inherit',
  //         });
  //       }, delay);
  //     }, aggregatedDelay);

  //     return () => {};
  //   }, []);

  //   useEffect(() => {
  //     if (ref?.current && styles.backgroundColor && styles.backgroundColor !== 'transparent') {
  //       // @ts-ignore
  //       console.log(styles.backgroundColor);
  //       // @ts-ignore
  //       ref.current.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'nearest',
  //       });
  //     }

  //     return () => {};
  //   }, [styles.backgroundColor]);

  return (
    <>
      <span
        style={{
          ...defaultStyles,
          position: 'relative',
          display: 'inline-block',
          zIndex: 1,
          color: styles.color,
        }}
        ref={ref}
      >
        <span
          style={{
            ...defaultStyles,
            ...styles,
            position: 'absolute',
            zIndex: -1,
            width: '100%',
            height: '100%',
            paddingLeft: 4,
            paddingRight: isLast ? 4 : 0,
            marginLeft: -4,
          }}
        />
        {getWordSpacing(word, isFirst, isLast)}
      </span>
    </>
  );
}
