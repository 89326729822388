import { useEffect } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where } from 'firebase/firestore';
import { Collection, InviteReminderStatus, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { convertFStoDate } from '../utils/formatTime';
import {
  InviteReminder,
  setInviteReminders,
  setInviteRemindersLoadingState,
} from 'src/redux/slices/inviteReminders';

// if passed here it will load X users reviews
export default function useInviteReminders() {
  const dispatch = useDispatch();
  const workspaceId = useSelector((state) => state.workspaceReview.currentWorkspace?.id);

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId) {
      dispatch(setInviteRemindersLoadingState());
      const q = query(
        collection(DB, getCollection[Collection.USERS_INVITES_REMINDERS](workspaceId)),
        where('status', '==', InviteReminderStatus.PENDING),
        orderBy('endDate', 'asc'),
        limit(100)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const invites = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            endDate: convertFStoDate(data.endDate),
          } as InviteReminder;
        });

        dispatch(setInviteReminders(invites));
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
}
