import { sentenceCase } from 'change-case';
import { useState, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Slide,
  Tooltip,
  Box,
  Stack,
  Chip,
  Divider,
  styled,
  TooltipProps,
  tooltipClasses,
  Alert,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
// sections
import { UserListHead } from '../../sections/@dashboard/user/list';
import { TransitionProps } from '@mui/material/transitions';
import Drawer from './Drawer';
import { useSelector } from '../../redux/store';
import { writeBatch, doc, setDoc } from 'firebase/firestore';
import { DB } from '../../contexts/FirebaseContext';
import { Collection } from '../../enum';
import { getCollection } from '../../api';
import { useTranslation } from 'react-i18next';
import SkeletonTableList from '../../components/skeleton/SkeletonTableList';
import FlatCard from '../../components/simply/Cards/FlatCard';
import { getInitials } from '../../utils/names';
import { useSnackbar } from 'notistack';
import {
  LeadType,
  loadingLeadFormSubmissionsState,
  selectLeadSubmissions,
} from 'src/redux/slices/leadFormsSubmissionSlice';
import LeadsToolbar from './LeadsToolbar';
import LeadStatusCell, { statuses } from './Status';
import CopyToClipboard from 'react-copy-to-clipboard';
import { loadingLeadFormState } from 'src/redux/slices/leadFormsSlice';
import ForwardZenfitLead from './ForwardZenfitLead';
import ActivityCell from './ActivityCell';

// ----------------------------------------------------------------------

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const TABLE_HEAD = [
  { id: 'name', label: 'invites.list.headers.name', alignRight: false },
  // { id: 'email', label: 'invites.list.headers.email', alignRight: false },
  // { id: 'phone', label: 'invites.list.headers.phone', alignRight: false },
  { id: 'contact', label: 'leadSubmissions.drawer.contact.headline', alignRight: false },
  { id: 'activity', label: 'leadSubmissions.list.headers.activity', alignRight: false },
  { id: 'date', label: 'invites.list.headers.date', alignRight: false },
  {
    id: 'status',
    label: 'invites.list.headers.status',
    alignRight: false,
    filterOptions: statuses,
  },

  // { id: 'status', label: 'Status', alignRight: false },
  // { id: '' },
  { id: 'zenfitApi', label: '' },
  { id: '', label: '' },
];

// ----------------------------------------------------------------------

export default function InviteList() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const hasZenfitLeadAPI = useSelector((state) => state.apiIntegrations.zenfitLeadIds.length > 0);
  const userId = useSelector((state) => state.workspaceLeads.currentWorkspace?.id);
  const submissions = useSelector(selectLeadSubmissions);

  const isLoadingLeadForm = useSelector(loadingLeadFormState);
  const isLoading = useSelector(loadingLeadFormSubmissionsState);
  const { themeStretch } = useSettings();
  // const [userList, setUserList] = useState(invites);

  const [selectedFilters, setSelectedFilters] = useState<(string | null | number)[]>([]);

  const [page, setPage] = useState(0);
  const [utmEnabled, setUtmEnabled] = useState(false);

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const [selected, setSelected] = useState<string[]>([]);

  const [orderBy, setOrderBy] = useState('date');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const [open, setOpen] = useState(false);

  const [openDrawer, setOpenDrawer] = useState<null | string>(null);

  // const handleClose = () => setOpen(false);

  const handleCloseDrawer = () => setOpenDrawer(null);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = submissions.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const deleteLeads = async (leadIds: string[]) => {
    if (!userId || leadIds.length < 1) return null;
    const batch = writeBatch(DB);
    leadIds.forEach((leadId) => {
      batch.delete(doc(DB, getCollection[Collection.LEADS_FORMS_SUBMISSIONS](userId), leadId));
    });

    await batch.commit();
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteLead = async (userId: string) => {
    if (!userId) return;
    await deleteLeads([userId]);
    setSelected([]);
    // setUserList(deleteUser);
  };

  const handleDeleteMultiLeads = async (selected: string[]) => {
    try {
      await deleteLeads(selected);
      setSelected([]);
      enqueueSnackbar(t('invites.deleteSuccess'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('invites.deleteError'), { variant: 'error' });
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - submissions.length) : 0;

  const filteredUsers = applySortFilter(submissions, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && Boolean(filterName);

  const handleSelectedFilters = (id: string | number | null) => {
    const actualId = id || undefined;
    // @ts-ignore
    if (selectedFilters.includes(actualId)) {
      setSelectedFilters(selectedFilters.filter((s) => s !== actualId));
    } else {
      // @ts-ignore
      setSelectedFilters((prev) => [...prev, actualId]);
    }
  };

  // if any submission has utms, enable utm column
  // @ts-ignore
  const hasUtm = submissions.some((s) => Object.keys(s?.utms || {}).length > 0);

  const onCopy = (email: string) => {
    if (email) {
      enqueueSnackbar(t('general.copied'));
    }
  };

  const FILTERED_TABLE_HEAD = TABLE_HEAD.filter((head) => {
    if (head.id === 'zenfitApi' && !hasZenfitLeadAPI) {
      return false;
    }
    return true;
  });

  return (
    <Page title="Leads: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Alert severity="info">
          👋 Are you using Zenfit? We are currently testing a new feature that allows you to sync
          leads to Zenfit. If you are interested in testing this feature, please reach out to us in
          the chat. (on the right of the screen)
        </Alert>
        <Typography variant="h4" gutterBottom>
          {t('leadSubmissions.list.inviteHeadline')}
        </Typography>

        <FlatCard>
          {!isLoadingLeadForm && (
            <LeadsToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDeleteUsers={() => handleDeleteMultiLeads(selected)}
              setUtmEnabled={setUtmEnabled}
              utmEnabled={utmEnabled}
              hasUtm={hasUtm}
            />
          )}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading && <SkeletonTableList sx={{ pl: 3, pr: 3 }} />}
              {!isLoading && (
                <Table size="small">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={FILTERED_TABLE_HEAD as any[]}
                    rowCount={submissions.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    selectedFilters={selectedFilters as number[]}
                    onSelectFilters={handleSelectedFilters}
                  />
                  <TableBody>
                    {filteredUsers
                      .filter((row) =>
                        selectedFilters.length > 0 ? selectedFilters.includes(row.status) : row
                      )
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          status,
                          name,
                          email,
                          phone,
                          isPhoneValid,
                          isEmailValid,
                          createTime,
                          scriptRecommendation,
                          type,
                          utms,
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isItemSelected} onClick={() => handleClick(id)} />
                            </TableCell>
                            <TableCell
                              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              onClick={() => setOpenDrawer(id)}
                            >
                              <Avatar alt={name} sx={{ mr: 2 }}>
                                {getInitials(name)}
                              </Avatar>

                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>

                              {utmEnabled && utms && (
                                <Stack
                                  direction="row"
                                  spacing={0.5}
                                  style={Object.keys(utms).length ? { marginLeft: 8 } : {}}
                                >
                                  {Object.entries(utms).map(([key, value]) => (
                                    <Tooltip title={key}>
                                      <Chip
                                        variant="outlined"
                                        label={value as string}
                                        size="small"
                                      />
                                    </Tooltip>
                                  ))}
                                </Stack>
                              )}
                            </TableCell>
                            <TableCell>
                              <Stack flexDirection="row">
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                  <CopyToClipboard text={email} onCopy={() => onCopy(email)}>
                                    <Tooltip title={email}>
                                      <IconButton size="small">
                                        <Iconify icon="ic:round-email" fontSize={24} />
                                      </IconButton>
                                    </Tooltip>
                                  </CopyToClipboard>

                                  {!isEmailValid && (
                                    <Tooltip title={t('leads.email.validation.warning')}>
                                      <Box style={{ marginLeft: -13, marginTop: -16, zIndex: 2 }}>
                                        <Iconify
                                          sx={{ alignSelf: 'center' }}
                                          fontSize={18}
                                          color="warning.main"
                                          icon="mdi:warning-octagon-outline"
                                        />
                                      </Box>
                                    </Tooltip>
                                  )}
                                </Stack>
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                  <CopyToClipboard text={phone} onCopy={() => onCopy(phone)}>
                                    <Tooltip title={phone}>
                                      <IconButton size="small">
                                        <Iconify icon="ic:round-phone" fontSize={24} />
                                      </IconButton>
                                    </Tooltip>
                                  </CopyToClipboard>

                                  {!isPhoneValid && (
                                    <Tooltip title={t('leads.phone.validation.warning')}>
                                      <Box style={{ marginLeft: -13, marginTop: -16, zIndex: 2 }}>
                                        <Iconify
                                          sx={{ alignSelf: 'center' }}
                                          fontSize={18}
                                          color="warning.main"
                                          icon="mdi:warning-octagon-outline"
                                        />
                                      </Box>
                                    </Tooltip>
                                  )}
                                </Stack>
                              </Stack>
                            </TableCell>

                            <TableCell
                              align="left"
                              sx={{ cursor: 'pointer', width: 200 }}
                              onClick={() => setOpenDrawer(id)}
                            >
                              <ActivityCell id={id} />
                            </TableCell>
                            <TableCell align="left">
                              {t('general.dateShort', { date: createTime })}
                            </TableCell>

                            <LeadStatusCell
                              id={id}
                              workspaceId={userId}
                              currentStatus={status}
                              key={`lead-status-${id}`}
                            />
                            {hasZenfitLeadAPI && (
                              <TableCell align="left" sx={{ width: 165, cursor: 'pointer' }}>
                                <ForwardZenfitLead leadId={id} />
                              </TableCell>
                            )}
                            <TableCell
                              align="left"
                              sx={{ width: 80, cursor: 'pointer' }}
                              onClick={() => setOpenDrawer(id)}
                            >
                              {/* <Tooltip title={scriptRecommendation}> */}
                              <Box sx={{ cursor: 'pointer' }}>
                                {/* <Label
                                  sx={{ cursor: 'pointer' }}
                                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                  color="primary"
                                > */}
                                <Iconify icon="ph:caret-right-bold" fontSize={20} />
                                {/* {t('leadSubmissions.list.viewResponse')} */}
                                {/* </Label> */}
                              </Box>
                              {/* </Tooltip> */}
                            </TableCell>

                            {/* <TableCell align="right">
                              <LeadItemMenu
                                onView={() => setOpenDrawer(id)}
                                onDelete={() => handleDeleteInvite(id)}
                                userName={name}
                              />
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={submissions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('general.pagination.label')}
          />
        </FlatCard>
      </Container>
      <Drawer
        id={openDrawer}
        onClose={handleCloseDrawer}
        onDeleteLead={() => handleDeleteLead(`${openDrawer}`)}
      />
    </Page>
  );
}

// ----------------------------------------------------------------------

type Anonymous = Record<string | number, string>;

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array: any[], comparator: (a: any, b: any) => number, query: string) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
