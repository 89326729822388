import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { RequestState } from '../../enum';
import keyBy from 'lodash.keyby';

export enum AutomationType {
  REVIEW_REMINDER = 'REVIEW_REMINDER',
}

export enum AutomationPlatform {
  ZENFIT = 'zenfit',
}

export interface AutomationConfig {
  reminderDays: number;
}

export interface Automation {
  id: string;
  createTime: Date;
  config: AutomationConfig;
  type: AutomationType;
  workspaceId: string;
  platform: AutomationPlatform;
  active: boolean;
}

// Define a type for the slice state
interface AutomationState {
  state: RequestState;
  list: Automation[];
  ids: string[];
  map: { [automationId: string]: Automation };
}

// Define the initial state using that type
const initialState: AutomationState = {
  state: RequestState.IDLE,
  list: [],
  ids: [],
  map: {},
};

export const automationSlice = createSlice({
  name: 'automations',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAutomations: (state, action: PayloadAction<Automation[]>) => {
      const map = keyBy(action.payload, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        ids: action.payload.filter(Boolean).map((automation) => automation.id),
        list: action.payload,
        map,
        state: RequestState.RESOLVED,
      };
    },
  },
});

export const { setAutomations } = automationSlice.actions;

export const selectAutomationLoadingState = (state: RootState) =>
  state.automations.state === RequestState.PENDING || state.automations.state === RequestState.IDLE;

export const selectZenfitAutomation = (state: RootState) =>
  state.automations.list.find((automation) => automation?.platform === AutomationPlatform.ZENFIT);

export default automationSlice.reducer;
