import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Divider, Stack } from '@mui/material';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import {
  Conversation,
  selectConversation,
  selectConversationParticipants,
} from '../../../redux/slices/chat';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
// import { Conversation, Participant, SendMessage } from '../../../@types/chat';
//
import ChatRoom from './ChatRoom';
import ChatMessageList from './ChatMessageList';
import ChatHeaderDetail from './ChatHeaderDetail';
import ChatMessageInput from './ChatMessageInput';
import ChatHeaderCompose from './ChatHeaderCompose';
import { Message } from 'src/hooks/useMessages';
import { arrayUnion, collection, doc, getFirestore, writeBatch } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

export default function ChatWindow() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { conversationKey = '' } = useParams();
  const activeConversationId = useSelector((state) => state.conversations.currentId);
  const user = useSelector((state) => ({
    uid: state.user.id as string,
    displayName: state.user.displayName,
    photoURL: state.user.photoURL,
  }));

  const participants = useSelector(selectConversationParticipants);
  const conversation = useSelector(selectConversation) as Conversation;

  const mode = conversationKey ? 'DETAIL' : 'COMPOSE';
  const displayParticipants = participants;
  const externalParticipants = displayParticipants.filter((p) => p.type === 'EXTERNAL');

  // useEffect(() => {
  //   const getDetails = async () => {
  //     dispatch(getParticipants(conversationKey));
  //     try {
  //       await dispatch(getConversation(conversationKey));
  //     } catch (error) {
  //       console.error(error);
  //       navigate(PATH_DASHBOARD.chat.new);
  //     }
  //   };
  //   if (conversationKey) {
  //     getDetails();
  //   } else if (activeConversationId) {
  //     dispatch(resetActiveConversation());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [conversationKey]);

  // useEffect(() => {
  //   if (activeConversationId) {
  //     dispatch(markConversationAsRead(activeConversationId));
  //   }
  // }, [dispatch, activeConversationId]);

  // const handleAddRecipients = (recipients: Participant[]) => {
  //   // dispatch(addRecipients(recipients));
  // };

  const handleSendMessage = async (value: /* SendMessage */ any) => {
    try {
      sendMessage({
        workspaceId: 'simplyLabs',
        conversationId: value.conversationId,
        user,
        message: {
          message: value.message,
          sender: {
            ...user,
          },
        },
        type: 'TEAM',
      });
      // dispatch(onSendMessage(value));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack sx={{ flexGrow: 1, minWidth: '1px' }}>
      <ChatHeaderDetail participants={externalParticipants} />
      {/* //  : (
    //   <ChatHeaderCompose
    //     recipients={recipients}
    //     contacts={Object.values(contacts.byId)}
    //     onAddRecipients={handleAddRecipients}
    //   />
    // ) */}

      <Divider />

      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <ChatMessageList conversation={conversation} />

          <Divider />

          <ChatMessageInput
            workspaceId="simplyLabs"
            conversationId={activeConversationId}
            onSend={handleSendMessage}
            disabled={pathname === PATH_DASHBOARD.chat.new}
          />
        </Stack>

        {/* {mode === 'DETAIL' && (
          <ChatRoom conversation={conversation} participants={displayParticipants} />
        )} */}
      </Box>
    </Stack>
  );
}

export async function sendMessage({
  workspaceId,
  conversationId,
  user,
  message,
  type,
}: {
  workspaceId: string;
  conversationId: string;
  user: { uid: string; displayName: string | null; photoURL: string | null };
  message: Omit<Message, 'id' | 'createTime' | 'updateTime'>;
  type: 'TEAM' | 'EXTERNAL';
}) {
  try {
    if (!workspaceId || !conversationId || !message.message) {
      throw new Error('Invalid parameters');
    }

    const conversationRef = doc(DB, 'workspaceChats', workspaceId, 'conversations', conversationId);
    const messageRef = doc(
      collection(DB, 'workspaceChats', workspaceId, 'conversations', conversationId, 'messages')
    );

    const batchWrite = writeBatch(DB);

    batchWrite.set(messageRef, {
      ...message,
      createTime: new Date(),
      updateTime: new Date(),
    });

    batchWrite.set(
      conversationRef,
      {
        updateTime: new Date(),
        latestMessage: {
          uid: user.uid,
          content: message.message,
        },
        participants: {
          [user.uid]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
            type,
          },
        },
        participantsList: arrayUnion(user.uid),
      },
      { merge: true }
    );

    await batchWrite.commit();
  } catch (error) {
    console.error(error);
  }
}

export async function createConversation({
  workspaceId,
  user,
  message,
  type,
}: {
  workspaceId: string;
  user: { uid: string; displayName: string | null; photoURL: string | null };
  message: Omit<Message, 'id' | 'createTime' | 'updateTime'>;
  type: 'TEAM' | 'EXTERNAL';
}): Promise<string | null> {
  try {
    if (!workspaceId || !message.message) {
      throw new Error('Invalid parameters');
    }

    const conversationRef = doc(collection(DB, 'workspaceChats', workspaceId, 'conversations'));
    const messageRef = doc(
      collection(DB, 'workspaceChats', workspaceId, 'conversations', conversationRef.id, 'messages')
    );

    const batchWrite = writeBatch(DB);
    batchWrite.set(
      conversationRef,
      {
        createTime: new Date(),
        updateTime: new Date(),
        latestMessage: {
          uid: user.uid,
          content: message.message,
        },
        participants: {
          [user.uid]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL ?? null,
            type,
          },
        },
        participantsList: arrayUnion(user.uid),
      },
      { merge: true }
    );
    batchWrite.set(messageRef, {
      ...message,
      createTime: new Date(),
      updateTime: new Date(),
    });

    await batchWrite.commit();
    return conversationRef.id;
  } catch (error) {
    console.error(error);
    return null;
  }
}
